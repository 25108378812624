<template>
  <div class="px-10 py-5" v-if="!loading">
    <h1 class="warning--text">
      Reports
      <v-btn
        :disabled="userProfile.role === 'trial' && userProfile.trialEnded"
        elevation="0"
        class="mb-1 ml-4"
        color="success"
        @click="$store.commit('setReportWizard', true)"
        ><v-icon small>mdi-plus</v-icon> New report</v-btn
      >
      <v-btn
        v-if="userProfile.role === 'trial' && userProfile.trialEnded"
        elevation="0"
        class="mb-1 ml-4"
        color="error"
        style="text-transform: none"
        @click="$router.push('/subscription')"
        >Upgrade</v-btn
      >
    </h1>

    <div class="reports">
      <NoReport
        @newReport="$emit('report', true)"
        v-if="reports.length === 0"
      />
      <v-card
        width="300"
        variant="flat"
        @click="openReport(report.uid)"
        v-for="report in reports"
        :key="report.uid"
      >
        <div
          v-if="report.status === 'requested'"
          class="grey lighten-2"
          style="height: 168.75px"
        ></div>
        <v-img
          v-if="report && report.status === 'done' && report.heatmapObjects[0]"
          :aspect-ratio="16 / 9"
          :src="report.heatmapObjects[0].imageURL"
        >
        </v-img>
        <v-card-text class="pt-6" style="position: relative">
          <p class="text-h6 warning--text mb-0">{{ report.title }}</p>
          <p class="subtitle">
            <span v-if="report.type === 'ooh'"
              ><v-icon class="mr-1">mdi-billboard</v-icon> Out-Of-Home</span
            >
            <span v-if="report.type === 'display'"
              ><v-icon class="mr-1">mdi-monitor</v-icon> Display Ads</span
            >
            <span v-if="report.type === 'social'"
              ><v-icon class="mr-1">mdi-facebook</v-icon> Social media</span
            >
          </p>

          <v-chip-group column>
            <v-chip>
              <v-avatar left>
                <b>{{ report.heatmaps.length }}</b>
              </v-avatar>
              Ads
            </v-chip>

            <v-chip>
              <v-avatar left>
                <b>{{ report.ids.length }}</b>
              </v-avatar>
              Templates
            </v-chip>
          </v-chip-group>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions class="d-flex justify-space-between">
          <v-progress-linear
            rounded
            color="grey"
            indeterminate
            v-if="report.status === 'requested'"
            class="my-4 mx-4"
          ></v-progress-linear>

          <v-btn text v-if="report.status === 'done'"> Full Report </v-btn>
          <v-btn icon @click.stop="deleteItem(report.uid)"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase";
import NoReport from "@/components/no-report/NoReport.vue";
import { mapState } from "vuex";

export default {
  name: "ReportsPage",
  components: {
    NoReport,
  },
  data() {
    return {
      menu: [
        {
          title: "Share",
          icon: "mdi-share",
        },
        {
          title: "Download",
          icon: "mdi-download",
        },
        {
          title: "Delete",
          icon: "mdi-delete",
        },
      ],
      selected: [],
      types: [
        "Out-Of-Home",
        "Display ads",
        "UX Design",
        "YouTube Thumbnail",
        "Print",
      ],
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      users: (state) => state.users.users,
      workspace: (state) => state.workspace.workspace,
      heatmaps: (state) => state.heatmaps.heatmaps,
      projects: (state) => state.projects.projects,
      reports: (state) => state.reports.reports,
      tags: (state) => state.heatmaps.tags,
      defaults: (state) => state.defaults.defaults,
      subscription: (state) => state.subscription.subscription,
    }),
  },
  methods: {
    deleteItem(uid) {
      let r = confirm("Are you sure?!");
      if (r === true) {
        const vm = this;
        db.collection("reports")
          .doc(uid)
          .delete()
          .then(() => {
            console.log("Document successfully deleted!");
            vm.$store.dispatch("fetchReports");
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
      }
    },
    openReport(uid) {
      if (this.reports.find((r) => r.uid === uid).status === "done") {
        this.$router.push(`/report/${uid}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bar {
  display: flex;
  gap: 30px;
  align-items: flex-end;
  .v-chip {
    padding: 18px;
    margin-bottom: 0px;
  }
}

.reports {
  display: flex;
  gap: 30px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.score {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
