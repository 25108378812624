<template>
  <div v-if="userCopy">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="accent py-5 white--text">
          <span class="text-h5">User profile</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="mt-4 row">
              <v-col
                cols="12"
                sm="12"
                style="margin: 0; padding-bottom: 0; padding-top: 0"
                ><p class="group">Personal details</p></v-col
              >
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="First name"
                  required
                  v-model="userCopy.firstName"
                  hide-details=""
                  filled
                  rounded
                  :readonly="userProfile.role === 'member'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  filled
                  rounded
                  label="Last name"
                  hide-details=""
                  required
                  v-model="userCopy.lastName"
                  :readonly="userProfile.role === 'member'"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email (not editable)"
                  hide-details=""
                  required
                  rounded
                  filled
                  v-model="userCopy.email"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Phone number"
                  :readonly="userProfile.role === 'member'"
                  hide-details=""
                  required
                  rounded
                  filled
                  v-model="userCopy.phone"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="userProfile.superadmin"
                cols="12"
                sm="12"
                style="margin: 0; padding-bottom: 0"
                ><p class="group">Account</p></v-col
              >
              <v-col cols="12" sm="6" v-if="userProfile.superadmin">
                <v-select
                  label="Role"
                  v-model="userCopy.role"
                  filled
                  :items="$store.state.defaults.users.roles"
                  v-if="$store.state.defaults.users.roles.length > 0"
                  rounded
                  hide-details=""
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" v-if="userProfile.superadmin">
                <v-autocomplete
                  :items="workspaces"
                  hide-details=""
                  item-value="uid"
                  item-text="name"
                  v-model="userCopy.workspace"
                  label="Workspace"
                  filled
                  rounded
                  clearable
                  @change="checkWorkspace"
                ></v-autocomplete>
              </v-col>
              <v-col
                v-if="userCopy.role === 'trial'"
                cols="12"
                sm="12"
                style="margin: 0; padding-bottom: 0"
                ><p class="group">Trial</p></v-col
              >
              <v-col cols="12" sm="6" v-if="userCopy.role === 'trial'">
                <v-text-field
                  :value="getDate(userCopy.trialStart)"
                  label="Trial Start (not editable)"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  filled
                  rounded
                  hide-details=""
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-if="userCopy.role === 'trial'">
                <v-text-field
                  :value="getDate(userCopy.trialEnd)"
                  label="Trial End (change below)"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  filled
                  rounded
                  hide-details=""
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3"></v-col>
              <v-col
                cols="12"
                sm="3"
                v-if="userCopy.role === 'trial' && userProfile.superadmin"
              >
                <v-text-field
                  filled
                  rounded
                  label="Days"
                  hide-details=""
                  type="number"
                  v-model="days"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                v-if="userCopy.role === 'trial' && userProfile.superadmin"
              >
                <v-btn
                  rounded
                  color="warning"
                  elevation="0"
                  fab
                  v-if="days"
                  @click="addDays"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
                <v-btn
                  rounded
                  color="warning"
                  elevation="0"
                  class="ml-3"
                  fab
                  v-if="days"
                  @click="minDays"
                  ><v-icon>mdi-minus</v-icon></v-btn
                >
              </v-col>
              <v-col v-if="importantChanges" cols="12" sm="12"
                ><v-alert type="info" class="warning--text"
                  >Attention: You're changing the workspace of this user. This
                  user will not see any heatmaps of the previous workspace
                  anymore.</v-alert
                ></v-col
              >
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="close"> Close </v-btn>
          <v-btn
            color="warning"
            v-if="userProfile.role === 'admin'"
            text
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { db } from "@/firebase";
import { mapState } from "vuex";

export default {
  props: {
    workspaces: {
      type: Array,
    },
    user: {
      type: Object,
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      defaults: (state) => state.defaults.defaults,
    }),
  },
  data() {
    return {
      days: 1,
      date: "",
      date2: "",
      importantChanges: false,
      loading: true,
      menu: false,
      menu2: false,
      userCopy: { ...this.user },
    };
  },
  methods: {
    addDays() {
      this.userCopy.trialEnd.seconds =
        this.userCopy.trialEnd.seconds + 86400 * this.days;
    },
    checkWorkspace(val) {
      if (this.userCopy.workspace != "") {
        this.userCopy.role = "member";
      } else {
        this.userCopy.role = "trial";
      }
      this.importantChanges = this.userCopy.workspace != val;
    },
    close() {
      this.dialog = false;
      this.days = null;
    },
    getDate(d) {
      const date = d.toDate();
      return moment(date).format("DD-MM-YYYY");
    },
    minDays() {
      this.userCopy.trialEnd.seconds =
        this.userCopy.trialEnd.seconds - 86400 * this.days;
    },
    save() {
      const vm = this;
      let end = "";
      if (vm.userCopy.trialEnd) {
        end = vm.userCopy.trialEnd;
      }
      var userRef = db.collection("users").doc(vm.user.uid);
      userRef
        .set(
          {
            firstName: vm.userCopy.firstName,
            lastName: vm.userCopy.lastName,
            role: vm.userCopy.role,
            workspace: vm.userCopy.workspace,
            trialEnd: end,
            phone: vm.userCopy.phone,
            name: vm.userCopy.firstName.concat(" ", vm.userCopy.lastName),
          },
          { merge: true }
        )
        .then(() => {
          this.dialog = false
          vm.days = null;
          vm.$emit("updateSnackbar", { text: "Data saved", type: "success" });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  color: #8b78fa;
  margin: 0;
}
.row {
  .v-btn {
    text-transform: none;
    font-weight: 600;
    font-size: 12pt;
  }
}
</style>
