<template>
  <div class="login-wrapper">
    <div class="left">
      <div class="logo">
        <img src="@/assets/brand/full_logo.svg" height="40" alt="" />
      </div>
      <div>
        <h1 v-if="!workspaceName">Sign up <span>&#9996;&#65039;</span></h1>
        <h1 v-else>
          <span class="font-weight-regular">Sign up to join</span>
          {{ workspaceName }}<span>&#9996;&#65039;</span>
        </h1>

        <p class="subtitle">
          Already have an account?
          <u
            v-if="!workspace && !interested_in"
            style="cursor: pointer"
            @click="$router.push('/login')"
            >Go here</u
          >
          <u
            v-if="workspace"
            style="cursor: pointer"
            @click="
              $router.push(
                `/login?workspace=${workspace}&workspaceName=${workspaceName}`
              )
            "
            >Go here</u
          >
          <u
            v-if="interested_in"
            style="cursor: pointer"
            @click="$router.push(`/login?sub=${interested_in}`)"
            >Go here</u
          >
        </p>
        <div class="google" @click="google">
          <img src="@/assets/google.png" alt="" />
          <span>Sign up with Google</span>
        </div>

        <div class="divider">
          <hr />
          <span>or sign up with email</span>
          <hr />
        </div>
        <div class="form">
          <div class="d-flex" style="gap: 10px">
            <v-text-field
              rounded
              variant="solo"
              flat
              label="First name"
              bg-color="white"
              type="email"
              hide-details="true"
              class="mb-5"
              v-model="firstName"
            ></v-text-field>
            <v-text-field
              label="Last name"
              bg-color="white"
              type="email"
              hide-details="true"
              class="mb-5"
              rounded
              variant="solo"
              flat
              v-model="lastName"
            ></v-text-field>
          </div>
          <v-text-field
            label="Email"
            bg-color="white"
            type="email"
            hide-details="true"
            class="mb-5"
            rounded
            variant="solo"
            flat
            v-model="email"
          ></v-text-field>
          <v-text-field
            label="Password"
            bg-color="white"
            type="password"
            hide-details="true"
            rounded
            variant="solo"
            flat
            v-model="password"
          ></v-text-field>
          <v-btn
            @click="signup"
            block
            rounded
            elevation="0"
            height="56"
            color="success"
            class="mt-5"
            :disabled="!password || !email || !firstName || !lastName"
            >Sign up!</v-btn
          >
          <p class="error--text mt-4" v-if="useError">
            This email already has an account.
            <u @click="$router.push('/login')" style="cursor: pointer"
              >Click here</u
            >
            to login.
          </p>
          <p class="error--text mt-4" v-if="nameError">
            Please fill in your first and last name.
          </p>
        </div>
      </div>
      <div class="terms">
        <p>
          By signing in or creating an account, you agree with our
          <u @click="$router.push('/terms')" style="cursor: pointer"
            >terms and conditions</u
          >
          and
          <u @click="$router.push('/privacy')" style="cursor: pointer"
            >privacy policy</u
          >.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/";
import * as fb from "@/firebase";
const axios = require("axios");
import { db } from "@/firebase";
import { mapState } from "vuex";

export default {
  name: "SignUp",
  data() {
    return {
      email: "",
      name: "",
      firstName: "",
      keyword: "",
      lastName: "",
      password: "",
      interested_in: "",
      useError: false,
      nameError: false,
      workspace: null,
      workspaceName: "",
      role: "trial",
      source: "",
    };
  },
  mounted() {
    if (this.$route.query.workspace) {
      this.workspace = this.$route.query.workspace;
      this.workspaceName = this.$route.query.workspaceName;
      this.role = "member";
    }
    if (this.$route.query.sub) {
      this.interested_in = this.$route.query.sub;
    }
    if (this.$route.query.key) {
      this.keyword = this.$route.query.key;
    }
    if (this.$route.query.source) {
      this.source = this.$route.query.source;
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      defaults: (state) => state.defaults.defaults,
    }),
  },
  methods: {
    async join(uid, email, verified) {
      this.$store.commit("setLoading", true);
      var seats = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("seatsAvailable");
      const res = await seats({ workspace: this.workspace });

      if (res.data) {
        const vm = this;
        this.$store.dispatch({
          type: "acceptInvite",
          uid: uid,
          workspace: vm.workspace,
          email: email,
          emailVerified: verified,
        });
      } else {
        this.$emit("error", {
          text: "All user seats are taken in this workspace.",
          dialog: true,
        });
        this.$store.commit("setLoading", false);
      }
    },
    async google() {
      const provider = new firebase.auth.GoogleAuthProvider();
      const vm = this;
      await firebase
        .auth()
        .signInWithPopup(provider)
        .then(async function (result) {
          const user = result.user;
          if (result.additionalUserInfo.isNewUser) {
            console.log("Sign up: new user");
            let image = null;
            let dataUri = null;
            try {
              image = await axios.get(result.user.photoURL, {
                responseType: "arraybuffer",
              });
              const avatar = Buffer(image.data, "binary").toString("base64");
              var prefix = "data:" + image.headers["content-type"] + ";base64,";
              dataUri = prefix + avatar;
            } catch (error) {
              console.error(error);
            }

            const name = result.user.displayName;
            const nameArray = name.split(" ");
            let firstName = nameArray[0];
            nameArray.splice(0, 1);

            var endDate = new Date();
            const days = vm.defaults.trial.days;
            endDate.setDate(endDate.getDate() + days);

            fb.usersCollection
              .doc(user.uid)
              .set({
                firstName: firstName,
                lastName: nameArray.join(" "),
                name: result.user.displayName,
                uid: user.uid,
                workspace: vm.workspace,
                email: user.email,
                avatar: dataUri,
                keyword: vm.keyword,
                interested_in: vm.interested_in,
                role: vm.role,
                intro: false,
                phone: "",
                source: vm.source,
                trialStart: new Date(),
                trialEnd: endDate,
                imageHeatmaps: 0,
                imageCredits: vm.workspace ? 0 : vm.defaults.trial.images,
                videoCredits: vm.workspace ? 0 : vm.defaults.trial.videos,
                videoHeatmaps: 0,
                visitedSubscriptions: 0,
              })
              .then(async () => {
                if (!vm.workspace) {
                  var checkInviteForWorkspace = firebase
                    .app()
                    .functions("europe-west1")
                    .httpsCallable("checkInviteForWorkspace");
                  const res = await checkInviteForWorkspace({
                    email: user.email,
                    workspace: vm.workspace ? vm.workspace : "",
                  });
                  console.log("Checking invites");
                  console.log(res);
                  if (res.data.available && res.data.workspace) {
                    vm.workspace = res.data.workspace;
                  }
                }
              })
              .then(() => {
                if (vm.workspace) {
                  var workspaceRef = db
                    .collection("workspaces")
                    .doc(vm.workspace);
                  workspaceRef.update({
                    members: firebase.firestore.FieldValue.arrayUnion(user.uid),
                    seats: firebase.firestore.FieldValue.increment(-1),
                  });
                }
              })
              .then(() => {
                console.log("dispatch userProfile");
                vm.$store.dispatch("fetchUserProfile", user);
              })
              .catch((error) => {
                console.error("Error setting document: ", error);
              });
          } else {
            console.log("Log in: existing user");
            if (vm.workspace) {
              vm.join(user.uid, user.email, user.emailVerified);
            }
          }
        });
    },
    async signup() {
      if (this.fistName === "" || this.lastName === "") {
        this.nameError = true;
      } else {
        this.nameError = false;
        let workspace = this.workspace;
        let name = this.firstName.concat(" ", this.lastName);

        // TODO: IF WORKSPACE IS PROVIDED, CHECK IF IT HAS SEATS AVAILABLE

        this.$store
          .dispatch("signup", {
            email: this.email,
            password: this.password,
            lastName: this.lastName,
            name: name,
            firstName: this.firstName,
            workspace: workspace,
            role: workspace ? "member" : "trial",
            interested_in: this.interested_in,
            source: this.source,
            keyword: this.keyword,
          })
          .catch((err) => {
            console.log(err);
            console.log("already in use");
            this.useError = true;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  display: flex;
  color: #282447;

  .left {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    width: 60%;
    max-width: 400px;

    .logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        margin-bottom: 5px;
      }
    }
    .subtitle {
      font-size: 14pt;
    }

    .google {
      margin-top: 30px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: white;
      padding: 13px 0;
      border-radius: 30px;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 0px 3px rgba(209, 219, 229, 1);
      }
      img {
        width: 30px;
      }
    }

    .divider {
      margin-top: 30px;
      margin-bottom: 30px;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr 1fr;
      span {
        text-align: center;
        font-size: 10pt;
      }
    }
    .forgot {
      color: #8b78fa;
      margin-top: 10px;
      text-align: end;
    }

    .v-btn {
      text-transform: none;
      font-weight: 600;
      font-size: 12pt;
    }

    .terms {
      text-align: center;
    }
  }

  .right {
    background-color: #282447;
  }
}
</style>
