<template>
  <v-snackbar
    @input="change"
    :timeout="uploading.timeout"
    app
    :model-value="uploading.show"
    color="primary"
  >
    <div class="pb-4 font-weight-medium">
      Uploading {{ uploading.values.length }} file<span
        v-if="uploading.values.length > 1"
        >s</span
      >
    </div>
    <v-progress-linear :model-value="value"></v-progress-linear>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";

export default {
  watch: {
    uploading: {
      handler: function () {
        if (this.value === 100) {
          this.$store.commit("setTimeout", 3000);
        } else {
          this.$store.commit("setTimeout", -1);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      uploading: (state) => state.uploads.uploading,
    }),
    value() {
      if (this.uploading.values.length === 0) {
        return 0;
      }
      let total = 0;
      let max = this.uploading.values.length * 100;
      this.uploading.values.forEach((v) => (total = total + v.value));
      console.log((total / max) * 100);
      return (total / max) * 100;
    },
  },
  methods: {
    change(v) {
      if (!v) {
        this.$store.commit("setUploadingFalse");
      }
    },
  },
};
</script>
