<template>
  <v-card width="350" class="pa-6 transparent" outlined>
    <div class="pa-4 text-center">
      <v-img
        class="mb-6"
        contain
        height="128"
        :src="require('@/assets/no-project.svg')"
      ></v-img>
      <h3 class="text-h6 font-weight-regular">Create your first project</h3>
      <v-btn
        rounded
        class="mt-5 success white--text"
        elevation="0"
        @click="$emit('addProject')"
        >Add project</v-btn
      >
    </div>
  </v-card>
</template>
<script>
export default {
  name: "NoProject",
};
</script>
