<template>
  <div class="support">
    <div class="wrapper" v-if="!sent">
      <h1>Support</h1>
      <p class="mb-8">
        Ask your question and we will reply via email within a couple days.
      </p>
      <v-textarea
        label="Where can we help you with?"
        solo
        flat
        style="min-width: 500px"
        v-model="question"
        hide-details=""
      ></v-textarea>
      <v-btn
        class="success mt-4"
        :disabled="question === ''"
        elevation="0"
        @click="send"
        :loading="sending"
        >Send email</v-btn
      >
    </div>
    <div class="wrapper text-center" v-else>
      <h1 class="mb-3">Thanks!</h1>
      <p>
        Your question has been sent to our support team! <br />We will get back
        to you as soon as possible.
      </p>

      <div class="d-flex">
        <v-btn class="mt-3" elevation="0" color="success" @click="sent = false"
          >New question <v-icon small>mdi-arrow-right</v-icon></v-btn
        >
        <v-btn
          class="mt-3 ml-3"
          elevation="0"
          color="warning"
          @click="
            sent = false;
            $router.push('/heatmaps');
          "
          >Heatmaps <v-icon small>mdi-arrow-right</v-icon></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { db, auth } from "@/firebase";

export default {
  data() {
    return {
      sending: false,
      sent: false,
      question: "",
    };
  },
  methods: {
    send() {
      const vm = this;
      this.sending = true;
      db.collection("mail")
        .add({
          to: "support@brainsight.app",
          replyTo: auth.currentUser.email,
          from: "Brainsight <info@brainsight.app>",
          template: {
            name: "support",
            data: {
              ticket: vm.question,
              first: vm.$store.state.users.userProfile.firstName,
            },
          },
        })
        .then(function () {
          console.log("email send to support");
          db.collection("mail")
            .add({
              to: auth.currentUser.email,
              replyTo: "support@brainsight.app",
              from: "Brainsight <info@brainsight.app>",
              template: {
                name: "support",
                data: {
                  ticket: vm.question,
                  first: vm.$store.state.users.userProfile.firstName,
                },
              },
            })
            .then(function () {
              console.log("CC send to user");
            })
            .catch(function (error) {
              console.error("Error adding document: ", error);
            });
        })
        .then(() => {
          vm.sending = false;
          vm.question = "";
          vm.sent = true;
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    },
    getColor() {
      return "#00d38c";
    },
  },
};
</script>

<style lang="scss" scoped>
.support {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(80vh);
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.v-data-table .v-data-table__content {
  box-shadow: none !important;
}
</style>
