import { db, auth } from "@/firebase";

const reports = {
  state: {
    wizard: false,
    reports: [],
  },
  mutations: {
    setReports(state, val) {
      state.reports = val;
    },
    setReportWizard(state, val) {
      state.wizard = val;
    },
  },
  actions: {
    fetchReports({ commit, rootState }) {
      return new Promise((resolve) => {
        let reports = [];
        let superadmin = false;
        let member = false;

        if (rootState.users.userProfile.superadmin) {
          superadmin = true;
        }
        if (
          rootState.users.userProfile.role === "member" ||
          rootState.users.userProfile.role === "admin"
        ) {
          member = true;
        }

        let query = db
          .collection("reports")
          .where("created_by_uid", "==", auth.currentUser.uid)
          .where("workspace", "==", rootState.users.userProfile.workspace);
        if (superadmin) {
          query = db.collection("reports");
        } else if (member && rootState.users.userProfile.workspace != "") {
          query = db
            .collection("reports")
            .where("workspace", "==", rootState.users.userProfile.workspace);
        }

        query
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              reports.push(doc.data());
            });
            reports.sort((a, b) =>
              a.created_at < b.created_at
                ? 1
                : b.created_at < a.created_at
                  ? -1
                  : 0
            );
          })
          .then(() => {
            reports.forEach((r, index) => {
              let counter = 0;
              r.heatmaps.forEach((h) => {
                db.collection("heatmaps")
                  .doc(h)
                  .get()
                  .then((doc) => {
                    if (doc.exists) {
                      const heatmap = doc.data();

                      if (heatmap.template_scores) {
                        const arr = Object.values(
                          heatmap.template_scores[r.type]
                        );
                        const average = (arr) =>
                          arr.reduce((a, b) => a + b, 0) / arr.length;
                        heatmap.template_average = average(arr);
                        reports[index].heatmapObjects.push(heatmap);
                      }

                      if (heatmap.status === "done" && r.status != "done") {
                        counter++;
                        if (counter === reports[index].heatmaps.length) {
                          reports[index].status = "done";
                          db.collection("reports")
                            .doc(r.uid)
                            .update({
                              status: "done",
                            })
                            .then(() => {
                              console.log("Report set to done");
                            })
                            .catch((error) => {
                              // The document probably doesn't exist.
                              console.error("Error updating document: ", error);
                            });
                        }
                      }
                    } else {
                      // doc.data() will be undefined in this case
                      console.log("No such document!");
                    }
                  })
                  .catch((error) => {
                    console.log("Error getting document:", error);
                  });
              });
            });
          })
          .then(() => {
            commit("setReports", reports);
            resolve("Reports loaded");
          })
          .catch((error) => {
            console.log("Error getting reports: ", error);
            resolve("No reports found");
          });
      });
    },
  },
  getters: {},
};

export default reports;
