import { createApp } from "vue";
import { auth } from "./firebase";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueGtm from "vue-gtm";
import { ObserveVisibility } from "vue-observe-visibility";

let app;

document.title = "Brainsight";

auth.onAuthStateChanged((user) => {
  if (user) {
    store.dispatch("fetchUserProfile", user);
  }
  if (!app) {
    if (!user) {
      store.commit("setLoading", false);
    }
    // Start the app
    app = createApp(App).use(router).use(vuetify).use(store);
    app.config.productionTip = false;
    app.use(VueGtm, {
      id: "GTM-P9KT7HV", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
    });
    app.directive("observe-visibility", ObserveVisibility);
    app.mount("#app");
  }
});
