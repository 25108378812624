<template>
  <div class="bm-container">
    <div class="bg-smoke">
      <div class="pa-4 d-flex align-center" style="gap: 10px">
        <v-menu v-model="menu" :close-on-content-click="false" location="end">
          <template v-slot:activator="{ props }">
            <v-btn
              :disabled="loading"
              height="40"
              color="primary"
              flat
              v-bind="props"
            >
              Add benchmark</v-btn
            >
          </template>

          <v-card min-width="300">
            <div class="pa-4 d-flex">
              <v-text-field
                density="compact"
                hide-details=""
                label="Name"
                v-model="name"
              ></v-text-field>
              <v-btn height="40" color="primary" @click="addBenchmark">
                Save
              </v-btn>
            </div>
          </v-card>
        </v-menu>
        <v-select
          :items="benchmarks"
          hide-details=""
          @update:model-value="
            (val) => $router.push(`/superadmin/benchmark/${val.uid}`)
          "
          variant="solo"
          flat=""
          :loading="loading"
          return-object
          density="compact"
          style="max-width: 300px"
          bg-color="white"
          item-title="name"
          placeholder="Select benchmark"
          v-model="benchmark"
        ></v-select>
        <v-spacer></v-spacer>
      </div>
      <v-divider></v-divider>
    </div>
    <router-view v-slot="{ Component, route }">
      <transition name="slide-fade" mode="out-in">
        <component :is="Component" :key="route.path" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      benchmarks: (state) => state.benchmarks.benchmarks,
      benchmark: (state) => state.benchmarks.benchmark,
      loading: (state) => state.admin.loading.benchmarks,
      workspaces: (state) => state.admin.workspaces,
    }),
  },
  data() {
    return {
      menu: false,
      name: "",
    };
  },
  mounted() {
    if (this.benchmark) {
      this.$router.push(`/superadmin/benchmark/${this.benchmark.uid}`);
    }
  },
  methods: {
    async addBenchmark() {
      const docRef = await db.collection("benchmarks").add({
        name: this.name,
        categories: [],
        access: "admin",
        created: Math.round(Date.now() / 1000),
        attention: {
          failed: 0,
          remaining_subjects: 0,
          remaining_templates: 0,
        },
        clarity: {
          failed: 0,
          remaining_subjects: 0,
          remaining_templates: 0,
        },
        last_update: Math.floor(Date.now() / 1000),
        status: "ready",
        version: 0,
      });
      await db
        .collection("benchmarks")
        .doc(docRef.id)
        .update({ uid: docRef.id });
      this.$router.push(`/benchmark/${docRef.id}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
