<template>
  <div>
    <RequestDialog
      @close="dialog = false"
      :dialog="dialog"
      :request="request"
    />

    <div>
      <div class="bg-smoke align-center d-flex pa-2" style="height: 72px">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Search"
          bg-color="smoke"
          variant="solo"
          flat
          density="compact"
          hide-details
          style="max-width: 300px"
        ></v-text-field>
      </div>
      <v-divider></v-divider>
    </div>
    <div class="users">
      <v-card class="table" flat>
        <v-data-table-virtual
          show-select
          item-value="uid"
          dense
          height="calc(100vh - 72px)"
          fixed-header=""
          :headers="headers"
          :items="filtered"
          :search="search"
        >
          <template v-slot:[`item.webhook`]="{ item }">
            <v-btn
              readonly
              variant="text"
              :icon="item.webhook ? 'mdi-check' : ''"
              density="compact"
              size="small"
              color="success"
            ></v-btn>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              @click.stop="open(item)"
              icon="mdi-chevron-right"
              variant="tonal"
              size="small"
              :color="request && request.uid === request.uid ? 'primary' : ''"
              density="comfortable"
              class="mr-2"
            ></v-btn>
          </template>
        </v-data-table-virtual>
      </v-card>
    </div>
  </div>
</template>

<script>
// import { db } from "@/firebase";
// import moment from "moment";
// import firebase from "firebase/app";
import { mapState } from "vuex";
import RequestDialog from "./RequestDialog.vue";

export default {
  components: {
    RequestDialog,
  },
  data() {
    return {
      dialog: false,
      request: null,
      search: "",
      headers: [
        {
          title: "Title",
          sortable: true,
          value: "title",
        },
        {
          title: "Status",
          sortable: true,
          value: "status",
        },
        {
          title: "Webhook",
          sortable: true,
          value: "webhook",
        },
        {
          title: "Workspace",
          sortable: true,
          value: "workspace.name",
        },
        {
          title: "",
          sortable: true,
          align: "end",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    filtered() {
      let filtered = this.api;

      if (this.search) {
        filtered = filtered.filter(
          (u) =>
            u.title.toLowerCase().includes(this.search.toLowerCase()) ||
            u.workspace.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }

      return filtered;
    },
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      api: (state) => state.admin.api,
    }),
  },
  methods: {
    open(item) {
      this.request = item;
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
