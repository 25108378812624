<template>
  <div>
    <router-view v-slot="{ Component }">
      <transition name="slide-fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: "SuperAdmin",
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
