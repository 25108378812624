import { db, auth } from "@/firebase";

const subscription = {
  state: {
    subscription: {},
  },
  mutations: {
    setSubscription(state, val) {
      state.subscription = val;
    },
  },
  actions: {
    fetchSubscription({ commit, rootState }) {
      return new Promise((resolve) => {
        let subscription = {
          subscriptions: [],
          tier: "",
          interval: "",
          active: null,
          renewalDay: null,
          uid: "",
        };

        let userBought = false;

        db.collection("customers")
          .doc(auth.currentUser.uid)
          .collection("subscriptions")
          .where("status", "==", "active")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach(() => {
              userBought = true;
            });
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });

        let uid = "";
        if (rootState.users.userProfile.role === "admin") {
          uid = auth.currentUser.uid;
        } else if (
          rootState.users.userProfile.role === "member" &&
          rootState.users.userProfile.workspace
        ) {
          if (rootState.workspace.workspace.admin) {
            uid = rootState.workspace.workspace.admin;
          } else if (rootState.workspace.workspace.canceled) {
            uid = rootState.workspace.workspace.canceled_by;
          }
        } else {
          uid = auth.currentUser.uid;
        }

        db.collection("customers")
          .doc(uid)
          .collection("subscriptions")
          .where("status", "==", "past_due")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              subscription.canceled = doc.data().cancel_at_period_end;
              subscription.cancel_at = doc.data().cancel_at;
              subscription.subscriptions.push(doc.data());
              subscription.tier = doc.data().items[0].price.product.name;
              subscription.active = doc.data().status === "past_due";
              subscription.interval = doc.data().items[0].plan.interval;
              subscription.renewalDay = doc
                .data()
                .current_period_start.toDate()
                .getDate();
              subscription.uid = doc.id;
            });
          })
          .catch((error) => {
            resolve("No subscription found" + error);
          });

        db.collection("customers")
          .doc(uid)
          .collection("subscriptions")
          .where("status", "==", "active")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              subscription.canceled = doc.data().cancel_at_period_end;
              subscription.cancel_at = doc.data().cancel_at;
              subscription.subscriptions.push(doc.data());
              subscription.tier = doc.data().items[0].price.product.name;
              subscription.active = doc.data().status === "active";
              subscription.interval = doc.data().items[0].plan.interval;
              subscription.renewalDay = doc
                .data()
                .current_period_start.toDate()
                .getDate();
              subscription.uid = doc.id;
            });
          })
          .then(() => {
            if (subscription.subscriptions.length === 0 && !userBought) {
              subscription.active = false;
              commit("setSubscription", subscription);
              resolve("Subscription loaded");
            } else if (
              subscription.subscriptions.length === 0 &&
              userBought &&
              rootState.workspace.workspace.admin != auth.currentUser.uid
            ) {
              db.collection("customers")
                .doc(auth.currentUser.uid)
                .collection("subscriptions")
                .where("status", "==", "active")
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    subscription.canceled = doc.data().cancel_at_period_end;
                    subscription.cancel_at = doc.data().cancel_at;
                    subscription.subscriptions.push(doc.data());
                    subscription.tier = doc.data().items[0].price.product.name;
                    subscription.active = doc.data().status === "active";
                    subscription.interval = doc.data().items[0].plan.interval;
                    subscription.renewalDay = doc
                      .data()
                      .current_period_start.toDate()
                      .getDate();
                    subscription.uid = doc.id;
                  });
                })
                .then(() => {
                  commit("setSubscription", subscription);
                  resolve("Subscription loaded");
                })
                .catch((error) => {
                  resolve("No subscription found" + error);
                });
            } else {
              commit("setSubscription", subscription);
              resolve("Subscription loaded");
            }
          })
          .catch((error) => {
            resolve("No subscription found" + error);
          });
      });
    },
  },
};

export default subscription;
