<template>
  <div id="myChart" class="mb-5 mt-2">
    <div style="height: 200px">
      <Bar
        id="my-chart-id"
        ref="chartcontainer"
        :options="chartOptions"
        :data="chartData"
      />
    </div>
  </div>
</template>

<script>
import { Line, Bar } from "vue-chartjs";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  BarElement,
  Filler,
} from "chart.js";
import { boolean } from "joi";

ChartJS.register(
  Title,
  Filler,
  Tooltip,
  BarElement,
  Legend,
  PointElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  annotationPlugin
);

export default {
  name: "LineChart",

  mounted() {
    this.chartWidth = this.$refs.chartcontainer.clientWidth;
    this.chartHeight = this.$refs.chartcontainer.clientHeight;

    var ro = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        this.chartWidth = cr.width;
        this.chartHeight = cr.height;
      }
    });

    // Observe one or multiple elements
    ro.observe(document.querySelector("#myChart"));
  },
  props: {
    attention_score: {
      default: 0,
      type: Number,
    },
    application: {
      default: "Social media",
      type: String,
    },
  },
  components: { Line, Bar },
  computed: {
    scrubber() {
      return 10;
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: "index",
        },
        plugins: {
          tooltip: {
            // callbacks: {
            //   label: function (context) {
            //     return context.dataset.label + ": " + context.raw + "%";
            //   },
            // },
            // filter: function (tooltipItem) {
            //   return tooltipItem.dataset.percentiled;
            // },
            enabled: false,
          },
          annotation: {
            annotations: {
              line1: {
                type: "line",
                xMin: (this.attention_score / 100) * 10,
                xMax: (this.attention_score / 100) * 10,
                borderColor: "#ebebef",
                borderWidth: 3,
                label: {
                  content: `Your Score: ${Math.round(this.attention_score)}`,
                  display: true,
                },
              },
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            display: false,
          },
          y: {
            display: false,
            stacked: true,
            min: 0,
            max: 45,
            grid: {
              display: false,
            },
          },
        },
      };
    },
    chartData() {
      let cfg = {
        labels: [],
        datasets: [],
      };

      cfg.datasets.push({
        label: `${this.application} Benchmark`,
        backgroundColor: "#6515dd",
        borderColor: "#6515dd",
        data: [0, 3, 8, 15, 30, 40, 30, 15, 8, 3, 0],
        tension: 0.5,
        type: "line",
        stack: 0,
        pointStyle: false,
        percentiled: false,
        fill: true,
      });

      let steps = 11;

      for (let step = 1; step <= steps; step++) {
        cfg.labels.push(step);
      }

      return cfg;
    },
  },
  data() {
    return {
      chartWidth: null,
      chartHeight: null,
      left: null,
      brandColor: ["#FBB81B", "#00d38c", "#ff5555"],
    };
  },
};
</script>

<style lang="scss" scoped>
#myChart {
  position: relative;
  margin: 0 10px;
}

.scrubber {
  width: 5px;
  background-color: #c4c4c4;
  opacity: 0.5;
  border-radius: 10px;
  position: absolute;
  z-index: 99;
}
</style>
