<template>
  <div class="model-container">
    <div class="bg-smoke">
      <!-- <v-btn elevation="0" c color="secondary">Create new model</v-btn> -->
      <div class="pa-4 d-flex" style="gap: 10px">
        <v-menu v-model="menu" :close-on-content-click="false" location="end">
          <template v-slot:activator="{ props }">
            <v-btn height="40" color="primary" flat v-bind="props">
              Add model</v-btn
            >
          </template>

          <v-card min-width="300">
            <div class="pa-4 d-flex">
              <v-text-field
                density="compact"
                hide-details=""
                label="Name"
                v-model="model_name"
              ></v-text-field>
              <v-btn height="40" color="primary" @click="addModel">
                Save
              </v-btn>
            </div>
          </v-card>
        </v-menu>
        <v-select
          :items="models"
          hide-details=""
          v-model="model"
          @update:model-value="heatmap = null"
          variant="solo"
          flat=""
          return-object=""
          density="compact"
          style="max-width: 300px"
          bg-color="white"
          item-title="name"
          placeholder="Pick a model"
        ></v-select>
        <v-spacer></v-spacer>
        <v-menu bottom offset-y v-if="model">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              elevation="0"
              size="small"
              variant="text"
              icon="mdi-dots-vertical"
            >
            </v-btn>
          </template>

          <v-list density="compact">
            <v-list-item style="font-size: 11pt" link @click="addObject">
              Add object
            </v-list-item>
            <v-list-item style="font-size: 11pt" link @click="updateModel">
              Train model
            </v-list-item>
            <v-list-item
              style="font-size: 11pt"
              link
              @click="firebase(model.uid)"
            >
              Open in Firebase
            </v-list-item>
            <v-list-item style="font-size: 11pt" link @click="removeModel">
              Delete model
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-divider></v-divider>
    </div>

    <div v-if="model && model.last_update" class="pa-4">
      <div class="twogrid">
        <v-card flat class="pt-8 pb-2 px-4" style="gap: 20px">
          <v-text-field
            label="Name"
            v-model="model.name"
            flat
            variant="solo"
            bg-color="smoke"
            dense
            @update:model-value="
              ($event) => {
                updateModelName(model.uid, $event);
              }
            "
          ></v-text-field>
          <v-text-field
            v-model="model.version"
            flat
            dense
            variant="solo"
            bg-color="smoke"
            number
            readonly
            label="Version (readonly)"
          ></v-text-field>
          <div class="d-flex">
            <span class="mr-3">Status:</span>
            <v-chip
              :color="
                model.status === 'training'
                  ? 'primary'
                  : model.status === 'failed'
                    ? 'error'
                    : ''
              "
              >{{ model.status }}</v-chip
            >
          </div>
        </v-card>

        <v-card flat class="pa-4">
          <v-data-table
            :headers="headersObjects"
            :items="model.objects"
            :items-per-page="10"
            density="compact"
            ><template #bottom></template>
            <template v-slot:[`item.name`]="{ item }">
              <v-text-field
                @change="updateObject(item)"
                flat
                dense
                bg-color="white"
                hide-details=""
                v-model="item.name"
              ></v-text-field>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <v-select
                @update:model-value="
                  ($event) => {
                    updateObject(item, $event);
                  }
                "
                v-model="item.type"
                flat
                bg-color="white"
                hide-details=""
                dense
                :items="['image', 'text']"
              ></v-select>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                size="small"
                variant="text"
                icon="mdi-delete"
                @click="removeObject(item)"
              ></v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>

      <v-card flat class="mt-4">
        <div
          class="pa-4 d-flex justify-space-between"
          v-if="heatmaps.length > 0"
        >
          <div class="d-flex" style="gap: 15px; width: 100%">
            <v-spacer></v-spacer>
            <v-select
              style="max-width: 300px"
              solo
              hide-details=""
              flat
              background-color="smoke"
              :items="filteredHeatmaps"
              item-title="title"
              item-value="uid"
              return-object
              placeholder="Add heatmap"
              item-props=""
            >
              <template v-slot:[`append-item`]>
                <v-btn
                  class="pa-4"
                  block
                  elevation="0"
                  :loading="$store.state.heatmaps.loading"
                  @click="$store.dispatch('fetchHeatmaps')"
                  >Load more</v-btn
                >
              </template>

              <template v-slot:item="{ item }">
                <v-tooltip left color="warning">
                  <template v-slot:activator="{ props }">
                    <v-list-item
                      v-bind="props"
                      @click="addHeatmap(item.props)"
                      :title="item.title"
                    ></v-list-item>
                  </template>
                  <div class="py-2">
                    <img :src="item.props.thumbnail" alt="" />
                  </div>
                </v-tooltip>
              </template>
            </v-select>
          </div>
        </div>

        <v-data-table
          :headers="headers"
          :items="model.heatmaps"
          v-model="selected_heatmaps"
          :items-per-page="5"
        >
          <template v-slot:[`item.title`]="{ item }">
            <span
              :class="{
                'text-secondary': heatmap && item.uid === heatmap.uid,
              }"
              >{{ item.title }}</span
            >
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-menu bottom left offset-y>
              <template v-slot:activator="{ props }">
                <v-btn
                  class="ml-6"
                  variant="text"
                  size="small"
                  icon="mdi-dots-vertical"
                  v-bind="props"
                  elevation="0"
                >
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="open(item.uid)" link class="px-6 py-2"
                  >Open in new tab
                </v-list-item>
                <v-list-item @click="fetchData(item)" link class="px-6 py-2"
                  >Edit labels
                </v-list-item>
                <v-list-item
                  link
                  class="px-6 py-2"
                  @click="
                    $store.dispatch('predict', {
                      model: model,
                      heatmap: item,
                    })
                  "
                  >Predict</v-list-item
                >
                <v-list-item
                  @click="removeHeatmap(item)"
                  link
                  class="px-6 py-2"
                >
                  Remove heatmap
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>

      <v-card v-if="heatmap" flat class="pa-4 mt-4">
        <div class="d-flex justify-space-between">
          <h2>{{ heatmap.title }}</h2>
          <v-btn icon @click="heatmap = null"
            ><v-icon size="20">mdi-close</v-icon></v-btn
          >
        </div>
        <div style="height: 58px" class="d-flex align-center">
          <p class="ma-0 pa-0 mr-2">Select frames to label:</p>
          <v-slider
            style="max-width: 550px"
            v-model="slider"
            :max="frames.length - 1"
            :min="0"
            hide-details=""
            :step="1"
          ></v-slider>
          <div class="d-flex" style="gap: 10px">
            <v-btn
              elevation="0"
              @click="slider > 0 ? (slider = slider - 1) : ''"
              ><v-icon>mdi-chevron-left</v-icon></v-btn
            >
            <v-btn
              elevation="0"
              @click="slider < frames.length - 1 ? slider++ : ''"
              ><v-icon>mdi-chevron-right</v-icon></v-btn
            >
          </div>
          <v-btn
            color="error"
            text
            class="ml-10"
            elevation="0"
            @click="clearLabels"
            >Clear labels</v-btn
          >
        </div>
        <div
          class="d-flex align-center"
          v-if="model && model.objects && model.objects.length > 0"
        >
          <v-chip-group mandatory v-model="object" color="primary" column>
            <v-chip
              :value="o"
              v-for="o in model.objects.filter((o) => o.type === 'image')"
              :key="o.uid"
            >
              {{ o.name }}
            </v-chip>
          </v-chip-group>
          <p v-if="!object">
            <v-icon>mdi-arrow-left</v-icon> First pick an object
          </p>
        </div>
        <Heat
          v-if="object"
          variant="original"
          :showBoxes="true"
          :data="[]"
          :image="frame.image"
          :disabled="false"
          :loadBoxes="frame.areas ? frame.areas : []"
          :originalWidth="frame.originalWidth"
          :opacity="0"
          :uid="frame.uid"
          :compare="false"
          :type="frame.type"
          :object="object ? object : null"
          ref="heatmap"
          :tracking="true"
          @update="updateBoxes"
          :key="reKey"
        />
      </v-card>
    </div>
    <div v-else class="pa-4">
      <p>Select model to start..</p>
      <v-btn v-if="model" @click="importModel(this.model)">Import model</v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Heat from "./heat/HeatmapComp.vue";
import { db } from "@/firebase";
import firebase from "firebase/";

export default {
  components: {
    Heat,
  },
  async mounted() {
    if (this.$route.query.model && this.$route.query.heatmap) {
      await this.$store.dispatch("fetchModels");
      this.model = this.models.find((m) => m.uid === this.$route.query.model);
      let heatmap = this.heatmaps.find(
        (h) => h.uid === this.$route.query.heatmap
      );
      this.model.heatmaps.push(heatmap);
      this.fetchData(heatmap);
      if (this.model.objects && this.model.objects.length > 0) {
        this.object = this.model.objects[0];
      }
    }
  },
  data() {
    return {
      frames: [],
      heatmap: null,
      headersObjects: [
        {
          title: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          title: "Type",
          align: "start",
          sortable: false,
          value: "type",
        },
        {
          title: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      headers: [
        {
          title: "Name",
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          title: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      menu: false,
      model_name: "",
      model: null,
      modelChanged: false,
      object: null,
      reKey: 0,
      search: "",
      selected_heatmaps: [],
      slider: 0,
    };
  },
  computed: {
    frame() {
      return this.frames[this.slider];
    },
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      users: (state) => state.users.users,
      models: (state) => state.admin.models,
      heatmaps: (state) => state.heatmaps.heatmaps,
    }),
    filteredHeatmaps() {
      let list = this.heatmaps.filter((h) => h.type === "video");
      return list;
    },
  },
  methods: {
    addObject() {
      let newObject = { type: "image", name: "", uid: null };
      this.model.objects.push(newObject);
      this.updateObject(newObject);
    },
    async addModel() {
      this.menu = false;
      this.$store.commit("setLoading", true);
      const docRef = await db.collection("models").add({
        name: this.model_name,
        last_update: new Date(),
        heatmaps: [],
        queue: [],
        version: 0,
        status: "ready",
      });
      await db.collection("models").doc(docRef.id).update({
        uid: docRef.id,
      });
      await this.$store.dispatch("fetchModels");
      this.model = this.models.find((m) => m.uid === docRef.id);
      this.$store.commit("setLoading", false);
    },
    async addHeatmap(i) {
      this.heatmap = i;
      if (this.model.heatmaps.findIndex((h) => h.uid === i.uid) < 0) {
        this.model.heatmaps.push(i);
        this.modelChanged = true;
        await db
          .collection("models")
          .doc(this.model.uid)
          .update({
            heatmaps: firebase.firestore.FieldValue.arrayUnion(i.uid),
            last_update: new Date(),
          });
      }
      this.fetchData(i);
    },
    clearLabels() {
      this.frames.forEach((f) => {
        f.areas = [];
      });
      this.updateBoxes([]);
    },
    firebase(uid) {
      let production = window.location.origin.includes("brainsight");
      let url = `https://console.firebase.google.com/u/0/project/${
        production ? "deepgaze-io" : "deepgaze-staging"
      }/firestore/data/~2Fmodels~2F${uid}`;
      window.open(url, "_blank");
    },
    async fetchData(heatmap) {
      const doc = await db.collection("heatmaps").doc(heatmap.uid).get();
      const h = doc.data();
      let arr = [];

      const dc = await db
        .collection("models")
        .doc(this.model.uid)
        .collection("labels")
        .doc(heatmap.uid)
        .get();

      if (dc.exists) {
        this.frames = dc.data().labels;
      } else {
        this.$store.commit("setLoading", true);

        await Promise.all(
          h.logo_frames.map(async (f) => {
            let heat = f;
            const imageOriginal = await fetch(f.image);
            const imgblobOriginal = await imageOriginal.blob();
            const imgOriginal = await createImageBitmap(imgblobOriginal);
            heat.originalWidth = imgOriginal.width;
            heat.originalHeight = imgOriginal.height;
            arr.push(heat);
          })
        );
        arr.sort((a, b) => (a.name < b.name ? -1 : b.name < a.name ? 1 : 0));
        this.frames = arr;
        this.$store.commit("setLoading", false);
      }

      this.heatmap = h;
      this.slider = 0;
    },
    open(uid) {
      window.open(`${window.location.origin}/heatmap/${uid}`, "_blank");
    },
    // async predict(heatmap) {
    //   let reqData = {
    //     remote_model_path: `models/${this.model.uid}-v${this.model.version}.pt`,
    //     path: `${heatmap.requested_by}/${heatmap.uid}`,
    //     filename: `${heatmap.uid}.${heatmap.extension}`,
    //     worker: "training",
    //     text:
    //       this.model &&
    //       this.model.objects &&
    //       this.model.objects.findIndex((o) => o.type === "text") >= 0
    //         ? this.model.objects.find((o) => o.type === "text").name
    //         : "",
    //   };
    //   console.log(reqData);
    //   var requestPrediction = firebase
    //     .app()
    //     .functions("europe-west1")
    //     .httpsCallable("requestPrediction");

    //   const res = await requestPrediction(reqData);
    //   console.log(res.data);
    // },
    async removeObject(v) {
      console.log(v);
      let r = confirm(
        "Are you sure? All labels that include this object will be removed."
      );
      if (r) {
        let i = this.model.objects.findIndex((o) => o === v);
        this.model.objects.splice(i, 1);

        if (v.uid) {
          await db
            .collection("models")
            .doc(this.model.uid)
            .collection("objects")
            .doc(v.uid)
            .delete();
          await db.collection("models").doc(this.model.uid).update({
            last_update: new Date(),
          });
          this.frames.forEach((f) => {
            f.areas = f.areas.filter((c) => c.uid != v.uid);
          });
          this.updateBoxes(this.frame.areas);
        }
      }
    },
    async updateBoxes(v) {
      let arr = this.frames;
      const vm = this;

      let val = v;

      for (let x in val) {
        let b = val[x];
        b.yolo = `${
          (b.left + b.width / 2) / vm.frames[vm.slider].originalWidth
        } ${(b.top + b.height / 2) / vm.frames[vm.slider].originalHeight} ${
          b.width / vm.frames[vm.slider].originalWidth
        } ${b.height / vm.frames[vm.slider].originalHeight}`;
      }
      this.frames[this.slider].areas = val;

      await db
        .collection("models")
        .doc(this.model.uid)
        .collection("labels")
        .doc(this.heatmap.uid)
        .set(
          {
            labels: arr,
            uid: this.heatmap.uid,
            last_update: new Date(),
          },
          { merge: true }
        );
      await db.collection("models").doc(this.model.uid).update({
        last_update: new Date(),
      });

      this.modelChanged = true;
    },

    async updateModel() {
      console.log("Updating model: " + this.model.name);
      this.$store.commit("setLoading", true);
      this.model.status = "training requested";
      var trainModel = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("trainMultiModel");
      const res = await trainModel({ uid: this.model.uid });
      console.log(res.data);
      await db.collection("models").doc(this.model.uid).update({
        last_update: new Date(),
        status: "training requested",
      });

      this.$store.commit("setLoading", false);
    },
    async updateObject(object, val) {
      if (val) {
        object.type = val;
      }
      console.log(object);
      if (object.uid) {
        await db
          .collection("models")
          .doc(this.model.uid)
          .collection("objects")
          .doc(object.uid)
          .set(object, {
            merge: true,
          });
      } else {
        const docRef = await db
          .collection("models")
          .doc(this.model.uid)
          .collection("objects")
          .add(object);
        await db
          .collection("models")
          .doc(this.model.uid)
          .collection("objects")
          .doc(docRef.id)
          .update({
            uid: docRef.id,
          });
        object.uid = docRef.id;
      }
      await db.collection("models").doc(this.model.uid).update({
        last_update: new Date(),
      });
    },
    async updateModelName(uid, name) {
      await db.collection("models").doc(uid).update({
        name: name,
      });
    },
    async removeModel() {
      console.log("removing " + this.model.uid);
      let r = confirm("Are you sure?");
      if (r) {
        this.$store.commit("setLoading", true);

        var removeModel = firebase
          .app()
          .functions("europe-west1")
          .httpsCallable("removeModel");
        await removeModel({ uid: this.model.uid });

        this.model = null;
        this.heatmap = null;
        this.object = null;
        await this.$store.dispatch("fetchModels");
        this.$store.commit("setLoading", false);
      }
    },
    async removeHeatmap(i) {
      let idx = this.model.heatmaps.findIndex((h) => h.uid === i.uid);
      if (this.heatmap && this.heatmap.uid === i.uid) {
        this.heatmap = null;
      }
      this.model.heatmaps.splice(idx, 1);
      this.modelChanged = true;

      await db
        .collection("models")
        .doc(this.model.uid)
        .update({
          heatmaps: firebase.firestore.FieldValue.arrayRemove(i.uid),
        });

      await db
        .collection("models")
        .doc(this.model.uid)
        .collection("labels")
        .doc(i.uid)
        .delete();

      await db.collection("models").doc(this.model.uid).update({
        last_update: new Date(),
      });
    },
    async importModel(m) {
      console.log(m);
      const docRef = await db
        .collection("models")
        .doc(m.uid)
        .collection("objects")
        .add({
          type: "image",
          name: "Logo",
        });
      await db
        .collection("models")
        .doc(m.uid)
        .collection("objects")
        .doc(docRef.id)
        .update({
          uid: docRef.id,
        });

      await Promise.all(
        m.old_heatmaps.map(async (h) => {
          const hm = await db.collection("heatmaps").doc(h).get();
          let labels = hm.data().logo_frames;
          for (let x in labels) {
            for (let y in labels[x].areas) {
              let area = labels[x].areas[y];
              area.uid = docRef.id;
              area.name = "Logo";
            }
          }
          await db
            .collection("models")
            .doc(m.uid)
            .collection("labels")
            .doc(h)
            .set({
              labels: labels,
              last_update: new Date(),
              uid: h,
            });
        })
      );

      await db.collection("models").doc(this.model.uid).update({
        last_update: new Date(),
        status: "ready",
      });
      await this.$store.dispatch("fetchModels");
      this.model = this.models.find((md) => md.uid === m.uid);
    },
  },
};
</script>
<style lang="scss" scoped>
.model-container {
  display: grid;
  grid-template-rows: 72px auto;
  min-height: 100vh;
}
.twogrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}
</style>
