<template>
  <div>
    <div v-if="frame" class="labelling">
      <div class="pa-4">
        <v-select
          placeholder="Where to start"
          :items="[
            'Use existing model',
            'Create new model',
            'Update existing model',
          ]"
          v-model="mode"
          solo
          flat
          hide-details=""
          class="mb-2"
        ></v-select>
        <div v-if="mode === 'Use existing model'">
          <v-select
            solo
            flat
            v-model="model"
            item-text="name"
            :items="models"
            hide-details=""
            return-object
            placeholder="Pick model"
          ></v-select>

          <v-switch
            v-model="text_tracking"
            label="Add text tracking"
            hide-details=""
            class="mb-4"
          ></v-switch>
          <v-text-field
            solo
            v-if="text_tracking"
            v-model="text"
            flat
            hide-details=""
            placeholder="Text detection"
          ></v-text-field>
          <v-btn
            @click="predict"
            block
            color="primary"
            :loading="loading"
            class="mt-2"
            :disabled="!model || heatmap.logo_tracking_status === 'processing'"
            >Predict</v-btn
          >
        </div>
        <div v-if="mode === 'Update existing model'">
          <v-select
            solo
            flat
            v-model="model"
            item-text="name"
            :items="models"
            hide-details=""
            return-object
          ></v-select>
          <v-switch
            v-model="text_tracking"
            label="Add text tracking"
            hide-details=""
            class="mb-4"
          ></v-switch>
          <v-text-field
            solo
            v-if="text_tracking"
            v-model="text"
            flat
            hide-details=""
            placeholder="Text detection"
          ></v-text-field>
          <v-btn
            block
            :loading="loading"
            color="primary"
            class="mt-2"
            :disabled="!model || framesCount < 6"
            @click="updateModel"
            >Update & train!</v-btn
          >
        </div>

        <div v-if="mode === 'Create new model'">
          <v-text-field
            v-model="name"
            solo
            :hide-details="!error"
            flat
            placeholder="Name of model"
            :error="error"
            :error-messages="['This name is already taken']"
          ></v-text-field>
          <v-switch
            v-model="text_tracking"
            label="Add text tracking"
            hide-details=""
            class="mb-4"
          ></v-switch>
          <v-text-field
            hide-details=""
            solo
            v-if="text_tracking"
            v-model="text"
            flat
            placeholder="Text detection"
          ></v-text-field>

          <v-btn
            block
            :loading="loading"
            color="primary"
            class="mt-2"
            @click="createModel"
            :disabled="
              !name || error || (text_tracking && !text) || framesCount < 6
            "
            >Create & train!</v-btn
          >
          <p style="font-size: 9pt; margin-top: 5px" v-if="framesCount < 6">
            <i
              ><v-icon x-small>mdi-alert</v-icon> Create minimum of 6 labels to
              continue</i
            >
          </p>
        </div>
        <v-btn
          v-if="mode === 'Create new model' || mode === 'Update existing model'"
          block
          color="primary"
          text
          class="mt-2"
          @click="removeLabels"
          >clear labels</v-btn
        >
        <v-alert
          type="info"
          v-if="heatmap.logo_tracking_status === 'processing'"
          >Prediction already in progress</v-alert
        >
      </div>
      <div
        class="right pa-4"
        v-if="
          mode && mode != 'Use existing model' && frames && frames.length > 0
        "
      >
        <div style="height: 58px" class="d-flex align-center">
          <p class="ma-0 pa-0 mr-2">Select frames to label:</p>
          <v-slider
            style="max-width: 550px"
            v-model="slider"
            :max="frames.length - 1"
            :min="0"
            :step="1"
            hide-details=""
          ></v-slider>
        </div>
        <Heat
          variant="original"
          :showBoxes="true"
          :data="[]"
          :image="frame.image"
          :loadBoxes="frame.areas ? frame.areas : []"
          :originalWidth="frame.originalWidth"
          :opacity="0"
          :uid="frame.uid"
          :compare="false"
          :type="frame.type"
          :slider.sync="slider"
          ref="heatmap"
          :tracking="true"
          @onSelectBox="selectBox"
          @boxUpdate="updateBoxes"
          @update="refreshCompare"
          @newBox="addAOI"
          @initialized="initialized"
          :key="reKey"
        />
      </div>
    </div>
    <div class="pa-10" v-else>
      <v-progress-circular
        indeterminate
        size="40"
        width="5"
      ></v-progress-circular>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.labelling {
  max-height: 80vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 4fr;
}
</style>

<script>
import { db } from "@/firebase";
import Heat from "@/views/Heatmap/heat/HeatBox.vue";
import firebase from "firebase/app";
import { mapState } from "vuex";

export default {
  name: "LabelView",
  components: {
    Heat,
  },
  data() {
    return {
      boxes: [],
      frames: [],
      heatmap: null,
      loadedBoxes: false,
      loading: false,
      mode: null,
      model: null,
      name: "",
      reKey: 0,
      slider: 0,
      text: "",
      text_tracking: false,
    };
  },
  computed: {
    frame() {
      return this.frames[this.slider];
    },
    framesCount() {
      return this.frames.filter((f) => f.areas.length > 0).length;
    },
    ...mapState({
      models: (state) => state.admin.models,
    }),
    error() {
      if (!this.models || this.models.length === 0) {
        return false;
      } else {
        let i = this.models.findIndex(
          (m) => m.name.toLowerCase() === this.name.toLowerCase()
        );
        return i >= 0;
      }
    },
  },

  mounted() {
    this.fetchData();
  },
  methods: {
    async addAOI(val) {
      console.log(val);
      // const res = await API.test.createAOI(this.$route.params.heatmapId, val);
    },
    async createModel() {
      console.log("Creating model: " + this.name);
      this.loading = true;
      const docRef = await db.collection("models").add({
        name: this.name,
        version: 0,
        heatmaps: [],
        queue: [this.heatmap.uid],
      });
      await db.collection("models").doc(docRef.id).update({
        uid: docRef.id,
      });

      var trainModel = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("trainModel");
      const res = await trainModel({ uid: docRef.id });
      console.log(res.data);

      await db.collection("heatmaps").doc(this.heatmap.uid).update({
        logo_tracking_model: docRef.id,
      });
      if (this.text_tracking && this.text) {
        this.requestTextTracking();
      }
      this.loading = false;
      this.$router.push(`/heatmap/${this.$route.params.id}`);
    },
    async fetchData() {
      console.log("Fetching frames");
      const doc = await db
        .collection("heatmaps")
        .doc(this.$route.params.id)
        .get();
      const h = doc.data();
      console.log(h);
      this.heatmap = h;
      let arr = [];
      await Promise.all(
        h.logo_frames.map(async (f) => {
          console.log("fetching " + f.name);
          let heat = f;
          const imageOriginal = await fetch(f.image);
          const imgblobOriginal = await imageOriginal.blob();
          const imgOriginal = await createImageBitmap(imgblobOriginal);
          heat.originalWidth = imgOriginal.width;
          heat.originalHeight = imgOriginal.height;
          arr.push(heat);
        })
      );

      arr.sort((a, b) => (a.name < b.name ? -1 : b.name < a.name ? 1 : 0));
      this.frames = arr;
      this.slider = 0;
    },
    initialized(val) {
      this.loadedBoxes = false;
      this.loadedBoxes = val;
    },
    selectBox(val) {
      this.activeBoxIndex = val;
    },
    refreshCompare(b) {
      console.log("compare");
      this.frame.areas = b;
      this.updateBoxes(this.frames[this.slider]);
    },
    removeLabels() {
      console.log("remove labels");

      this.frames.forEach((f) => {
        f.areas = [];
      });
      this.updateBoxes();
    },
    async updateModel() {
      console.log("Updating model: " + this.model.name);
      this.loading = true;
      await db
        .collection("models")
        .doc(this.model.uid)
        .update({
          queue: firebase.firestore.FieldValue.arrayUnion(this.heatmap.uid),
        });

      var trainModel = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("trainModel");
      const res = await trainModel({ uid: this.model.uid });
      console.log(res.data);

      await db.collection("heatmaps").doc(this.heatmap.uid).update({
        logo_tracking_status: "requested",
        logo_tracking_model: this.model.uid,
      });

      if (this.text_tracking && this.text) {
        this.requestTextTracking();
      }
      this.loading = false;
      this.$router.push(`/heatmap/${this.$route.params.id}`);
    },
    async predict() {
      this.loading = true;
      let reqData = {
        remote_model_path: `models/${this.model.uid}-v${this.model.version}.pt`,
        path: `${this.heatmap.requested_by}/${this.heatmap.uid}`,
        filename: `${this.heatmap.uid}.${this.heatmap.extension}`,
        worker: "logo",
      };
      console.log(reqData);
      var requestPrediction = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("requestPrediction");

      try {
        const res = await requestPrediction(reqData);
        console.log(res.data);
        if (this.text_tracking && this.text) {
          this.requestTextTracking();
        }
        await db.collection("heatmaps").doc(this.heatmap.uid).update({
          logo_tracking_model: this.model.uid,
          logo_tracking_model_version: this.model.version,
        });

        await db
          .collection("models")
          .doc(this.model.uid)
          .update({
            heatmaps: firebase.firestore.FieldValue.arrayUnion(
              this.heatmap.uid
            ),
          });
        this.loading = false;
        this.$router.push(`/heatmap/${this.heatmap.uid}`);
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async requestTextTracking() {
      // ADD FUNCTION TO CALL THE TEXT TRACKING WORKER
      // ASk joan to change the text_trackign_status to 'proessisng'

      await db.collection("heatmaps").doc(this.heatmap.uid).update({
        text_tracking_status: "requested",
        text_tracking: true,
        text_tracking_text: this.text,
      });
    },
    async updateBoxes(val) {
      console.log("update boxes");
      console.log(val);
      this.isBoxesUpdating = true;
      this.activeBoxIndex = null;
      const vm = this;

      let arr = this.frames;

      for (let x in arr[this.slider].areas) {
        let b = arr[this.slider].areas[x];
        b.yolo = `${
          (b.left + b.width / 2) / vm.frames[vm.slider].originalWidth
        } ${(b.top + b.height / 2) / vm.frames[vm.slider].originalHeight} ${
          b.width / vm.frames[vm.slider].originalWidth
        } ${b.height / vm.frames[vm.slider].originalHeight}`;
      }

      await db.collection("heatmaps").doc(this.$route.params.id).update({
        logo_frames: arr,
      });

      this.boxes = val;
      this.isBoxesUpdating = false;
    },
  },
};
</script>
