<template>
  <div class="pa-10">
    <h1>Subscription</h1>
    <div
      class="sub"
      v-if="
        (userProfile.role === 'admin' && subscription.active) ||
        (userProfile.role === 'member' && subscription.active)
      "
    >
      You are currently on the {{ subscription.interval }}ly
      <span v-if="subscription.tier === 'basic' && subscription.active"
        >Basic</span
      >
      <span v-if="subscription.tier === 'business' && subscription.active"
        >Business</span
      >
      plan. &#127881;
    </div>
    <div
      v-if="
        (userProfile.role === 'admin' &&
          subscription.active === false &&
          workspace.beta != true) ||
        (userProfile.role === 'member' &&
          subscription.active === false &&
          workspace.beta != true)
      "
      class="sub"
    >
      Oops! It seems that your workspace doesn't have an active subscription.
      Pick one to continue.
    </div>
    <div
      v-if="
        (userProfile.role === 'admin' &&
          subscription.active === false &&
          workspace.beta === true) ||
        (userProfile.role === 'member' &&
          subscription.active === false &&
          workspace.beta === true)
      "
      class="sub"
    >
      Dear Beta-user, thank you very much for beta-testing our new Brainsight
      product. We hope you want to continue your journey with Brainsight. For a
      special Beta discount offer: get in touch via the 'Schedule call'-button
      or email me: max@braingineers.com.
    </div>
    <p class="sub" v-if="userProfile.role === 'freemium'">
      You're currently on the Freemium subscription. <i>Ready to do more?</i>
    </p>
    <p class="sub" v-if="userProfile.role === 'trial'">
      We hope you like<span v-if="userProfile.trialEnded">d</span> your trial of
      Brainsight so far! &#128293; If you feel like doing more, and adding team
      members, upgrade to a plan below:
    </p>

    <div style="max-width: 1000px" class="mt-4" v-if="subscription.active">
      <v-alert color="white">
        <div class="d-flex justify-space-between align-center">
          <div>
            <b>{{ workspace.imageCredits }}</b> image credits left
          </div>
          <div>
            <b> {{ workspace.videoCredits }}</b>
            video credits left
          </div>
        </div>
      </v-alert>

      <v-alert v-if="workspace.members" color="white" class="mt-2">
        <div class="mt-2 d-flex justify-space-between align-center">
          <div>
            <b>{{ workspace.members.length }}</b> of
            {{ workspace.seats + workspace.members.length }} seats used in your
            workspace.
          </div>

          <v-btn flat @click="$router.push('/workspace')" class="success"
            >Manage users</v-btn
          >
        </div>
      </v-alert>
    </div>
    <v-btn
      v-if="subscription.active"
      class="mt-3 mr-5"
      color="success"
      elevation="0"
      @click="openPricing"
      >View pricing</v-btn
    >
    <v-btn
      v-if="subscription.active && userProfile.role === 'admin'"
      @click="
        stripe();
        invoicesLoading = true;
      "
      elevation="0"
      color="warning"
      class="mt-3"
      :loading="invoicesLoading"
      >View invoices</v-btn
    >
    <v-btn
      v-if="subscription.active && userProfile.role === 'admin'"
      elevation="0"
      text
      class="mt-3 ml-5"
      :loading="stripeLoading"
      @click="
        stripe();
        stripeLoading = true;
      "
      >Cancel current plan</v-btn
    >
    <div v-if="!subscription.active">
      <span class="d-flex align-end mt-4"
        ><span v-bind:class="{ 'primary--text font-weight-medium': !annual }"
          >Monthly</span
        ><v-switch
          inset
          v-model="annual"
          hide-details=""
          class="ml-4"
        ></v-switch
        ><span v-bind:class="{ 'primary--text font-weight-medium': annual }"
          >Yearly (10% discount, paid yearly)</span
        ></span
      >

      <Pricing @checkout="checkout" :annual="annual" ref="pricing" />
    </div>
  </div>
</template>

<script>
import { db, auth, app } from "@/firebase.js";
import firebase from "firebase/";
import { mapState } from "vuex";
import Pricing from "@/components/pricing/PricingCard.vue";

export default {
  name: "SubscriptionPage",
  components: {
    Pricing,
  },
  data() {
    return {
      annual: true,
      checkoutLoading: [
        { loading: false },
        { loading: false },
        { loading: false },
      ],
      invoicesLoading: false,
      stripeLoading: false,
      products: [],
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      workspace: (state) => state.workspace.workspace,
      subscription: (state) => state.subscription.subscription,
    }),
    trial() {
      return this.userProfile.trial && this.userProfile.trialEnd < new Date();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const vm = this;
      db.collection("products")
        .where("active", "==", true)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(async function (doc) {
            const product = doc.data();
            product.prices = [];
            const priceSnap = await doc.ref.collection("prices").get();
            priceSnap.docs.forEach((doc) => {
              const price = doc.data();
              price.id = doc.id;
              if (price.active) {
                product.prices.push(price);
              }
            });
            vm.products.push(product);
          });
        });
      var userRef = db.collection("users").doc(auth.currentUser.uid);
      userRef.update({
        visitedSubscriptions: firebase.firestore.FieldValue.increment(1),
      });
      if (vm.subscription.interval === "year") {
        this.annual = true;
      }
    },
    calendly() {
      window.open(
        "https://calendly.com/deepgaze-max-van-kaathoven/custom_offer",
        " _blank"
      );
    },
    openPricing() {
      window.open("https://www.brainsight.app/pricing", " _blank");
    },
    async checkout(i) {
      console.log("Purchasing " + i);

      // If 'freemium' then upgrade to freemium without Stripe
      if (
        i === "freemium" &&
        this.userProfile.workspace &&
        this.userProfile.role === "admin"
      ) {
        this.$emit("error", {
          text: "To downgrade to freemium you can cancel your plan. After the end-date you will be able to convert to Freemium.",
          dialog: true,
        });
        const child = this.$refs.pricing;
        child.resetLoading();
      } else if (i === "freemium" && this.userProfile.workspace) {
        let r = confirm("Are you sure?");
        if (r) {
          const free = await this.$store.dispatch(
            "removeUser",
            this.userProfile
          );
          if (free) {
            this.$emit("message", {
              text: "Thanks for picking the Free plan!",
              title: "Success",
              confetti: true,
              buttons: [
                {
                  text: "Continue",
                  route: "/home",
                  color: "success",
                },
              ],
            });
          }
          const child = this.$refs.pricing;
          child.resetLoading();
        }
      } else if (i === "freemium" && this.userProfile.role === "trial") {
        const free = await this.$store.dispatch("removeUser", this.userProfile);
        if (free) {
          const child = this.$refs.pricing;
          child.resetLoading();
          this.$emit("message", {
            dialog: true,
            confetti: true,
            text: "Thanks for picking the Free plan! Enjoy!",
            title: "Converted to free plan",
            buttons: [
              {
                text: "Continue",
                route: "/heatmaps",
                color: "success",
              },
            ],
          });
        }
      } else {
        // Else: use Stripe to complete payment
        const product = this.products.find((p) => p.name === i);
        const priceTag = product.prices.find(
          (p) => p.interval === (this.annual ? "year" : "month")
        ).id;

        const docRef = await db
          .collection("customers")
          .doc(auth.currentUser.uid)
          .collection("checkout_sessions")
          .add({
            price: priceTag,
            success_url: "https://ai.brainsight.app/success",
            cancel_url: "https://ai.brainsight.app/subscription",
            automatic_tax: true,
            tax_id_collection: true,
            allow_promotion_codes: true,
          });
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
          const { error, url } = snap.data();
          if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
            const child = this.$refs.pricing;
            child.resetLoading();
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.replace(url);
            const child = this.$refs.pricing;
            child.resetLoading();
          }
        });
        const child = this.$refs.pricing;
        child.resetLoading();
      }
    },
    async stripe() {
      let ext = window.location.origin.includes("ai.brainsight.app")
        ? "ext-firestore-stripe-payments-createPortalLink"
        : "ext-firestore-stripe-subscriptions-createPortalLink";
      const functionRef = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable(ext);
      const { data } = await functionRef({ returnUrl: window.location.origin });

      this.stripeLoading = false;
      this.invoicesLoading = false;
      window.open(data.url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.sub {
  font-size: 14pt;
  max-width: 850px;
}
.pricing {
  display: flex;
  gap: 30px;
}

.usage {
  max-width: 1200px;
  display: grid;
  grid-template-columns: 50% 50%;
}
.card {
  width: 250px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #282447;
  border: 2px solid #282447;
  border-radius: 6px;
  position: relative;

  .popular {
    background-color: #3e1191;
    height: 40px;
    border: 2px solid #282447;
    border-radius: 6px;
    width: 250px;
    position: absolute;
    top: -36px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10pt;
    font-weight: 600;
    letter-spacing: 1.3px;
  }

  .subtitle {
    font-size: 15pt;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  p {
    margin: 0;
  }

  .item {
    display: grid;
    grid-template-columns: 45px auto;

    .left {
      text-align: end;
      margin-right: 8px;
    }
  }
}

.request {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.line {
  width: 75px;
  border-bottom: 2px solid #6f6fff;
  margin: 10px 0;
}

.slide-fade-pricing-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-pricing-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-pricing-enter, .slide-fade-pricing-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
