<template>
  <div>
    <v-dialog v-model="dialog" persistent width="400px">
      <v-card>
        <v-card-title class="accent py-5 white--text">
          <v-icon color="white" class="mr-3">mdi-folder</v-icon
          ><span class="text-h5">Add to project</span>
        </v-card-title>
        <v-card-text>
          <v-select
            class="mt-4"
            multiple
            filled
            background-color="white"
            label="Project"
            hide-details
            clearable
            item-text="title"
            item-value="uid"
            v-model="projectIds"
            :items="projects"
            :menu-props="{ rounded: true, offsetY: true }"
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="close"> Close </v-btn>
          <v-btn color="warning" text @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import moment from "moment";
import { db } from "@/firebase";
import { mapState } from "vuex";
import firebase from "firebase";

export default {
  name: 'AddToProject',
  props: {
    addId: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      users: (state) => state.users.users,
      workspace: (state) => state.workspace.workspace,
      heatmaps: (state) => state.heatmaps.heatmaps,
      projects: (state) => state.projects.projects,
      tags: (state) => state.heatmaps.tags,
      defaults: (state) => state.defaults.defaults,
      subscription: (state) => state.subscription.subscription,
      reports: (state) => state.reports.reports,
    }),
  },
  data() {
    return {
      projectIds: [],
      dialog: true,
    };
  },
  mounted() {
    this.projectIds = [];
    this.projects.forEach((p) => {
      if (p.heatmaps.includes(this.addId)) {
        this.projectIds.push(p.uid);
      }
    });
  },
  methods: {
    close() {
      this.projectIds = [];
      this.dialog = false;
    },
    save() {
      const vm = this;
      this.projects.forEach((p) => {
        if (
          this.projectIds.includes(p.uid) &&
          !p.heatmaps.includes(this.addId)
        ) {
          var projectRef = db.collection("projects").doc(p.uid);
          projectRef.update({
            heatmaps: firebase.firestore.FieldValue.arrayUnion(`${vm.addId}`),
          });
        } else if (
          !this.projectIds.includes(p.uid) &&
          p.heatmaps.includes(this.addId)
        ) {
          var ref = db.collection("projects").doc(p.uid);
          ref.update({
            heatmaps: firebase.firestore.FieldValue.arrayRemove(`${vm.addId}`),
          });
        }
      });
      this.$store.dispatch("fetchProjects");
      this.$store.dispatch("fetchAllHeatmaps");
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
