<template>
  <div>
    <v-card
      color="dark"
      flat
      style="height: 80vh"
      class="mt-6 mr-4 ml-4 d-flex flex-column justify-space-between"
    >
      <div class="pa-4">
        <div
          style="
            display: grid;
            grid-template-columns: 50px auto;
            align-items: start;
          "
        >
          <v-avatar color="white" size="30" class="pa-1">
            <v-img :src="require('@/assets/chatgpt.svg')"></v-img
          ></v-avatar>
          <p style="font-size: 10pt">
            Message herodf jsodifj osidjf osijdf osidjf oisdjfo sdfjsoidf jsoid
            foisdj foisdjfoisdjf oisdjf oisdjf oisdj foisd fjse
          </p>
        </div>
      </div>
      <div class="pa-4">
        <v-divider class="my-4"></v-divider>
        <v-text-field
          variant="solo"
          rounded
          density="compact"
          bg-color="smoke"
          hide-details
          placeholder="Message"
        ></v-text-field>
      </div>
    </v-card>
  </div>
</template>
