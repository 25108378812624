<template>
  <div v-if="message != null">
    <v-dialog v-model="message.dialog" persistent width="500px">
      <v-card>
        <div class="lottie">
          <Vue3Lottie
            :options="defaultOptions"
            :height="300"
            :width="500"
            v-on:animCreated="handleAnimation"
          />
        </div>
        <v-card-title class="warning py-3 white--text">
          <v-icon small v-if="message.icon" color="white" class="mr-3">{{
            message.icon
          }}</v-icon
          ><span class="text-h6">{{ message.title }}</span>
        </v-card-title>
        <v-card-text class="py-6">
          <p style="font-size: 14pt" v-html="message.text"></p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="close()"> Close </v-btn>
          <v-btn
            elevation="0"
            :color="button.color"
            v-for="(button, index) in message.buttons"
            :key="index"
            @click="goTo(button.route)"
          >
            {{ button.text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Vue3Lottie from "vue3-lottie";
import * as animationData from "@/assets/celebration.json";

export default {
  props: {
    messageProp: {
      type: Object,
    },
  },
  components: {
    Vue3Lottie,
  },
  watch: {
    loading() {
      if (this.loading === false && this.message.confetti) {
        this.play();
      }
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      defaults: (state) => state.defaults.defaults,
    }),
  },
  data() {
    return {
      animationSpeed: 1.5,
      defaultOptions: {
        animationData: animationData.default,
        autoplay: false,
        loop: false,
      },
      message: this.messageProp,
      loaded: false,
      show: false,
    };
  },
  methods: {
    close() {
      this.$emit("message", {
        dialog: false,
        text: "",
        title: "",
        confetti: false,
        icon: "",
        buttons: [],
      });
    },
    goTo(r) {
      this.$router.push(r);
      this.$emit("message", {
        dialog: false,
        text: "",
        title: "",
        confetti: false,
        icon: "",
        buttons: [],
      });
    },
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    stop: function () {
      this.anim.stop();
    },
    play: function () {
      this.show = true;
      this.anim.play();
    },
    pause: function () {
      this.anim.pause();
    },
    onSpeedChange: function () {
      this.anim.setSpeed(this.animationSpeed);
    },
  },
};
</script>

<style lang="scss" scoped>
.lottie {
  position: absolute;
}
</style>
