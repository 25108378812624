<template>
  <div class="dashboard pa-4 justify-start d-flex">
    <Delete
      :dialog="dialogDelete"
      :deleteProjectId="deleteProjectId"
      :id="deleteId"
      @dialog="setDialogDelete"
    />
    <AddToProject
      v-if="addId"
      :addId="addId"
      :dialog="dialogProject"
      @dialog="setDialogProject"
    />
    <div class="right" v-if="!searching">
      <p
        style="margin-bottom: 5px; font-weight: 500"
        v-if="heatmapStore.length > 0"
      >
        Recent heatmaps
      </p>
      <div
        class="heatmaps"
        @mouseenter="hoverHeatmaps = true"
        @mouseleave="hoverHeatmaps = false"
      >
        <NoHeatmapCard
          v-if="heatmapStore.length === 0 && !$store.state.loading"
        />

        <Card
          v-if="heatmapStore[0]"
          :key="heatmapStore[0].uid"
          :heatmapProp="heatmapStore[0]"
          @analyze="openHeatmap"
          @share="shareHeatmap"
          @delete="deleteHeatmap"
          @addToProject="addToProject"
        />
        <Card
          :key="heatmapStore[1].uid"
          v-if="heatmapStore[1]"
          :heatmapProp="heatmapStore[1]"
          @analyze="openHeatmap"
          @share="shareHeatmap"
          @addToProject="addToProject"
        />
        <!-- <Card
          v-if="this.$store.state.heatmaps[2]"
          :heatmap="this.$store.state.heatmaps[2]"
        /> -->
        <v-btn
          v-if="heatmapStore.length > 0"
          elevation="0"
          rounded
          color="accent"
          @click="$router.push('/heatmaps')"
          >All heatmaps</v-btn
        >
      </div>
      <p
        v-if="projectStore.length > 0"
        style="margin-bottom: 15px; margin-top: 30px; font-weight: 500"
      >
        Recent projects
      </p>

      <div
        class="heatmaps"
        v-if="projectStore.length > 0"
        @mouseenter="hoverProjects = true"
        @mouseleave="hoverProjects = false"
      >
        <Project
          v-if="projectStore[0]"
          :projectProp="projectStore[0]"
          @delete="deleteProject"
          @open="open"
        />
        <Project
          v-if="projectStore[1]"
          :projectProp="projectStore[1]"
          @delete="deleteProject"
          @open="open"
        />
        <Project
          v-if="projectStore[2]"
          :projectProp="projectStore[2]"
          @delete="deleteProject"
          @open="open"
        />
        <!-- <Project
          v-if="this.$store.state.projects[2]"
          :project="this.$store.state.projects[2]"
        /> -->
        <v-btn
          elevation="0"
          rounded
          color="accent"
          @click="$router.push('/projects')"
        >
          All projects
        </v-btn>
      </div>
    </div>
    <div class="right pa-8 search" v-else>
      <Card
        v-for="(heatmap, index) in heatmaps"
        :key="index"
        :heatmapProp="heatmap"
        @analyze="openHeatmap"
        @delete="deleteHeatmap"
        @addToProject="addToProject"
      />
      <Project
        v-for="(project, index) in projects"
        :key="index"
        :projectProp="project"
        @open="open"
        @delete="deleteProject"
      />
    </div>
  </div>
</template>

<script>
import Card from "@/components/heatmap-card/HeatmapCard.vue";
import Project from "@/components/heatmap-project-card/HeatmapProjectCard.vue";
import Delete from "@/components/delete/DeleteCard.vue";
import AddToProject from "@/components/addToProject/AddToProject.vue";
import NoHeatmapCard from "@/components/no-heatmap/NoHeatmap.vue";
import { mapState } from "vuex";
// import firebase from "firebase/compat";

export default {
  name: "HomeDashboard",
  props: ["heatmaps", "searching", "projects"],
  components: {
    Card,
    Project,
    Delete,
    AddToProject,
    NoHeatmapCard,
  },
  data() {
    return {
      addId: null,
      deleteId: null,
      deleteProjectId: null,
      dialog: false,
      dialogDelete: false,
      dialogProject: false,
      hoverHeatmaps: false,
      hoverProjects: false,
      shareId: null,
      uploads: [],
    };
  },
  computed: {
    ...mapState({
      heatmapStore: (state) => state.heatmaps.heatmaps,
      projectStore: (state) => state.projects.projects,
    }),
  },
  methods: {
    addToProject(id) {
      this.addId = id;
      this.dialogProject = true;
    },
    deleteHeatmap(id) {
      this.deleteId = id;
      this.dialogDelete = true;
    },
    deleteProject(id) {
      this.deleteProjectId = id;
      this.dialogDelete = true;
    },
    openHeatmap(i) {
      this.$router.push(`/heatmap/${i}`);
    },
    open(val) {
      this.$router.push(`/project/${val}`);
    },
    setDialog(val) {
      this.dialog = val;
    },
    setDialogDelete(val) {
      this.dialogDelete = val;
    },
    setDialogProject(val) {
      this.dialogProject = val;
    },
    shareHeatmap(val) {
      this.shareId = val;
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.heatmaps {
  display: flex;
  gap: 30px;
  align-items: center;
}
.search {
  display: flex;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;
}
</style>
