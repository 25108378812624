<template>
  <div v-if="benchmark">
    <div class="">
      <div class="d-flex align-center">
        <v-tabs class="px-4" v-model="tab" bg-color="transparent">
          <v-tab style="font-size: 10pt" value="settings">Settings</v-tab>
          <v-tab style="font-size: 10pt" value="templates">Templates</v-tab>
          <v-tab style="font-size: 10pt" value="subjects">Subjects</v-tab>
        </v-tabs>
        <v-spacer></v-spacer>

        <v-menu bottom offset-y v-if="benchmark">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              elevation="0"
              size="small"
              variant="text"
              icon="mdi-dots-vertical"
              class="mr-4"
            >
            </v-btn>
          </template>

          <v-list density="compact">
            <v-list-item
              v-if="benchmark.status === 'ready'"
              style="font-size: 11pt"
              link
              @click="buildBenchmark"
            >
              Build benchmark
            </v-list-item>
            <v-list-item style="font-size: 11pt" link>
              Download CSV
            </v-list-item>
            <v-list-item
              style="font-size: 11pt"
              link
              @click="firebase(benchmark.uid)"
            >
              Open in Firebase
            </v-list-item>
            <v-list-item style="font-size: 11pt" link>
              Delete benchmark</v-list-item
            >
          </v-list>
        </v-menu>
      </div>
      <v-divider></v-divider>
    </div>

    <div v-if="tab === 'settings'" class="px-4">
      <div class="mt-4" style="max-width: 300px">
        <p style="font-size: 10pt" class="mb-1">Name:</p>
        <v-text-field
          v-model="benchmark.name"
          hide-details
          variant="solo"
          flat
        ></v-text-field>
      </div>

      <div class="mt-4">
        <p style="font-size: 10pt" class="mb-1">Status:</p>
        <v-chip
          variant="flat"
          v-if="benchmark"
          :color="benchmark.status === 'ready' ? 'success' : 'info'"
        >
          <div class="px-3">
            {{ benchmark.status }}
            <v-progress-circular
              indeterminate
              size="15"
              width="1"
              class="ml-2"
              v-if="benchmark.status != 'ready'"
            ></v-progress-circular>
          </div>
        </v-chip>
      </div>

      <div class="mt-4" style="max-width: 920px">
        <p style="font-size: 10pt" class="mb-1">Templates:</p>
        <div
          v-if="benchmark"
          style="display: grid; grid-template-columns: 1fr 1fr 1fr; gap: 10px"
        >
          <div>
            <v-card flat>
              <v-list class="pb-0">
                <p class="px-4 pt-1 pb-2" style="font-size: 9pt">Categories:</p>

                <v-divider></v-divider>
                <v-list-item
                  @click="setCategory(c, 'templates')"
                  v-for="c in benchmark.categories_templates"
                  :key="c.name"
                  ><div class="d-flex justify-space-between align-center">
                    <div>
                      <p>{{ c.name }}</p>
                      <p style="font-size: 8pt">
                        {{ c.values.length }} options
                      </p>
                    </div>
                    <v-btn
                      variant="text"
                      size="small"
                      icon
                      @click.stop="deleteCategory(c, 'templates')"
                      ><v-icon size="small">mdi-delete</v-icon></v-btn
                    >
                  </div>
                </v-list-item>

                <div>
                  <v-divider></v-divider>
                  <v-text-field
                    placeholder="New category"
                    variant="solo"
                    flat
                    density="compact"
                    append-inner-icon="mdi-plus"
                    v-model="new_category"
                    hide-details
                    @keyup.enter="addCategory('templates')"
                    @click:append-inner="addCategory('templates')"
                  ></v-text-field>
                </div>
              </v-list>
            </v-card>
          </div>
          <div v-if="category.templates">
            <AdjustTemplate
              :value="selected_value"
              :dialog="template_options.show"
              @close="template_options.show = false"
              :template_name="category.templates.name"
            />
            <v-card flat>
              <v-list class="pb-0">
                <p class="px-4 py-1" style="font-size: 9pt">Options:</p>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(c, i) in category.templates.values"
                  :key="i"
                >
                  <div class="d-flex justify-space-between align-center">
                    <div>
                      <p>{{ c.value }}</p>
                    </div>
                    <div>
                      <v-btn
                        variant="text"
                        size="small"
                        icon
                        @click.stop="selectValue(c)"
                        ><v-icon size="small">mdi-cog</v-icon></v-btn
                      >
                      <v-btn
                        variant="text"
                        size="small"
                        icon
                        @click.stop="deleteValue(i, 'templates')"
                        ><v-icon size="small">mdi-delete</v-icon></v-btn
                      >
                    </div>
                  </div>
                </v-list-item>
                <div>
                  <v-divider></v-divider>
                  <v-text-field
                    placeholder="New value"
                    variant="solo"
                    flat
                    density="compact"
                    append-inner-icon="mdi-plus"
                    v-model="new_value"
                    hide-details
                    @keyup.enter="addValue('templates')"
                    @click:append-inner="addValue('templates')"
                  ></v-text-field>
                </div>
              </v-list>
            </v-card>
          </div>
        </div>
      </div>
      <div class="mt-4" style="max-width: 920px">
        <p style="font-size: 10pt" class="mb-1">Subjects:</p>
        <div
          v-if="benchmark"
          style="display: grid; grid-template-columns: 1fr 1fr 1fr; gap: 10px"
        >
          <div>
            <v-card flat>
              <v-list class="pb-0">
                <p class="px-4 pt-1 pb-2" style="font-size: 9pt">Categories:</p>

                <v-divider></v-divider>
                <v-list-item
                  @click="setCategory(c, 'subjects')"
                  v-for="c in benchmark.categories_subjects"
                  :key="c.name"
                >
                  <div class="d-flex justify-space-between align-center">
                    <div>
                      <p>{{ c.name }}</p>
                      <p style="font-size: 8pt">
                        {{ c.values.length }} options
                      </p>
                    </div>
                    <v-btn
                      variant="text"
                      size="small"
                      icon
                      @click.stop="deleteCategory(c, 'subjects')"
                      ><v-icon size="small">mdi-delete</v-icon>
                    </v-btn>
                  </div></v-list-item
                >

                <div>
                  <v-divider></v-divider>
                  <v-text-field
                    placeholder="New category"
                    variant="solo"
                    flat
                    density="compact"
                    append-inner-icon="mdi-plus"
                    v-model="new_subject"
                    hide-details
                    @keyup.enter="addCategory('subjects')"
                    @click:append-inner="addCategory('subjects')"
                  ></v-text-field>
                </div>
              </v-list>
            </v-card>
          </div>
          <div v-if="category.subjects">
            <v-card flat>
              <v-list class="pb-0">
                <p class="px-4 py-1" style="font-size: 9pt">Options:</p>
                <v-divider></v-divider>
                <v-list-item
                  v-for="(c, i) in category.subjects.values"
                  :key="i"
                >
                  <div class="d-flex justify-space-between align-center">
                    <div>
                      <p>{{ c }}</p>
                    </div>
                    <v-btn
                      variant="text"
                      size="small"
                      icon
                      @click.stop="deleteValue(i, 'subjects')"
                      ><v-icon size="small">mdi-delete</v-icon></v-btn
                    >
                  </div>
                </v-list-item>
                <div>
                  <v-divider></v-divider>
                  <v-text-field
                    placeholder="New value"
                    variant="solo"
                    flat
                    density="compact"
                    append-inner-icon="mdi-plus"
                    v-model="new_value"
                    hide-details
                    @keyup.enter="addValue('subjects')"
                    @click:append-inner="addValue('subjects')"
                  ></v-text-field>
                </div>
              </v-list>
            </v-card>
          </div>
        </div>
      </div>

      <div
        style="
          max-width: 300px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
        "
      >
        <div class="mt-4" style="max-width: 300px">
          <p style="font-size: 10pt" class="mb-1">Version:</p>
          <v-text-field
            v-model="benchmark.version"
            type="number"
            hide-details
            variant="solo"
            flat
          ></v-text-field>
        </div>
        <div
          v-if="benchmark.next_version"
          class="mt-4"
          style="max-width: 300px"
        >
          <p style="font-size: 10pt" class="mb-1">Building next version:</p>
          <v-text-field
            v-model="benchmark.next_version"
            type="number"
            hide-details
            variant="solo"
            :loading="true"
            flat
            readonly
          ></v-text-field>
        </div>
      </div>

      <div class="mt-4" style="max-width: 300px">
        <p style="font-size: 10pt" class="mb-1">Access:</p>
        <v-select
          v-model="benchmark.access"
          variant="solo"
          flat
          hide-details
          class="mb-2"
          :items="['admin', 'beta', 'public', 'restricted']"
        ></v-select>
        <v-select
          label="Restricted access:"
          v-if="benchmark.access === 'restricted'"
          v-model="benchmark.access_restricted"
          hide-details
          multiple
          variant="solo"
          flat
          :items="workspaces"
          item-title="name"
          item-value="uid"
        ></v-select>
      </div>

      <p style="font-size: 10pt" class="mb-2 mt-4">Remaining:</p>
      <div
        style="
          max-width: 450px;
          display: grid;
          align-items: center;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          gap: 10px;
        "
      >
        <div>
          <v-text-field
            :model-value="benchmark.remaining_attention"
            readonly
            label="Attention"
            hide-details
            variant="solo"
            flat
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            :model-value="benchmark.remaining_templates"
            readonly
            label="Templates"
            hide-details
            variant="solo"
            flat
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            readonly
            label="Clarity"
            :model-value="benchmark.remaining_clarity"
            hide-details
            variant="solo"
            flat
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            readonly
            :model-value="benchmark.failed.length"
            hide-details
            label="Failed"
            variant="solo"
            flat
          ></v-text-field>
        </div>
        <div>
          <v-btn variant="text" @click="resetRemaining" flat icon
            ><v-icon>mdi-refresh</v-icon></v-btn
          >
        </div>
      </div>

      <div class="d-flex mb-10">
        <v-btn
          :disabled="benchmark.status != 'ready'"
          class="mt-4"
          color="primary"
          @click="save"
          flat
          >Save</v-btn
        >
      </div>
    </div>

    <div v-if="tab === 'subjects'">
      <div class="d-flex py-2 pr-4 align-center">
        <AddSubject />
        <v-text-field
          variant="solo"
          hide-details
          density="compact"
          flat
          bg-color="white"
          placeholder="Search"
          v-model="search"
        ></v-text-field>
        <Transition name="slide-fade">
          <div class="ml-2" v-if="selected_subjects.length > 0">
            <v-btn @click="deleteSubjects()" height="40" variant="tonal"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </div>
        </Transition>
      </div>
      <v-divider></v-divider>
      <v-data-table-virtual
        show-select
        item-value="uid"
        dense
        height="calc(100vh - 72px - 50px - 56px)"
        hide-default-header
        :headers="headers"
        :items="benchmark.subjects"
        :search="search"
        :loading="loading"
        v-model="selected_subjects"
        class="bg-smoke"
        return-object
      >
        <template v-slot:[`item.thumbnail`]="{ item }">
          <div style="height: 250px; width: 250px">
            <v-img :key="item.thumbnail" height="250" :src="item.thumbnail">
            </v-img>
          </div>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            variant="flat"
            v-if="item.status"
            :color="item.status === 'ready' ? 'success' : 'info'"
          >
            <div class="px-3">
              {{ item.status }}
              <v-progress-circular
                indeterminate
                size="15"
                width="1"
                class="ml-2"
                v-if="item.status != 'ready'"
              ></v-progress-circular>
            </div>
          </v-chip>
        </template>

        <template v-slot:[`item.categories`]="{ item }">
          <div class="d-flex align-center flex-column" style="gap: 8px">
            <div v-for="(c, i) in benchmark.categories_subjects" :key="i">
              <v-select
                style="width: 300px"
                hide-details
                variant="solo"
                flat
                bg-color="white"
                :label="c.name"
                v-model="item.categories[c.name]"
                :items="c.values"
                @update:modelValue="updateItem(item, 'subjects')"
              ></v-select>
            </div>
          </div>
        </template>

        <!-- <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              @click.stop="user = item"
              icon="mdi-chevron-right"
              variant="tonal"
              size="small"
              :color="user && user.uid === item.uid ? 'primary' : ''"
              density="comfortable"
              class="mr-2"
            ></v-btn>
          </template> -->
      </v-data-table-virtual>
    </div>

    <div v-if="tab === 'templates'">
      <div class="d-flex py-2 pr-4 align-center">
        <AddTemplate />
        <v-text-field
          variant="solo"
          hide-details
          density="compact"
          flat
          bg-color="white"
          placeholder="Search"
          v-model="search"
        ></v-text-field>
        <Transition name="slide-fade">
          <div class="ml-2" v-if="selected_templates.length > 0">
            <v-btn @click="deleteTemplates()" height="40" variant="tonal"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </div>
        </Transition>
      </div>
      <v-divider></v-divider>
      <v-data-table-virtual
        show-select
        item-value="uid"
        dense
        height="calc(100vh - 72px - 81px - 56px)"
        hide-default-header
        :headers="headers"
        :items="benchmark.templates"
        :search="search"
        :loading="loading"
        v-model="selected_templates"
        class="bg-smoke"
        return-object
      >
        <template v-slot:[`item.thumbnail`]="{ item }">
          <div style="height: 300px; position: relative">
            <img
              :key="item.thumbnail"
              style="height: 100%; object-fit: contain"
              :src="item.thumbnail"
              :ref="`image_${item.uid}`"
              @load="drawGreenArea(item)"
            />
            <canvas
              :ref="`canvas_${item.uid}`"
              :height="300"
              :width="400"
              style="position: absolute; top: 0; left: 0; pointer-events: none"
            ></canvas>
          </div>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            variant="flat"
            v-if="item.status"
            :color="item.status === 'ready' ? 'success' : 'info'"
          >
            <div class="px-3">
              {{ item.status }}
              <v-progress-circular
                indeterminate
                size="15"
                width="1"
                class="ml-2"
                v-if="item.status != 'ready'"
              ></v-progress-circular>
            </div>
          </v-chip>
        </template>

        <template v-slot:[`item.categories`]="{ item }">
          <div class="d-flex align-center flex-column" style="gap: 8px">
            <div v-for="(c, i) in benchmark.categories_templates" :key="i">
              <v-select
                style="width: 300px"
                hide-details
                variant="solo"
                flat
                bg-color="white"
                :label="c.name"
                v-model="item.categories[c.name]"
                :items="c.values"
                item-title="value"
                @update:modelValue="updateItem(item, 'templates')"
              ></v-select>
            </div>
          </div>
        </template>

        <!-- <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon="mdi-dots-vertical"
            variant="text"
            size="small"
            density="comfortable"
            class="mr-2"
            @click="() => console.log(item)"
          ></v-btn>
        </template> -->
      </v-data-table-virtual>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase";
import firebase from "firebase/";
import AddTemplate from "../AddTemplate/index.vue";
import AddSubject from "../AddSubject";
import AdjustTemplate from "../adjustTemplate.vue";

export default {
  components: {
    AddTemplate,
    AddSubject,
    AdjustTemplate,
  },
  computed: {
    benchmark: {
      get() {
        return this.$store.state.benchmarks.benchmark;
      },
      set(value) {
        this.$store.commit("setBenchmarkValue", value);
      },
    },
  },
  data() {
    return {
      category: {
        templates: null,
        subjects: null,
      },
      headers: [
        {
          title: "Thumbnail",
          sortable: true,
          value: "thumbnail",
        },
        {
          title: "Categories",
          sortable: true,
          value: "categories",
        },
        {
          title: "Status",
          sortable: true,
          value: "status",
        },
        {
          title: "Actions",
          sortable: true,
          value: "actions",
        },
      ],
      loading: false,
      listener: null,
      new_category: "",
      new_value: "",
      new_subject: "",
      search: "",
      selected_subjects: [],
      selected_templates: [],
      selected_value: null,
      template_options: { show: false, options: [] },
      tab: "settings",
    };
  },
  async mounted() {
    await this.$store.dispatch("fetchBenchmark", this.$route.params.id);

    this.listener = db.collection("benchmarks").onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "modified") {
          console.log("modified");
          this.benchmark.remaining_attention =
            change.doc.data().remaining_attention;

          this.benchmark.remaining_clarity =
            change.doc.data().remaining_clarity;

          this.benchmark.remaining_templates =
            change.doc.data().remaining_templates;

          this.benchmark.failed = change.doc.data().failed;

          this.benchmark.status = change.doc.data().status;
          this.benchmark.version = change.doc.data().version;

          this.benchmark.categories_templates =
            change.doc.data().categories_templates;
          if (this.category.templates) {
            this.category.templates = change.doc
              .data()
              .categories_templates.find(
                (c) => c.name === this.category.templates.name
              );
          }
          if (change.doc.data().next_version && !this.benchmark.next_version) {
            this.benchmark.next_version = change.doc.data().next_version;
          } else if (
            !change.doc.data().next_version &&
            this.benchmark.next_version
          ) {
            delete this.benchmark.next_version;
          }
          this.benchmark.categories_subjects =
            change.doc.data().categories_subjects;

          if (this.category.subjects) {
            this.category.subjects = change.doc
              .data()
              .categories_subjects.find(
                (c) => c.name === this.category.subjects.name
              );
          }
        }
      });
    });
  },
  async beforeUnmount() {
    this.listener();
  },
  methods: {
    setCategory(val, type) {
      this.category = {
        templates: null,
        subjects: null,
      };
      if (!this.category[type]) {
        this.category[type] = val;
      }
    },
    async addCategory(type) {
      let n = type === "templates" ? this.new_category : this.new_subject;
      let r =
        type === "templates" ? "categories_templates" : "categories_subjects";
      if (n) {
        await db
          .collection("benchmarks")
          .doc(this.benchmark.uid)
          .update({
            [r]: firebase.firestore.FieldValue.arrayUnion({
              values: [],
              name: n,
            }),
          });

        this.category[type] = {
          values: [],
          name: n,
        };
        this.new_category = "";
        this.new_subject = "";
        this.$store.commit("setSnackbar", {
          text: "Category added",
          timeout: 2000,
          show: true,
        });
      }
    },
    async addValue(type) {
      let i = this.benchmark[`categories_${type}`].findIndex(
        (c) => c.name === this.category[type].name
      );
      let r =
        type === "templates" ? "categories_templates" : "categories_subjects";
      if (
        this.new_value &&
        !this.benchmark[`categories_${type}`][i].values.includes(this.new_value)
      ) {
        let updated_categories = this.benchmark[`categories_${type}`];
        console.log(updated_categories);

        updated_categories[i].values.push({
          value: this.new_value,
          options: [],
        });
        this.new_value = "";
        await db
          .collection("benchmarks")
          .doc(this.benchmark.uid)
          .update({
            [r]: updated_categories,
          });

        this.$store.commit("setSnackbar", {
          text: "Value added",
          timeout: 2000,
          show: true,
        });
      }
    },
    drawGreenArea(item) {
      console.log(item);
      const canvas = this.$refs[`canvas_${item.uid}`];
      const ctx = canvas.getContext("2d");

      // Ensure canvas matches the image dimensions
      const img = this.$refs[`image_${item.uid}`];
      this.canvasWidth = img.naturalWidth;
      this.canvasHeight = img.naturalHeight;
      let ratio = item.originalHeight / 300;
      console.log("ratio: " + ratio);

      // Clear previous drawings
      ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

      // Set fill style for the polygon
      ctx.fillStyle = "rgba(0, 255, 0, 0.8)";

      // Draw the polygon
      let coordinates = [
        [
          item.coordinates.left_top.x / ratio,
          item.coordinates.left_top.y / ratio,
        ],
        [
          item.coordinates.right_top.x / ratio,
          item.coordinates.right_top.y / ratio,
        ],
        [
          item.coordinates.right_bottom.x / ratio,
          item.coordinates.right_bottom.y / ratio,
        ],
        [
          item.coordinates.left_bottom.x / ratio,
          item.coordinates.left_bottom.y / ratio,
        ],
      ];

      console.log(coordinates);
      ctx.beginPath();
      ctx.moveTo(coordinates[0][0], coordinates[0][1]);
      for (let i = 1; i < coordinates.length; i++) {
        ctx.lineTo(coordinates[i][0], coordinates[i][1]);
      }
      ctx.closePath();
      ctx.fill();
    },
    async deleteValue(i, type) {
      let updated_values = this.category[type].values;
      let val = this.category[type].name;
      let value = this.category[type].values[i];
      console.log(val);
      updated_values.splice(i, 1);
      let updated_categories = this.benchmark[`categories_${type}`];
      let idx = updated_categories.findIndex(
        (c) => c.name === this.category[type].name
      );
      updated_categories[idx].values = updated_values;

      let t =
        type === "templates" ? "categories_templates" : "categories_subjects";
      await db
        .collection("benchmarks")
        .doc(this.benchmark.uid)
        .update({
          [t]: updated_categories,
        });

      const querySnapshot = await db
        .collection("benchmarks")
        .doc(this.benchmark.uid)
        .collection(type)
        .get();

      await Promise.all(
        querySnapshot.docs.map(async (item) => {
          if (
            item.data().categories.hasOwnProperty(val) &&
            item.data().categories[val] === value.value
          ) {
            let r = `categories.${val}`;
            await db
              .collection("benchmarks")
              .doc(this.benchmark.uid)
              .collection(type)
              .doc(item.id)
              .update({
                [r]: null,
              });
          }
        })
      );

      this.$store.commit("setSnackbar", {
        text: "Value deleted",
        timeout: 2000,
        show: true,
      });
    },
    async deleteCategory(v, type) {
      let s = window.confirm("Are you sure?");
      if (s) {
        console.log(v);
        let r =
          type === "templates" ? "categories_templates" : "categories_subjects";
        await db
          .collection("benchmarks")
          .doc(this.benchmark.uid)
          .update({
            [r]: firebase.firestore.FieldValue.arrayRemove(v),
          });

        const querySnapshot = await db
          .collection("benchmarks")
          .doc(this.benchmark.uid)
          .collection(type)
          .get();

        await Promise.all(
          querySnapshot.docs.map(async (item) => {
            if (item.data().categories.hasOwnProperty(v.name)) {
              let r = `categories.${v.name}`;
              await db
                .collection("benchmarks")
                .doc(this.benchmark.uid)
                .collection(type)
                .doc(item.id)
                .update({
                  [r]: firebase.firestore.FieldValue.delete(),
                });
            }
          })
        );
        this.$store.commit("setSnackbar", {
          text: "Category removed",
          timeout: 2000,
          show: true,
        });
      }
    },
    firebase(uid) {
      let production = window.location.origin.includes("brainsight");
      let url = `https://console.firebase.google.com/u/0/project/${
        production ? "deepgaze-io" : "deepgaze-staging"
      }/firestore/data/~2Fbenchmarks~2F${uid}`;
      window.open(url, "_blank");
    },
    async resetRemaining() {
      await db.collection("benchmarks").doc(this.benchmark.uid).update({
        remaining_templates: 0,
        remaining_attention: 0,
        failed: [],
        remaining_clarity: 0,
      });
    },
    async buildBenchmark() {
      await db
        .collection("benchmarks")
        .doc(this.benchmark.uid)
        .update({ status: "requested" });
      await this.$store.dispatch("fetchBenchmark", this.benchmark.uid);

      this.$store.commit("setSnackbar", {
        text: "Building benchmark...",
        timeout: 2000,
        show: true,
      });
    },
    async deleteSubjects() {
      this.$store.commit("setLoading", true);

      await Promise.all(
        this.selected_subjects.map(async (t) => {
          console.log(t);
          await db
            .collection("benchmarks")
            .doc(this.benchmark.uid)
            .collection("subjects")
            .doc(t.uid)
            .delete();
          var storageRef = firebase.storage().ref();
          let path = storageRef.child(
            `benchmarks/${this.benchmark.uid}/subjects/${t.uid}.${t.extension}`
          );

          path.delete();
        })
      );
      this.selected_subjects = [];
      await this.$store.dispatch("fetchBenchmark", this.benchmark.uid);
      this.$store.commit("setLoading", false);
      this.$store.commit("setSnackbar", {
        text: "Subject(s) deleted",
        timeout: 2000,
        show: true,
      });
    },
    async deleteTemplates() {
      this.$store.commit("setLoading", true);

      await Promise.all(
        this.selected_templates.map(async (t) => {
          console.log(t);
          await db
            .collection("benchmarks")
            .doc(this.benchmark.uid)
            .collection("templates")
            .doc(t.uid)
            .delete();
          var storageRef = firebase.storage().ref();
          let path = storageRef.child(
            `benchmarks/${this.benchmark.uid}/templates/${t.uid}.${t.extension}`
          );
          // Delete the file
          path.delete();
        })
      );
      this.selected_templates = [];
      await this.$store.dispatch("fetchBenchmark", this.benchmark.uid);
      this.$store.commit("setLoading", false);
      this.$store.commit("setSnackbar", {
        text: "Template(s) deleted",
        timeout: 2000,
        show: true,
      });
    },

    async onFileChange() {
      var files = this.$refs.uploadRef.files;

      for (const [key] of Object.entries(files)) {
        await this.upload(files[key]);
      }
      this.$store.commit("setSnackbar", {
        text: "Subject(s) added!",
        timeout: 2000,
        show: true,
      });
    },
    async updateItem(item, type) {
      await db
        .collection("benchmarks")
        .doc(this.benchmark.uid)
        .collection(type)
        .doc(item.uid)
        .update(item);

      this.$store.commit("setSnackbar", {
        text: `${type.substring(0, type.length - 1)} updated`,
        timeout: 2000,
        show: true,
      });
    },
    trigger() {
      this.$refs.uploadRef.click();
    },
    async save() {
      let update = {
        name: this.benchmark.name,
        access: this.benchmark.access,
        access_restricted: this.benchmark.access_restricted,
      };
      await db.collection("benchmarks").doc(this.benchmark.uid).update(update);

      this.$store.commit("setSnackbar", {
        text: "Benchmark updated",
        timeout: 2000,
        show: true,
      });
    },
    async upload(upload) {
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            console.log(upload);
            var fileExtension = upload.name.split(".").pop();
            var storageRef = firebase.storage().ref();

            const docRef = await db
              .collection("benchmarks")
              .doc(this.benchmark.uid)
              .collection("subjects")
              .add({
                extension: fileExtension,
                thumbnail: null,
                created: Math.round(Date.now() / 1000),
                categories: {},
              });
            await db
              .collection("benchmarks")
              .doc(this.benchmark.uid)
              .collection("subjects")
              .doc(docRef.id)
              .update({
                uid: docRef.id,
              });

            let path = storageRef.child(
              `benchmarks/${this.benchmark.uid}/subjects/${docRef.id}.${fileExtension}`
            );

            var uploadTask = path.put(upload);

            this.$store.commit("setLoading", true);
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                var progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(progress);

                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    // console.log("Upload is paused");
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    // console.log("Upload is running");
                    break;
                }
              },
              (error) => {
                switch (error.code) {
                  case "storage/unauthorized":
                    // User doesn't have permission to access the object
                    break;
                  case "storage/canceled":
                    // User canceled the upload
                    break;

                  // ...

                  case "storage/unknown":
                    // Unknown error occurred, inspect error.serverResponse
                    break;
                }
              },
              async () => {
                uploadTask.snapshot.ref
                  .getDownloadURL()
                  .then(async (downloadURL) => {
                    await db
                      .collection("benchmarks")
                      .doc(this.benchmark.uid)
                      .collection("subjects")
                      .doc(docRef.id)
                      .update({
                        src: downloadURL,
                        thumbnail: downloadURL,
                      });
                    this.$store.commit("setLoading", false);
                    this.$store.dispatch("fetchBenchmark", this.benchmark.uid);
                    return resolve();
                  });
              }
            );
          } catch (error) {
            reject();
          }
        })();
      });
    },
    selectValue(v) {
      this.template_options.show = true;
      this.selected_value = v;
    },
  },
};
</script>
<style scoped lang="scss">
@import "./styles.scss";
</style>
