import firebase from "firebase/";
import { db } from "@/firebase";

const benchmarks = {
  state: {
    benchmarks: [],
    benchmark: null,
  },
  mutations: {
    setBenchmarks(state, val) {
      state.benchmarks = val;
    },
    setBenchmark(state, val) {
      state.benchmark = val;
    },
    setBenchmarkValue(state, val) {
      state.benchmark[val.key] = val.value;
    },
  },
  actions: {
    fetchBenchmarks({ commit, rootState }) {
      return new Promise(async (resolve) => {
        var getBenchmarks = firebase
          .app()
          .functions("europe-west1")
          .httpsCallable("getBenchmarks");
        const res = await getBenchmarks(rootState.workspace.workspace.uid);
        commit("setBenchmarks", res.data);
        resolve();
      });
    },
    fetchBenchmark({ commit }, uid) {
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            const doc = await db.collection("benchmarks").doc(uid).get();
            let bm = doc.data();
            bm.templates = [];
            bm.subjects = [];
            const tempSnap = await db
              .collection("benchmarks")
              .doc(uid)
              .collection("templates")
              .get();
            const subSnap = await db
              .collection("benchmarks")
              .doc(uid)
              .collection("subjects")
              .get();

            await Promise.all(
              tempSnap.docs.map((t) => {
                bm.templates.push(t.data());
              })
            );
            await Promise.all(
              subSnap.docs.map((s) => {
                bm.subjects.push(s.data());
              })
            );
            bm.templates.sort((a, b) =>
              a.created < b.created ? 1 : b.created < a.created ? -1 : 0
            );
            bm.subjects.sort((a, b) =>
              a.created < b.created ? 1 : b.created < a.created ? -1 : 0
            );

            commit("setBenchmark", bm);
            return resolve();
          } catch (err) {
            return reject(err);
          }
        })();
      });
    },
  },
  getters: {},
};

export default benchmarks;
