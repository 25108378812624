<template>
  <div>
    <v-dialog v-model="dialog" persistent width="400px">
      <v-card>
        <template slot="progress">
          <v-progress-linear
            color="accent"
            height="5"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-card-title class="accent py-5 white--text">
          <v-icon color="white" class="mr-3">mdi-folder-outline</v-icon
          ><span class="text-h5">New project</span>
        </v-card-title>

        <v-card-text>
          <v-text-field
            class="mt-4"
            label="Project title"
            hide-details
            filled
            background-color="white"
            flat
            v-model="title"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="
              title = '';
              $emit('dialog', false);
            "
          >
            Close
          </v-btn>
          <v-btn color="warning" text @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import moment from "moment";
import { db, auth } from "@/firebase";
import { mapState } from "vuex";

export default {
  props: {
    upload: {
      type: String,
    },
    dialogProp: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      defaults: (state) => state.defaults.defaults,
    }),
  },
  data() {
    return {
      dialog: this.dialogProp,
      title: "",
    };
  },
  methods: {
    save() {
      const vm = this;
      db.collection("projects")
        .add({
          title: vm.title,
          creator_uid: auth.currentUser.uid,
          heatmaps: [],
          created_at: new Date(),
          workspace: vm.userProfile.workspace,
        })
        .then((docRef) => {
          var doc = db.collection("projects").doc(docRef.id);

          return doc
            .update({
              uid: docRef.id,
            })
            .then(() => {
              console.log("Document successfully updated!");
              vm.$emit("dialog", false);
            })
            .then(() => {
              this.$store.dispatch("fetchProjects");
              vm.$router.push(`/projects`);
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        })

        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
