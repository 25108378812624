<template>
  <div
    v-show="show"
    @drop="handleDrop"
    @dragover="allowDrag"
    ref="dropzone"
    id="dropzone"
    class="dropzone"
  >
    <div class="dropscreen">
      <h1>&#128293;&#128293;&#128293;</h1>
      <h1>Drop it like it's hot</h1>
      <p>Add files by dropping them here</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["show"],
  methods: {
    allowDrag(e) {
      if (true) {
        // Test that the item being dragged is a valid one
        e.dataTransfer.dropEffect = "copy";
        e.preventDefault();
      }
    },
    handleDrop(e) {
      console.log("Dropping");
      e.preventDefault();
      console.log(e.dataTransfer.files);
      this.$emit("hide");
      this.$store.dispatch("addFiles", e.dataTransfer.files);
      console.log("Finishd Dropping");
    },
  },
};
</script>

<style lang="scss" scoped>
.dropzone {
  box-sizing: border-box;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 99999;
}

.dropscreen {
  background-color: rgba($color: #282447, $alpha: 0.8);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
}
</style>
