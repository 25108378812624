<template>
  <v-card flat min-width="400" max-width="1000">
    <v-card-title
      class="px-5 d-flex bg-primary white--text font-weight-regular"
      v-bind:class="{ accent: kpi === 'Clarity', warning: kpi === 'Attention' }"
      v-if="winnerCard"
      >&#127942; Highest
      <span class="font-weight-bold mx-1">{{ kpi }}</span> Score
      <v-spacer></v-spacer>
      <!-- <v-btn
        color="lighten-1"
        elevation="0"
        class="white--text"
        @click="$emit('tab', kpi === 'Attention' ? 1 : 2)"
        v-bind:class="{
          accent: kpi === 'Clarity',
          warning: kpi === 'Attention',
        }"
        >View all</v-btn
      > -->
    </v-card-title>
    <v-card-title
      class="px-5 accent white--text font-weight-regular"
      v-if="!winnerCard"
    >
      <span class="font-weight-bold mx-1">{{ kpi }}</span> Score
      <v-spacer></v-spacer>
      <v-btn
        v-if="winnerCard"
        color="lighten-1"
        elevation="0"
        class="white--text"
        v-bind:class="{
          accent: kpi === 'Clarity',
          warning: kpi === 'Attention',
        }"
        >View all</v-btn
      >
    </v-card-title>
    <div class="split">
      <div class="middle" style="flex-direction: column">
        <v-img
          :src="image"
          :max-height="expand ? 1000 : 200"
          width="200"
          :style="!expand ? 'border-radius: 6px' : ''"
        >
          <transition name="fade">
            <v-btn v-if="!expand" absolute bottom left class="mx-auto"
              >{{ ranking }} <span v-if="rank === 1">&#127942;</span></v-btn
            >
          </transition>
        </v-img>
        <transition name="fade">
          <v-btn-toggle
            mandatory
            v-if="expand"
            class="mt-3"
            v-model="view"
            color="secondary"
          >
            <v-btn value="original">
              <v-icon>mdi-image</v-icon>
            </v-btn>
            <v-btn value="heatmap">
              <v-icon>mdi-fire</v-icon>
            </v-btn>

            <v-btn value="peekthrough">
              <v-icon>mdi-eye-arrow-right-outline</v-icon>
            </v-btn>
          </v-btn-toggle>
        </transition>
      </div>
      <div class="middle">
        <p>
          <span
            v-if="kpi === 'Attention'"
            style="font-size: 24pt; margin-bottom: 30px; font-weight: 800"
            >{{ Math.round(winner.template_average) }}%
            <span v-if="winner.template_average > 70">&#128079;</span></span
          >
          <span
            v-if="kpi === 'Clarity'"
            style="font-size: 24pt; margin-bottom: 30px; font-weight: 800"
            >{{ winner.clarity_score_percentile }}%
            <span v-if="winner.clarity_score_percentile > 70"
              >&#128079;</span
            ></span
          >
          <br />
          <span>{{ kpi }} score <v-icon small>mdi-copyright</v-icon></span>
          <br />
          <span
            style="font-size: 10pt"
            class="grey--text"
            v-if="kpi === 'Attention'"
            >Clarity score: {{ winner.clarity_score_percentile }}%</span
          >
          <span
            style="font-size: 10pt"
            v-if="kpi === 'Clarity'"
            class="grey--text"
            >Attention score: {{ Math.round(winner.template_average) }}%</span
          >
          <br />
          <span
            style="font-size: 10pt; font-weight: 400"
            v-if="kpi === 'Attention'"
          >
            The Attention score indicates how much your ad draws attention in
            the contextual templates.
          </span>
          <span
            style="font-size: 10pt; font-weight: 400"
            v-if="kpi === 'Clarity'"
          >
            The clarity score indicates how clear and easy it is for a person to
            perceive your ad.
          </span>
          <br />
        </p>
      </div>
      <div class="middle">
        <p
          class="font-weight-bold warning--text"
          style="font-size: 14pt"
          v-if="kpi === 'Attention'"
        >
          Your ad <span style="color: #8b78fa">{{ winner.title }}</span> draws
          <span style="color: #8b78fa"
            >more attention than
            {{ Math.round(winner.template_average) }}%</span
          >, and less than {{ 100 - Math.round(winner.template_average) }}% of
          the benchmark.
        </p>
        <p
          class="font-weight-bold warning--text"
          style="font-size: 14pt"
          v-if="kpi === 'Clarity'"
        >
          Your ad <span style="color: #8b78fa">{{ winner.title }}</span> is
          perceived
          <span style="color: #8b78fa"
            >clearer than {{ winner.clarity_score_percentile }}%</span
          >, and less clear than {{ 100 - winner.clarity_score_percentile }}% of
          the benchmark.
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["rank", "kpi", "winner", "winnerCard"],
  computed: {
    image() {
      if (this.view === "heatmap") {
        return this.winner.combined;
      } else if (this.view === "peekthrough") {
        return this.winner.peekthrough;
      } else {
        return this.winner.imageURL;
      }
    },
    ranking() {
      let text = "";
      switch (this.rank) {
        case 1:
          text = "Winner";
          break;
        case 2:
          text = "2nd";
          break;
        case 3:
          text = "3rd";
          break;
        case 4:
          text = "4th";
          break;
        case 5:
          text = "4th";
          break;
        case 6:
          text = "6th";
          break;
        case 7:
          text = "7th";
          break;
        case 8:
          text = "8th";
          break;
        case 9:
          text = "9th";
          break;
        case 10:
          text = "10th";
          break;
      }
      return text;
    },
  },
  data() {
    return {
      expand: false,
      show: false,
      view: "original",
    };
  },
};
</script>

<style lang="scss" scoped>
* {
  transition: all ease 0.4s;
}
.v-btn {
  text-transform: none;
}

.split {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  min-height: 200px;

  .middle {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px;
    border-right: 1px solid #ebebef;

    p {
      font-size: 13pt;
      font-weight: 700;
    }
  }
}

.explanation {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
