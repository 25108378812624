<template>
  <div>
    <div
      class="video-container"
      ref="app"
      @mousedown="startDrawingBox"
      @mousemove="changeBox"
      @mouseup="stopDrawingBox"
    >
      <Box
        v-show="showBoxes && !disabled"
        v-if="drawingBox.active"
        :b-width="drawingBox.width"
        :b-height="drawingBox.height"
        :b-top="drawingBox.top"
        :b-left="drawingBox.left"
      />

      <Box
        v-show="showBoxes"
        v-for="(box, i) in scaledBoxes"
        :key="i"
        :b-top="box.top"
        :b-left="box.left"
        v-bind:b-label="box.name"
        :b-width="box.width"
        :b-height="box.height"
        :b-active="i === activeBoxIndex"
        :on-select="makeBoxActive"
        :b-index="i"
        :on-delete="removeBox"
        @updated="updateLabel"
        :disabled="disabled"
        :tracking="tracking"
      />

      <img
        v-if="this.variant === 'Heatmap'"
        :src="this.combined"
        alt="1"
        style="opacity: 0; position: absolute; z-index: 3"
      />
      <img
        :id="uid + compare"
        :src="source"
        alt="1"
        v-bind:style="{
          backgroundColor: 'rgba(101, 21, 221,' + opacity / 100 + ')',
        }"
        @load="observe"
      />
      <img
        v-if="this.variant === 'Heatmap'"
        style="position: absolute; top: 0; left: 0; z-index: -1"
        id="original"
        :src="image"
        alt="2"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.video-container {
  position: relative;
  max-width: 100%;
  margin: auto;
  max-height: 100%;
  display: inline-block;
  z-index: 2;

  img {
    display: block;
    border: 2px solid #6515dd;
    border-radius: 6px;
  }
}
.slider {
  max-width: 400px;
}

.heatmapjs-container {
  position: absolute;
  height: 100%;
  z-index: 6;
  pointer-events: none;
}

.imagewrapper {
  width: 100%;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  max-width: 100%;
  max-height: calc(100vh - 350px);
  object-fit: contain;
  position: relative;
}
</style>

<script>
import Box from "./box/BoxIndex.vue";
// import { pick } from "lodash";
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    compare: {
      type: Boolean,
      default: false,
    },
    gazeplot: {
      type: String,
    },
    image: {
      type: String,
    },
    uid: {
      type: String,
      default: "no-uid",
    },
    data: {
      type: Array,
    },
    imgwidth: {
      type: Number,
    },
    imgheight: {
      type: Number,
    },
    originalWidth: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
    showBoxes: {
      type: Boolean,
    },
    variant: {
      type: String,
    },
    loadBoxes: {
      type: Array,
    },
    image_result: {
      type: String,
    },
    heatmap: {
      type: String,
    },
    opacity: {
      type: Number,
    },
    peekthrough: {
      type: String,
    },
    type: {
      type: String,
    },
    slider: {
      type: Number,
    },
    tracking: {
      type: Boolean,
    },
    combined: {
      type: String,
    },
  },
  components: {
    Box,
  },
  data() {
    return {
      activeBoxIndex: null,
      left: 0,
      show: true,
      width: 0,
      height: 0,
      max: 255,
      min: 0,
      moveDrawable: false,
      clickDrawable: false,
      drawValue: 5,

      drawingBox: {
        active: false,
        top: 0,
        left: 0,
        height: 0,
        width: 0,
      },
      ro: null,
      boxes: [],
      refWidth: 0,
      mice: [],
      screenshot: null,
      heatmaps: [],
      total: 0,
    };
  },
  watch: {
    activeBoxIndex: function () {
      if (this.activeBoxIndex != null) {
        this.$emit("onSelectBox", this.activeBoxIndex);
      } else if (this.activeBoxIndex == null) {
        this.$emit("onSelectBox", "-", "-", "-", "-");
      }
    },
    boxes: {
      deep: true,
      handler() {
        this.$emit("initialized", true);
      },
    },
    loadBoxes: {
      deep: true,
      handler() {
        this.boxes = [];
        this.loadBoxes.forEach(async (el) => {
          this.boxes.push({
            width: el.width,
            height: el.height,
            top: el.top,
            left: el.left,
            value: await this.getBoxValue(el),
            name: el.name,
          });
        });
      },
    },
  },
  mounted() {
    if (this.loadBoxes.length > 0) {
      this.loadBoxes.forEach(async (el) => {
        this.boxes.push({
          width: el.width,
          height: el.height,
          top: el.top,
          left: el.left,
          value: await this.getBoxValue(el),
          name: el.name,
        });
      });
    } else {
      console.log("no boxes to load");
      this.$emit("initialized", true);
    }
  },
  beforeUnmount() {
    this.$emit("initialized", false);
    this.ro.unobserve(document.getElementById(`${this.uid}${this.compare}`));
  },

  computed: {
    source() {
      if (this.variant === "Heatmap") {
        return this.heatmap;
      } else if (this.variant === "Peekthrough") {
        return this.peekthrough;
      } else if (this.variant === "Gazeplot") {
        return this.gazeplot;
      } else {
        return this.image;
      }
    },
    scale: function () {
      return this.width / this.originalWidth;
    },
    radius: function () {
      return 61 * this.scale;
    },
    blur: function () {
      return 0.6;
    },
    options: function () {
      const options = {
        radius: this.radius,
        blur: 1,
        gradient: {
          // enter n keys between 0 and 1 here
          // for gradient color customization
          ".3": "rgba(9, 49, 240, 1)",
          "0.50": "#00DEAB",
          ".8": "#F1C40F",
          ".9": "#FF14A9",
        },
      };

      const optionsPeekthrough = {
        radius: this.radius,
        blur: this.blur,
        minOpacity: 0,
        maxOpacity: 1,
        gradient: {
          // enter n keys between 0 and 1 here
          // for gradient color customization
          ".5": "transparent",
          ".51": "#141A77",
        },
      };
      if (this.variant === "Heatmap") {
        return options;
      } else {
        return optionsPeekthrough;
      }
    },
    eyeData: function () {
      let scale = this.width / this.originalWidth;
      let newEyes = [];

      this.data.forEach((element) => {
        newEyes.push({
          x: Math.round(element.x * scale),
          y: Math.round(element.y * scale),
          value: Math.round(element.value * scale),
        });
      });

      let data = [];
      newEyes.forEach((el) => {
        let value = null;
        if (el.value / scale < 40) {
          value = 255;
        }
        data.push({
          x: el.x,
          y: el.y,
          value: value * scale,
        });
      });

      if (this.variant === "Heatmap") {
        return newEyes;
      } else {
        return data;
      }
    },
    totalValue: function () {
      let total = 0;
      this.data.forEach((el) => {
        total = total + el.value;
      });
      return total;
    },
    boxData: function () {
      const box = this.scaledBoxes[this.activeBoxIndex];
      let filtered;
      if (this.activeBoxIndex != null) {
        filtered = this.eyeData.filter(xy);
      }

      function xy(obj) {
        if (obj.y < box.top || obj.y > box.top + box.height) {
          return false;
        } else if (obj.x < box.left || obj.x > box.left + box.width) {
          return false;
        } else {
          return true;
        }
      }
      return filtered;
    },
    scaledBoxes: function () {
      let boxes = this.boxes;

      let converted = [];
      if (boxes.length > 0) {
        boxes.forEach((el) => {
          converted.push({
            height: Math.round(el.height * this.scale),
            width: Math.round(el.width * this.scale),
            left: Math.round(el.left * this.scale),
            top: Math.round(el.top * this.scale),
            name: el.name,
          });
        });
      }

      return converted;
    },
  },
  methods: {
    observe() {
      this.ro = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const cr = entry.contentRect;
          this.width = cr.width;
          console.log(cr.width);
          this.refWidth = cr.width;
          this.height = cr.height;
        }
        this.left = document
          .getElementById(`${this.uid}${this.compare}`)
          .getBoundingClientRect().left;
      });

      // Observe one or multiple elements
      this.ro.observe(document.getElementById(`${this.uid}${this.compare}`));
    },
    getBoxValue(box) {
      if (this.data.length === 0) {
        return 0;
      }
      let score = 0;
      function xy(obj) {
        if (obj.y < box.top || obj.y > box.top + box.height) {
          return false;
        } else if (obj.x < box.left || obj.x > box.left + box.width) {
          return false;
        } else {
          return true;
        }
      }
      let value = 0;

      let filtered = this.data.filter(xy);
      filtered.forEach((el) => {
        value = value + el.value;
      });

      score = value / this.totalValue;

      return score;
    },
    getActiveBoxValue() {
      if (this.data.length === 0) {
        return 0;
      }
      let score = 0;
      const box = {
        height: this.drawingBox.height / this.scale,
        left: this.drawingBox.left / this.scale,
        top: this.drawingBox.top / this.scale,
        width: this.drawingBox.width / this.scale,
      };

      function xy(obj) {
        if (obj.y < box.top || obj.y > box.top + box.height) {
          return false;
        } else if (obj.x < box.left || obj.x > box.left + box.width) {
          return false;
        } else {
          return true;
        }
      }

      if (this.drawingBox.active) {
        let value = 0;

        let filtered = this.data.filter(xy);

        filtered.forEach((el) => {
          value = value + el.value;
        });

        score = value / this.totalValue;
      }
      return score;
    },
    startDrawingBox(e) {
      if (this.showBoxes && !this.disabled) {
        this.drawingBox = {
          width: 0,
          height: 0,
          top: e.offsetY,
          left: e.offsetX,
          active: true,
        };
      }
    },
    changeBox(e) {
      if (this.showBoxes && !this.disabled) {
        if (this.drawingBox.active) {
          this.drawingBox = {
            ...this.drawingBox,
            width: e.offsetX - this.drawingBox.left,
            height: e.offsetY - this.drawingBox.top,
          };
        }
      }
    },
    makeBoxActive(i) {
      this.activeBoxIndex = i;
    },
    updateSlider(v) {
      this.$emit("update:slider", v);
    },
    removeBox(i) {
      // this.boxes = this.boxes.filter((elem, index) => {
      //   return index !== i;
      // });
      this.boxes.splice(i, 1);
      this.$emit("update", this.boxes);

      this.activeBoxIndex = null;
    },
    async addBox(aoi) {
      console.log(aoi);
      const newBox = {
        width: aoi.width,
        height: aoi.height,
        top: aoi.top,
        left: aoi.left,
        value: await this.getBoxValue(aoi),
        name: aoi.name,
      };
      this.boxes.push(newBox);

      this.$emit("update", this.boxes);
    },
    async stopDrawingBox() {
      if (this.drawingBox.active && this.showBoxes && !this.disabled) {
        if (this.drawingBox.width > 5) {
          const newBox = {
            width: this.drawingBox.width / this.scale,
            height: this.drawingBox.height / this.scale,
            top: this.drawingBox.top / this.scale,
            left: this.drawingBox.left / this.scale,
            value: await this.getActiveBoxValue(),
            name: `Box ${this.boxes.length + 1}`,
            uid: uuidv4(),
          };
          this.boxes.push(newBox);
          this.$emit("update", this.boxes);
        }
        this.drawingBox = {
          active: false,
          top: 0,
          left: 0,
          height: 0,
          width: 0,
        };
      }
    },
    updateLabel(val, index) {
      if (val) {
        this.boxes[index].name = val;
      } else {
        this.boxes[index].name = `Box ${index}`;
      }
      this.$emit("updateName", this.boxes[index]);
    },
  },
};
</script>

<style lang="scss" scoped>
#label-bar {
  float: right;
  margin-right: 50px;
  width: 220px;

  ul {
    padding: 0;

    li {
      list-style-type: none;
      padding: 8px 16px;

      &.active {
        background-color: lightblue;
      }

      a {
        cursor: pointer;
        display: inline-block;
        margin-left: 4px;
        font-weight: bold;
        color: red;
      }
    }
  }
}
</style>
