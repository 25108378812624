<template>
  <v-navigation-drawer
    v-model="drawer"
    absolute
    temporary
    right
    style="z-index: 999"
    width="600"
  >
    <div class="pa-8 warning--text" v-if="variant === 'clarity'">
      <div class="d-flex pb-3">
        <h1 class="warning--text">
          Clarity score <v-icon small>mdi-copyright</v-icon>
        </h1>
        <v-spacer></v-spacer>
        <v-btn @click="drawer = false" icon color="warning lighten-4"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>

      <v-sheet color="accent" class="py-4 px-4 my-5" rounded="" elevation="1">
        <h4 class="white--text font-weight-regular">
          Clarity score <u>example</u>:
        </h4>
        <div class="d-flex align-end">
          <span class="font-weight-bold white--text" style="font-size: 22pt"
            >65
            <span class="font-weight-regular" style="font-size: 15pt"
              >/100</span
            >
          </span>
        </div>
      </v-sheet>
      <h3 class="mb-1">What is it?</h3>
      <p>
        The Clarity score is a metric indicating how cluttered vs. clear your
        image or video is in terms of visual attention.
      </p>
      <h3 class="mb-1">Why is it important?</h3>
      <p>
        High clutterness negatively impacts the task performance of your
        users/viewers and obstructs them from reaching their goal. Whether this
        is to buy something on a website, or find certain information, or
        understand the main message of any video. A high clarity score indicates
        if people are (visually) enabled to perceive the message of your image
        or video.
      </p>
      <h3 class="mb-1">How does it work?</h3>
      <p>
        It instantly scores a design for clarity in an objective way, providing
        a rating between 0 to 100 where 50 represents the average image or
        video. A score of 0 indicates an extremely cluttered and busy visual,
        whereas a score of 100 means your visual is extremely clean and clear.
      </p>
      <p>
        Generally speaking, cleaner and clearer designs tend to be more
        successful than cluttered designs. Aim for higher than average Clarity
        scores, without compromising content. Clarity is important. If your
        content is too cluttered, you may be losing visitor attention.
        <u
          >Cleaner, clearer designs make it easier for customers to find what
          they’re looking for, and reduces their cognitive load.</u
        >
      </p>
    </div>

    <div class="pa-8 warning--text" v-if="variant === 'out-of-home'">
      <div class="d-flex pb-3">
        <h1 class="warning--text">
          Report: Out of Home <v-icon small>mdi-copyright</v-icon>
        </h1>
        <v-spacer></v-spacer>
        <v-btn @click="drawer = false" icon color="warning lighten-4"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>

      <v-img :src="require('@/assets/template_example.png')"></v-img>

      <h3 class="mb-1 mt-3">Description</h3>
      <p>
        A report helps you find the optimal ad / variant to use in an Out of
        Home campaign. The report calculates, based on the templates that you
        chose, how much attention your ad receives in all templates combined and
        compares this to a benchmark with thousands of ads.
      </p>
      <h3 class="mb-1">What is the Attention score?</h3>
      <p>
        The Attention score predicts how much attention your ad will receive
        once it will be placed in the templates that you chose.
      </p>
      <p class="px-10">
        <i>
          "Think of people walking in the city: the higher the Attention score,
          the bigger the chance that they will actually see your ad."</i
        >
      </p>
      <p>
        A score of 50 is average, where 75 (for example) indicates that it will
        receive more attention than 75% of the ads in the benchmark (5000+ ads).
      </p>
      <h3 class="mb-1">What is the Clarity score?</h3>
      <p>
        The Clarity score predicts how easy it is for people to focus within
        your ad. If you ad has a lot of parts that draw attention, then the
        Clarity will be lower. A low Clarity score will result in difficulties
        to perceive your ad, and reduces the chances of people remembering your
        ad and/or product.
      </p>
    </div>

    <div
      class="pa-8 warning--text"
      v-if="variant === 'attention_display' || variant === 'attention_social'"
    >
      <div class="d-flex pb-3">
        <h1 class="warning--text">
          Attention score for
          <span v-if="variant === 'attention_display'">display ads</span
          ><span v-else-if="variant === 'attention_social'">social ads</span>
          <v-icon small>mdi-copyright</v-icon>
        </h1>
        <v-spacer></v-spacer>
        <v-btn @click="drawer = false" icon color="warning lighten-4"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>

      <p v-if="variant === 'attention_display'">
        The Attention score for display ads indicates the visibility of your ad
        in context. Your ad can be beautiful on its own, but it's always placed
        in websites or apps, via any display network. This means the attention
        towards your ad is determined by the surroundings, and that's exactly
        what the Attention score measures for you.
      </p>
      <p v-else-if="variant === 'attention_social'">
        The Attention score for social ads indicates the visibility of your ad
        in context. Your ad can be beautiful on its own, but it's always placed
        in social media websites or apps. This means the attention towards your
        ad is determined by the surroundings, and that's exactly what the
        Attention score measures for you.
      </p>
      <h3 class="mb-1">How is this calculated?</h3>
      <p v-if="variant === 'attention_display'">
        <b>1.</b><v-icon small>mdi-arrow-down</v-icon> Your ad is placed inside
        templates which contain placeholders for your ads dimensions. These
        templates represent the average context. This is done for multiple
        templates with your ads dimensions, but as an example we explain the
        process of one template.
      </p>
      <p v-else-if="variant === 'attention_social'">
        <b>1.</b><v-icon small>mdi-arrow-down</v-icon> Your ad is placed inside
        templates which contain placeholders for your ads. These templates
        represent the average context. This is done for multiple templates with
        your ads dimensions, but as an example we explain the process of one
        template.
      </p>
      <img
        src="@/assets/attention_display.png"
        alt=""
        style="max-width: 100%"
        class="my-2"
        v-if="variant === 'attention_display'"
      />
      <img
        src="@/assets/attention_social.png"
        alt=""
        style="max-width: 100%"
        class="my-2"
        v-if="variant === 'attention_social'"
      />
      <p>
        <b>2.</b><v-icon small>mdi-arrow-down</v-icon> Then we generate an
        attention heatmap to calculate the percentage of attention towards your
        ad within context.
      </p>
      <img
        src="@/assets/attention_display2.png"
        alt=""
        style="max-width: 100%"
        class="my-2"
        v-if="variant === 'attention_display'"
      /><img
        src="@/assets/attention_social2.png"
        alt=""
        style="max-width: 100%"
        class="my-2"
        v-if="variant === 'attention_social'"
      />
      <p>
        <b>3.</b><v-icon small>mdi-arrow-down</v-icon> Then we compare your
        attention percentage to the benchmark, with 5.000+ different ads, to see
        if yours scores above or below average. We consider an Attention score
        above 65% good. This means that your ad is more visible than 65% of the
        benchmark.
      </p>
      <img
        src="@/assets/attention_display3.png"
        alt=""
        style="max-width: 100%"
        class="my-2"
      />
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      variant: null,
    };
  },
  methods: {
    activate(v) {
      this.drawer = true;
      this.variant = v;
    },
  },
};
</script>
