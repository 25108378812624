<template>
  <div v-if="error != null">
    <v-dialog v-model="error.dialog" persistent width="400px">
      <v-card>
        <v-card-title class="warning py-5 white--text">
          <v-icon color="white" class="mr-3">mdi-alert</v-icon
          ><span class="text-h5">Oops..</span>
        </v-card-title>
        <v-card-text class="py-6 text-h6">
          {{ error.text }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="$emit('dialog', null)">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    errorProp: {
      type: Object,
    },
  },
  data() {
    return {
      error: this.errorProp
    }
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      defaults: (state) => state.defaults.defaults,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
