<template>
  <div class="login-wrapper">
    <div class="left">
      <div class="logo">
        <img src="@/assets/logo-dark.svg" width="50" alt="" />
        <span><b>brainsight</b></span>
      </div>
      <div>
        <h1>Verify your email</h1>
        <p class="subtitle">
          We've sent you an email to verify your account. Please click the link
          in the email to continue.
        </p>
        <p>Almost there.. &#128079;</p>
      </div>
      <div class="terms"></div>
    </div>
    <div class="right"></div>
  </div>
</template>

<script>
import firebase from "firebase/";
import { apiKey } from "@/firebase";

export default {
  data() {
    return {};
  },
  mounted() {
    document.addEventListener(
      "DOMContentLoaded",
      () => {
        // Get the action to complete.
        var mode = this.getParameterByName("mode");
        // Get the one-time code from the query parameter.
        var actionCode = this.getParameterByName("oobCode");
        // (Optional) Get the continue URL from the query parameter if available.
        var continueUrl = this.getParameterByName("continueUrl");
        // (Optional) Get the language code if available.
        var lang = this.getParameterByName("lang") || "en";

        // Configure the Firebase SDK.
        // This is the minimum configuration required for the API to be used.
        var config = {
          apiKey: apiKey, // Copy this key from the web initialization
          // snippet found in the Firebase console.
        };
        var app = firebase.initializeApp(config);
        var auth = app.auth();

        // Handle the user management action.
        switch (mode) {
          case "resetPassword":
            // Display reset password handler and UI.
            this.handleResetPassword(auth, actionCode, continueUrl, lang);
            break;
          case "recoverEmail":
            // Display email recovery handler and UI.
            this.handleRecoverEmail(auth, actionCode, lang);
            break;
          case "verifyEmail":
            // Display email verification handler and UI.
            this.handleVerifyEmail(auth, actionCode, continueUrl, lang);
            break;
          default:
          // Error: invalid mode.
        }
      },
      false
    );
  },
  methods: {
    getParameterByName(n) {
      console.log(n);
    },
    handleResetPassword(auth, actionCode, continueUrl, lang) {
      console.log(lang);

      // Verify the password reset code is valid.
      auth
        .verifyPasswordResetCode(actionCode)
        .then((email) => {
          //   var accountEmail = email;
          console.log(email);

          // TODO: Show the reset screen with the user's email and ask the user for
          // the new password.
          var newPassword = "...";

          // Save the new password.
          auth
            .confirmPasswordReset(actionCode, newPassword)
            .then((resp) => {
              console.log(resp);
              // Password reset has been confirmed and new password updated.
              // TODO: Display a link back to the app, or sign-in the user directly
              // if the page belongs to the same domain as the app:
              // auth.signInWithEmailAndPassword(accountEmail, newPassword);
              // TODO: If a continue URL is available, display a button which on
              // click redirects the user back to the app via continueUrl with
              // additional state determined from that URL's parameters.
            })
            .catch((error) => {
              console.log(
                error +
                  "Error occurred during confirmation. The code might have expired or the password is too weak."
              );
            });
        })
        .catch((error) => {
          console.log(
            error +
              "Invalid or expired action code. Try to reset the password again"
          );
        });
    },
    handleRecoverEmail(auth, actionCode, lang) {
      console.log(lang);
      // Localize the UI to the selected language as determined by the lang
      // parameter.
      var restoredEmail = null;
      // Confirm the action code is valid.
      auth
        .checkActionCode(actionCode)
        .then((info) => {
          // Get the restored email address.
          restoredEmail = info["data"]["email"];

          // Revert to the old email.
          return auth.applyActionCode(actionCode);
        })
        .then(() => {
          // Account email reverted to restoredEmail

          // TODO: Display a confirmation message to the user.

          // You might also want to give the user the option to reset their password
          // in case the account was compromised:
          auth
            .sendPasswordResetEmail(restoredEmail)
            .then(() => {
              // Password reset confirmation sent. Ask user to check their email.
            })
            .catch((error) => {
              console.log(
                error + "Error encountered while sending password reset code."
              );
            });
        })
        .catch((error) => {
          console.log(error + "Invalid code.");
        });
    },
    handleVerifyEmail(auth, actionCode, continueUrl, lang) {
      console.log(lang);
      // Localize the UI to the selected language as determined by the lang
      // parameter.
      // Try to apply the email verification code.
      auth
        .applyActionCode(actionCode)
        .then((resp) => {
          console.log(resp);
          // Email address has been verified.
          // TODO: Display a confirmation message to the user.
          // You could also provide the user with a link back to the app.
          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.
        })
        .catch((error) => {
          console.log(
            error +
              "Code is invalid or expired. Ask the user to verify their email address"
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  color: #282447;

  .left {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    width: 50%;
    max-width: 500px;

    .logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        margin-bottom: 5px;
      }
    }
    .subtitle {
      font-size: 14pt;
    }

    .google {
      margin-top: 30px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: white;
      padding: 13px 0;
      border-radius: 30px;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 0px 3px rgba(209, 219, 229, 1);
      }
      img {
        width: 30px;
      }
    }

    .divider {
      margin-top: 30px;
      margin-bottom: 30px;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr 1fr;
      span {
        text-align: center;
        font-size: 10pt;
      }
    }
    .forgot {
      color: #8b78fa;
      margin-top: 10px;
      text-align: end;
    }

    .v-btn {
      text-transform: none;
      font-weight: 600;
      font-size: 12pt;
    }

    .terms {
      text-align: center;
    }
  }

  .right {
    background-color: #282447;
  }
}
</style>
