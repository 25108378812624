<template>
  <div>
    <v-dialog v-model="dialog" persistent nudge-width="500">
      <v-card class="mx-auto" width="600px">
        <div
          class="text-h6 pa-2 d-flex bg-accent white--text font-weight-medium align-center justify-space-between"
        >
          <div style="width: 30%">Introduction</div>
          <div style="width: 30%; display: flex; justify-content: center">
            <span style="font-size: 12pt" class="ml-2"
              ><b>{{ step }}</b> of 4</span
            >
          </div>
          <div style="width: 30%; display: flex; justify-content: end">
            <v-btn
              density="comfortable"
              variant="tonal"
              @click="$emit('dialog', false)"
              icon="mdi-close"
            ></v-btn>
          </div>
        </div>

        <v-window v-model="step">
          <v-window-item :value="1">
            <div class="pa-4 text-center">
              <h3 class="text-h4 font-weight-regular mt-4">
                Welcome &#128075;
              </h3>
              <h3 class="text-h6 font-weight-light mb-2">
                Learn in four steps how Brainsight works
              </h3>
              <v-img
                :src="require('@/assets/brainsight.png')"
                width="50%"
                class="mx-auto my-6"
              ></v-img>
            </div>
          </v-window-item>

          <v-window-item :value="2" class="px-6">
            <div class="pa-4 text-center">
              <h3 class="text-h5 font-weight-regular my-4">
                How to <b>create a heatmap</b>
              </h3>
              <p class="font-weight-medium my-4 text-left">
                Three ways to upload:
              </p>
              <v-expansion-panels accordion>
                <v-expansion-panel title="By URL">
                  <v-expansion-panel-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-img
                          style="border-radius: 4px"
                          :src="require('@/assets/url.png')"
                        ></v-img>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        class="text-left d-flex align-center"
                        >Start typing any URL and select from which device you
                        want a screenshot.</v-col
                      >
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>

                <v-expansion-panel title="By uploading">
                  <v-expansion-panel-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-img
                          style="border-radius: 4px"
                          :src="require('@/assets/upload.png')"
                        ></v-img>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        class="text-left d-flex align-center"
                        >Click the button 'Upload image or video' to select a
                        file from your computer.</v-col
                      >
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>

                <v-expansion-panel title="By dropping files">
                  <v-expansion-panel-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-img
                          style="border-radius: 4px"
                          :src="require('@/assets/drop.png')"
                        ></v-img>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        class="text-left d-flex align-center"
                        >Simply select files from your computer and drag them
                        into the app.</v-col
                      >
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
              <p class="font-weight-regular mt-4 text-left">
                <b>Processing time: </b>
              </p>
              <ul class="font-weight-regular text-left">
                <li>Images: within 2 minutes</li>
                <li>
                  Videos: ± 10 minutes per minute of video
                  <i>(you will receive an email notification)</i>
                </li>
              </ul>
            </div>
          </v-window-item>

          <v-window-item :value="3">
            <div class="pa-4 text-center">
              <h3 class="text-h5 font-weight-regular mt-4">
                How to <b>create a project</b>
              </h3>
              <p class="font-weight-regular my-2">
                Click 'Add project' to add a new project to your workspace. Use
                projects to cluster heatmaps that are related to the same
                project or topic.
              </p>
              <v-img
                :src="require('@/assets/new-project.png')"
                width="50%"
                class="mx-auto my-6"
              ></v-img>
            </div>
          </v-window-item>

          <v-window-item :value="4">
            <div class="pa-4 text-center">
              <h3 class="text-h5 font-weight-regular mt-4">Get started!</h3>
              <p class="font-weight-regular my-2">Create your first heatmap!</p>
            </div>
          </v-window-item>
        </v-window>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn :disabled="step === 1" text @click="step--"> Back </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            v-if="step === 4"
            @click="$emit('dialog', false)"
            color="success"
            >Start!</v-btn
          >
          <v-btn v-else color="primary" depressed @click="step++"> Next </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialogProp"],
  data() {
    return {
      step: 1,
      dialog: this.dialogProp,
    };
  },
};
</script>
