<template>
  <v-bottom-sheet
    hide-overlay
    v-model="$store.state.heatmaps.compareSheet"
    inset
    persistent
    width="800px"
    no-click-animation
    ref="sheet"
    :retain-focus="false"
    :scrim="false"
    scroll-strategy="none"
  >
    <v-sheet
      style="border-radius: 10px 10px 0 0"
      class="bg-warning text-center"
    >
      <div class="d-flex pt-4 justify-space-between align-center px-4">
        <v-btn
          variant="text"
          dark
          icon="mdi-close"
          @click="$store.commit('closeCompare')"
        >
        </v-btn>
        <v-btn
          small
          class="warning--text font-weight-bold"
          color="success"
          elevation="0"
          @click="
            $router.push('/compare');
            $store.commit('closeCompare');
          "
          >Compare</v-btn
        >
      </div>
      <div class="cards">
        <v-card outlined width="100%" v-if="compare.length > 0">
          <v-img height="150" cover :src="compare[0].thumbnail"></v-img>
          <div class="py-2 truncate" style="font-size: 10pt">
            {{ compare[0].title }}
          </div>
          <v-btn
            @click="remove(0)"
            class="smoke"
            color="warning"
            fab
            x-small
            icon
            right
            absolute
            top
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </v-card>

        <v-card outlined width="100%" v-if="compare.length > 1">
          <v-img height="150" cover :src="compare[1].thumbnail"></v-img>
          <div class="py-2 truncate" style="font-size: 10pt">
            {{ compare[1].title }}
          </div>
          <v-btn
            @click="remove(1)"
            class="smoke"
            color="warning"
            fab
            x-small
            icon
            absolute
            right
            top
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </v-card>

        <v-card outlined width="100%" v-if="compare.length > 2">
          <v-img height="150" cover :src="compare[2].thumbnail"></v-img>
          <div class="py-2 truncate" style="font-size: 10pt">
            {{ compare[2].title }}
          </div>
          <v-btn
            @click="remove(2)"
            class="smoke"
            color="warning"
            fab
            x-small
            icon
            absolute
            right
            top
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </v-card>

        <v-card outlined width="100%" v-if="compare.length < 2" dark>
          <div style="height: 150px"></div>
          <div class="py-2 truncate" style="font-size: 10pt">Add heatmap</div>
        </v-card>

        <v-card outlined width="100%" v-if="compare.length < 3" dark>
          <div style="height: 150px"></div>
          <div class="py-2 truncate" style="font-size: 10pt">Add heatmap</div>
        </v-card>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      heatmaps: (state) => state.heatmaps.heatmaps,
      show: (state) => state.heatmaps.compareSheet,
    }),
    compare() {
      let list = [];
      for (let x in this.$store.state.heatmaps.compare) {
        list.push(
          this.heatmaps.find(
            (h) => h.uid === this.$store.state.heatmaps.compare[x]
          )
        );
      }
      return list;
    },
  },
  data() {
    return {
      sheet: true,
    };
  },
  watch: {
    // show() {
    //   this.$nextTick(() => {
    //     this.$refs.sheet.showScroll();
    //     // either set :retain-focus="false" above or do this:
    //     this.$nextTick(() => this.$refs.sheet.unbind());
    //   });
    // },
  },
  methods: {
    // change(v) {
    //   if (!v) {
    //     this.$store.commit("closeCompare");
    //   }
    // },
    remove(i) {
      console.log(i);
      this.$store.state.heatmaps.compare.splice(i, 1);
      if (this.compare.length === 0) {
        this.$store.commit("closeCompare");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./styles.scss";
</style>
