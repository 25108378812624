import { db } from "@/firebase";

const defaults = {
  state: {
    defaults: {},
  },
  mutations: {
    setDefaults(state, val) {
      state.defaults = val;
    },
  },
  actions: {
    fetchDefaults({ commit }) {
      return new Promise((resolve) => {
        let defaults = {};
        db.collection("defaults")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              defaults[`${doc.id}`] = doc.data();
            });
          })
          .then(() => {
            defaults.templates.social = [];
            db.collection("defaults")
              .doc("templates")
              .collection("social")
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  defaults.templates.social.push(doc.data());
                });
              });
          })
          .then(() => {
            defaults.templates.display = [];
            db.collection("defaults")
              .doc("templates")
              .collection("display")
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  defaults.templates.display.push(doc.data());
                });
              });
          })
          .then(() => {
            defaults.templates.ooh = [];
            db.collection("defaults")
              .doc("templates")
              .collection("ooh")
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  defaults.templates.ooh.push(doc.data());
                });
              });
          })
          .then(() => {
            defaults.heatmap.types.sort((a, b) => (a < b ? -1 : b < a ? 1 : 0));
            commit("setDefaults", defaults);
            resolve("Defaults loaded");
          });
      });
    },
    allowed({ rootState }, file) {
      return new Promise((resolve) => {
        const videoLimit =
          rootState.users.userProfile.role === "trial" ? 61 : 901;

        function isImage(filename) {
          var ext = getExtension(filename);
          switch (ext.toLowerCase()) {
            case "jpg":
            case "gif":
            case "bmp":
            case "png":
            case "jpeg":
              return true;
          }
          return false;
        }

        function getExtension(filename) {
          var parts = filename.split(".");
          return parts[parts.length - 1];
        }

        function isVideo(filename) {
          var ext = getExtension(filename);
          switch (ext.toLowerCase()) {
            case "m4v":
            case "avi":
            case "mpg":
            case "mp4":
              // etc
              return true;
          }
          return false;
        }

        if (isVideo(file.name)) {
          var video = document.createElement("video");
          window.URL = window.URL || window.webkitURL;
          video.preload = "metadata";

          video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);
            var duration = video.duration;
            file.duration = duration;

            if (duration > videoLimit) {
              resolve(false);
            } else {
              resolve(true);
            }
          };
          video.src = URL.createObjectURL(file);
        } else if (isImage(file.name)) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    },
  },
  getters: {},
};

export default defaults;
