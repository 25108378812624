<template>
  <div>
    <div>
      <div class="bg-smoke align-center d-flex pa-2" style="height: 72px">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Search"
          bg-color="smoke"
          variant="solo"
          flat
          density="compact"
          hide-details
          style="max-width: 300px"
        ></v-text-field>
      </div>
      <v-divider></v-divider>
    </div>
    <div class="users">
      <v-card class="table" flat>
        <v-data-table
          :loading="loading"
          dense
          height="calc(100vh - 72px - 64px)"
          fixed-header=""
          :headers="headers"
          :items="mails"
          :search="search"
          :items-per-page="100"
        >
          <template v-slot:[`item.delivery.startTime`]="{ item }">
            {{ item.delivery.startTime.toDate().toUTCString() }}
          </template>

          <!-- <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              @click.stop="open(item)"
              icon="mdi-chevron-right"
              variant="tonal"
              size="small"
              density="comfortable"
              class="mr-2"
            ></v-btn>
          </template> -->
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      mails: (state) => state.admin.mail,
    }),
  },
  data() {
    return {
      dialog: false,
      headers: [
        {
          title: "To",
          sortable: true,
          value: "to",
        },
        {
          title: "Template",
          sortable: true,
          value: "template.name",
        },
        {
          title: "Status",
          sortable: true,
          value: "delivery.state",
        },

        {
          title: "Timestamp",
          sortable: true,
          value: "delivery.startTime",
        },
        // {
        //   title: "",
        //   sortable: true,
        //   align: "end",
        //   value: "actions",
        // },
      ],
      loading: false,
      mail: null,
      search: "",
    };
  },
  async mounted() {
    if (this.mails.length === 0) {
      this.loading = true;
      await this.$store.dispatch("fetchAdminMail");
      this.loading = false;
    }
  },
  methods: {
    open(item) {
      this.mail = item;
      this.dialog = true;
    },
  },
};
</script>
