<template>
  <div class="demo">
    <v-card
      flat
      style="border-radius: 25px"
      width="100%"
      max-width="1280px"
      class="px-4 py-10 upload d-flex flex-column align-center"
    >
      <input
        ref="uploadRef"
        type="file"
        @change="onFileChange"
        @click="resetValue"
        accept="image/jpeg, image/png, image/jpg"
      />

      <template v-slot:image>
        <img
          id="bg-image"
          :src="require('@/assets/demo_background.svg')"
          style="object-fit: cover; width: 100%; -webkit-user-drag: none"
        />
      </template>
      <h1 class="mb-1 text-white" style="font-size: 60px; font-weight: 600">
        Try it yourself
      </h1>
      <div
        v-if="step === 1"
        class="d-flex align-center flex-column text-center"
      >
        <p
          style="font-size: 16px; max-width: 600px; text-align: center"
          class="mb-6 text-white"
        >
          Feel how fast and easy your heatmaps get generated.
        </p>

        <v-btn
          size="x-large"
          rounded
          variant="flat"
          style="font-size: 12pt"
          color="dark"
          prepend-icon="mdi-upload"
          @click="trigger"
          >Upload image</v-btn
        >
      </div>
      <div class="pa-4" v-if="step === 2 || step === 4">
        <p style="text-align: center; font-size: 12pt" class="mb-2 text-white">
          Processing..
        </p>
        <v-progress-linear
          rounded
          style="width: 100px"
          color="smoke"
          indeterminate
        ></v-progress-linear>
      </div>

      <div v-if="step === 3">
        <p style="text-align: center; font-size: 12pt" class="mb-2 text-white">
          Where are you planning to use this image for?
        </p>
        <v-select
          label="Type"
          variant="solo"
          bg-color="white"
          rounded
          placeholder="Choose a type"
          flat
          v-model="application"
          style="width: 400px; min-width: 200px"
          :items="
            defaults.heatmap.types.filter((a) => a != 'Ad / commercial (video)')
          "
        >
        </v-select>

        <v-select
          v-if="application && application === 'UX Design'"
          label="Device"
          variant="solo"
          bg-color="white"
          rounded
          placeholder="Choose a device"
          flat
          v-model="device"
          style="width: 400px; min-width: 200px"
          :items="['desktop', 'mobile']"
        >
        </v-select>
        <v-btn
          @click.stop="step = 4"
          :disabled="!application || (application === 'UX Design' && !device)"
          block
          color="dark"
          size="x-large"
          rounded
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>

      <v-text-field
        label="Your email"
        variant="solo"
        bg-color="white"
        rounded
        v-if="step === 5"
        flat
        style="width: 400px; min-width: 200px"
      >
        <template v-slot:append-inner>
          <v-btn variant="flat" color="primary" rounded="">Send</v-btn>
        </template>
      </v-text-field>
      <v-btn
        size="x-large"
        rounded
        v-if="step === 6"
        variant="flat"
        style="font-size: 12pt"
        color="dark"
        prepend-icon="mdi-fire"
        >View heatmap</v-btn
      >
    </v-card>
  </div>
</template>
<script>
import firebase from "firebase/";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      defaults: (state) => state.defaults.defaults,
    }),
  },
  data() {
    return {
      application: "",
      device: "",
      email: "",
      file: null,
      step: 1,
    };
  },
  methods: {
    async onFileChange() {
      this.step = 2;
      // var files = this.$refs.uploadRef.files;

      // // const response = await fetch("https://api.ipify.org");
      // // const ip = await response.text();

      // for (const [key] of Object.entries(files)) {
      //   this.file = files[key];
      // }
      this.step = 3;
    },
    async uploadFile(upload) {
      console.log(upload);
      const vm = this;
      var fileName = upload.original.name;
      var fileExtension = fileName.split(".").pop();
      var storageRef = firebase.storage().ref();
      let path;
      let device = upload.device ? upload.device.toLowerCase() : "";
      let type = "";

      // The type is defined to calculate the Clarity score:
      switch (upload.application) {
        case "Display Ads":
          type = "DISPLAY";
          break;
        case "(Digital) Out Of Home":
          type = "AD";
          break;
        case "Social media":
          type = "AD";
          break;
        case "UX Design":
          type = "UX";
          break;
      }
      let country = upload.country ? upload.country : "";
      let condition = type === "UX" ? "viewport" : "";
      let industry = upload.industry ? upload.industry : "";

      const docRef = await db.collection("heatmaps").add({
        application: upload.application ? upload.application : "",
        audit: upload.audit ? upload.audit : false,
        areas_of_interest: [],
        created_at: new Date(),
        country: country,
        condition: condition,
        dimensions: upload.dimensions ? upload.dimensions : "",
        device: device,
        duration: upload.type === "video" ? upload.duration : null,
        extension: fileExtension,
        heatmap: "",
        frames:
          (upload.application === "Display Ads" && upload.type === "video") ||
          (upload.application === "Social media" && upload.type === "video") ||
          (upload.application === "(Digital) Out Of Home" &&
            upload.type === "video"),
        frames_children: [],
        image: "",
        hidden: false,
        industry: industry,
        logo_tracking: upload.type === "video",
        logo_tracking_status: null,
        peekthrough: "",
        public: false,
        readability: null,
        requested_by: vm.userProfile.uid,
        requested_by_name: vm.userProfile.name,
        requested_by_email: vm.userProfile.email,
        email_sent: false,
        reports: [],
        status: "requested",
        tags: [],
        tempFileName: fileName,
        template_scores: null,
        thumbnail: "",
        timeline: 0,
        title: upload.title,
        type: upload.type,
        workspace: vm.userProfile.workspace,
      });

      this.$store.commit("addUploading", {
        value: 0,
        uid: docRef.id,
      });

      const templates = await this.getTemplates(upload);

      await db.collection("heatmaps").doc(docRef.id).update({
        uid: docRef.id,
        templates: templates,
        template_scores: {},
      });

      if (upload.projectIds.length > 0) {
        const vm = this;
        vm.projects.forEach((p) => {
          if (
            upload.projectIds.includes(p.uid) &&
            !p.heatmaps.includes(docRef.id)
          ) {
            var projectRef = db.collection("projects").doc(p.uid);
            projectRef.update({
              heatmaps: firebase.firestore.FieldValue.arrayUnion(
                `${docRef.id}`
              ),
            });
          } else if (
            !upload.projectIds.includes(p.uid) &&
            p.heatmaps.includes(docRef.id)
          ) {
            var ref = db.collection("projects").doc(p.uid);
            ref.update({
              heatmaps: firebase.firestore.FieldValue.arrayRemove(
                `${docRef.id}`
              ),
            });
          }
        });
        vm.$store.dispatch("fetchProjects");
      }

      if (upload.type === "image") {
        path = storageRef.child(
          `${auth.currentUser.uid}/${docRef.id}/${docRef.id}.${fileExtension}`
        );
      } else if (upload.type === "video") {
        path = storageRef.child(
          `${auth.currentUser.uid}/${docRef.id}/${docRef.id}.${fileExtension}`
        );
      }

      var uploadTask = path.put(upload.original);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (progress < 100) {
            this.$store.commit("updateUploading", {
              value: progress,
              uid: docRef.id,
            });
          }

          this.uploadPercentage = progress;
          this.uploads.upload_percentage = progress;
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              // console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              // console.log("Upload is running");
              break;
          }
        },
        (error) => {
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        async () => {
          var generate = firebase
            .app()
            .functions("europe-west1")
            .httpsCallable("generate");

          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            await generate({ uid: docRef.id });
            console.log(
              "Generating: " + docRef.id + ". Available at: " + downloadURL
            );
            this.$store.commit("updateUploading", {
              value: 100,
              uid: docRef.id,
            });
          });

          upload.extension = fileExtension;
          upload.uid = docRef.id;
          console.log(upload);
          if (this.$route.name != "Project" && this.$route.name != "Heatmaps") {
            this.$router.push("/heatmaps");
          }
        }
      );

      console.log(upload);

      var userRef = db.collection("users").doc(auth.currentUser.uid);

      if (upload.type === "image") {
        userRef.update({
          imageHeatmaps: firebase.firestore.FieldValue.increment(1),
        });
      } else if (upload.type === "video") {
        userRef.update({
          videoHeatmaps: firebase.firestore.FieldValue.increment(1),
        });
      }

      if (
        vm.userProfile.workspace &&
        (vm.userProfile.role === "member" || vm.userProfile.role === "admin")
      ) {
        var workspaceRef = db
          .collection("workspaces")
          .doc(vm.userProfile.workspace);
        if (upload.type === "image") {
          workspaceRef.update({
            imageHeatmaps: firebase.firestore.FieldValue.increment(1),
            imageCredits: firebase.firestore.FieldValue.increment(-1),
          });
          vm.$store.commit("deductCredit", "image");
        } else if (upload.type === "video") {
          workspaceRef.update({
            videoHeatmaps: firebase.firestore.FieldValue.increment(1),
            videoCredits: firebase.firestore.FieldValue.increment(-1),
          });
          vm.$store.commit("deductCredit", "video");
        }
      } else {
        if (upload.type === "image") {
          userRef.update({
            imageCredits: firebase.firestore.FieldValue.increment(-1),
          });
          vm.$store.commit("deductCredit", "image");
        } else {
          userRef.update({
            videoCredits: firebase.firestore.FieldValue.increment(-1),
          });
          vm.$store.commit("deductCredit", "video");
        }
      }
    },
    resetValue(event) {
      event.target.value = "";
    },
    trigger() {
      this.$refs.uploadRef.click();
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.demo {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  border: 2px solid red;
}
</style>
