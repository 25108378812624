const display_config = [
  { id: 1, width: 728, height: 90, dark: false, select: false },
  { id: 2, width: 300, height: 200, dark: false, select: false },
  { id: 3, width: 300, height: 200, dark: false, select: false },
  { id: 4, width: 300, height: 250, dark: false, select: false },
  { id: 5, width: 300, height: 250, dark: false, select: false },
  { id: 6, width: 300, height: 250, dark: true, select: false },
  { id: 7, width: 300, height: 600, dark: false, select: false },
  { id: 8, width: 300, height: 200, dark: false, select: false },
  { id: 9, width: 728, height: 90, dark: false, select: false },
  { id: 10, width: 300, height: 200, dark: false, select: false },
  { id: 11, width: 728, height: 90, dark: true, select: false },
  { id: 12, width: 728, height: 90, dark: false, select: false },
  { id: 13, width: 300, height: 600, dark: false, select: false },
  { id: 14, width: 300, height: 600, dark: false, select: false },
  { id: 15, width: 970, height: 250, dark: false, select: false },
  { id: 16, width: 728, height: 90, dark: false, select: false },
  { id: 17, width: 300, height: 250, dark: false, select: false },
  { id: 18, width: 728, height: 90, dark: true, select: false },
  { id: 19, width: 300, height: 250, dark: true, select: false },
  { id: 20, width: 300, height: 250, dark: false, select: false },
  { id: 21, width: 300, height: 250, dark: true, select: false },
  { id: 22, width: 300, height: 250, dark: false, select: false },
  { id: 23, width: 970, height: 250, dark: false, select: false },
  { id: 24, width: 970, height: 250, dark: false, select: false },
  { id: 25, width: 300, height: 200, dark: true, select: false },
  { id: 26, width: 300, height: 200, dark: false, select: false },
  { id: 27, width: 320, height: 100, dark: false, select: false },
  { id: 28, width: 320, height: 100, dark: false, select: false },
  { id: 29, width: 160, height: 600, dark: false, select: false },
  { id: 30, width: 970, height: 250, dark: false, select: false },
  { id: 31, width: 970, height: 250, dark: false, select: false },
  { id: 32, width: 300, height: 200, dark: false, select: false },
  { id: 33, width: 320, height: 100, dark: false, select: false },
  { id: 34, width: 300, height: 200, dark: false, select: false },
  { id: 35, width: 300, height: 200, dark: false, select: false },
  { id: 36, width: 300, height: 200, dark: false, select: false },
  { id: 37, width: 320, height: 100, dark: false, select: false },
  { id: 38, width: 320, height: 100, dark: false, select: false },
  { id: 39, width: 320, height: 100, dark: true, select: false },
  { id: 40, width: 320, height: 100, dark: true, select: false },
  { id: 41, width: 320, height: 50, dark: false, select: false },
  { id: 42, width: 320, height: 50, dark: false, select: false },
  { id: 43, width: 320, height: 100, dark: false, select: false },
  { id: 44, width: 300, height: 200, dark: false, select: false },
  { id: 45, width: 300, height: 200, dark: false, select: false },
  { id: 46, width: 300, height: 200, dark: true, select: false },
  { id: 47, width: 160, height: 600, dark: true, select: false },
  { id: 48, width: 728, height: 90, dark: true, select: false },
  { id: 49, width: 728, height: 90, dark: false, select: false },
  { id: 50, width: 300, height: 200, dark: false, select: false },
  { id: 51, width: 970, height: 250, dark: false, select: false },
  { id: 55, width: 160, height: 600, dark: false, select: false },
  { id: 56, width: 970, height: 250, dark: false, select: false },
  { id: 57, width: 300, height: 250, dark: false, select: false },
  { id: 58, width: 160, height: 600, dark: false, select: false },
  { id: 59, width: 970, height: 90, dark: false, select: false },
  { id: 60, width: 728, height: 90, dark: false, select: false },
  { id: 61, width: 300, height: 250, dark: false, select: false },
  { id: 62, width: 970, height: 250, dark: false, select: false },
  { id: 63, width: 970, height: 250, dark: false, select: false },
  { id: 64, width: 970, height: 250, dark: false, select: false },
  { id: 65, width: 300, height: 600, dark: false, select: false },
  { id: 70, width: 300, height: 600, dark: false, select: false },
  { id: 71, width: 970, height: 90, dark: false, select: false },
  { id: 72, width: 160, height: 600, dark: false, select: false },
  { id: 73, width: 970, height: 90, dark: false, select: false },
  { id: 74, width: 300, height: 600, dark: false, select: false },
  { id: 75, width: 160, height: 600, dark: false, select: false },
  { id: 76, width: 970, height: 90, dark: false, select: false },
  { id: 77, width: 160, height: 600, dark: false, select: false },
  { id: 78, width: 160, height: 600, dark: false, select: false },
  { id: 79, width: 970, height: 90, dark: false, select: false },
  { id: 80, width: 970, height: 90, dark: false, select: false },
  { id: 81, width: 970, height: 90, dark: false, select: false },
  { id: 82, width: 320, height: 50, dark: false, select: false },
  { id: 83, width: 320, height: 50, dark: false, select: false },
  { id: 84, width: 300, height: 600, dark: false, select: false },
  { id: 85, width: 300, height: 600, dark: false, select: false },
  { id: 86, width: 300, height: 600, dark: false, select: false },
  { id: 87, width: 320, height: 50, dark: false, select: false },
  { id: 88, width: 320, height: 50, dark: false, select: false },
  { id: 89, width: 320, height: 50, dark: true, select: false },
  { id: 90, width: 970, height: 90, dark: false, select: false },
  { id: 91, width: 970, height: 90, dark: false, select: false },
  { id: 92, width: 970, height: 90, dark: false, select: false },
  { id: 93, width: 160, height: 600, dark: false, select: false },
  { id: 94, width: 160, height: 600, dark: false, select: false },
  { id: 95, width: 320, height: 100, dark: false, select: false },
  { id: 96, width: 320, height: 100, dark: false, select: false },
];

export default display_config;
