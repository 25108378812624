<template>
  <div>
    <v-dialog :model-value="wizard" persistent max-width="800px">
      <v-card
        class="mx-auto d-flex"
        style="flex-direction: column"
        height="640"
      >
        <v-card-title
          class="text-h6 d-flex align-center bg-accent white--text font-weight-medium justify-space-between"
        >
          <span style="width: 40%">Automatic report generation</span>

          <div style="width: 30%; display: flex; justify-content: end">
            <v-btn
              icon="mdi-close"
              variant="text"
              @click="
                $store.commit('setReportWizard', false);
                step = 0;
                uploads = [];
                title = '';
                selected = [];
                tagsSelected = [];
                uploadCounter = 0;
                uids = [];
                unselectAll();
              "
            ></v-btn>
          </div>
        </v-card-title>

        <v-window v-model="step" style="height: 100%">
          <v-window-item :value="0">
            <div class="pa-6">
              <h2 class="warning--text mb-3">Select a report:</h2>
              <div
                class="reports overflow-y-auto"
                style="max-height: 480px; max-width: 100%"
              >
                <v-card
                  v-for="(type, index) in reportTypes"
                  :key="index"
                  class="mb-6"
                  variant="outlined"
                >
                  <v-row class="pa-0 ma-0">
                    <v-col class="ma-0 pa-0" cols="12" sm="3"
                      ><v-img
                        contain
                        style="border-radius: 4px 0 0 4px"
                        :src="require(`./images/${type.img}`)"
                      >
                        <v-chip
                          v-if="type.available"
                          style="position: absolute; bottom: 10px; left: 10px"
                          ><b class="mr-1">{{ type.templates.length }} </b>
                          Templates</v-chip
                        >
                      </v-img></v-col
                    >
                    <v-col
                      cols="12"
                      sm="6"
                      class="py-3 px-5 d-flex align-center"
                    >
                      <div>
                        <h3>{{ type.main }}</h3>
                        <p class="mb-0 pb-0" v-if="type.available">
                          {{ type.description }}
                        </p>
                        <p v-else class="mb-0 pb-0">Coming soon...</p>
                      </div>
                    </v-col>
                    <v-col class="align-center d-flex" cols="12" sm="3">
                      <v-btn
                        v-if="type.available"
                        @click="
                          step++;
                          reportType = type;
                        "
                        color="success"
                        elevation="0"
                        variant="flat"
                        >Select</v-btn
                      >
                      <v-btn outlined disabled v-else>Coming soon!</v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>
          </v-window-item>

          <v-window-item :value="1">
            <div class="pa-6" style="max-width: 400px">
              <h2 class="warning--text mb-3">Add report details:</h2>
              <p class="mb-2 mt-5 warning--text" style="font-size: 10pt">
                Add a title to your report:
              </p>
              <v-form ref="form" v-model="valid">
                <v-text-field
                  required
                  variant="solo"
                  :rules="validRules"
                  bg-color="smoke"
                  flat
                  label="Title"
                  hide-details=""
                  class="mb-3"
                  v-model="title"
                ></v-text-field>
              </v-form>

              <!-- <p class="mb-0 mt-5 warning--text" style="font-size: 10pt">
                Select benchmark KPI's:
              </p>
              <v-chip-group v-model="kpiSelected" multiple>
                <v-chip
                  color="primary"
                  v-for="type in kpi"
                  :key="type"
                  filter
                  outlined
                >
                  {{ type }}
                </v-chip>
              </v-chip-group> -->
            </div>
          </v-window-item>

          <v-window-item :value="2">
            <div class="pa-6">
              <h2 class="warning--text mb-3">Upload your ads:</h2>
              <input
                ref="reportRef"
                type="file"
                multiple
                @change="onFileChange"
                @click="resetValue"
                accept="image/png, image/jpeg, image/jpg"
              />
              <v-btn
                color="success"
                elevation="0"
                rounded
                width="200"
                @click="trigger"
                >Upload images</v-btn
              >
              <div class="uploads overflow-y-auto" style="height: 385px">
                <v-card
                  v-for="(upload, index) in uploads"
                  :key="index"
                  width="200"
                  variant="outlined"
                >
                  <v-img :src="upload.base64" contain></v-img>
                  <v-text-field
                    class="px-4 pt-6"
                    label="Title"
                    :value="upload.title"
                    v-model="upload.title"
                    hide-details=""
                  ></v-text-field>
                  <v-checkbox
                    v-if="subscription.tier === 'business'"
                    class="px-4"
                    v-model="upload.heatmap"
                    label="Also create heatmap"
                  ></v-checkbox>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="uploads.splice(index, 1)"
                      icon
                      small
                      elevation="0"
                      color="error"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </div>
              <!-- <div class="dropscreen">
                <img src="./images/file.png" style="width: 40px" class="mb-2" />
                <p class="mb-0 warning--text font-weight-medium">
                  Drop your image here
                  <span class="accent--text" style="cursor: pointer"
                    ><u>or browse</u></span
                  >
                </p>
                <p style="font-size: 10pt" class="grey--text mb-0">
                  Supports: JPG, PNG, MP4
                </p>
              </div> -->
            </div>
          </v-window-item>

          <v-window-item :value="3">
            <div class="pa-6">
              <div class="d-flex">
                <h2 class="warning--text">
                  Select from
                  <u v-if="reportType">{{ filtered.length }}</u>
                  templates
                </h2>
                <v-spacer></v-spacer>

                <v-btn
                  small
                  @click="selectAll"
                  variant="outlined"
                  rounded
                  elevation="0"
                  >Select all</v-btn
                >
                <v-btn
                  class="ml-3"
                  small
                  @click="unselectAll"
                  variant="outlined"
                  rounded
                  elevation="0"
                  >Unselect all</v-btn
                >
              </div>

              <v-chip-group v-model="tagsSelected" column multiple class="mb-4">
                <v-chip
                  v-for="chip in tags"
                  :key="chip"
                  filter
                  outlined
                  :value="chip"
                >
                  {{ chip }}
                </v-chip>
              </v-chip-group>

              <div
                style="max-height: 350px; max-width: 100%"
                class="ads overflow-y-auto"
              >
                <v-card
                  width="230"
                  v-for="(template, index) in filtered"
                  :key="index"
                  outlined
                  class="d-flex flex-start flex-column"
                >
                  <v-img
                    contain
                    height="180"
                    width="230"
                    :src="
                      require(`./images/${reportType.type}/${template.id}.jpg`)
                    "
                  ></v-img>
                  <v-checkbox
                    class="px-4"
                    v-model="template.select"
                    label="Select template"
                  ></v-checkbox>
                  <!-- <div class="chips px-4 pb-4">
                    <v-chip v-if="template.city">City</v-chip>
                    <v-chip v-if="!template.city">Rural</v-chip>
                    <v-chip v-if="template.crowded === 'high'"
                      >Highly crowded</v-chip
                    >
                    <v-chip v-if="template.crowded === 'low'"
                      >None or less crowded</v-chip
                    >
                    <v-chip v-if="template.day">Day</v-chip>
                    <v-chip v-if="!template.day">Night</v-chip>
                    <v-chip v-if="template.distance === 'short'"
                      >Short distance</v-chip
                    >
                    <v-chip v-if="template.distance === 'medium'"
                      >Medium distance</v-chip
                    >
                    <v-chip v-if="template.distance === 'long'"
                      >Long distance</v-chip
                    >
                    <v-chip v-if="template.traffic === 'low'"
                      >None or Low traffic</v-chip
                    >
                    <v-chip v-if="template.traffic === 'high'"
                      >High traffic</v-chip
                    >
                    <v-chip v-if="template.location === 'street'"
                      >Street</v-chip
                    >
                    <v-chip v-if="template.location === 'place-based'"
                      >Transit</v-chip
                    >
                    <v-chip v-if="template.location === 'transit'"
                      >Transit</v-chip
                    >
                    <v-chip v-if="template.portrait">Portrait</v-chip>
                    <v-chip v-if="!template.portrait">Landscape</v-chip>
                  </div> -->
                </v-card>
              </div>
            </div>
          </v-window-item>

          <v-window-item :value="4" style="height: 100%">
            <div
              class="pa-6 text-center"
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                max-width: 200px;
                margin: auto;
              "
            >
              <p class="warning--text mb-3">Uploading ads</p>
              <v-progress-circular
                color="accent"
                indeterminate
                rounded
                height="6"
              ></v-progress-circular>
            </div>
          </v-window-item>
        </v-window>
        <v-spacer></v-spacer>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            variant="outlined"
            :disabled="step === 0"
            v-if="step != 0 && step != 4"
            text
            @click="step--"
          >
            Back
          </v-btn>
          <v-spacer></v-spacer>
          <p class="my-2 grey--text" style="font-size: 10pt">
            Powered by Brainsight's attention prediction algorithms and saliency
            benchmark datasets.
          </p>
          <v-spacer></v-spacer>
          <v-btn
            v-if="step != 0 && step != 4"
            color="success"
            variant="tonal"
            depressed
            @click="next()"
            :loading="loading"
          >
            Next
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db, auth } from "@/firebase";
import firebase from "firebase/";
import { mapState } from "vuex";
import ooh_config from "./ooh_config";
import display_config from "./display_config";
import social_config from "./social_config";
export default {
  name: "reportWizard",
  data() {
    return {
      kpi: ["Attention score", "Clarity score"],
      kpiSelected: [0, 1],
      tagsSelected: [],
      step: 0,
      selected: [],
      reportType: "",
      uids: [],
      dialog: false,
      uploads: [],
      uploadCounter: 0,
      loading: false,
      reportTypes: [
        {
          title: "Outdoor / (D)OOH",
          main: "Out-Of-Home impact prediction",
          icon: "mdi-billboard",
          description:
            "Generates a report that predicts the attention towards your ads in real life environment.",
          img: "OOH.jpg",
          templates: ooh_config,
          type: "ooh",
          available: true,
        },
        {
          title: "Display Ads",
          main: "Display Ads impact prediction",
          icon: "mdi-advertisements",
          description:
            "Predict attention towards display ads and pick the winning variant.",
          img: "display.jpg",
          templates: display_config,
          type: "display",
          available: true,
        },
        {
          title: "Social media",
          main: "Social media impact prediction",
          icon: "mdi-facebook",
          description:
            "Generates a report that predicts a winner from multiple variants.",
          img: "social.jpg",
          templates: social_config,
          type: "social",
          available: true,
        },
        // {
        //   title: "YouTube thumbnails",
        //   main: "YouTube thumbnail impact prediction",
        //   icon: "mdi-youtube",
        //   description:
        //     "Generates a report that predicts tdicts a winner from multiple variants.",
        //   img: "youtube.jpg",
        //   templates: [
        //     {
        //       id: 1,
        //       select: false,
        //     },
        //     {
        //       id: 2,
        //       select: false,
        //     },
        //   ],
        //   type: "thumbnails",
        //   available: false,
        // },
      ],
      title: "",
      valid: false,
      validRules: [(v) => !!v || "Name is required"],
    };
  },
  methods: {
    uploading() {
      console.log("generating report");
      this.uploads.forEach((u, index) => {
        this.uploadFiles(u, index);
      });
    },
    async createReport() {
      const vm = this;
      let selected = [];
      let uids = this.uids;
      var generate = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("generate");

      this.filtered.forEach((t) => {
        if (t.select) {
          selected.push(t.id);
        }
      });

      this.uploads.map(async (u) => {
        await db
          .collection("heatmaps")
          .doc(u.uid)
          .update({
            templates: {
              ooh_ids: vm.reportType.type === "ooh" ? selected : [],
              display_ids: vm.reportType.type === "display" ? selected : [],
              social_ids: vm.reportType.type === "social" ? selected : [],
            },
          });

        const res = await generate({ uid: u.uid });
        console.log(res);
      });

      const docRef = await db.collection("reports").add({
        title: vm.title,
        clarity_benchmark: vm.kpiSelected.includes(1),
        attention_benchmark: vm.kpiSelected.includes(0),
        type: vm.reportType.type,
        heatmaps: uids,
        heatmapObjects: [],
        created_by_name: vm.userProfile.name,
        created_by_email: vm.userProfile.email,
        created_by_uid: vm.userProfile.uid,
        ids: selected,
        workspace: vm.userProfile.workspace,
        created_at: new Date(),
        status: "requested",
      });

      console.log("Report written with ID: ", docRef.id);
      console.log(uids);
      await db.collection("reports").doc(docRef.id).update({
        uid: docRef.id,
      });

      uids.map(async (uid) => {
        console.log(1);
        await db
          .collection("heatmaps")
          .doc(uid)
          .update({
            reports: firebase.firestore.FieldValue.arrayUnion(docRef.id),
          });
      });
      vm.uploadCounter = 0;
      vm.uploads = [];
      vm.tagsSelected = [];
      vm.title = "";
      vm.uids = [];
      uids = [];
      vm.step = 0;
      vm.unselectAll();

      vm.$store.commit("setReportWizard", false);
      vm.$store.dispatch("fetchReports");
    },
    async onFileChange() {
      var files = this.$refs.reportRef.files;

      let application = "";
      switch (this.reportType.type) {
        case "ooh":
          application = "(Digital) Out Of Home";
          break;
        case "display":
          application = "Display Ads";
          break;
        case "social":
          application = "Social media";
          break;
      }
      for (const [index, [key]] of Object.entries(Object.entries(files))) {
        let f = files[key];
        console.log(f);
        var fileName = f.name.split(".").shift();
        const res = await this.toBase64(f);
        this.uploads.push({
          file: f,
          base64: res,
          title: fileName,
          heatmap: this.userProfile.role != "trial",
          application: application,
          type: "image",
          uid: "",
          path: "",
        });
      }
    },
    next() {
      if (this.step === 1) {
        this.validate();
        if (this.valid && this.kpiSelected.length > 0) {
          this.step++;
        }
      } else if (this.step === 2) {
        if (this.uploads.length > 0) {
          this.loading = true;
          this.uploading();
        }
      } else if (this.step === 3) {
        if (this.templateCount) {
          this.step++;
          this.createReport();
        }
      }
    },
    resetValue(event) {
      event.target.value = "";
    },
    selectAll() {
      this.filtered.forEach((t) => {
        t.select = true;
      });
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    trigger() {
      this.$refs.reportRef.click();
    },
    unselectAll() {
      this.filtered.forEach((t) => {
        t.select = false;
      });
    },
    uploadFiles(upload, index) {
      console.log("start upload");
      const vm = this;
      var fileName = upload.file.name;
      var fileExtension = fileName.split(".").pop();
      var storageRef = firebase.storage().ref();
      let path;
      let generatePath;

      db.collection("heatmaps")
        .add({
          application: upload.application ? upload.application : "",
          areas_of_interest: [],
          created_at: new Date(),
          device: upload.device ? upload.device : "",
          extension: fileExtension,
          heatmap: "",
          hidden: upload.heatmap === false,
          image: "",
          industry: "",
          peekthrough: "",
          public: false,
          readability: null,
          requested_by: vm.userProfile.uid,
          requested_by_name: vm.userProfile.name,
          requested_by_email: vm.userProfile.email,
          email_sent: false,
          status: "requested",
          tags: [],
          thumbnail: "",
          timeline: 0,
          title: upload.title,
          type: upload.type,
          workspace: vm.userProfile.workspace,
        })
        .then(async (docRef) => {
          path = storageRef.child(
            `${auth.currentUser.uid}/${docRef.id}/${docRef.id}.${fileExtension}`
          );

          this.uids.push(docRef.id);

          await db.collection("heatmaps").doc(docRef.id).update({
            uid: docRef.id,
          });

          var uploadTask = path.put(upload.file);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

              console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log("Upload is paused");
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log("Upload is running");
                  break;
              }
              console.log(snapshot.ref);
            },
            (error) => {
              switch (error.code) {
                case "storage/unauthorized":
                  // User doesn't have permission to access the object
                  break;
                case "storage/canceled":
                  // User canceled the upload
                  break;

                // ...

                case "storage/unknown":
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
            },
            async () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                console.log("File available at", downloadURL);
                vm.uploads[index].uid = docRef.id;
                vm.uploads[index].path = generatePath;
                vm.uploads[index].extension = fileExtension;
                vm.uploadCounter++;
                if (vm.uploadCounter === vm.uploads.length) {
                  vm.loading = false;
                  vm.step++;
                }
              });
            }
          );
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    validate() {
      this.$refs.form.validate();
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      users: (state) => state.users.users,
      workspace: (state) => state.workspace.workspace,
      heatmaps: (state) => state.heatmaps.heatmaps,
      projects: (state) => state.projects.projects,
      reports: (state) => state.reports.reports,
      wizard: (state) => state.reports.wizard,
      defaults: (state) => state.defaults.defaults,
      subscription: (state) => state.subscription.subscription,
    }),
    tags() {
      if (this.reportType.type === "display") {
        return this.defaults.reports.tags_display;
      } else if (this.reportType.type === "ooh") {
        return this.defaults.reports.tags_ooh;
      } else if (this.reportType.type === "social") {
        return this.defaults.reports.tags_social;
      } else {
        return [];
      }
    },
    templateCount() {
      let tem = this.reportType.templates;
      let count = 0;

      tem.forEach((t) => {
        if (t.select) {
          count++;
        }
      });
      return count > 0;
    },
    filtered() {
      if (!this.reportType) {
        return [];
      }

      let filtered = this.reportType.templates;

      if (
        this.tagsSelected.includes("Rural") &&
        !this.tagsSelected.includes("City")
      ) {
        filtered = filtered.filter((t) => !t.city);
      } else if (
        this.tagsSelected.includes("City") &&
        !this.tagsSelected.includes("Rural")
      ) {
        filtered = filtered.filter((t) => t.city);
      }

      if (
        this.tagsSelected.includes("Highly crowded") &&
        !this.tagsSelected.includes("None or less crowded")
      ) {
        filtered = filtered.filter((t) => t.crowded === "high");
      } else if (
        this.tagsSelected.includes("None or less crowded") &&
        !this.tagsSelected.includes("Highly crowded")
      ) {
        filtered = filtered.filter((t) => t.crowded === "low");
      }

      if (
        this.tagsSelected.includes("Day") &&
        !this.tagsSelected.includes("Night")
      ) {
        filtered = filtered.filter((t) => t.day);
      } else if (
        this.tagsSelected.includes("Night") &&
        !this.tagsSelected.includes("Day")
      ) {
        filtered = filtered.filter((t) => !t.day);
      }

      if (
        this.tagsSelected.includes("Portrait") &&
        !this.tagsSelected.includes("Landscape")
      ) {
        filtered = filtered.filter((t) => t.portrait);
      } else if (
        this.tagsSelected.includes("Landscape") &&
        !this.tagsSelected.includes("Portrait")
      ) {
        filtered = filtered.filter((t) => !t.portrait);
      }

      if (
        this.tagsSelected.includes("High traffic") &&
        !this.tagsSelected.includes("None or low traffic")
      ) {
        filtered = filtered.filter((t) => t.traffic === "high");
      } else if (
        this.tagsSelected.includes("None or low traffic") &&
        !this.tagsSelected.includes("High traffic")
      ) {
        filtered = filtered.filter((t) => t.traffic === "low");
      }

      if (
        this.tagsSelected.includes("Long distance") &&
        !this.tagsSelected.includes("Medium distance") &&
        !this.tagsSelected.includes("Short distance")
      ) {
        filtered = filtered.filter((t) => t.distance === "long");
      } else if (
        this.tagsSelected.includes("Medium distance") &&
        !this.tagsSelected.includes("Long distance") &&
        !this.tagsSelected.includes("Short distance")
      ) {
        filtered = filtered.filter((t) => t.distance === "medium");
      } else if (
        this.tagsSelected.includes("Short distance") &&
        !this.tagsSelected.includes("Long distance") &&
        !this.tagsSelected.includes("Medium distance")
      ) {
        filtered = filtered.filter((t) => t.distance === "short");
      }

      if (
        this.tagsSelected.includes("Street") &&
        !this.tagsSelected.includes("Transit") &&
        !this.tagsSelected.includes("Place-based")
      ) {
        filtered = filtered.filter((t) => t.location === "street");
      } else if (
        this.tagsSelected.includes("Transit") &&
        !this.tagsSelected.includes("Street") &&
        !this.tagsSelected.includes("Place-based")
      ) {
        filtered = filtered.filter((t) => t.location === "transit");
      } else if (
        this.tagsSelected.includes("Place-based") &&
        !this.tagsSelected.includes("Street") &&
        !this.tagsSelected.includes("Transit")
      ) {
        filtered = filtered.filter((t) => t.location === "place-based");
      }

      if (
        this.tagsSelected.includes("dark") &&
        !this.tagsSelected.includes("light")
      ) {
        filtered = filtered.filter((t) => t.dark);
      } else if (
        this.tagsSelected.includes("light") &&
        !this.tagsSelected.includes("dark")
      ) {
        filtered = filtered.filter((t) => !t.dark);
      }

      if (this.reportType.type === "display" && this.tagsSelected.length > 0) {
        filtered = filtered.filter((t) => {
          const size = String(t.width).concat(`x${t.height}`);
          return (
            this.tagsSelected.includes(size) ||
            this.tagsSelected.includes(t.dark ? "dark" : "light")
          );
        });
      }

      if (this.reportType.type === "social" && this.tagsSelected.length > 0) {
        filtered = filtered.filter((t) => {
          const light = this.tagsSelected.includes(t.dark ? "dark" : "light");
          const platform = this.tagsSelected.includes(t.platform);
          const device = this.tagsSelected.includes(
            t.mobile ? "mobile" : "desktop"
          );
          return light || platform || device;
        });
      }

      return filtered;
    },
  },
};
</script>

<style lang="scss" scoped>
.type-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.my-tabs [role="tab"] {
  justify-content: flex-start;
}

.ads {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  max-width: 660px;
  margin: auto;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.dropscreen {
  border: 4px dashed #8b78fa;
  border-radius: 8px;
  height: 150px;

  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  border: 2px solid red;
}
.uploads {
  margin-top: 25px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
</style>
