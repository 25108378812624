<template>
  <div>
    <v-card flat class="mt-2" v-if="displayScore || socialScore || oohScore">
      <div class="pa-4">
        <div class="d-flex align-center">
          <h4 class="font-weight-medium pr-2">Attention Score:</h4>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              openLink(
                'https://braingineers.gitbook.io/brainsight/metrics/attention-score'
              )
            "
            ><v-icon small>mdi-information</v-icon></v-btn
          >
        </div>

        <div class="mt-5 mb-2" style="position: relative">
          <v-icon
            small
            color="primary"
            style="position: absolute; top: -10px"
            :style="{ left: displayScore + 'px' }"
            >mdi-triangle-down</v-icon
          >
          <div
            style="
              width: 100%;
              height: 7px;
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr;
            "
          >
            <div class="error" style="border-radius: 10px 0 0 10px"></div>
            <div class="info"></div>
            <div class="success"></div>
            <div class="success" style="border-radius: 0 10px 10px 0"></div>
          </div>
        </div>

        <span
          v-if="displayScore"
          class="font-weight-bold"
          style="font-size: 22pt"
          >{{ Math.round(displayScore) }}
          <span class="font-weight-regular" style="font-size: 15pt">/100</span>
          <span v-if="displayScore >= 70"> &#128079;</span>
          <span v-if="displayScore > 60 && displayScore < 70"> &#128077; </span>
        </span>
        <span v-if="oohScore" class="font-weight-bold" style="font-size: 22pt"
          >{{ Math.round(oohScore) }}
          <span class="font-weight-regular" style="font-size: 15pt">/100</span>
          <span v-if="oohScore >= 70"> &#128079;</span>
          <span v-if="oohScore > 60 && oohScore < 70"> &#128077; </span>
        </span>

        <span
          v-if="socialScore"
          class="font-weight-bold"
          style="font-size: 22pt"
          >{{ Math.round(socialScore) }}
          <span class="font-weight-regular" style="font-size: 15pt">/100</span>
          <span v-if="socialScore >= 70"> &#128079;</span>
          <span v-if="socialScore > 60 && socialScore < 70"> &#128077; </span>
        </span>
        <br v-if="heatmap.type === 'image'" />
      </div>

      <v-divider></v-divider>
      <v-list-item class="d-flex py-1" link>
        <p class="mb-0 pb-0" style="font-size: 10pt; font-weight: 600">
          Category
        </p>
        <v-spacer></v-spacer>
        <p class="ma-0" style="font-size: 10pt">Social Media</p>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["heatmap", "displayScore", "oohScore", "socialScore"],
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
