<template>
  <div class="home-wrapper">
    <Intro v-if="introDialog" :dialogProp="introDialog" @dialog="setDialog" />

    <CompareBar />

    <Info
      @addFiles="addFiles"
      :urlLoading="urlLoading"
      :searchProp="search"
      :tags="tags"
      :users="userSearch"
      :type="type"
      :toggle="toggle"
      :project="project"
      @error="setError"
    />

    <router-view v-slot="{ Component, route }">
      <transition name="slide-fade" mode="out-in">
        <component
          :heatmaps="filteredHeatmaps"
          :searching="searching"
          :is="Component"
          :key="route.path"
        />
      </transition>
    </router-view>
  </div>
</template>

<script>
import Info from "@/components/info/InfoCard.vue";
import { db, auth } from "@/firebase";
// import store from "@/store";
// import firebase from "firebase/";
import { mapState } from "vuex";
import Intro from "@/components/intro/IntroCard.vue";
import CompareBar from "@/components/compareBar/CompareBar.vue";

export default {
  name: "HomePage",
  components: {
    Info,
    Intro,
    CompareBar,
  },
  data() {
    return {
      hoverHeatmaps: false,
      hoverProjects: false,
      includesTag: false,
      introDialog: false,
      search: "",
      searching: false,
      tags: [],
      toggle: [],
      type: "",
      url: "",
      urlLoading: false,
      userSearch: [],
    };
  },
  mounted() {
    if (this.userProfile.intro == false) {
      this.introDialog = true;
    }
  },
  watch: {
    tags(val) {
      if (
        val.length > 0 ||
        this.type != "" ||
        this.search != "" ||
        this.userSearch.length > 0 ||
        this.toggle.length > 0
      ) {
        this.searching = true;
      } else {
        this.searching = false;
      }
    },
    userSearch(val) {
      if (
        val.length > 0 ||
        this.type != "" ||
        this.search != "" ||
        this.tags.length > 0 ||
        this.toggle.length > 0
      ) {
        this.searching = true;
      } else {
        this.searching = false;
      }
    },
    type(val) {
      if (
        val != "" ||
        this.search != "" ||
        this.tags.length > 0 ||
        this.userSearch.length > 0 ||
        this.toggle.length > 0
      ) {
        this.searching = true;
      } else {
        this.searching = false;
      }
    },
    search(val) {
      if (
        val != "" ||
        this.type != "" ||
        this.tags.length > 0 ||
        this.userSearch.length > 0 ||
        this.toggle.length > 0
      ) {
        this.searching = true;
      } else {
        this.searching = false;
      }
    },
    toggle(val) {
      if (
        val.length > 0 ||
        this.search != "" ||
        this.type != "" ||
        this.tags.length > 0 ||
        this.userSearch.length > 0
      ) {
        this.searching = true;
      } else {
        this.searching = false;
      }
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      users: (state) => state.users.users,
      workspace: (state) => state.workspace.workspace,
      heatmaps: (state) => state.heatmaps.heatmaps,
      projects: (state) => state.projects.projects,
      projectSearch: (state) => state.projects.search,
    }),
    project() {
      if (this.projects.length === 0) {
        return null;
      } else {
        return this.projects.find((p) => p.uid === this.$route.params.id);
      }
    },
    fire() {
      if (this.url != "") {
        return "success";
      } else {
        return "primary";
      }
    },

    filteredHeatmaps() {
      let filtered = this.heatmaps;

      filtered = filtered.filter((h) =>
        h.title.toLowerCase().includes(this.search.toLowerCase())
      );

      if (this.$route.name === "Project") {
        let project = this.projects.find(
          (p) => p.uid === this.$route.params.id
        );
        if (project && project.heatmaps.length > 0) {
          filtered = filtered.filter((h) => project.heatmaps.includes(h.uid));
        } else {
          return [];
        }
      }

      // Filter by users
      if (this.userSearch.length > 0) {
        filtered = filtered.filter((h) =>
          this.userSearch.includes(h.requested_by)
        );
      }

      // Filter by TAGS
      if (this.tags.length > 0) {
        filtered = filtered.filter((h) => {
          let incl = false;
          this.tags.forEach((t) => {
            if (h.tags.includes(t)) {
              incl = true;
            }
          });
          return incl;
        });
      }

      // Filter by type
      if (this.type) {
        filtered = filtered.filter((h) => h.application === this.type);
      }

      // Filter by toggle
      if (this.toggle.length > 0) {
        console.log(this.toggle);
        filtered = filtered.filter((h) => this.toggle.includes(h.type));
      }

      return filtered;
    },
  },
  methods: {
    addFiles(val) {
      this.$emit("addFiles", val);
    },

    back() {
      this.uploads = [];
    },

    setDialog(v) {
      this.introDialog = v;
      this.setIntro();
    },
    setIntro() {
      const vm = this;
      db.collection("users")
        .doc(auth.currentUser.uid)
        .update({
          intro: true,
        })
        .then(() => {
          console.log("Document successfully updated!");
          this.intro = true;
        })
        .then(() => {
          vm.$store.dispatch("fetchWorkspace");
          vm.$store.commit("setIntro");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
    setError(v) {
      this.$emit("error", v);
    },

    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(5px);
  opacity: 0;
}
</style>
