<template>
  <div id="myChart" class="mb-5 mt-2">
    <div style="height: 200px">
      <!-- <div
        class="scrubber"
        v-bind:style="{ left: left + '%', height: chartHeight + 'px' }"
      ></div> -->
      <p
        style="
          font-size: 9pt;
          opacity: 0.5;
          font-weight: 600;
          text-align: center;
          margin-top: 10px;
        "
      >
        <v-icon size="12">mdi-cursor-default-click</v-icon> Click labels to
        show/hide.
      </p>
      <Bar
        id="my-chart-id"
        ref="chartcontainer"
        :options="chartOptions"
        :data="chartData"
      />
    </div>
  </div>
</template>

<script>
import { Line, Bar } from "vue-chartjs";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  BarElement,
  Filler,
} from "chart.js";
import { boolean } from "joi";

ChartJS.register(
  Title,
  Filler,
  Tooltip,
  BarElement,
  Legend,
  PointElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  annotationPlugin
);

export default {
  name: "LineChart",

  mounted() {
    this.chartWidth = this.$refs.chartcontainer.clientWidth;
    this.chartHeight = this.$refs.chartcontainer.clientHeight;

    var ro = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        this.chartWidth = cr.width;
        this.chartHeight = cr.height;
      }
    });

    // Observe one or multiple elements
    ro.observe(document.querySelector("#myChart"));
  },
  props: {
    type: {
      default: null,
      type: Array,
    },
    metric_types: {
      type: Array,
      default: null,
    },
    attention: {
      default: null,
      type: Array,
    },
    clarity: {
      default: null,
      type: Object,
    },
    brand: {
      default: null,
      type: Object,
    },
    duration: {
      default: 0,
      type: Number,
    },
    currentTime: {
      default: 0,
      type: Number,
    },
  },
  components: { Line, Bar },
  computed: {
    scrubber() {
      if (!this.currentTime) {
        return 0;
      } else {
        let x = this.currentTime;
        let length = this.clarity
          ? this.clarity.length - 1
          : this.attention
            ? this.attention.length - 1
            : Object.values(this.brand.texts).length > 0
              ? Object.values(this.brand.texts)[0].attention.length - 1
              : Object.values(this.brand.objects)[0].attention.length - 1;
        return (x / this.duration) * length;
      }
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: "index",
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                return context.dataset.label + ": " + context.raw + "%";
              },
            },
            filter: function (tooltipItem) {
              return tooltipItem.dataset.percentiled;
            },
            // enabled: this.clarity && this.clarity.percentiled,
          },
          annotation: {
            annotations: {
              line1: {
                type: "line",
                xMin: this.scrubber,
                xMax: this.scrubber,
                borderColor: "#ebebef",
                borderWidth: 3,
              },
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            display: false,
          },
          y: {
            display:
              (this.clarity && this.clarity.percentiled) ||
              (!this.clarity &&
                (this.attention ||
                  Object.values(this.brand.texts).length > 0 ||
                  Object.values(this.brand.objects).length > 0)),
            stacked: true,
            min: this.clarity
              ? this.clarity.min
              : this.attention
                ? Math.min(...this.attention)
                : 0,
            max: this.clarity
              ? this.clarity.max
              : this.attention
                ? Math.max(...this.attention)
                : 100,
            grid: {
              display: false,
            },
          },
          yPerc: {
            display: false,
            stacked: true,
            min: 0,
            max: 100,
            grid: {
              display: false,
            },
          },
        },
      };
    },
    chartData() {
      let cfg = {
        labels: [],
        datasets: [],
      };

      if (this.clarity && this.clarity.hasOwnProperty("clarity_frames")) {
        cfg.datasets.push({
          label: "Clarity",
          backgroundColor: "#6515dd",
          borderColor: "#6515dd",
          data: this.clarity.clarity_frames,
          tension: 0.5,
          type: "line",
          stack: 0,
          pointStyle: false,
          percentiled: false,
        });
      }

      if (this.clarity && this.clarity.focus && this.clarity.focus.length > 0) {
        cfg.datasets.push({
          label: "Focus",
          backgroundColor: "#6515dd",
          borderColor: "#6515dd",
          data: this.clarity.focus,
          tension: 0.5,
          type: "line",
          stack: 0,
          // pointStyle: this.clarity.percentiled ? "circle" : false,
          pointStyle: false,
          percentiled: this.clarity.percentiled,
        });
      }

      if (
        this.clarity &&
        this.clarity.proximity &&
        this.clarity.proximity.length > 0
      ) {
        cfg.datasets.push({
          label: "Proximity",
          backgroundColor: "#8b78fa",
          borderColor: "#8b78fa",
          data: this.clarity.proximity,
          tension: 0.5,
          type: "line",
          stack: 1,
          // pointStyle: this.clarity.percentiled ? "circle" : false,\
          pointStyle: false,
          percentiled: this.clarity.percentiled,
        });
      }

      if (
        (!this.clarity || this.clarity.percentiled) &&
        this.attention &&
        this.attention.length > 0
      ) {
        cfg.datasets.push({
          label: "Attention",
          backgroundColor: "#282447",
          borderColor: "#282447",
          data: this.attention,
          tension: 0.5,
          type: "line",
          stack: 2,
          percentiled: true,
          pointStyle: false,
        });
      }
      let colorIndex = 0;
      if (
        this.brand &&
        this.brand.objects &&
        Object.entries(this.brand.objects).length > 0
      ) {
        for (const [key, value] of Object.entries(this.brand.objects)) {
          cfg.datasets.push({
            label: key,
            backgroundColor: this.brandColor[colorIndex],
            borderColor: this.brandColor[colorIndex],
            data: value.attention,
            tension: 0.5,
            type: "bar",
            stack: 3,
            yAxisID: "yPerc",
            percentiled: true,
          });
          colorIndex++;
          if (colorIndex === this.brandColor.length) {
            colorIndex = 0;
          }
        }
      }
      if (
        this.brand &&
        this.brand.texts &&
        Object.entries(this.brand.texts).length > 0
      ) {
        for (const [key, value] of Object.entries(this.brand.texts)) {
          cfg.datasets.push({
            label: `"${key}"`,
            backgroundColor: this.brandColor[colorIndex],
            borderColor: this.brandColor[colorIndex],
            data: value.attention,
            tension: 0.5,
            type: "bar",
            stack: 3,
            yAxisID: "yPerc",
            percentiled: true,
          });
          colorIndex++;
          if (colorIndex === this.brandColor.length) {
            colorIndex = 0;
          }
        }
      }

      let steps = this.clarity
        ? this.clarity.length
        : this.attention
          ? this.attention.length
          : Object.values(this.brand.texts).length > 0
            ? Object.values(this.brand.texts)[0].attention.length
            : Object.values(this.brand.objects)[0].attention.length;

      function fancyTimeFormat(duration) {
        // Hours, minutes and seconds
        const hrs = ~~(duration / 3600);
        const mins = ~~((duration % 3600) / 60);
        const secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = "";

        if (hrs > 0) {
          ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;

        return ret;
      }

      for (let step = 0; step <= steps - 1; step++) {
        cfg.labels.push(fancyTimeFormat(step));
      }

      return cfg;
    },
  },
  data() {
    return {
      chartWidth: null,
      chartHeight: null,
      left: null,
      brandColor: ["#FBB81B", "#00d38c", "#ff5555"],
    };
  },
};
</script>

<style lang="scss" scoped>
#myChart {
  position: relative;
  margin: 0 10px;
}

.scrubber {
  width: 5px;
  background-color: #c4c4c4;
  opacity: 0.5;
  border-radius: 10px;
  position: absolute;
  z-index: 99;
}
</style>
