import { db } from "@/firebase";
import store from "@/store";

const workspace = {
  state: {
    workspace: {},
  },
  mutations: {
    setWorkspace(state, val) {
      state.workspace = val;
    },
  },
  actions: {
    fetchWorkspace({ commit, rootState }) {
      return new Promise((resolve) => {
        if (rootState.users.userProfile.workspace != "") {
          var docRef = db
            .collection("workspaces")
            .doc(rootState.users.userProfile.workspace);
          docRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                commit("setWorkspace", doc.data());
                resolve("Workspace loaded");
              } else {
                resolve("No Workspace Found!");
              }
              store.dispatch("fetchSubscription");
            })
            .catch((error) => {
              console.log("Error getting workspace:", error);
              resolve("Workspace not found");
            });
        }
      });
    },
  },
  getters: {},
};

export default workspace;
