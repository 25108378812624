<template>
  <v-card width="350" class="pa-6 bg-transparent" flat>
    <div class="pa-4 text-center">
      <v-img
        class="mb-6"
        contain
        height="128"
        :src="require('@/assets/no-heatmap.svg')"
      ></v-img>
      <h3 class="text-h6 font-weight-regular">Create your first heatmap</h3>
      <span class="mb-3"
        >by uploading, adding a url or dragging and dropping files. Start by
        clicking 'New Heatmap'.</span
      >
    </div>
  </v-card>
</template>
<script>
export default {
  name: "NoHeatmap",
};
</script>
