<template>
  <div>
    <NewProject :dialogProp="dialog" @dialog="setDialog" />
    <div class="right">
      <div class="heatmaps">
        <NoProjectCard
          @addProject="dialog = true"
          v-if="projectStore.length === 0"
        />
        <div class="heatmap" v-for="project in filtered" :key="project.uid">
          <Card @open="open" :projectProp="project" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/heatmap-project-card/HeatmapProjectCard.vue";
import NoProjectCard from "@/components/no-project/NoProject.vue";
import NewProject from "@/components/newProject/NewProject.vue";
import { mapState } from "vuex";

export default {
  name: "HomeProjects",
  components: {
    Card,
    NoProjectCard,
    NewProject,
  },
  props: {
    projects: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      deleteId: null,
    };
  },
  beforeUnmount() {
    this.search = "";
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      projectStore: (state) => state.projects.projects,
    }),
    search: {
      get() {
        return this.$store.state.projects.search;
      },
      set(value) {
        this.$store.commit("setProjectSearch", value);
      },
    },
    filtered() {
      let f = this.projectStore;
      if (this.search) {
        f = f.filter((p) => {
          let pass = false;
          let t = p.title.toLowerCase();
          let s = this.search.toLowerCase();

          if (t && t.includes(s)) {
            pass = true;
          }
          return pass;
        });
      }
      return f;
    },
  },
  methods: {
    deleteProject(id) {
      this.deleteId = id;
      this.deleteDialog = true;
    },
    open(val) {
      this.$router.push(`/project/${val}`);
    },
    setDialogDelete(val) {
      this.deleteDialog = val;
    },
    setDialog(val) {
      this.dialog = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.right {
  margin-bottom: 100px;
  margin-top: 24px;

  .heatmaps {
    display: flex;
    gap: 40px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 16px;
  }

  .uploads {
    flex-wrap: wrap;
  }
}
</style>
