<template>
  <div>
    <h1>{{ application_type.text }}</h1>
    <div class="phone">
      <img class="iphone" src="./assets/iphone.png" alt="" />
      <div class="header">
        <p class="white--text ma-0" style="font-size: 8pt">09:17</p>
        <div class="d-flex" style="gap: 5px">
          <v-icon size="14" style="transform: rotate(90deg)" color="white"
            >mdi-battery-medium</v-icon
          >
          <p class="white--text ma-0" style="font-size: 8pt">5G</p>
        </div>
      </div>
      <div class="instagram_story">
        <img :src="heatmap.imageURL" width="300px" />
        <div class="top overlay"></div>
        <div class="bottom overlay"></div>
      </div>
      <div class="footer">
        <v-icon color="black" size="14">mdi-chevron-up</v-icon>
        <v-icon color="white">mdi-chevron-up</v-icon>
        <v-icon color="white" style="transform: rotate(-30deg)" size="14"
          >mdi-send-outline</v-icon
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heatmap: {
      type: Object,
      default: null,
    },
    application_type: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
