<template>
  <v-card width="350" class="pa-6 transparent" outlined>
    <div class="pa-4 text-center">
      <v-img
        class="mb-6"
        contain
        height="128"
        src="@/assets/no-report.svg"
      ></v-img>
      <h3 class="text-h6 font-weight-regular">Create your first report</h3>
      <v-btn
        rounded
        class="mt-5 success white--text"
        elevation="0"
        :disabled="
          ($store.state.reports.length > 0 &&
            $store.state.users.userProfile.role === 'trial') ||
          ($store.state.users.userProfile.role === 'trial' &&
            $store.state.users.userProfile.trialEnded)
        "
        @click="$emit('newReport')"
        >Add report</v-btn
      >
    </div>
  </v-card>
</template>
