<template>
  <div
    class="wrapp"
    :style="{
      backgroundColor: branding
        ? branding.background
        : branding_default.background,
    }"
  >
    <v-overlay
      opacity="1"
      style="
        -webkit-transition: background-color 1000ms linear;
        -ms-transition: background-color 1000ms linear;
        transition: background-color 1000ms linear;
      "
      z-index="9999"
      :model-value="loading"
      class="align-center justify-center"
      :scrim="branding ? branding.background : branding_default.background"
    >
      <Transition name="slide-up">
        <v-img
          class="mb-5"
          style="
            user-drag: none;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            -ms-user-select: none;
          "
          contain
          width="140px"
          :src="branding.logo"
          v-if="branding"
        ></v-img
      ></Transition>
      <div class="d-flex justify-center">
        <v-progress-linear
          rounded
          style="width: 50px"
          indeterminate
        ></v-progress-linear>
      </div>
      <!-- <p class="mt-2" style="font-size: 10pt">Pre-heating ...</p> -->
    </v-overlay>
    <v-system-bar
      absolute=""
      height="40"
      v-if="!loading"
      :color="branding ? branding.bar : branding_default.bar"
      class="px-8 d-flex justify-end"
    >
      <p>
        Powered by
        <span style="font-weight: 600; cursor: pointer" @click="openWebsite"
          >Brainsight</span
        >
      </p>
    </v-system-bar>
    <div v-if="heatmap" class="heatmapContainer">
      <div style="padding: 25px">
        <div
          v-if="branding && branding.logo"
          flat
          class="mb-2 px-4 py-6 d-flex align-center"
        >
          <v-img max-width="130" :src="branding.logo"></v-img>
        </div>
        <v-card class="pa-4 mb-2" flat>
          <div class="d-flex align-center justify-space-between">
            <h4 style="font-weight: 500">{{ heatmap.title }}</h4>
          </div>
        </v-card>
        <options-buttons
          :heatmap="heatmap"
          :models="[]"
          :userProfile="null"
          @download="download"
          @downloadAll="downloadAll"
          @copy="copy"
          class="mb-2"
        />
        <div class="d-flex mb-2">
          <v-text-field
            v-if="heatmap.application === 'UX Design'"
            label="Device"
            readonly
            variant="solo"
            bg-color="white"
            flat=""
            class="mb-2"
            hide-details="true"
            :model-value="heatmap.device"
          ></v-text-field>
        </div>
        <div class="mb-2">
          <div>
            <AttentionCard
              style="width: 100%"
              :attention_score="attention_score"
              :type="heatmap.type"
              :application="heatmap.application"
              class="mb-2"
              v-if="attention_score"
            />
          </div>

          <div>
            <ClarityCard class="mb-2" :heatmap="heatmap" />
          </div>

          <div>
            <BrandAttention
              class="mb-2"
              :metrics="heatmap.brand"
              :duration="heatmap.duration"
              v-if="heatmap.logo_tracking_status === 'done'"
            />
          </div>
        </div>
      </div>

      <div v-if="heatmap && heatmap.type === 'image' && !loading">
        <div
          class="image-heatmap"
          style="position: relative; margin-right: 50px; margin-top: 25px"
          :style="{ display: ratio > 1 ? 'inline-block' : 'inline-block' }"
        >
          <v-card
            max-width="1000px"
            max-height="60vh"
            flat
            v-if="!loading && heatmap.type === 'image'"
            class="pa-4"
          >
            <div style="position: relative">
              <img
                :src="source"
                alt="1"
                style="
                  display: block;
                  border: 2px solid #6515dd;
                  border-radius: 6px;
                "
                v-bind:style="{
                  backgroundColor: `${backgroundColor}` + slider / 100 + ')',
                  border: `2px solid ${
                    branding ? branding.menu : branding_default.menu
                  }`,
                }"
              />
              <img
                v-if="variant === 'Heatmap'"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: -1;
                  display: block;
                  border: 2px solid #6515dd;
                  border-radius: 6px;
                "
                :style="{
                  border: `2px solid ${
                    branding ? branding.menu : branding_default.menu
                  }`,
                }"
                id="original"
                :src="heatmap.imageURL"
                alt="2"
              />
            </div>
          </v-card>

          <v-card
            :style="{
              backgroundColor: branding ? branding.menu : branding_default.menu,
            }"
            dark
            v-if="heatmap.type === 'image' && !loading"
            style="
              border-radius: 0px 10px 10px 0;
              position: absolute;
              right: -44px;
              top: 50px;
              z-index: 98;
              display: flex;
              flex-direction: column;
              gap: 10px;
              padding: 10px;
            "
          >
            <v-btn
              size="small"
              variant="text"
              icon="mdi-eye"
              @click="variant = 'Heatmap'"
              :color="
                variant === 'Heatmap'
                  ? branding
                    ? branding.button
                    : branding_default.button
                  : 'white'
              "
            >
            </v-btn>
            <v-btn
              @click="variant = 'Peekthrough'"
              size="small"
              variant="text"
              icon="mdi-domino-mask"
              :color="
                variant === 'Peekthrough'
                  ? branding
                    ? branding.button
                    : branding_default.button
                  : 'white'
              "
            >
            </v-btn>
            <v-btn
              @click="variant = 'Gazeplot'"
              size="small"
              variant="text"
              icon="mdi-scatter-plot"
              :color="
                variant === 'Gazeplot'
                  ? branding
                    ? branding.button
                    : branding_default.button
                  : 'white'
              "
            >
            </v-btn>
            <v-btn
              v-if="heatmap.logo_tracking_video"
              @click="variant = 'Brand detection'"
              size="small"
              variant="text"
              icon="mdi-watermark"
              :color="
                variant === 'Heatmap'
                  ? branding
                    ? branding.button
                    : branding_default.button
                  : 'white'
              "
            >
            </v-btn>
            <v-btn
              @click="variant = 'Original'"
              size="small"
              variant="text"
              icon="mdi-image"
              :color="
                variant === 'Original'
                  ? branding
                    ? branding.button
                    : branding_default.button
                  : 'white'
              "
            >
            </v-btn>
          </v-card>
          <v-card
            class="slider"
            :style="{
              backgroundColor: branding ? branding.menu : branding_default.menu,
              bottom: ratio > 1 ? '-34px' : '-51px',
            }"
            v-if="
              this.variant === 'Heatmap' && heatmap.type === 'image' && !loading
            "
            style="
              position: absolute;
              left: 10%;
              z-index: 98;
              display: flex;
              border-radius: 0 0 10px 10px;
              padding: 10px 20px;
              width: 81%;
              color: white;
            "
          >
            <v-slider
              v-model="slider"
              :label="`Background`"
              color="smoke"
              step="10"
              hide-details=""
            ></v-slider>
          </v-card>
        </div>
      </div>

      <div v-else-if="heatmap.type === 'video'" class="video-container">
        <div style="position: relative">
          <v-card class="pa-4" flat>
            <Transition mode="out-in" name="slide-up">
              <video
                v-if="variant === 'Heatmap'"
                :src="heatmap.heatmapURL"
                controls
                key="heatmap"
                width="100%"
                @canplay="getRatio"
                @timeupdate="timeUpdate"
                ref="vid"
                style="border-radius: 6px; max-height: 60vh"
                :style="{
                  border: `2px solid ${
                    branding ? branding.menu : branding_default.menu
                  }`,
                }"
              ></video>
              <video
                @canplay="getRatio"
                key="peekthrough"
                v-else-if="variant === 'Peekthrough'"
                :src="heatmap.peekthroughURL"
                @timeupdate="timeUpdate"
                controls
                width="100%"
                style="border-radius: 6px; max-height: 55vh"
                :style="{
                  border: `2px solid ${
                    branding ? branding.menu : branding_default.menu
                  }`,
                }"
                ref="vid"
              ></video>
              <video
                @canplay="getRatio"
                key="brand"
                v-else-if="variant === 'Brand detection'"
                :src="heatmap.logo_tracking_video"
                @timeupdate="timeUpdate"
                controls
                width="100%"
                ref="vid"
                style="border-radius: 6px; max-height: 55vh"
                :style="{
                  border: `2px solid ${
                    branding ? branding.menu : branding_default.menu
                  }`,
                }"
              ></video>
              <video
                @canplay="getRatio"
                key="original"
                v-else-if="variant === 'Original'"
                :src="heatmap.imageURL"
                @timeupdate="timeUpdate"
                controls
                ref="vid"
                width="100%"
                style="border-radius: 6px; max-height: 55vh"
                :style="{
                  border: `2px solid ${
                    branding ? branding.menu : branding_default.menu
                  }`,
                }"
              ></video>
            </Transition>

            <v-expand-transition>
              <div
                class="mt-2 px-1"
                style="width: 100%"
                v-if="
                  metricsLoaded &&
                  (heatmap.type === 'video' ||
                    (navigation === 'Attention' &&
                      heatmap.logo_tracking_status === 'done'))
                "
              >
                <ChartJS
                  :currentTime="currentTime"
                  :duration="videoDuration"
                  :brand="heatmap.brand"
                  :attention="heatmap.attention"
                  :clarity="heatmap.clarity"
                  :metric_types="metric_types"
                  :type="metric"
                />
              </div>
            </v-expand-transition>
          </v-card>
          <v-card
            :style="{
              backgroundColor: branding ? branding.menu : branding_default.menu,
            }"
            dark
            style="
              border-radius: 0px 10px 10px 0;
              position: absolute;
              right: -88px;
              top: 50px;
              z-index: 99;
              display: flex;
              flex-direction: column;
              gap: 10px;
            "
          >
            <v-btn
              @click="variant = 'Heatmap'"
              prepend-icon="mdi-eye"
              variant="text"
              size="small"
              style="font-size: 8pt"
              stacked
              :color="
                variant === 'Heatmap'
                  ? branding
                    ? branding.button
                    : branding_default.button
                  : 'white'
              "
              >Heatmap
            </v-btn>
            <v-btn
              @click="variant = 'Peekthrough'"
              prepend-icon="mdi-domino-mask"
              variant="text"
              size="small"
              stacked
              style="font-size: 8pt"
              :color="
                variant === 'Peekthrough'
                  ? branding
                    ? branding.button
                    : branding_default.button
                  : 'white'
              "
              >Peekthrough
            </v-btn>

            <v-btn
              v-if="heatmap.logo_tracking_video"
              @click="variant = 'Brand detection'"
              prepend-icon="mdi-watermark"
              variant="text"
              size="small"
              stacked
              style="font-size: 8pt"
              :color="
                variant === 'Brand detection'
                  ? branding
                    ? branding.button
                    : branding_default.button
                  : 'white'
              "
              >Brand
            </v-btn>
            <v-btn
              stacked
              @click="variant = 'Original'"
              prepend-icon="mdi-video"
              variant="text"
              size="small"
              style="font-size: 8pt"
              :color="
                variant === 'Original'
                  ? branding
                    ? branding.button
                    : branding_default.button
                  : 'white'
              "
              >Original
            </v-btn>
          </v-card>
        </div>
      </div>
    </div>
    <div
      v-if="!access"
      style="width: 100vw; height: calc(100vh - 40px)"
      class="no-access d-flex flex-column align-center justify-center"
    >
      <h1 class="font-weight-light text-center">
        Unfortunately, this heatmap is no longer accessible.
      </h1>
      <p class="mt-2">Please inquire with your contact person.</p>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase";
import firebase from "firebase/";
import ChartJS from "../Heatmap/ChartJS/chartJs.vue";
import AttentionCard from "../Heatmap/attentionCard/AttentionCard.vue";
import BrandAttention from "../Heatmap/brandAttention/BrandAttention.vue";
import ClarityCard from "../Heatmap/clarityCard/ClarityCard.vue";
import OptionsButtons from "../Heatmap/OptionsButtons/OptionsButtons.vue";
const axios = require("axios");
import mergeImages from "merge-images";

export default {
  components: {
    ChartJS,
    ClarityCard,
    BrandAttention,
    AttentionCard,
    OptionsButtons,
  },
  data() {
    return {
      access: true,
      branding: null,
      branding_default: {
        bar: "#6515dd",
        background: "#ebebef",
        button: "#00d38c",
        menu: "#6515dd",
        logo: null,
      },
      baseblue: null,
      currentTime: null,
      heatmap: null,
      heatmapData: [],
      loading: true,
      metric: [],
      ratio: null,
      metric_types: ["clarity", "attention", "brand"],
      metricsLoaded: false,
      originalWidth: null,
      originalHeight: null,
      opacity: 0,
      return_url: "",
      shareMenu: false,
      slider: 10,
      variant: "Heatmap",
      videoDuration: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    backgroundColor() {
      function hexToRgbA(hex) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
          c = hex.substring(1).split("");
          if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
          }
          c = "0x" + c.join("");
          return (
            "rgba(" +
            [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
            ",1)"
          );
        }
        throw new Error("Bad Hex");
      }
      let x = hexToRgbA(
        this.branding ? this.branding.menu : this.branding_default.menu
      );

      return x.slice(0, -2);
    },
    attention_score() {
      if (!this.heatmap) {
        return null;
      } else if (this.heatmap.attention_score) {
        return this.heatmap.attention_score;
      } else {
        let score = null;
        let templates =
          this.heatmap.type === "image" &&
          this.heatmap.application != "UX Design" &&
          this.heatmap.application != "Other";
        if (this.heatmap.template_scores && templates) {
          let type = null;
          switch (this.heatmap.application) {
            case "Display Ads":
              type = "display";
              break;
            case "(Digital) Out Of Home":
              type = "ooh";
              break;
            case "Social media":
              type = "social";
              break;
          }
          const display = this.heatmap.template_scores[type];
          let sum = 0;
          let count = 0;
          Object.keys(display).forEach(function (key) {
            count++;
            sum = sum + display[key];
          });
          score = sum / count;
        }

        if (
          this.heatmap.application === "Display Ads" &&
          this.heatmap.type === "video" &&
          this.heatmap.attention_scores &&
          this.heatmap.attention_scores.length > 0
        ) {
          const att = this.heatmap.attention_scores;
          let sum = 0;
          let count = 0;

          att.forEach((a) => {
            sum = sum + a.score;
            count = count + 1;
          });
          score = sum / count;
        } else if (
          this.heatmap.application === "(Digital) Out Of Home" &&
          this.heatmap.type === "video" &&
          this.heatmap.attention_scores &&
          this.heatmap.attention_scores.length > 0
        ) {
          const att = this.heatmap.attention_scores;
          let sum = 0;
          let count = 0;

          att.forEach((a) => {
            sum = sum + a.score;
            count = count + 1;
          });
          score = sum / count;
        } else if (
          this.heatmap.application === "Social media" &&
          this.heatmap.type === "video" &&
          this.heatmap.attention_scores &&
          this.heatmap.attention_scores.length > 0
        ) {
          const att = this.heatmap.attention_scores;
          let sum = 0;
          let count = 0;

          att.forEach((a) => {
            sum = sum + a.score;
            count = count + 1;
          });
          score = sum / count;
        }
        return score;
      }
    },
    metrics() {
      let x = {
        ...this.heatmap.clarity_graph,
        ...this.heatmap.brand,
        attention_scores: this.heatmap.attention_scores,
      };
      return x;
    },
    source() {
      if (this.variant === "Heatmap") {
        return this.heatmap.heatmap;
      } else if (this.variant === "Peekthrough") {
        return this.heatmap.peekthrough;
      } else if (this.variant === "Gazeplot") {
        return this.heatmap.gazeplot;
      } else {
        return this.heatmap.imageURL;
      }
    },
  },
  methods: {
    async createTestHeatmap() {
      this.heatmapData = [];
      const imageOriginal = await fetch(this.heatmap.imageURL);
      const imgblobOriginal = await imageOriginal.blob();
      const imgOriginal = await createImageBitmap(imgblobOriginal);
      this.originalWidth = imgOriginal.width;
      this.originalHeight = imgOriginal.height;

      var blue = document.createElement("canvas");
      blue.width = imgOriginal.width;
      blue.height = imgOriginal.height;
      var ctx = blue.getContext("2d");
      ctx.fillStyle = "#1821F0";
      ctx.fillRect(0, 0, blue.width, blue.height);
      this.baseblue = blue.toDataURL();

      const image = await fetch(this.heatmap.mask);
      const imgblob = await image.blob();
      const img = await createImageBitmap(imgblob);
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ratio = this.originalWidth / img.width;
      this.ratio = img.width / img.height;
      canvas.getContext("2d").drawImage(img, 0, 0, img.width, img.height);

      for (let x = 0; x < img.width + 100; x++) {
        for (let y = 0; y < img.height + 100; y++) {
          var pixelData = canvas.getContext("2d").getImageData(x, y, 1, 1).data;
          const Y =
            0.2126 * pixelData[0] +
            0.7152 * pixelData[1] +
            0.0722 * pixelData[2];

          this.heatmapData.push({
            x: x * ratio,
            y: y * ratio,
            value: Y * (ratio / 100),
          });
        }
      }
    },
    copy() {
      navigator.clipboard
        .writeText(`ai.brainsight.app/viewonly/${this.heatmap.uid}`)
        .then(
          this.$store.commit("setSnackbar", {
            text: "Copied to clipboard",
            timeout: 2000,
            show: true,
          }),
          function () {
            console.log("copy failed");
          }
        );
      this.shareMenu = false;
    },
    downloadAll() {
      this.download(1);
      this.download(2);
      this.download(3);
      this.download(4);
    },
    async download(v) {
      const h = this.heatmap;
      var storage = firebase.storage();
      var storageRef = firebase.storage().ref();
      const storagePath = storageRef.child(`${h.requested_by}/${h.uid}`);
      var imageRef = storage.refFromURL(`${storagePath}`);

      if (h.type === "image") {
        const b64 = await mergeImages([
          { src: this.baseimg },
          {
            src: this.baseblue,
            opacity: this.slider > 0 ? this.slider / 100 : 0.01,
          },
          { src: this.baseheat },
        ]);
        this.heatmap_opacity = b64;
      }

      if (h.type === "image") {
        let path = `${h.uid}.${h.extension}`;
        let label = "";
        if (this.variant === "Heatmap") {
          label = `${h.title} - Heatmap`;
          path = `${h.uid}-combined.png`;
        } else if (this.variant === "Peekthrough") {
          label = `${h.title} - Peekthrough`;
          path = `${h.uid}-peekthrough.png`;
        } else if (this.variant === "Gazeplot") {
          label = `${h.title} - Gazeplot`;
          path = `${h.uid}-gazeplot.png`;
        } else {
          label = `${h.title}`;
        }
        if (v === 1) {
          label = `${h.title} - Heatmap`;
          path = `${h.uid}-combined.png`;
        } else if (v === 2) {
          label = `${h.title} - Peekthrough`;
          path = `${h.uid}-peekthrough.png`;
        } else if (v === 3) {
          label = `${h.title}`;
          path = `${h.uid}.${h.extension}`;
        } else if (v === 4) {
          label = `${h.title} - Gazeplot`;
          path = `${h.uid}-gazeplot.png`;
        }

        if (v === 1) {
          const link = document.createElement("a");
          link.href = this.heatmap_opacity;
          link.download = label + ".png";
          link.click();
          URL.revokeObjectURL(link.href);
        } else {
          const url = await imageRef.child(path).getDownloadURL();
          axios
            .get(url, { responseType: "blob" })
            .then((response) => {
              const blob = new Blob([response.data], { type: "image/png" });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = label + ".png";
              link.click();
              URL.revokeObjectURL(link.href);
            })
            .catch(console.error);
        }
      } else {
        let path = `${h.uid}.mp4`;
        let label = "";
        if (this.variant === "Heatmap") {
          label = `${h.title} - Heatmap`;
          path = `${h.uid}-combined.mp4`;
        } else if (this.variant === "Peekthrough") {
          label = `${h.title} - Peekthrough`;
          path = `${h.uid}-peekthrough.mp4`;
        } else {
          label = `${h.title}`;
        }

        if (v === 1) {
          label = `${h.title} - Heatmap`;
          path = `${h.uid}-combined.mp4`;
        } else if (v === 2) {
          label = `${h.title} - Peekthrough`;
          path = `${h.uid}-peekthrough.mp4`;
        } else if (v === 3) {
          label = `${h.title}`;
          path = `${h.uid}.mp4`;
        }

        const url = await imageRef.child(path).getDownloadURL();
        axios
          .get(url, { responseType: "blob" })
          .then((response) => {
            const blob = new Blob([response.data], { type: "video/mp4" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = label + ".mp4";
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch(console.error);
      }
    },
    async fetchData() {
      this.loading = true;
      const vm = this;
      let doc;

      try {
        doc = await db.collection("heatmaps").doc(this.$route.params.id).get();
      } catch (error) {
        console.log("error");
        this.access = false;
        this.loading = false;
        return;
      }

      this.heatmap = doc.data();

      var getBranding = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("getBranding");
      const getBrandingRes = await getBranding(this.heatmap.workspace);
      this.branding = getBrandingRes.data;

      if (this.heatmap.type === "video") {
        // Metrics: Video Clarity Graph
        if (
          (this.heatmap.application != "Other" && this.heatmap.metrics) ||
          this.heatmap.hasOwnProperty("clarity_scores_frames")
        ) {
          var videoClarity = firebase
            .app()
            .functions("europe-west1")
            .httpsCallable("videoClarity");
          const videoClarityRes = await videoClarity(
            JSON.stringify(this.heatmap)
          );
          this.heatmap.clarity = videoClarityRes.data;
        }

        if (
          this.heatmap.logo_tracking_status &&
          this.heatmap.logo_tracking_status === "done"
        ) {
          // Metrics: Brand Attention
          var brandAttention = firebase
            .app()
            .functions("europe-west1")
            .httpsCallable("brandAttention");
          const brandAttentionRes = await brandAttention(
            JSON.stringify(this.heatmap)
          );
          this.heatmap.brand = brandAttentionRes.data;
        }

        if (
          this.heatmap.attention_scores &&
          this.heatmap.attention_scores.length > 0
        ) {
          var videoAttention = firebase
            .app()
            .functions("europe-west1")
            .httpsCallable("videoAttention");
          const att = await videoAttention(JSON.stringify(this.heatmap));
          this.heatmap.attention = att.data;
        } else {
          this.attention = null;
        }

        this.metricsLoaded = true;
      } else if (vm.heatmap.type === "image") {
        vm.createTestHeatmap();

        axios
          .get(this.heatmap.imageURL, { responseType: "blob" })
          .then(async (response) => {
            const blob = new Blob([response.data], { type: "image/png" });
            this.baseimg = await this.toBase64(blob);
          })
          .catch(console.error);

        axios
          .get(this.heatmap.heatmap, { responseType: "blob" })
          .then(async (response) => {
            const blob2 = new Blob([response.data], { type: "image/png" });
            this.baseheat = await this.toBase64(blob2);
          })
          .catch(console.error);
      }

      setTimeout(() => {
        this.loading = false;
      }, 600);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    getRatio() {
      this.ratio = this.$refs.vid.clientWidth / this.$refs.vid.clientHeight;
    },
    openWebsite() {
      window.open("https://www.brainsight.app", "_blank");
    },
    timeUpdate(t) {
      const video = this.$refs.vid;
      console.log(t);
      this.currentTime = video.currentTime;
      this.videoDuration = video.duration;
    },
  },
};
</script>

<style lang="scss" scoped>
.heatmapContainer {
  display: grid;
  grid-template-columns: 292px auto;
  padding-bottom: 0px;
}

.video-container {
  max-width: 70%;
  padding-top: 25px;
  margin-bottom: 100px;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

.slider {
  max-width: 400px;
}

.heatmapjs-container {
  position: absolute;
  height: 100%;
  z-index: 6;
  pointer-events: none;
}

.imagewrapper {
  width: 100%;
}
.wrapp {
  height: 100%;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  max-width: 100%;
  max-height: calc(100vh - 350px);
  object-fit: contain;
  position: relative;
}
</style>
