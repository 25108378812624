const social_config = [
  {
    id: 1,
    platform: "linkedin",
    mobile: false,
    dark: false,
    select: false,
  },
  {
    id: 2,
    platform: "linkedin",

    mobile: false,
    dark: false,
    select: false,
  },
  {
    id: 3,
    platform: "facebook",

    mobile: false,
    dark: false,
    select: false,
  },
  {
    id: 4,
    platform: "facebook",

    mobile: false,
    dark: true,
    select: false,
  },
  {
    id: 5,
    platform: "facebook",
    mobile: false,
    dark: true,
    select: false,
  },
  {
    id: 6,
    platform: "facebook",
    mobile: false,
    dark: false,
    select: false,
  },
  {
    id: 7,
    platform: "twitter",
    mobile: false,
    dark: true,
    select: false,
  },
  {
    id: 8,
    platform: "twitter",
    mobile: false,
    select: false,
    dark: true,
  },
  {
    id: 9,
    platform: "twitter",
    mobile: false,
    dark: false,
    select: false,
  },
  {
    id: 10,
    platform: "twitter",
    mobile: false,
    dark: false,
    select: false,
  },
  {
    id: 11,
    platform: "linkedin",

    mobile: true,
    dark: false,
    select: false,
  },
  {
    id: 12,
    platform: "linkedin",
    mobile: true,
    dark: false,
    select: false,
  },
  {
    id: 13,
    platform: "facebook",

    mobile: true,
    dark: true,
    select: false,
  },
  {
    id: 14,
    platform: "facebook",
    mobile: true,
    dark: false,
    select: false,
  },
  {
    id: 15,
    platform: "instagram",

    mobile: true,
    dark: false,
    select: false,
  },
  {
    id: 17,
    platform: "instagram",
    mobile: true,
    dark: true,
    select: false,
  },
  {
    id: 18,
    platform: "twitter",

    mobile: true,
    dark: false,
    select: false,
  },
  {
    id: 19,
    platform: "twitter",

    mobile: true,
    dark: true,
    select: false,
  },
];

export default social_config;
