import { db } from "@/firebase";

const blogs = {
  state: {
    blogs: [],
  },
  mutations: {
    setBlogs(state, val) {
      state.blogs = val;
    },
  },
  actions: {
    fetchBlogs({ commit }) {
      return new Promise((resolve) => {
        let blogs = [];

        db.collection("blog")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              blogs.push(doc.data());
            });
          })
          .then(() => {
            blogs.sort((a, b) =>
              a.created < b.created ? -1 : b.created < a.created ? 1 : 0
            );
            commit("setBlogs", blogs);
            resolve("Blogs loaded");
          });
      });
    },
    fetchTutorials({ commit }) {
      return new Promise((resolve) => {
        let tutorials = [];
        db.collection("tutorials")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              tutorials.push(doc.data());
            });
          })
          .then(() => {
            tutorials.sort((a, b) => (a.id < b.id ? -1 : b.id < a.id ? 1 : 0));
            commit("setTutorials", tutorials);
            resolve("Tutorials loaded");
          });
      });
    },
  },
  getters: {},
};

export default blogs;
