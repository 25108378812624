<template>
  <div
    class="d-flex align-center flex-column px-10 py-10"
    v-if="report && !loading"
  >
    <div>
      <v-card flat min-width="400" max-width="90vw" class="titlebar">
        <v-img
          style="border-radius: 10px"
          :src="report.heatmapObjects[0].imageURL"
          height="140"
        >
        </v-img>

        <div class="my-6">
          <p class="text-h5 font-weight-bold mb-1">
            {{ report.title }}
          </p>
          <p class="pr-10 mb-2" style="font-size: 10pt">
            This report predicts how ads would perform in real life, <br />
            based on an Attention score and Clarity score.
          </p>
          <v-btn
            v-if="report.type === 'ooh'"
            style="text-transform: none"
            elevation="0"
            variant="tonal"
            @click="$emit('drawer', 'out-of-home')"
            class="mr-4"
            prepend-icon="mdi-information-outline"
          >
            Explanation
          </v-btn>
          <!-- <v-btn
            @click="downloadAll"
            v-if="
              report.heatmapObjects[0].templates_images &&
              report.heatmapObjects[0].templates_images.length > 0
            "
            elevation="0"
            dark
            variant="tonal"
          >
            <v-icon class="mr-2" small>mdi-download</v-icon>
            Download templates
          </v-btn> -->
        </div>
        <div class="partthree py-6 px-3">
          <p class="mb-0" v-if="report.type === 'ooh'">
            <v-icon class="mr-1">mdi-billboard</v-icon> (Digital) Out of Home
          </p>
          <p class="mb-0" v-if="report.type === 'display'">
            <v-icon class="mr-1">mdi-monitor</v-icon> Display Ads
          </p>
          <p class="mb-0" v-if="report.type === 'social'">
            <v-icon class="mr-1">mdi-facebook</v-icon> Social Media
          </p>
          <p class="mb-0">{{ report.created_by_name }}</p>
          <p class="mb-0">{{ date }}</p>
        </div>
      </v-card>
    </div>

    <div class="tabs mt-6">
      <v-tabs
        v-model="tab"
        light
        color="warning"
        background-color="transparent"
      >
        <v-tab style="text-transform: none">Results</v-tab>
        <v-tab style="text-transform: none">Ads</v-tab>
        <!-- <v-tab style="text-transform: none" v-if="report.attention_benchmark"
          >Templates</v-tab
        > -->
      </v-tabs>
    </div>
    <transition name="slide-fade" mode="out-in">
      <div class="datacards" key="report" v-if="tab === 0">
        <Card
          @tab="setTab"
          :rank="1"
          kpi="Attention"
          :winner="winnerAttention"
          :winnerCard="true"
          v-if="report.attention_benchmark"
        />
        <Card
          @tab="setTab"
          :rank="1"
          kpi="Clarity"
          :winner="winnerClarity"
          :winnerCard="true"
          v-if="report.clarity_benchmark"
        />
        <!-- <Saliency
          v-if="
            report &&
            report.heatmapObjects.length === report.heatmaps.length &&
            report.attention_benchmark
          "
          :report="report"
          :winner="winnerAttention"
          :suggestedMin="minAttention"
          :suggestedMax="maxAttention"
        />
        <Clarity
          v-if="
            report &&
            report.heatmapObjects.length === report.heatmaps.length &&
            report.clarity_benchmark
          "
          :report="report"
          :winner="winnerClarity"
          :suggestedMin="minClarity"
          :suggestedMax="maxClarity"
        /> -->
        <Chart :report="report" />
      </div>

      <div class="datacards" key="ads" v-else-if="tab === 1">
        <v-card
          flat
          min-width="400"
          max-width="1000"
          v-for="(heatmap, index) in report.heatmapObjects"
          :key="index"
          class="ad"
        >
          <img
            :ref="`ad${heatmap.uid}`"
            :src="heatmap.imageURL"
            style="max-width: 100%"
            v-if="heatmap.imageURL"
          />
          <div class="information">
            <h2 class="mb-3">{{ heatmap.title }}</h2>
            <div class="d-flex">
              <v-sheet
                v-if="report.attention_benchmark"
                color="warning"
                class="pa-2 white--text text-center mr-1"
                rounded=""
                ><span style="font-size: 10pt"
                  >Attention:
                  <b>{{ Math.round(heatmap.template_average) }}</b></span
                ></v-sheet
              >
              <v-sheet
                v-if="report.clarity_benchmark"
                color="accent"
                class="pa-2 white--text text-center"
                rounded=""
                ><span style="font-size: 10pt"
                  >Clarity: <b>{{ heatmap.clarity_score_percentile }}</b></span
                ></v-sheet
              >
            </div>
            <div
              class="d-flex mt-4"
              v-if="
                (subscription.tier === 'business' && subscription.active) ||
                workspace.beta
              "
            >
              <v-btn
                elevation="0"
                style="text-transform: none"
                class="mr-3"
                @click="openHeatmap(heatmap.uid)"
                icon="mdi-open-in-new"
              >
              </v-btn>
              <v-btn
                elevation="0"
                v-if="report.heatmapObjects.length > 1"
                style="text-transform: none"
                @click="removeItem(heatmap.uid)"
                icon="mdi-delete"
              ></v-btn>
            </div>
          </div>
        </v-card>
      </div>
      <div class="d-flex flex-column align-center" v-else-if="tab === 2">
        <v-btn @click="downloadAll" class="mt-4" outlined>
          <v-icon class="mr-2" small>mdi-download</v-icon>
          Download all templates
        </v-btn>
        <div class="templatecards" key="templates">
          <Templatecard
            v-for="(template, index) in report.ids"
            :key="index"
            :template="template"
            :report="report"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Card from "@/components/report/card/ReportCard.vue";
// import Saliency from "@/components/report/saliency";
// import Clarity from "@/components/report/clarity";
import Chart from "@/components/report/chart/ReportChart.vue";
import moment from "moment";
import { db } from "@/firebase";
import firebase from "firebase/";
import Templatecard from "@/components/templateCard/TemplateCard.vue";
import { mapState } from "vuex";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export default {
  name: "ReportDetail",
  components: {
    Card,
    // Saliency,
    // Clarity,
    Templatecard,
    Chart,
  },
  data() {
    return {
      loading: true,
      report: null,
      tab: 0,
    };
  },
  mounted() {
    this.fetchReport();
  },
  computed: {
    winnerAttention() {
      const highest = this.report.heatmapObjects.reduce(
        function (prev, current) {
          return prev.template_average > current.template_average
            ? prev
            : current;
        }
      );
      return highest;
    },
    winnerClarity() {
      const highest = this.report.heatmapObjects.reduce(
        function (prev, current) {
          return prev.clarity_score_percentile >
            current.clarity_score_percentile
            ? prev
            : current;
        }
      );
      return highest;
    },
    date() {
      return moment(this.report.created_at.toDate()).format("DD-MM-YYYY");
    },
    minAttention() {
      const lowest = this.report.heatmapObjects.reduce(
        function (prev, current) {
          return prev.template_average < current.template_average
            ? prev
            : current;
        }
      );
      return lowest.template_average - 10 > 0
        ? lowest.template_average - 10
        : 0;
    },
    maxAttention() {
      return this.winnerAttention.template_average + 10 < 100
        ? this.winnerAttention.template_average + 10
        : 100;
    },
    minClarity() {
      const lowest = this.report.heatmapObjects.reduce(
        function (prev, current) {
          return prev.clarity_score_percentile <
            current.clarity_score_percentile
            ? prev
            : current;
        }
      );
      return lowest.template_average - 10 > 0
        ? lowest.template_average - 10
        : 0;
    },
    maxClarity() {
      return this.winnerClarity.clarity_score_percentile + 10 < 100
        ? this.winnerClarity.clarity_score_percentile + 10
        : 100;
    },
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      users: (state) => state.users.users,
      workspace: (state) => state.workspace.workspace,
      heatmaps: (state) => state.heatmaps.heatmaps,
      projects: (state) => state.projects.projects,
      reports: (state) => state.reports.reports,
      tags: (state) => state.heatmaps.tags,
      defaults: (state) => state.defaults.defaults,
      subscription: (state) => state.subscription.subscription,
    }),
  },
  methods: {
    async fetchReport() {
      const rep = await db
        .collection("reports")
        .doc(this.$route.params.id)
        .get();
      const report = rep.data();
      report.heatmapObjects = [];
      let counter = 0;

      await Promise.all(
        report.heatmaps.map(async (h) => {
          const doc = await db.collection("heatmaps").doc(h).get();
          const heatmap = doc.data();

          if (heatmap.template_scores) {
            const arr = Object.values(heatmap.template_scores[report.type]);
            const average = (arr) =>
              arr.reduce((a, b) => a + b, 0) / arr.length;
            heatmap.template_average = average(arr);
            report.heatmapObjects.push(heatmap);
          }

          if (heatmap.status === "done" && report.status != "done") {
            counter++;
            if (counter === report.heatmaps.length) {
              report.status = "done";
              await db.collection("reports").doc(report.uid).update({
                status: "done",
              });
            }
          }
        })
      );
      this.report = report;
      this.loading = false;
    },
    async downloadAll() {
      console.log("downloading all templates inside report");
      let arr = [];

      for (let x in this.report.heatmapObjects) {
        let h = this.report.heatmapObjects[x];
        for (let y in h.templates_images) {
          arr.push(
            {
              url: h.templates_images[y].original,
              label: `${h.templates_images[y].id}`,
            },
            {
              url: h.templates_images[y].heatmap,
              label: `${h.templates_images[y].id} heatmap`,
            }
          );
        }
      }
      this.saveZip(arr);
    },
    removeItem(uid) {
      let r = confirm("Are you sure?");
      if (r === true) {
        const vm = this;
        db.collection("reports")
          .doc(vm.$route.params.id)
          .update({
            heatmaps: firebase.firestore.FieldValue.arrayRemove(uid),
          })
          .then(() => {
            vm.$store.dispatch("fetchReports");
          });
      }
    },
    openHeatmap(uid) {
      let routeData = this.$router.resolve({
        name: "Heatmap",
        params: {
          id: uid,
        },
      });
      window.open(routeData.href, "_blank");
    },
    setTab(v) {
      console.log(v);
      this.tab = v;
    },
    async saveZip(files) {
      if (!files) return;

      const zip = new JSZip();
      const folder = zip.folder(files[0].project_name);

      for (let x in files) {
        let f = files[x];
        const blobPromise = fetch(f.url).then((r) => {
          if (r.status === 200) return r.blob();
          return Promise.reject(new Error(r.statusText));
        });

        const name = f.label.replace(/\//g, "-").concat(`.jpg`);
        folder.file(name, blobPromise);
      }

      zip
        .generateAsync({ type: "blob" })
        .then((blob) => saveAs(blob, `${this.report.title} templates`));
    },
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
