<template>
  <v-card flat v-if="report" max-width="1000" min-width="400" width="100%">
    <v-card-title class="px-6 bg-accent white--text"
      ><span class="font-weight-bold mr-1">Benchmark scores</span>
    </v-card-title>
    <div class="split">
      <Bar
        id="my-report-chart"
        ref="chartcontainer"
        :options="chartOptions"
        :data="chartData"
        :style="myStyles"
      />

      <!-- <div class="middle" style="flex-direction: column">
        <v-img
          :src="image"
          :max-height="expand ? 1000 : 200"
          width="200"
          :style="!expand ? 'border-radius: 6px' : ''"
        >
        </v-img>
        <p class="text-center mt-3 mb-0">
          {{ report.heatmapObjects[Number(this.selected)].title }}
        </p>
        <transition name="fade">
          <v-btn-toggle
            mandatory
            v-if="expand"
            class="mt-3"
            v-model="view"
            color="secondary"
          >
            <v-btn value="original">
              <v-icon>mdi-image</v-icon>
            </v-btn>
            <v-btn value="heatmap">
              <v-icon>mdi-fire</v-icon>
            </v-btn>

            <v-btn value="peekthrough">
              <v-icon>mdi-eye-arrow-right-outline</v-icon>
            </v-btn>
          </v-btn-toggle>
        </transition>
      </div> -->
    </div>
  </v-card>
</template>

<script>
import { Line, Bar } from "vue-chartjs";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  BarElement,
  Filler,
} from "chart.js";

ChartJS.register(
  Title,
  Filler,
  Tooltip,
  BarElement,
  Legend,
  PointElement,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  annotationPlugin
);

export default {
  props: ["report", "winner", "suggestedMin", "suggestedMax"],
  components: { Line, Bar },
  computed: {
    image() {
      if (this.view === "heatmap") {
        return this.report.heatmapObjects[this.selectedIndex].combined;
      } else if (this.view === "peekthrough") {
        return this.report.heatmapObjects[this.selectedIndex].peekthrough;
      } else {
        return this.report.heatmapObjects[this.selectedIndex].imageURL;
      }
    },
    chartData() {
      const newDataset = {
        labels: [],
        datasets: [
          {
            label: "Attention",
            data: [],
            backgroundColor: [],
          },
          {
            label: "Clarity",
            data: [],
            backgroundColor: [],
          },
        ],
      };
      this.report.heatmapObjects.forEach((h) => {
        newDataset.labels.push(h.title);
        newDataset.datasets[1].data.push(h.clarity_score_percentile);
        newDataset.datasets[0].backgroundColor.push("#706D84");
        newDataset.datasets[1].backgroundColor.push("#B2A5FC");
        newDataset.datasets[0].data.push(h.template_average);
      });

      // if (i) {
      //   this.selectedIndex = i;

      //   newDataset.datasets[0].backgroundColor[i] = "#282447";
      //   newDataset.datasets[1].backgroundColor[i] = "#8b78fa";
      // }

      return newDataset;
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          annotation: {
            annotations: {
              line1: {
                type: "line",
                yMin: 50,
                yMax: 50,
                borderColor: "#ebebef",
                borderWidth: 3,
              },
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            display: true,
          },
          y: {
            display: true,

            min: 0,
            max: 100,
            grid: {
              display: false,
            },
          },
        },
      };
    },
  },
  mounted() {
    this.chartWidth = this.$refs.chartcontainer.clientWidth;
    this.chartHeight = this.$refs.chartcontainer.clientHeight;

    var ro = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        this.chartWidth = cr.width;
        this.chartHeight = cr.height;
      }
    });

    // Observe one or multiple elements
    ro.observe(document.querySelector("#my-report-chart"));
  },
  data() {
    return {
      chartWidth: null,
      chartHeight: null,
      selected: "0",
      myStyles: {
        height: "250px",
        width: "90%",
        position: "relative",
      },
      expand: false,
      show: false,
      view: "original",
      datacollection: {},
      selectedIndex: 0,
    };
  },
  methods: {
    handle(point, event) {
      const item = event[0];
      const num = String(item._index);
      this.generateNewDataset(num);
      this.selected = num;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  transition: all ease 0.4s;
}
.split {
  align-items: center;
  padding: 30px 20px;
}
.middle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;
  border-right: 1px solid #ebebef;

  p {
    font-size: 13pt;
    font-weight: 700;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
