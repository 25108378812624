<template>
  <div ref="chartcontainer" id="myChart">
    <div
      class="scrubber"
      v-bind:style="{ left: left + '%', height: chartHeight + 'px' }"
    ></div>

    <v-sparkline
      :value="metrics[type]"
      :smooth="1"
      type="bar"
      :padding="padding"
      :gradient="['#6515dd']"
      auto-line-width
      height="50"
      v-if="
        type === 'brand_exposure' ||
        type === 'brand_in_audio' ||
        type === 'brand_attention'
      "
    ></v-sparkline>
    <v-sparkline
      v-else-if="type === 'focus' || type === 'proximity' || type === 'clarity'"
      color="primary"
      :model-value="metrics[type]"
      :smooth="10"
      :padding="padding"
      :line-width="width"
      :stroke-linecap="lineCap"
      :gradient-direction="gradientDirection"
      :fill="fill"
      type="trend"
      :auto-line-width="autoLineWidth"
      auto-draw
      height="50"
    ></v-sparkline>

    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  props: ["duration", "currentTime", "type", "metrics"],
  watch: {
    currentTime() {
      const correction = (2.5 / this.chartWidth) * 100;
      this.left = (this.currentTime / this.duration) * 100 - correction;
    },
  },
  mounted() {
    this.chartWidth = this.$refs.chartcontainer.clientWidth;
    this.chartHeight = this.$refs.chartcontainer.clientHeight;

    var ro = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        this.chartWidth = cr.width;
        this.chartHeight = cr.height;
      }
    });

    // Observe one or multiple elements
    ro.observe(document.querySelector("#myChart"));
  },
  data: () => ({
    left: 0,
    width: 1.5,
    radius: 10,
    padding: 1,
    lineCap: "round",
    gradient: ["#00d38c", "#00d38c", "#fbb81b", "#ff5555"],
    clarity: [],
    gradientDirection: "top",
    fill: false,
    graph_type: "trend",
    autoLineWidth: true,
    chartWidth: null,
    chartHeight: null,
  }),
};
</script>

<style lang="scss" scoped>
#myChart {
  position: relative;
  margin: 0 10px;
}

.sparkline {
  position: absolute;
}

.scrubber {
  width: 5px;
  background-color: #c4c4c4;
  opacity: 0.5;
  border-radius: 10px;
  position: absolute;
  z-index: 99;
}
</style>
