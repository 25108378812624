<template>
  <div>
    <v-dialog persistent opacity="0.75" max-width="500" :model-value="dialog">
      <v-card>
        <v-sheet
          class="d-flex justify-space-between align-center bg-primary pa-4"
        >
          <h3>Adjust template</h3>
          <v-btn
            variant="text"
            @click="$emit('close')"
            icon="mdi-close"
          ></v-btn>
        </v-sheet>

        <div
          class="pa-4"
          style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px"
        >
          <div>
            <v-select
              v-model="selected"
              :items="options"
              item-title="name"
              multiple
              return-object
              variant="solo"
              bg-color="smoke"
              flat
            ></v-select>
          </div>
          <div>
            <div v-if="selected.find((o) => o.name === 'split') != undefined">
              <p class="mb-3" style="font-size: 10pt">Split:</p>
              <v-text-field
                variant="solo"
                bg-color="smoke"
                flat
                v-model.number="selected.find((o) => o.name === 'split').amount"
                type="number"
                label="Split percentage"
                hide-details
                class="mb-2"
              ></v-text-field>
              <v-select
                variant="solo"
                hide-details
                class="mb-2"
                bg-color="smoke"
                flat
                v-model="selected.find((o) => o.name === 'split').axis"
                :items="['vertical', 'horizontal']"
              ></v-select>
              <v-text-field
                variant="solo"
                bg-color="smoke"
                flat
                v-model.number="selected.find((o) => o.name === 'split').index"
                type="number"
                label="Index"
                hide-details
                class="mb-2"
              ></v-text-field>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <div class="d-flex justify-end">
            <v-btn @click="save">Save</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { db } from "@/firebase";

export default {
  props: ["value", "dialog", "template_name"],
  data() {
    return {
      options: [
        {
          name: "split",
          axis: "vertical",
          amount: 50,
          index: 0,
        },
      ],
      selected: [],
    };
  },
  watch: {
    dialog: {
      handler: function () {
        if (
          this.dialog &&
          this.value &&
          this.value.options &&
          this.value.options.length > 0
        ) {
          this.value.options.map((o) => this.selected.push(o));
        } else if (this.dialog === false) {
          this.selected = [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      benchmark: (state) => state.benchmarks.benchmark,
    }),
  },
  methods: {
    async save() {
      let cat = this.benchmark.categories_templates;
      let i = cat.findIndex((c) => c.name == this.template_name);
      cat[i].values.find((v) => v.value === this.value.value).options =
        this.selected;
      await db.collection("benchmarks").doc(this.benchmark.uid).update({
        categories_templates: cat,
      });
      this.$emit("close");
    },
  },
};
</script>
<style scoped lang="scss"></style>
