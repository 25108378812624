<template>
  <div>
    <v-sheet color="warning" rounded="">
      <div class="pa-4">
        <div class="d-flex align-center">
          <h4 class="text-white font-weight-medium">Attention score:</h4>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            icon="mdi-information"
            size="small"
            variant="text"
            @click="
              openLink(
                'https://braingineers.gitbook.io/brainsight/metrics/attention-score'
              )
            "
          ></v-btn>
        </div>

        <span
          v-if="attention_score"
          class="font-weight-bold white--text"
          style="font-size: 22pt"
          >{{ Math.round(attention_score) }}
          <span class="font-weight-regular" style="font-size: 15pt">/100</span>
          <span v-if="attention_score >= 70"> &#128079;</span>
          <span v-if="attention_score > 60 && attention_score < 70">
            &#128077;
          </span>
        </span>

        <br v-if="type === 'image'" />
      </div>
      <v-divider></v-divider>
      <v-list bg-color="warning" class="py-0" style="border-radius: 6px">
        <v-list-item link style="width: 100%; font-size: 10pt">
          <span style="font-weight: 600"> Benchmark: </span>{{ application }}
        </v-list-item></v-list
      >
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: ["attention_score", "application", "type"],
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
  },
  computed: {},
};
</script>
