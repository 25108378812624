<template>
  <div>
    <div class="pa-2 d-flex align-center">
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="Search"
        variant="solo"
        flat
        bg-color="smoke"
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
        variant="tonal"
        class="mr-2"
        size="small"
        color="error"
        v-if="selected.length > 0"
        @click="deleteWorkspaces(selected)"
        >Delete</v-btn
      >
    </div>
    <v-divider></v-divider>
    <v-card flat>
      <v-data-table-virtual
        :headers="headers"
        :items="workspaces"
        dense
        :search="search"
        fixed-header=""
        item-value="uid"
        :loading="$store.state.loading"
        v-model="selected"
        hover
        height="calc(100vh - 72px)"
        show-select=""
        @click:row="
          (e, r) => $router.push(`/superadmin/workspace/${r.item.uid}`)
        "
      >
        <template v-slot:[`item.subscription`]="{ item }">
          <v-icon
            :color="
              item.subscription && item.subscription.active ? 'primary' : ''
            "
            >{{
              item.subscription && item.subscription.active
                ? "mdi-check-circle"
                : "mdi-close-circle"
            }}</v-icon
          >
        </template>

        <template v-slot:[`item.value`]="{ item }">
          <v-text-field
            solo
            flat
            hide-details=""
            background-color="smoke"
            placeholder="200"
            v-model="item.value"
            @input="updateValue(item)"
          ></v-text-field>
        </template>

        <template v-slot:[`item.admin`]="{ item }">
          <p class="mb-0" v-if="item.admin_data">
            {{ item.admin_data ? item.admin_data.name : item.admin }}
          </p>
          <p class="mb-0" v-if="item.admin_data">
            {{ item.admin_data.email }}
          </p>
          <p
            style="font-size: 8pt"
            class="mb-0 error--text"
            v-if="!item.admin_data"
          >
            User not found
          </p>
        </template>
      </v-data-table-virtual>
    </v-card>
    <v-navigation-drawer
      width="400"
      class="bg-smoke"
      v-if="workspace"
      location="right"
      permanent
    >
      <template v-slot:prepend>
        <v-btn
          style="position: absolute; top: 25px; left: -15px"
          @click="close"
          icon="mdi-close"
          size="small"
          density="comfortable"
        ></v-btn>
        <div class="pa-8 d-flex align-center">
          <div>
            <h1>{{ workspace.name }}</h1>
            <p>{{ workspace.uid }}</p>
          </div>
          <v-spacer></v-spacer>

          <v-menu bottom left offset-y>
            <template v-slot:activator="{ props }">
              <v-btn
                class="ml-6"
                variant="text"
                size="small"
                icon="mdi-dots-vertical"
                v-bind="props"
                elevation="0"
              >
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="
                  workspace.custom_image_credits ||
                  workspace.custom_video_credits
                "
                @click="removeCredits"
                link
                class="px-6 py-2"
              >
                Remove custom credits
              </v-list-item>
              <v-list-item
                v-if="
                  !workspace.custom_image_credits &&
                  !workspace.custom_video_credits
                "
                @click="addCredits"
                link
                class="px-6 py-2"
              >
                Add custom credits
              </v-list-item>
              <v-list-item link class="px-6 py-2"> Send invite </v-list-item>
              <v-list-item link class="px-6 py-2"
                >Delete workspace
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="px-6 pb-3">
          <v-btn
            block=""
            :color="changes ? 'primary' : ''"
            flat
            :disabled="!changes"
            @click="save"
            >Save changes</v-btn
          >
        </div>
      </template>

      <v-divider></v-divider>

      <div class="pa-6">
        <v-text-field
          label="Name"
          v-model="workspace.name"
          variant="solo"
          flat
          @input="changes = true"
        ></v-text-field>
        <v-select
          @update:model-value="updateAdmin"
          :items="workspace.members_data"
          item-title="email"
          return-object=""
          @input="changes = true"
          label="Admin"
          variant="solo"
          flat
          v-model="workspace.admin_data"
        ></v-select>

        <div class="d-flex" style="gap: 10px">
          <v-text-field
            label="Video credits"
            variant="solo"
            flat
            type="number"
            @input="changes = true"
            v-model="workspace.videoCredits"
          ></v-text-field>
          <v-text-field
            variant="solo"
            flat
            type="number"
            @input="changes = true"
            label="Image credits"
            v-model="workspace.imageCredits"
          ></v-text-field>
        </div>

        <div
          class="d-flex"
          style="gap: 10px"
          v-if="
            workspace.custom_image_credits || workspace.custom_video_credits
          "
        >
          <v-text-field
            label="Monthly video credits"
            type="number"
            variant="solo"
            @input="changes = true"
            flat
            v-model="workspace.custom_video_credits"
          ></v-text-field>
          <v-text-field
            type="number"
            @input="changes = true"
            variant="solo"
            flat
            label="Monthly video credits"
            v-model="workspace.custom_image_credits"
          ></v-text-field>
        </div>

        <div class="d-flex" style="gap: 10px">
          <v-text-field
            :label="`Seats left`"
            type="number"
            @input="changes = true"
            variant="solo"
            flat
            v-model="workspace.seats"
            @update:model-value="updateSeats"
          ></v-text-field>
          <v-text-field
            label="MRR"
            type="number"
            variant="solo"
            flat
            :key="workspace.uid"
            @input="changes = true"
            v-model="workspace.value"
            @update:model-value="updateValue"
          ></v-text-field>
        </div>

        <div class="d-flex mb-4" style="gap: 10px">
          <v-switch
            class="ma-0 pa-0"
            @input="changes = true"
            dense
            color="primary"
            label="Beta access"
            hide-details=""
            v-model="workspace.beta"
          ></v-switch>
          <v-switch
            @update:modelValue="changeAPI"
            class="ma-0 pa-0"
            dense
            color="primary"
            label="API access"
            hide-details=""
            :model-value="typeof workspace.api_key === 'string'"
          ></v-switch>
        </div>
        <v-text-field
          variant="solo"
          flat
          @input="changes = true"
          v-if="workspace.api_key"
          v-model="workspace.api_key"
          label="API key"
        ></v-text-field>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { db } from "@/firebase";
import firebase from "firebase/";
import { mapState } from "vuex";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "SuperAdminWorspaces",
  data() {
    return {
      changes: false,
      dialog: true,
      headers: [
        {
          title: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          title: "Admin",
          value: "admin_data.email",
        },
        {
          title: "Images",
          value: "imageHeatmaps",
          sortable: true,
        },
        {
          title: "Videos",
          value: "videoHeatmaps",
        },
        {
          title: "Users",
          value: "members.length",
          sortable: true,
        },

        {
          title: "Subscription",
          value: "subscription",
          sortable: true,
        },

        {
          title: "",
          value: "actions",
        },
      ],
      selected: [],
      search: "",
      workspace: null,
    };
  },

  computed: {
    ...mapState({
      workspaces: (state) => state.admin.workspaces,
    }),
  },
  methods: {
    async close() {
      if (this.changes) {
        let r = confirm(
          "You have made changes. Do you want to continue without saving?"
        );
        if (r) {
          this.$store.commit("setLoading", true);
          this.workspace = null;
          this.changes = false;
          await this.$store.dispatch("fetchAdminWorkspaces");
          this.$store.commit("setLoading", false);
        }
      } else {
        this.workspace = null;
      }
    },
    async changeAPI(v) {
      let uid = uuidv4();
      let api_key = uuidv4() + "-" + uid;
      this.changes = true;
      if (!v) {
        delete this.workspace.api_key;
      } else {
        this.workspace.api_key = api_key;
      }
    },
    async addCredits() {
      await db.collection("workspaces").doc(this.workspace.uid).update({
        custom_video_credits: 20,
        custom_image_credits: 1500,
      });
      this.$store.dispatch("fetchAdminWorkspaces");
    },
    closeDialog(v) {
      if (v === "save") {
        this.fetchData();
      }
      this.dialog = false;
    },
    async deleteWorkspaces(workspaces) {
      let r = confirm("Are you sure?");
      if (r) {
        console.log(workspaces);
        this.$store.commit("setLoading", true);
        let deleteWorkspaces = firebase
          .app()
          .functions("europe-west1")
          .httpsCallable("deleteWorkspaces");
        await deleteWorkspaces(workspaces);
        await this.$store.dispatch("fetchAdminWorkspaces");
        this.selected = [];
        this.$store.commit("setLoading", false);
      }
    },
    editItem(item) {
      this.workspace = item;
      this.dialog = true;
    },
    getDate(v) {
      console.log(moment(v));
      return moment(v.toDate()).format("MMMM Do YYYY");
    },
    async removeCredits() {
      await db.collection("workspaces").doc(this.workspace.uid).update({
        custom_video_credits: firebase.firestore.FieldValue.delete(),
        custom_image_credits: firebase.firestore.FieldValue.delete(),
      });
      this.$store.dispatch("fetchAdminWorkspaces");
    },
    showSnackbar(val) {
      this.$emit("snackbar", val);
    },

    async save() {
      this.$store.commit("setLoading", true);
      let w = { ...this.workspace };
      this.workspace = null;
      delete w.admin_data;
      delete w.members_data;
      delete w.subscription;

      if (w.custom_image_credits) {
        w.custom_image_credits = Number(w.custom_image_credits);
        w.custom_video_credits = Number(w.custom_video_credits);
      }

      if (!w.api_key) {
        await db.collection("workspaces").doc(w.uid).update({
          api_key: firebase.firestore.FieldValue.delete(),
        });
      }
      await db.collection("workspaces").doc(w.uid).update(w);
      await this.$store.dispatch("fetchAdminWorkspaces");
      this.workspace = this.workspaces.find((ws) => ws.uid === w.uid);
      this.changes = false;

      this.$store.commit("setLoading", false);
      this.$store.commit("setSnackbar", {
        text: "Workspace updated",
        timeout: 2000,
        show: true,
      });
    },
    async updateAdmin(v) {
      console.log(v);
      let r = window.confirm(
        "Are you sure? If you change admin, this user needs to have an active subscription in Stripe."
      );
      if (r) {
        await db.collection("workspaces").doc(this.workspace.uid).update({
          admin: v.uid,
        });
      }
      this.$store.dispatch("fetchAdminWorkspaces");
    },
    async updateValue(val) {
      await db
        .collection("workspaces")
        .doc(this.workspace.uid)
        .update({
          value: Number(val),
        });
    },
    async updateSeats(val) {
      await db
        .collection("workspaces")
        .doc(this.workspace.uid)
        .update({
          seats: Number(val),
        });
    },
    async updateCustomVideo(v) {
      await db
        .collection("workspaces")
        .doc(this.workspace.uid)
        .update({
          custom_video_credits: Number(val),
        });
    },
    async updateCustomImage(v) {
      await db
        .collection("workspaces")
        .doc(this.workspace.uid)
        .update({
          custom_image_credits: Number(val),
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  height: 100vh;
}
// @media (min-width: 1500px) {
//   .table {
//     max-width: 80%;
//   }
// }
</style>
