const articles = [
  {
    id: 1,
    title: "Agreement to these Terms",
    text: [
      "By using the Services, you agree to be bound by these Terms. If you don’t agree to these Terms, do not use the Services. If you are accessing and using the Services on behalf of a company (such as your employer) or other legal entity, you represent and warrant that you have the authority to bind that company or other legal entity to these Terms. In that case, “you” and “your” will refer to that company or other legal entity.",
    ],
  },
  {
    id: 2,
    title: "Changes to the Terms or Services",
    text: [
      "We may modify the Terms at any time, at our sole discretion. If we do so, we will let you know either by posting the modified Terms on the Site or through other communications. If you continue to use the Services after such a change, you are indicating that you agree to the modified Terms. We may also change or discontinue all or any part of the Services, at any time and without notice, at our sole discretion.",
    ],
  },
  {
    id: 3,
    title: "Service terms",
    text: [
      "3.1. Who may use the Services. You may only use the Services if you are old enough to consent (by yourself and not by a parent or guardian) to share your data under applicable law. For example, you must be 13 years or older under United States law, or 16 years or older under European Union law.",
      "3.2. Use Restrictions. Except as otherwise expressly authorized in these Terms, you will not, and will ensure your employees, contractors, and other persons associated with your Brainsight account (“Authorized Users”) do not, and will not encourage or assist third parties to: (i) reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code, object code, or underlying structure, ideas, know-how, or algorithms relevant to the Services (except to the extent that such a restriction is impermissible under applicable law); (ii) provide, sell, resell, transfer, sublicense, lend, distribute, rent, or otherwise allow others to access or use the Services; and (iii) copy, modify, create derivative works of, or remove proprietary notices from the Services.",
      "3.3. Acceptable Use Policy. You will comply with, and will ensure your Authorized Users comply with, Brainsight’s Acceptable Use Policy available at www.brainsight/privacy.",
      "3.4. Authorized Users; Accounts. As part of the registration process, you will identify an administrative username and password for your Brainsight account. You represent and warrant that all registration information, including with respect to the list of domains owned or controlled by you for purposes of domain capture, you provide is truthful, accurate, and complete, and that you will maintain the accuracy of such information. You are responsible and liable for maintaining control over your account, including the confidentiality of your username and password, and are solely responsible and liable for all activities that occur on or through your account and all Authorized Users’ accounts, whether authorized by you or not.",
      "3.5. Feedback; Use Rights. We welcome feedback, comments, and suggestions (“Feedback”). As we need to be able to freely work with your Feedback to improve the Services, you hereby irrevocably transfer and assign all right, title, and interest (including all intellectual property rights, such as copyrights or trade secrets) in and to the Feedback, including any and all “moral rights” that you might have in such Feedback, and you hereby forever waive and agree never to assert any and all “moral rights” you may have in the Feedback. Additionally, Brainsight will have the right to collect and analyze data and other information relating to the provision, use, and performance of various aspects of the Services, and related systems and technologies, and Brainsight will be free to use such data and information to maintain, improve, and enhance Brainsight’s products and services.",
      "3.6. Reservation of Rights. As between the parties, Brainsight owns all right, title, and interest in the Services, and you own all right, title, and interest in any material(s) that are uploaded to the Services by you (“User Content”). Except as expressly set forth in these Terms, each party retains all right, title, and interest in and to its intellectual property rights. All rights not expressly granted are reserved, and no license, covenant, immunity, transfer, authorization, or other right will be implied, by reason of statute, estoppel, or otherwise, under these Terms.",
      "3.7. Patent Assertion Entities. A “Patent Assertion Entity,” sometimes referred to as a ‘non-practicing entity’ or a ‘patent troll,’ is (a) any entity that derives or seeks to derive most of its revenue from the offensive assertion of patent rights, or (b) directly or indirectly controls, is controlled by, or is under common control with an entity described in (a). If you are a Patent Assertion Entity or are acting on behalf of, or for the benefit of a Patent Assertion Entity, you will not assert, or authorize, assist, encourage, or enable any third party to assert, any claim, or pursue any actions, suits, proceedings, or demands, against Brainsight or its affiliates that allege that the Services infringe, misappropriate, or otherwise violate any intellectual property rights (including patents). This section will survive any termination or expiration of these Terms.",
    ],
  },
  {
    id: 4,
    title: "License",
    text: [
      "Subject to the terms and conditions of these Terms, we hereby grant you a limited, non-exclusive, non-transferable, non-sublicensable license worldwide (with the exception of (i) jurisdictions that are embargoed by the European Union and/or the United States Government and (ii) jurisdictions whose laws do not permit engaging in business with Brainsight or use of the Services) for you to access and use the Services in connection with your internal business operations.",
    ],
  },
  {
    id: 5,
    title: "Payment terms",
    text: [
      "5.1. Pricing. Certain Services are offered under different pricing plans, the limits and features of which are available at www.brainsight/pricing. Your rights and obligations with respect to certain Services will be based in part on the pricing plan you choose.",
      "5.2. Payment Terms.",
      "5.2.1. When you purchase Services (each such purchase, a “Subscription”), you expressly authorize us or our third-party payment processor to charge you for such Services. You represent and warrant that you have the legal right to use all payment methods that you provide to us.",
      "5.2.2. All fees are stated and solely payable in Euros, non-cancelable, non-refundable, and not subject to setoff. You are solely responsible for any bank fees, interest charges, finance charges, overdraft charges, and any other fees you incur as a result of the charges billed by us.",
      "5.2.3. In the event that you fail to pay the full amount owed to us, we may limit your access to the Services, in addition to any other rights or remedies we may have.",
      "5.3. Authorization for Recurring Payments.",
      "5.3.1. All pricing plans involve recurring fees (each, along with any applicable taxes and other charges are a “Subscription Fee”). Depending on which options you choose, those fees may recur periodically (i.e. monthly, yearly) thereafter, at the then-current rate. Please note that our fees are subject to change, although we will notify you before we effect any change.",
      "5.3.2. By agreeing to these Terms and purchasing a Subscription, you acknowledge that your Subscription has recurring payment features and you accept responsibility for all recurring payment obligations prior to cancellation of your Subscription by you or Brainsight. We (or our third party payment processor) will automatically charge you in accordance with the terms of your Subscription (e.g., monthly, yearly), on the calendar day corresponding to the commencement of your Subscription using the payment information you have provided.",
      "5.3.3. In the event your Subscription begins on a day not contained in a later month, your payment method will be charged on such other day as we deem appropriate. For example, if you started a monthly Subscription on January 31st, your next payment date is likely to be February 28th, and your payment method would be billed on that date. We may also periodically authorize your payment method in anticipation of applicable fees or related charges.",
      "5.3.4. Your Subscription continues until cancelled by you or we terminate your access to or use of the Services or the Subscription in accordance with these Terms.",
      "5.4. Cancelling Subscriptions",
      "5.4.1. You may cancel your Subscription at any time but please note that such cancellation will only be effective at the end of the then-current Subscription period. Unless required by law, YOU WILL NOT RECEIVE A REFUND OF ANY PORTION OF THE SUBSCRIPTION FEE PAID FOR THE THEN-CURRENT SUBSCRIPTION PERIOD AT THE TIME OF CANCELLATION.",
      "5.4.2. To cancel, you can either (i) email us at support@brainsight.app and follow any instructions, if any, we provide to you in response to your cancellation request, or (ii) for some kinds of Subscriptions, initiate a cancellation through your Brainsight account settings within the Services. You will be responsible for all Subscription Fees incurred for the then-current Subscription period. Cancelling your Subscription will not terminate your Brainsight account. See Section 10 (Termination) below for information on terminating your Brainsight account.",
      "5.5. Taxes. Subscription Fees do not include taxes, and you agree to: (a) pay all sales/use, gross receipts, value-added, GST, personal property, or other tax (including any interest and penalties) with respect to the transactions and payments under these Terms, other than taxes based on our income, employees, or real property; and (b) be responsible for any filing of any information or tax returns with respect thereto.",
      "5.6. Withholding. All payments made by you to us under these Terms will be made free and clear of any deduction or withholding. If any such deduction or withholding (including but not limited to cross-border withholding taxes) is required by law, you will pay such additional amounts as are necessary so that the net amount received by us after such deduction or withholding will be equal to the full amount that we would have received if no deduction or withholding had been required. Each party will use commercially reasonable efforts to work with the other party to help obtain, reduce, or eliminate any necessary withholding, deduction, or royalty tax exemptions where applicable.",
    ],
  },
  {
    id: 6,
    title: "Confidentiality",
    text: [
      "6.1. Confidential Information. We (the “Discloser”) have disclosed or may disclose proprietary or non-public business, technical, financial, or other information (“Confidential Information”) to you (the “Recipient”). Our Confidential Information expressly includes non-public information regarding features, functionality, and performance of the Services, including security related information.",
      "6.2. Obligations. The Recipient will use the Discloser’s Confidential Information only for the purpose of evaluating whether or not to use (or continue to use) the Services. The Recipient will not disclose the Discloser’s Confidential Information to parties other than the Recipient’s employees, contractors, affiliates, agents, or professional advisors (“Representatives”) who need to know it and who have a legal obligation to keep it confidential. The Recipient will ensure that its Representatives are subject to no less restrictive confidentiality obligations than those herein. Notwithstanding the foregoing, the Recipient may disclose the Discloser’s Confidential Information: (a) if directed by Discloser; or (b) to the extent required by applicable legal process, provided that the Recipient uses commercially reasonable efforts to (i) promptly notify the Discloser in advance, to the extent permitted by law, and (ii) comply with the Discloser’s reasonable requests regarding its efforts to oppose the disclosure. The obligations set forth herein will survive for so long as these Terms are in effect between the parties and for five years thereafter.",
    ],
  },
  {
    id: 7,
    title: "Warranties",
    text: [
      "7.1. In the event of any loss or corruption of any data associated with the Services, Brainsight will use commercially reasonable efforts to restore the lost or corrupted data from the latest relevant backup maintained by Brainsight. EXCEPT FOR THE FOREGOING, BRAINSIGHT WILL NOT BE RESPONSIBLE FOR ANY LOSS, DESTRUCTION, ALTERATION, UNAUTHORIZED DISCLOSURE OR CORRUPTION OF ANY DATA.",
      "7.2. We make no warranty that the Services will meet your requirements or be available on an uninterrupted, secure, or error-free basis. We make no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or reliability of any Services, and we make no guarantees around data retention or preservation. EXCEPT AS SET FORTH IN SECTION 7.1, THE SERVICES ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, AND NON-INFRINGEMENT AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE.",
      "7.3. NON-BRAINSIGHT RESOURCES. BRAINSIGHT DOES NOT WARRANT OR SUPPORT, AND WILL NOT HAVE ANY RESPONSIBILITY OR LIABILITY OF ANY KIND FOR, ANY APPLICATION(S) AND/OR MATERIAL(S) THAT ARE DEVELOPED BY A PARTY OUTSIDE OF BRAINSIGHT’S ORGANIZATION, INCLUDING DESIGN FILES, PLUGINS, COMPONENT LIBRARIES, AND CODE COMPONENTS (“NON-BRAINSIGHT RESOURCES”). NON-BRAINSIGHT RESOURCES ARE PROVIDED BY THIRD PARTIES, NOT BRAINSIGHT, AND ANY USE OF NON-BRAINSIGHT RESOURCES IS SOLELY BETWEEN YOU AND THE APPLICABLE THIRD-PARTY PROVIDER.",
    ],
  },
  {
    id: 8,
    title: "Indemnity",
    text: [
      "You will indemnify and hold harmless Brainsight and its officers, directors, employees, and agents, from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with (i) your access to or use of the Services; (ii) your User Content; or (iii) your violation of these Terms.",
    ],
  },
  {
    id: 9,
    title: "Limitations of Liability",
    text: [
      "9.1. NEITHER BRAINSIGHT NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES (“SUPPLIERS”) WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE, SYSTEM FAILURE, OR THE COST OF SUBSTITUTE SERVICES ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT BRAINSIGHT HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE.",
      "9.2. IN NO EVENT WILL BRAINSIGHT OR SUPPLIERS’ TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES EXCEED THE AMOUNTS YOU HAVE PAID TO BRAINSIGHT IN THE PRECEDING TWELVE MONTHS FOR THE SERVICES, OR IF YOU HAVE NOT HAD ANY SUCH PAYMENT OBLIGATIONS, ONE HUNDRED UNITED STATES DOLLARS (EUR100).",
      "9.3. EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS TO ALLOCATE THE RISKS OF THESE TERMS BETWEEN THE PARTIES. THIS ALLOCATION IS REFLECTED IN THE PRICING OFFERED BY BRAINSIGHT TO YOU AND IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE LIMITATIONS IN THIS SECTION 9 WILL APPLY TO THE MAXIMUM EXTENT NOT PROHIBITED BY LAW AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY IN THESE TERMS.",
    ],
  },
  {
    id: 10,
    title: "Termination",
    text: [
      "10.1. We may terminate your access to and use of the Services, at our sole discretion, at any time and without notice or liability to you, provided that, if you have paid for a Subscription and the termination is not due to your breach of these terms, Brainsight will refund you any prepaid fees for the period of your Subscription that extends beyond the effective date of such termination.",
      "10.2. You may cease use of the Services at any time. If you are paying for a Subscription, you may terminate your Subscription through the process in Section 5.4. You may also cancel your Brainsight account at any time by sending an email to info@brainsight.app.",
      "10.3. Upon any termination, discontinuation, or cancellation of Services or your Brainsight account, the following provisions of these Terms will survive: Service Terms, Charges and Payment (to the extent you owe any fees at the time of termination); Confidentiality; provisions related to permissions to access User Content (to the extent applicable); Warranty Disclaimers; Indemnity; Limitations of Liability; Termination; and the Miscellaneous provisions under Section 11. Furthermore, we may remove or delete your User Content within a reasonable period of time after the termination or cancellation of Services or your Brainsight account.",
    ],
  },
  {
    id: 11,
    title: "Miscellaneous",
    text: [
      "11.1. Development Resources. Brainsight’s Developer Terms apply to any use by you of Brainsight’s Application Programming Interfaces (APIs), Software Development Kits (SDKs), and related documentation.",
      '11.2. Accessing Apps. The terms set forth on Exhibit A apply to any App accessed through or downloaded from any app store or distribution platform (like the Apple App Store or Google Play) where the App may now or in the future be made available (each an "App/Tool Provider").',
      "11.3. Privacy Policy. Our Privacy Policy (www.brainsight.app/privacy) governs how we collect, use and disclose information from the Services.",
      "11.4. Copyright and IP Policy. Brainsight respects copyright law and expects its users to do the same. Brainsight’s Copyright and IP Policy applies to you and is available at www.brainsight/copyright-and-ip-policy.",
      "11.5. Force Majeure. Brainsight will not be liable for, or be considered to be in breach of or default under these Terms on account of, any delay or failure to perform as required by these Terms as a result of any cause or condition beyond its reasonable control, so long as it uses commercially reasonable efforts to avoid or remove those causes of non-performance.",
      "11.6. Notices. Any notices or other communications provided by Brainsight under these Terms, including those regarding modifications to these Terms, will be given by Brainsight: (i) via e-mail; or (ii) by posting to the Site; or posting in the Brainsight tool. For notices made by e-mail, the date of receipt will be deemed the date on which such notice is transmitted.",
      "11.7. Severability. The invalidity or unenforceability of any provision of these Terms will not affect the validity or enforceability of any other provision hereof and it is the intent and agreement of the parties that these Terms will be deemed amended by modifying such provision to the extent necessary to render it valid, legal, and enforceable while preserving its intent or, if such modification is not possible, by substituting another provision that is legal and enforceable and that achieves the same objective.",
      "11.8. Groups. Certain features of the Services may allow you to participate in teams, workspaces and/or organizations (each a “Group”). In those situations, the administrator, owner, or equivalent of the Group (“Admin”) is responsible for the compliance of these Terms by each other member of the Group, payment of the Subscription Fee (if applicable), and all matters related to the Group. For clarity, each member of the Group is still responsible for their own compliance with these Terms.",
      "11.9. Assignment. These Terms (and your access to any of the Services) are not assignable or transferable by you without our prior written consent. Any purported assignment in violation of this section is null and void.",
      "11.10. Service Providers. For the avoidance of doubt, Brainsight may engage third parties as service providers to the Services (for example, as of the date of these Terms, Brainsight hosts the Services on Google Cloud Services).",
      "11.11. No Partnership. No agency, partnership, joint venture, or employment is created as a result of these Terms, and neither party has any authority of any kind to bind the other party in any respect whatsoever.",
      "11.12. Governing Law. These Terms will be governed by the laws of The Netherlands without regard to its conflict of laws provisions.",
      '11.13. Beta Features. Product features clearly identified as Alpha or Beta features (collectively “Early Access Features”) made available by Brainsight are provided to you for testing purposes only, and Brainsight does not make any commitment to provide Early Access Features in any future versions of the Services. You are not obligated to use Early Access Features. Brainsight may immediately and without notice remove Early Access Features for any reason without liability to you. Notwithstanding anything to the contrary in the Terms, all Early Access Features are provided "AS IS" without warranty of any kind and without any performance obligations.',
      "11.14. Entire Terms. These Terms supersedes all other agreements between the parties relating to its subject matter. The parties expressly agree that any different or additional terms set forth in any purchase order, vendor portal, code of conduct, or other similar documentation provided by you will not apply between the parties even if signed, acknowledged or accepted by Brainsight, unless Brainsight specifically references this clause and waives its rights.",
    ],
  },
  {
    id: 12,
    title: "Exhibit A - App/Tool Terms",
    text: [
      "1. You acknowledge and agree that: These Terms are concluded between you and Brainsight, and not with the App/Tool Provider, and Brainsight (not the App/Tool Provider), is solely responsible for the App/Tool.",
      "2. The App/Tool Provider has no obligation to furnish any maintenance and support services with respect to the App/Tool.",
      "3. In the event of any failure of the App/Tool to conform to any applicable warranty, you may notify the App/Tool Provider, and the App/Tool Provider will refund the purchase price for the App/Tool to you (if applicable) and, to the maximum extent permitted by applicable law, the App/Tool Provider will have no other warranty obligation whatsoever with respect to the App/Tool. Any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of Brainsight.",
      "4. The App/Tool Provider is not responsible for addressing any claims you have or any claims of any third party relating to the App/Tool or your possession and use of the App/Tool, including, but not limited to: (i) product liability claims; (ii) any claim that the App/Tool fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.",
      "5. In the event of any third party claim that the App/Tool or your possession and use of that App/Tool infringes that third party’s intellectual property rights, Brainsight will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by these Terms.",
      "6. The App/Tool Provider, and its subsidiaries, are third-party beneficiaries of these Terms as related to your license to the App/Tool, and that, upon your acceptance of the Terms, the App/Tool Provider will have the right (and will be deemed to have accepted the right) to enforce these Terms as related to your license of the App/Tool against you as a third-party beneficiary thereof.",
      "7. You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a terrorist- supporting country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.",
      "8. You must also comply with all applicable third party terms of service when using the App/Tool.",
    ],
  },
];

export default articles;
