<template>
  <div>
    <v-dialog v-model="dialog" persistent width="400px">
      <v-card>
        <template slot="progress">
          <v-progress-linear
            color="accent"
            height="5"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-card-title class="accent py-5 white--text">
          <v-icon color="white" class="mr-3">mdi-delete</v-icon
          ><span class="text-h5">Delete</span>
        </v-card-title>
        <v-card-text class="py-6 text-h6"> Are you sure?</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="
              title = '';
              dialog = false;
            "
          >
            No
          </v-btn>
          <v-btn @click="deleteItem" color="error" text> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import moment from "moment";
import { db } from "@/firebase";
import { mapState } from "vuex";
import firebase from "firebase/";

export default {
  name: "DeleteComponent",
  props: {
    id: {
      type: String,
    },
    deleteProjectId: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      defaults: (state) => state.defaults.defaults,
    }),
  },
  data() {
    return {
      link: "testlink",
      dialog: false,
    };
  },
  methods: {
    deleteItem() {
      const vm = this;
      const type = this.deleteProjectId ? "projects" : "heatmaps";
      const id = this.deleteProjectId ? this.deleteProjectId : this.id;
      db.collection(`${type}`)
        .doc(id)
        .delete()
        .then(function () {
          if (!vm.deleteProjectId) {
            db.collection("projects")
              .where("heatmaps", "array-contains", vm.id)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  db.collection("projects")
                    .doc(doc.id)
                    .update({
                      heatmaps: firebase.firestore.FieldValue.arrayRemove(
                        vm.id
                      ),
                    });
                });
              });
          }

          this.dialog = false;
          vm.$store.dispatch("fetchProjects");

          if (vm.$route.name === "Heatmap") {
            vm.$router.push("/heatmaps");
          }

          console.log("Document successfully deleted!");
        })
        .catch(function (error) {
          console.error("Error removing document: ", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
