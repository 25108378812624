<template>
  <v-card flat width="300" class="templates">
    <v-img
      width="300"
      height="200"
      :src="
        require(`@/components/reportWizard/images/${report.type}/${template}.jpg`)
      "
    ></v-img>
    <div style="padding: 15px 25px">
      <p class="font-weight-bold mb-1">Template ID: {{ template }}</p>
      <p>
        Average Attention score: <b>{{ attentionScore }}</b>
      </p>
      <v-btn
        elevation="0"
        style="text-transform: none"
        @click="deleteItem(template)"
        >Remove from report</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import { db } from "@/firebase";
import firebase from "firebase/";

export default {
  props: ["template", "report"],
  data() {
    return {};
  },
  computed: {
    attentionScore() {
      var total = 0;
      for (var i = 0; i < this.report.heatmapObjects.length; i++) {
        total +=
          this.report.heatmapObjects[i].template_scores[this.report.type][
            this.template
          ];
      }
      var avg = total / this.report.heatmapObjects.length;

      return Math.round(avg);
    },
  },
  methods: {
    deleteItem() {
      let r = confirm("Are you sure? This will impact the results.");
      if (r === true) {
        const vm = this;
        this.report.heatmapObjects.forEach((h) => {
          const scores = h.template_scores;
          delete scores[this.report.type][this.template];
          db.collection("heatmaps")
            .doc(h.uid)
            .update({ template_scores: scores })
            .then(() => {
              console.log("Document successfully updated!");
            });
        });
        db.collection("reports")
          .doc(vm.report.uid)
          .update({
            ids: firebase.firestore.FieldValue.arrayRemove(this.template),
          })
          .then(() => vm.$store.dispatch("fetchReports"));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.templates {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
</style>
