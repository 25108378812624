const ooh_config = [
  {
    id: 1.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 2.0,
    day: true,
    crowded: "low",
    traffic: "low",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 3.0,
    day: true,
    crowded: "low",
    traffic: "low",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 4.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: false,
    distance: "long",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 6.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 7.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: false,
    distance: "medium",
    select: false,
    portrait: true,
    location: "transit",
  },
  {
    id: 8.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: false,
    distance: "long",
    select: false,
    portrait: true,
    location: "transit",
  },
  {
    id: 9.0,
    day: true,
    crowded: "low",
    traffic: "low",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 10.0,
    day: false,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 11.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 12.0,
    day: true,
    crowded: "low",
    traffic: "low",
    city: false,
    distance: "medium",
    select: false,
    portrait: true,
    location: "transit",
  },
  {
    id: 14.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: false,
    distance: "medium",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 15.0,
    day: true,
    crowded: "low",
    traffic: "low",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 16.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "medium",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 17.0,
    day: true,
    crowded: "low",
    traffic: "low",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 18.0,
    day: true,
    crowded: "low",
    traffic: "low",
    city: true,
    distance: "long",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 19.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 20.0,
    day: true,
    crowded: "low",
    traffic: "low",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 21.0,
    day: false,
    crowded: "high",
    traffic: "low",
    city: false,
    distance: "medium",
    select: false,
    portrait: true,
    location: "transit",
  },
  {
    id: 22.0,
    day: true,
    crowded: "high",
    traffic: "high",
    city: false,
    distance: "medium",
    select: false,
    portrait: true,
    location: "transit",
  },
  {
    id: 23.0,
    day: false,
    crowded: "low",
    traffic: "low",
    city: false,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 24.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "medium",
    select: false,
    portrait: true,
    location: "place-based",
  },
  {
    id: 25.0,
    day: true,
    crowded: "low",
    traffic: "high",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "transit",
  },
  {
    id: 26.0,
    day: false,
    crowded: "low",
    traffic: "high",
    city: true,
    distance: "medium",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 27.0,
    day: true,
    crowded: "low",
    traffic: "high",
    city: true,
    distance: "medium",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 28.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "medium",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 29.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "long",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 30.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "long",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 32.0,
    day: false,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "long",
    select: false,
    portrait: true,
    location: "place-based",
  },
  {
    id: 33.0,
    day: true,
    crowded: "low",
    traffic: "high",
    city: false,
    distance: "medium",
    select: false,
    portrait: true,
    location: "place-based",
  },
  {
    id: 34.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "medium",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 35.0,
    day: true,
    crowded: "high",
    traffic: "high",
    city: true,
    distance: "long",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 36.0,
    day: false,
    crowded: "low",
    traffic: "high",
    city: true,
    distance: "long",
    select: false,
    portrait: false,
    location: "street",
  },
  {
    id: 37.0,
    day: true,
    crowded: "low",
    traffic: "high",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 40.0,
    day: false,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 42.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "long",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 43.0,
    day: true,
    crowded: "high",
    traffic: "high",
    city: true,
    distance: "medium",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 49.0,
    day: true,
    crowded: "low",
    traffic: "high",
    city: true,
    distance: "medium",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 52.0,
    day: true,
    crowded: "low",
    traffic: "low",
    city: true,
    distance: "long",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 54.0,
    day: true,
    crowded: "low",
    traffic: "high",
    city: true,
    distance: "long",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 57.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "medium",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 60.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "medium",
    select: false,
    portrait: true,
    location: "place-based",
  },
  {
    id: 61.0,
    day: true,
    crowded: "low",
    traffic: "low",
    city: true,
    distance: "medium",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 64.0,
    day: false,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "medium",
    select: false,
    portrait: true,
    location: "place-based",
  },
  {
    id: 66.0,
    day: true,
    crowded: "low",
    traffic: "low",
    city: true,
    distance: "long",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 67.0,
    day: true,
    crowded: "low",
    traffic: "low",
    city: true,
    distance: "medium",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 68.0,
    day: false,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 69.0,
    day: false,
    crowded: "low",
    traffic: "low",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 70.0,
    day: false,
    crowded: "low",
    traffic: "high",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 71.0,
    day: false,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 72.0,
    day: false,
    crowded: "low",
    traffic: "high",
    city: false,
    distance: "medium",
    select: false,
    portrait: true,
    location: "street",
  },
  {
    id: 73.0,
    day: false,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "short",
    select: false,
    portrait: true,
    location: "place-based",
  },
  {
    id: 74.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "medium",
    select: false,
    portrait: true,
    location: "place-based",
  },
  {
    id: 75.0,
    day: true,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "medium",
    select: false,
    portrait: true,
    location: "place-based",
  },
  {
    id: 76.0,
    day: false,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "medium",
    select: false,
    portrait: false,
    location: "transit",
  },
  {
    id: 77.0,
    day: false,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "medium",
    select: false,
    portrait: false,
    location: "transit",
  },
  {
    id: 78.0,
    day: true,
    crowded: "high",
    traffic: "high",
    city: true,
    distance: "medium",
    select: false,
    portrait: true,
    location: "transit",
  },
  {
    id: 79.0,
    day: false,
    crowded: "low",
    traffic: "low",
    city: true,
    distance: "medium",
    select: false,
    portrait: false,
    location: "transit",
  },
  {
    id: 80.0,
    day: false,
    crowded: "high",
    traffic: "low",
    city: true,
    distance: "long",
    select: false,
    portrait: false,
    location: "transit",
  },
  // {
  //   id: 81.0,
  //   day: false,
  //   crowded: "low",
  //   traffic: "high",
  //   city: true,
  //   distance: "short",
  //   select: false,
  //   portrait: false,
  //   location: "transit",
  // },
];

export default ooh_config;
