<template>
  <div>
    <p><i>Select all boxes that contain text:</i></p>
    <div
      style="position: relative; display: inline-block; border: 1px solid red"
    >
      <img :src="image" alt="" />
      <div class="layer">
        <div
          v-for="index in 25"
          :key="index"
          class="box"
          :class="{ selected: selected.includes(index) }"
      
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["uid", "image", "selected"],
  data() {
    return {};
  },
  methods: {
    // selectItem(index) {
    //   if (this.selected.includes(index)) {
    //     this.selected.splice(this.selected.indexOf(index), 1);
    //   } else {
    //     this.selected.push(index);
    //   }
    //   this.$store.dispatch("updateTextInAd", {
    //     uid: this.uid,
    //     value: this.selected,
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.layer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.box {
  opacity: 0.5;
  height: 100%;
  width: 100%;
  outline: 1px solid #ff5555;

  &.selected {
    background-color: blue;
  }
}

img {
  display: block;
}
</style>
