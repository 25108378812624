<template>
  <div
    class="card"
    @click="sendBack"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <img class="folder" :src="require('@/assets/folder.svg')" alt="" />
    <div class="images"></div>

    <div class="bottom">
      <div class="actions">
        <div class="details">
          <div class="title">
            <span>{{ project.title }}</span>
          </div>
          <div class="right-details">
            <p>
              Heatmaps: <b>{{ project.heatmaps.length }}</b>
            </p>
          </div>
        </div>
        <v-menu bottom left v-model="menu3">
          <template v-slot:activator="{ props }">
            <v-btn
              variant="plain"
              size="small"
              icon="mdi-dots-vertical"
              v-bind="props"
            >
            </v-btn>
          </template>

          <v-list>
            <v-menu bottom open-on-hover offset-x>
              <template v-slot:activator="{ props }">
                <v-list-item
                  prepend-icon="mdi-fire"
                  class="px-4"
                  v-bind="props"
                >
                  Download heatmaps
                </v-list-item>
              </template>

              <v-list>
                <v-list-item
                  class="px-6 py-2"
                  @click.stop="downloadHeatmaps()"
                  prepend-icon="mdi-download"
                >
                  Heatmap
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item
                  class="px-6 py-2"
                  @click.stop="downloadPeekthroughs()"
                  prepend-icon="mdi-download"
                >
                  Peekthrough
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item
                  prepend-icon="mdi-download"
                  class="px-6 py-2"
                  @click.stop="downloadOriginals()"
                >
                  Original
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item
                  prepend-icon="mdi-download"
                  class="px-6 py-2"
                  @click="downloadAll"
                >
                  Download all
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-x
            >
              <template v-slot:activator="{ props }">
                <v-list-item
                  class="px-4"
                  v-bind="props"
                  prepend-icon="mdi-pencil-outline"
                >
                  Rename
                </v-list-item>
              </template>

              <v-card>
                <div class="pa-4">
                  <v-text-field
                    hide-details=""
                    v-model="newTitle"
                  ></v-text-field>
                </div>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn text @click="menu2 = false"> Cancel </v-btn>
                  <v-btn
                    color="primary"
                    text
                    :disabled="newTitle === ''"
                    @click="updateTitle(project)"
                  >
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>

            <v-list-item
              prepend-icon="mdi-delete-outline"
              class="px-4"
              @click="$store.dispatch('deleteProject', project.uid)"
            >
              Delete project
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import firebase from "firebase/app";
const axios = require("axios");
import { db } from "@/firebase";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export default {
  props: ["projectProp"],
  data() {
    return {
      hover: false,
      menu: false,
      menu2: false,
      menu3: false,
      newTitle: "",
      project: this.projectProp,
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      heatmapStore: (state) => state.heatmaps.heatmaps,
    }),
  },
  methods: {
    async downloadHeatmaps() {
      let arr = [];
      for (let x in this.project.heatmaps) {
        let uid = this.project.heatmaps[x];
        const doc = await db.collection("heatmaps").doc(uid).get();
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          // this.downloadHeatmap(3, doc.data());
          const res = await this.downloadData(1, doc.data());
          arr.push(res);
        }
      }
      this.saveZip(arr);
    },
    async downloadPeekthroughs() {
      let arr = [];
      for (let x in this.project.heatmaps) {
        let uid = this.project.heatmaps[x];
        const doc = await db.collection("heatmaps").doc(uid).get();
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          // this.downloadHeatmap(3, doc.data());
          const res = await this.downloadData(2, doc.data());
          arr.push(res);
        }
      }
      this.saveZip(arr);
    },
    async downloadOriginals() {
      let arr = [];
      for (let x in this.project.heatmaps) {
        let uid = this.project.heatmaps[x];
        const doc = await db.collection("heatmaps").doc(uid).get();
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          // this.downloadHeatmap(3, doc.data());
          const res = await this.downloadData(3, doc.data());
          arr.push(res);
        }
      }
      this.saveZip(arr);
    },
    async downloadAll() {
      console.log("downloading all heatmaps inside project");
      let arr = [];

      for (let x in this.project.heatmaps) {
        let uid = this.project.heatmaps[x];

        const doc = await db.collection("heatmaps").doc(uid).get();
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          const res = await this.downloadData(1, doc.data());
          const res2 = await this.downloadData(2, doc.data());
          const res3 = await this.downloadData(3, doc.data());
          const res4 = await this.downloadData(4, doc.data());

          arr.push(res);
          arr.push(res2);
          arr.push(res3);
          arr.push(res4);
        }
      }

      this.saveZip(arr);
    },
    async downloadData(v, h) {
      var storage = firebase.storage();
      var storageRef = firebase.storage().ref();
      const storagePath = storageRef.child(`${h.requested_by}/${h.uid}`);
      var imageRef = storage.refFromURL(`${storagePath}`);

      if (h.type === "image") {
        let path = `${h.uid}.${h.extension}`;
        let label = "";
        if (this.variant === "Heatmap") {
          label = `${h.title} - Heatmap`;
          path = `${h.uid}-combined.png`;
        } else if (this.variant === "Peekthrough") {
          label = `${h.title} - Peekthrough`;
          path = `${h.uid}-peekthrough.png`;
        } else if (this.variant === "Gazeplot") {
          label = `${h.title} - Gazeplot`;
          path = `${h.uid}-gazeplot.png`;
        } else {
          label = `${h.title}`;
        }

        if (v === 1) {
          label = `${h.title} - Heatmap`;
          path = `${h.uid}-combined.png`;
        } else if (v === 2) {
          label = `${h.title} - Peekthrough`;
          path = `${h.uid}-peekthrough.png`;
        } else if (v === 3) {
          label = `${h.title}`;
          path = `${h.uid}.${h.extension}`;
        } else if (v === 4) {
          label = `${h.title} - Gazeplot`;
          path = `${h.uid}-gazeplot.png`;
        }

        const url = await imageRef.child(path).getDownloadURL();
        return {
          label: label,
          url: url,
          project_name: this.project.title,
          extension: v === 3 ? h.extension : "png",
        };
      } else {
        let path = `${h.uid}.mp4`;
        let label = "";
        if (this.variant === "Heatmap") {
          label = `${h.title} - Heatmap`;
          path = `${h.uid}-combined.mp4`;
        } else if (this.variant === "Peekthrough") {
          label = `${h.title} - Peekthrough`;
          path = `${h.uid}-peekthrough.mp4`;
        } else {
          label = `${h.title}`;
        }

        if (v === 1) {
          label = `${h.title} - Heatmap`;
          path = `${h.uid}-combined.mp4`;
        } else if (v === 2) {
          label = `${h.title} - Peekthrough`;
          path = `${h.uid}-peekthrough.mp4`;
        } else if (v === 3) {
          label = `${h.title}`;
          path = `${h.uid}.mp4`;
        }
        const url = await imageRef.child(path).getDownloadURL();
        return {
          label: label,
          url: url,
          project_name: this.project.title,
          extension: v === 3 ? h.extension : "mp4",
        };
      }
    },
    async download(url, label) {
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "image/png" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label + ".png";
      link.click();
      URL.revokeObjectURL(link.href);
    },
    async saveZip(files) {
      if (!files) return;

      const zip = new JSZip();
      const folder = zip.folder(files[0].project_name);

      for (let x in files) {
        let f = files[x];
        const blobPromise = fetch(f.url).then((r) => {
          if (r.status === 200) return r.blob();
          return Promise.reject(new Error(r.statusText));
        });

        const name = f.label.replace(/\//g, "-").concat(`.${f.extension}`);
        folder.file(name, blobPromise);
      }

      zip
        .generateAsync({ type: "blob" })
        .then((blob) => saveAs(blob, files[0].project_name));
    },
    sendBack() {
      this.$emit("open", this.project.uid);
    },
    updateTitle(v) {
      console.log(v);
      db.collection("projects")
        .doc(this.project.uid)
        .update({
          title: this.newTitle,
        })
        .then(() => {
          this.menu3 = false;
          this.menu2 = false;
          this.project.title = this.newTitle;
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./styles.scss";
</style>
