<template>
  <div
    class="pricing mt-15"
    v-bind:style="{
      width: mobile ? '900px' : '100%',
      justifyContent: marketingPage ? 'center' : 'start',
    }"
  >
    <div class="card py-10" v-for="(sub, index) in subscriptions" :key="index">
      <div
        v-if="sub.recommended && !mobile && !subscription.active"
        class="popular"
      >
        MOST CHOSEN
      </div>
      <div
        v-if="sub.tier === subscription.tier && subscription.active && !mobile"
        class="popular"
      >
        CURRENT
      </div>
      <h2>{{ sub.title }}</h2>
      <p class="subtitle">
        <span v-if="!mobile">'{{ sub.subtitle }}'</span
        ><span
          v-else-if="mobile && sub.recommended"
          class="font-weight-bold success--text"
          >Recommended</span
        >
      </p>

      <p v-if="sub.price > 0" class="text-center font-weight-bold">
        <span
          >€ {{ annual ? Math.round(sub.annual / 12) : sub.price }} per
          month</span
        >
      </p>
      <p
        style="font-size: 11pt"
        class="primary--text font-weight-medium"
        v-if="annual && sub.annual"
      >
        Yearly: €{{ sub.annual }}
      </p>
      <h4 v-else-if="sub.price === 0">Free</h4>

      <div class="line"></div>
      <div class="items mt-5">
        <div class="item my-3">
          <div class="left">
            <b>{{ sub.seats }}</b>
          </div>
          <div>User seat<span v-if="sub.seats > 1">s</span></div>
        </div>
        <div class="item my-3">
          <div class="left">
            <b>{{ sub.images }}</b>
          </div>
          <div>Image heatmaps</div>
        </div>
        <div class="item my-3">
          <div class="left" v-if="sub.videos > 0">
            <b>{{ sub.videos }}</b>
          </div>
          <div class="left" v-else-if="sub.videos === '∞'">∞</div>
          <div class="left" v-else>-</div>
          <div>Video heatmaps</div>
        </div>
        <div class="item my-3">
          <div class="left" v-if="sub.clarity">
            <v-icon color="success">mdi-check</v-icon>
          </div>
          <div class="left" v-else>-</div>
          <div>Clarity score</div>
        </div>
        <div class="item my-3">
          <div class="left">
            <v-icon color="success">mdi-check</v-icon>
          </div>
          <div>{{ sub.support }}</div>
        </div>

        <div class="item my-3">
          <div class="left" v-if="sub.projects">
            <v-icon color="success">mdi-check</v-icon>
          </div>
          <div class="left" v-else>-</div>
          <div>Projects</div>
        </div>

        <div class="item my-3">
          <div class="left" v-if="sub.collaboration">
            <v-icon color="success">mdi-check</v-icon>
          </div>
          <div class="left" v-else>-</div>
          <div>Team collaboration</div>
        </div>

        <div class="item my-3">
          <div class="left" v-if="sub.templates">
            <v-icon color="success">mdi-check</v-icon>
          </div>
          <div class="left" v-else>-</div>
          <div>Template reports</div>
        </div>
      </div>
      <div class="button">
        <v-btn
          v-if="sub.tier === 'custom'"
          color="primary"
          elevation="0"
          width="160"
          outlined
          rounded
          class="mt-5 mx-2"
          @click="calendly"
          >Schedule call</v-btn
        >
        <v-btn
          v-else-if="!userProfile.uid"
          color="primary"
          elevation="0"
          width="160"
          rounded
          class="mt-5 mx-2"
          @click="startTrial"
          >Start trial</v-btn
        >
        <v-btn
          v-else
          color="primary"
          elevation="0"
          width="160"
          :loading="loading === index"
          rounded
          class="mt-5 mx-2"
          :disabled="optionDisabled(sub.tier)"
          @click="checkout(sub, index)"
          >{{ sub.tier === "freemium" ? "Choose" : "Purchase" }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PricingPage",
  props: ["mobile", "marketingPage", "annual"],
  data() {
    return {
      loading: null,
      subscriptions: [
        {
          tier: "freemium",
          title: "Free",
          subtitle: "Free forever",
          price: 0,
          seats: 1,
          images: 5,
          videos: 0,
          clarity: true,
          support: "Email support",
          collaboration: false,
          recommended: false,
          active: false,
          projects: true,
          templates: false,
        },
        {
          tier: "basic",
          title: "Basic",
          subtitle: "Ready to go",
          price: 199,
          annual: "2148",
          seats: 5,
          images: 500,
          videos: 0,
          clarity: true,
          support: "Email support",
          collaboration: true,
          recommended: false,
          active: false,
          projects: true,
          templates: false,
        },
        {
          tier: "business",
          title: "Business",
          subtitle: "Go all out",
          price: 399,
          annual: "4308",
          seats: 10,
          images: 1500,
          videos: 20,
          clarity: true,
          support: "Consultant support",
          collaboration: true,
          recommended: true,
          active: false,
          projects: true,
          templates: true,
        },
        {
          tier: "custom",
          title: "Custom",
          subtitle: "Request offer",
          price: "",
          annual: "",
          seats: "∞",
          images: "∞",
          videos: "∞",
          clarity: true,
          support: "Consultant support",
          collaboration: true,
          recommended: false,
          active: false,
          projects: true,
          templates: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      subscription: (state) => state.subscription.subscription,
    }),
    interval() {
      return this.annual ? "year" : "month";
    },
  },

  methods: {
    calendly() {
      window.open("https://calendly.com/brainsight-app/30min", " _blank");
    },
    checkout(i, index) {
      this.loading = index;
      this.$emit("checkout", i.tier);
    },
    optionDisabled(tier) {
      let disabled = false;

      if (
        (this.userProfile.role === "member" ||
          this.userProfile.role === "admin") &&
        this.subscription.active
      ) {
        disabled = true;
      }

      if (this.userProfile.role === "freemium" && tier === "freemium") {
        disabled = true;
      }
      return disabled;
    },
    resetLoading() {
      this.loading = null;
    },
    startTrial() {
      this.$router.push("/signup");
    },
  },
};
</script>

<style lang="scss" scoped>
.sub {
  font-size: 14pt;
  max-width: 850px;
}
.pricing {
  display: flex;
  gap: 30px;
}
.v-btn {
  text-transform: none;
  font-weight: 500;
  font-size: 12pt;
}
.usage {
  max-width: 1200px;
  display: grid;
  grid-template-columns: 50% 50%;
}
.card {
  width: 250px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #282447;
  border: 2px solid #282447;
  border-radius: 6px;
  position: relative;

  .popular {
    background-color: #3e1191;
    height: 40px;
    border: 2px solid #282447;
    border-radius: 6px;
    width: 250px;
    position: absolute;
    top: -36px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10pt;
    font-weight: 600;
    letter-spacing: 1.3px;
  }

  .subtitle {
    font-size: 15pt;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  p {
    margin: 0;
  }

  .item {
    display: grid;
    grid-template-columns: 45px auto;
    text-align: start;

    .left {
      text-align: end;
      margin-right: 8px;
    }
  }
}

.request {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
}
.line {
  width: 75px;
  border-bottom: 2px solid #6515dd;
  margin: 10px 0;
}
</style>
