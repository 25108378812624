<template>
  <div>
    <v-dialog persistent opacity="0.75" max-width="1000" v-model="dialog">
      <template v-slot:activator="{ props: activatorProps }">
        <v-btn
          v-bind="activatorProps"
          class="ml-4 mr-2"
          flat
          height="40"
          color="dark"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <template v-slot:default="{ isActive }">
        <v-card>
          <v-sheet
            class="d-flex justify-space-between align-center bg-primary pa-4"
          >
            <h3>Add template</h3>
            <v-btn
              variant="text"
              @click="isActive.value = false"
              icon="mdi-close"
            ></v-btn>
          </v-sheet>
          <v-stepper
            editable
            v-model="step"
            flat
            :items="['Upload', 'Set coordinates', 'Set categories', 'Review']"
          >
            <template v-slot:actions="{ prev, next }">
              <v-stepper-actions
                v-if="step < 4"
                @click:next="next"
                @click:prev="prev"
              ></v-stepper-actions>
              <div v-else class="px-6 pb-4 d-flex justify-end">
                <v-btn variant="flat" color="primary" @click="addTemplate"
                  >Save</v-btn
                >
              </div>
            </template>
            <template v-slot:item.1>
              <v-card
                title="Upload"
                flat
                class="d-flex flex-column align-center"
              >
                <input
                  ref="uploadRef"
                  type="file"
                  @change="onFileChange"
                  @click="resetValue"
                  accept="image/jpeg, image/png, image/jpg"
                />
                <v-btn
                  @click="trigger"
                  class="ml-4 mr-2"
                  flat
                  height="40"
                  color="dark"
                >
                  <v-icon>mdi-upload</v-icon>
                </v-btn>
              </v-card>
            </template>

            <template v-slot:item.2>
              <v-card
                class="d-flex flex-column align-center"
                title="Set coordinates"
                flat
              >
                <div class="d-flex">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="secondary"
                    class="mb-2"
                    @click="coordinates = []"
                    >Clear</v-btn
                  >
                </div>

                <div style="position: relative">
                  <img
                    style="
                      position: relative;
                      max-height: 50vh;

                      object-fit: contain;
                    "
                    @click="getCoordinates"
                    id="myImg"
                    v-if="file"
                    ref="template"
                    :src="file.src"
                  />

                  <div v-for="(c, i) in coordinates" :key="c.x">
                    <v-btn
                      style="
                        position: absolute;
                        margin-left: -10px;
                        margin-top: -10px;
                        opacity: 0.5;
                      "
                      :style="{ top: `${c.y}px`, left: `${c.x}px` }"
                      icon="mdi-close"
                      size="small"
                      density="compact"
                      @click.stop="() => coordinates.splice(i, 1)"
                    ></v-btn>
                  </div>
                </div>
              </v-card>
            </template>

            <template v-slot:item.3>
              <v-card
                flat
                class="pa-4 d-flex justify-center align-center flex-column"
              >
                <div
                  v-for="(c, i) in benchmark.categories_templates"
                  :key="i"
                  style="width: 300px"
                >
                  <v-autocomplete
                    v-model="c.value"
                    :items="c.values"
                    variant="solo"
                    flat
                    hide-details
                    bg-color="smoke"
                    class="mb-2"
                    :label="c.name"
                  ></v-autocomplete>
                </div>
              </v-card>
            </template>

            <template v-slot:item.4>
              <v-card
                title="Review"
                flat
                class="d-flex flex-column align-center"
              >
                <div
                  style="
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 30px;
                  "
                >
                  <v-img
                    style="position: relative"
                    width="100%"
                    v-if="file"
                    id="reviewImg"
                    :src="file.src"
                  >
                    <div v-for="(c, i) in coordinates" :key="i">
                      <v-btn
                        style="
                          position: absolute;
                          margin-left: -10px;
                          margin-top: -10px;
                          opacity: 0.5;
                        "
                        :style="{ top: `${c.y}px`, left: `${c.x}px` }"
                        size="small"
                        density="compact"
                      ></v-btn>
                    </div>
                  </v-img>
                  <div>
                    <h3>Details</h3>
                    <p><u>categories</u></p>
                    <p>{{ final_categories }}</p>
                    <p><u>Coordinates</u></p>
                    <p>{{ final_coordinates }}</p>
                  </div>
                </div>
              </v-card>
            </template>
          </v-stepper>
          <!-- <v-btn
            color="primary"
            text="Close"
            class="mx-auto my-3"
            variant="text"
            @click="isActive.value = false"
          ></v-btn> -->
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import firebase from "firebase/";
import { db } from "@/firebase";

export default {
  mounted() {
    console.log(this.coordinates);
    console.log(this.categories);
  },
  data() {
    return {
      coordinates: [],
      categories: [],
      category: {
        key: null,
        value: null,
      },
      dialog: false,
      files: null,
      originalWidth: null,
      originalHeight: null,
      scaledHeight: null,
      step: 1,
    };
  },
  computed: {
    ...mapState({
      benchmark: (state) => state.benchmarks.benchmark,
    }),

    file() {
      let file = this.files && this.files.length > 0 ? this.files[0] : null;
      if (file) {
        file.src = URL.createObjectURL(file);
      }
      return file;
    },
    final_categories() {
      let categories = {};

      this.benchmark.categories_templates.map((varItem) => {
        categories[varItem.name] = varItem.value;
      });

      console.log(categories);
      return categories;
    },
    final_coordinates() {
      let coordinates = this.coordinates;
      let final_coordinates;
      if (coordinates.length > 3) {
        coordinates.sort((a, b) => (a.x < b.x ? -1 : b.x < a.x ? 1 : 0));
        let left = coordinates.slice(0, 2);
        let right = coordinates.slice(2, 4);
        left.sort((a, b) => (a.y < b.y ? -1 : b.y < a.y ? 1 : 0));
        right.sort((a, b) => (a.y < b.y ? -1 : b.y < a.y ? 1 : 0));
        let scale = this.scaledHeight / this.originalHeight;
        console.log(coordinates);
        final_coordinates = {
          left_top: {
            x: left[0].x / scale,
            y: left[0].y / scale,
          },
          left_bottom: {
            x: left[1].x / scale,
            y: left[1].y / scale,
          },
          right_top: {
            x: right[0].x / scale,
            y: right[0].y / scale,
          },
          right_bottom: {
            x: right[1].x / scale,
            y: right[1].y / scale,
          },
        };
      }

      return final_coordinates;
    },
  },
  methods: {
    async addTemplate() {
      console.log("Adding template");

      // SAVE TEMPLATE

      await this.upload({
        file: this.file,
        coordinates: this.final_coordinates,
        categories: this.final_categories,
      });

      // EMPTY everything
      //

      this.coordinates = [];
      this.categories = [];
      this.category = {
        key: null,
        value: null,
      };
      this.step = 1;
      this.scaledHeight = null;
      this.scaledHeight = null;
      this.files = null;
      this.dialog = false;
      await this.$store.dispatch("fetchBenchmark", this.benchmark.uid);
      this.$store.commit("setLoading", false);

      this.$store.commit("setSnackbar", {
        text: "Template added!",
        timeout: 2000,
        show: true,
      });
    },
    findPosition(oElement) {
      if (typeof oElement.offsetParent != "undefined") {
        for (
          var posX = 0, posY = 0;
          oElement;
          oElement = oElement.offsetParent
        ) {
          posX += oElement.offsetLeft;
          posY += oElement.offsetTop;
        }
        return [posX, posY];
      } else {
        return [oElement.x, oElement.y];
      }
    },
    getCoordinates(e) {
      if (this.coordinates.length === 4) {
        alert("Already have 4. Delete one first.");
        return;
      }
      var PosX = 0;
      var PosY = 0;
      var ImgPos;
      ImgPos = this.findPosition(myImg);
      if (!e) var e = window.event;
      if (e.pageX || e.pageY) {
        PosX = e.pageX;
        PosY = e.pageY;
      } else if (e.clientX || e.clientY) {
        PosX =
          e.clientX +
          document.body.scrollLeft +
          document.documentElement.scrollLeft;
        PosY =
          e.clientY +
          document.body.scrollTop +
          document.documentElement.scrollTop;
      }
      PosX = PosX - ImgPos[0];
      PosY = PosY - ImgPos[1];
      this.coordinates.push({
        x: PosX,
        y: PosY,
      });
    },
    async onFileChange() {
      this.step = 2;
      if (this.$refs.uploadRef.files) {
        this.files = this.$refs.uploadRef.files;
        const imgOriginal = await createImageBitmap(this.files[0]);
        this.originalWidth = imgOriginal.width;
        this.originalHeight = imgOriginal.height;
        this.scaledHeight = document.getElementById("myImg").offsetHeight;
        console.log(this.scaledHeight);
        console.log(this.originalHeight);
        console.log(this.scaledHeight / this.originalHeight);
      }
    },
    trigger() {
      this.$refs.uploadRef.click();
    },
    async upload(upload) {
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            console.log(upload);
            var fileExtension = upload.file.name.split(".").pop();
            var storageRef = firebase.storage().ref();

            const docRef = await db
              .collection("benchmarks")
              .doc(this.benchmark.uid)
              .collection("templates")
              .add({
                extension: fileExtension,
                categories: upload.categories,
                coordinates: upload.coordinates,
                status: "ready",
                thumbnail: null,
                created: Math.round(Date.now() / 1000),
                originalWidth: this.originalWidth,
                originalHeight: this.originalHeight,
              });
            await db
              .collection("benchmarks")
              .doc(this.benchmark.uid)
              .collection("templates")
              .doc(docRef.id)
              .update({
                uid: docRef.id,
              });

            let path = storageRef.child(
              `benchmarks/${this.benchmark.uid}/templates/${docRef.id}.${fileExtension}`
            );

            var uploadTask = path.put(upload.file);
            this.$store.commit("setLoading", true);
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                var progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(progress);

                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    // console.log("Upload is paused");
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    // console.log("Upload is running");
                    break;
                }
              },
              (error) => {
                switch (error.code) {
                  case "storage/unauthorized":
                    // User doesn't have permission to access the object
                    break;
                  case "storage/canceled":
                    // User canceled the upload
                    break;

                  // ...

                  case "storage/unknown":
                    // Unknown error occurred, inspect error.serverResponse
                    break;
                }
              },
              async () => {
                uploadTask.snapshot.ref
                  .getDownloadURL()
                  .then(async (downloadURL) => {
                    await db
                      .collection("benchmarks")
                      .doc(this.benchmark.uid)
                      .collection("templates")
                      .doc(docRef.id)
                      .update({
                        src: downloadURL,
                        thumbnail: downloadURL,
                      });

                    return resolve();
                  });
              }
            );
          } catch (err) {
            return reject(err);
          }
        })();
      });
    },
  },
};
</script>
<style scoped lang="scss">
input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  border: 2px solid red;
}
</style>
