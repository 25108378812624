<template>
  <v-snackbar
    @input="change"
    v-model="snackbar.show"
    :timeout="snackbar.timeout"
  >
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      snackbar: (state) => state.snackbar,
    }),
  },
  methods: {
    change(v) {
      console.log(v);
    },
  },
};
</script>
