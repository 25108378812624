<template>
  <div class="users-wrapper pa-10">
    <div style="width: 600px">
      <h1 class="mb-5">Account</h1>
      <v-card flat class="pa-8">
        <v-card-title class="px-0">My Account</v-card-title>
        <v-row>
          <v-col cols="12" sm="9">
            <div class="name">
              <v-text-field
                label="First name"
                color="primary"
                v-model="userProfile.firstName"
              ></v-text-field>
              <v-text-field
                label="Last name"
                color="primary"
                v-model="userProfile.lastName"
              ></v-text-field>
            </div>

            <v-text-field
              label="Email (readonly)"
              readonly
              v-model="userProfile.email"
              color="primary"
            ></v-text-field>
            <v-text-field
              label="Phone"
              v-model="userProfile.phone"
              color="primary"
            ></v-text-field>
            <v-text-field
              label="Role (readonly)"
              readonly
              v-model="userProfile.role"
              color="primary"
            ></v-text-field>
            <v-text-field
              v-if="
                userProfile.role != 'trial' && userProfile.role != 'freemium'
              "
              label="Workspace (readonly)"
              readonly
              v-model="workspace.name"
              color="primary"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <div class="d-flex justify-center mb-3">
              <v-avatar
                :image="userProfile.avatar ? userProfile.avatar : null"
                :icon="userProfile.avatar ? null : 'mdi-account'"
                class="mx-auto"
                width="75"
                height="75"
                color="primary"
              >
              </v-avatar>
            </div>

            <v-btn
              @click="trigger"
              class="mx-auto"
              variant="text"
              density="compact"
              style="font-size: 10pt"
              >Upload new</v-btn
            >
            <input
              ref="headerRef"
              type="file"
              @change="onFileChange"
              accept="image/*"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-space-between">
          <v-btn
            variant="flat"
            filled
            color="success"
            density="comfortable"
            @click="saveUser"
            >Save</v-btn
          >
          <v-btn
            density="comfortable"
            style="font-size: 10pt"
            variant="text"
            @click="deleteAccount"
            >Delete my account</v-btn
          >
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { db, auth } from "@/firebase";
import firebase from "firebase/";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      workspace: (state) => state.workspace.workspace,
      subscription: (state) => state.subscription.subscription,
    }),
  },
  methods: {
    deleteAccount() {
      const vm = this;
      if (this.userProfile.role != "admin") {
        let r = confirm("Are you sure? This will delete your account.");
        console.log(r);
        const user = auth.currentUser;
        if (r) {
          db.collection("users")
            .doc(user.uid)
            .update({
              deleted_at: new Date(),
            })
            .then(() => {
              user
                .delete()
                .then(() => {
                  console.log("user deleted");
                  this.$store.dispatch("logout", user.uid);
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        }
      } else if (
        this.userProfile.role === "admin" &&
        this.subscription.canceled != true
      ) {
        this.$emit("error", {
          text: "Deleting your account while you are the admin is not possible when you have an active subscription. Please cancel your subscription first.",
          dialog: true,
        });
      } else if (
        this.userProfile.role === "admin" &&
        this.subscription.canceled
      ) {
        let r = confirm("Are you sure? This will delete your account.");
        console.log(r);
        if (r) {
          db.collection("workspaces")
            .doc(vm.userProfile.workspace)
            .update({
              admin: "",
              canceled: true,
              cancel_at: vm.subscription.cancel_at,
              canceled_by: vm.userProfile.uid,
              members: firebase.firestore.FieldValue.arrayRemove(
                vm.userProfile.uid
              ),
              seats: firebase.firestore.FieldValue.increment(1),
            })
            .then(() => {
              console.log("Rest of subscription moved to workspace level");
              const user = auth.currentUser;
              db.collection("users")
                .doc(user.uid)
                .update({
                  deleted_at: new Date(),
                })
                .then(() => {
                  user
                    .delete()
                    .then(() => {
                      console.log("user deleted");
                      this.$store.dispatch("logout", user.uid);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                })
                .catch((error) => {
                  // The document probably doesn't exist.
                  console.error("Error updating document: ", error);
                });
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        }
      }
    },
    async onFileChange() {
      var files = this.$refs.headerRef.files;
      const res = await this.toBase64(files[0]);
      var userRef = db.collection("users").doc(auth.currentUser.uid);
      const vm = this;
      this.userProfile.avatar = res;

      userRef
        .update({
          avatar: res,
        })
        .then(() => {
          console.log("Document successfully updated!");
          vm.$emit("snackbar", { text: "Data saved!", type: "success" });
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
    saveUser() {
      const vm = this;
      var userRef = db.collection("users").doc(auth.currentUser.uid);
      userRef
        .set(
          {
            firstName: vm.userProfile.firstName,
            lastName: vm.userProfile.lastName,
            phone: vm.userProfile.phone,
            name: vm.userProfile.firstName.concat(" ", vm.userProfile.lastName),
          },
          { merge: true }
        )
        .then(() => {
          vm.$emit("snackbar", { text: "Data saved!", type: "success" });
        });
    },
    trigger() {
      this.$refs.headerRef.click();
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.users-wrapper {
  display: flex;
}

h1,
p,
td,
th {
  color: #282447;
}

.name {
  display: flex;
  gap: 10px;
  margin: 0;
}

input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  border: 2px solid red;
}
</style>
