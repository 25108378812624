export const uploads = {
  computed: {
    uploads: {
      get() {
        return this.$store.state.uploads.uploads;
      },
      set(value) {
        this.$store.commit("setUploads", value);
      },
    },
    uploads_dialog: {
      get() {
        return this.$store.state.uploads.dialog;
      },
      set(value) {
        this.$store.commit("setUploadsDialog", value);
      },
    },
    uploads_loading: {
      get() {
        return this.$store.state.uploads.loading;
      },
      set(value) {
        this.$store.commit("setUploadsLoading", value);
      },
    },
  },
};
