import "vuetify/styles";
import "@/styles/main.scss";
import "vuetify/dist/vuetify.min.css";

import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    iconfont: "mdi",
  },
  theme: {
    defaultTheme: "light",
    themes: {
      light: {
        colors: {
          primary: "#6515dd",
          secondary: "#3e1191",
          accent: "#8b78fa",
          error: "#ff5555",
          info: "#fbb81b",
          success: "#00d38c",
          warning: "#282447",
          dark: "#2a2d3b",
          smoke: "#ebebef",
        },
      },
    },
  },
});

export default vuetify;
