<template>
  <div class="px-4">
    <div class="d-flex mt-6 justify-start">
      <v-btn
        variant="outlined"
        color="warning"
        elevation="0"
        prepend-icon="mdi-arrow-left"
        @click="$router.push('/projects')"
        >Back</v-btn
      >
      <h2>{{ project }}</h2>
    </div>
    <div class="right">
      <Delete :dialog="dialogDelete" :id="deleteId" @dialog="setDialogDelete" />
      <AddToProject
        v-if="addId"
        :addId="addId"
        :dialog="dialogProject"
        @dialog="setDialogProject"
      />
      <div class="heatmaps">
        <NoHeatmapCard v-if="heatmaps.length === 0 && !$store.state.loading" />

        <div class="heatmap" v-for="heatmap in heatmaps" :key="heatmap.uid">
          <Card
            :heatmapProp="heatmap"
            @analyze="openHeatmap"
            @share="shareHeatmap"
            @addToProject="addToProject"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/heatmap-card/HeatmapCard.vue";
import Delete from "@/components/delete/DeleteCard.vue";
import AddToProject from "@/components/addToProject/AddToProject.vue";
import NoHeatmapCard from "@/components/no-heatmap/NoHeatmap.vue";
import { mapState } from "vuex";

export default {
  name: "HomeProject",
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      heatmaps: (state) => state.projects.heatmaps,
    }),
  },
  components: {
    Card,
    Delete,
    AddToProject,
    NoHeatmapCard,
  },
  props: {
    project: {
      type: Object,
    },
  },
  mounted() {
    if (this.$route.params.id != this.$store.state.projects.last) {
      this.$store.dispatch("fetchProjectHeatmaps", this.$route.params.id);
    }
  },
  data() {
    return {
      addId: null,
      deleteId: "",
      dialog: false,
      dialogDelete: false,
      dialogProject: false,
      shareId: null,
    };
  },
  methods: {
    addToProject(id) {
      this.addId = id;
      this.dialogProject = true;
    },
    deleteHeatmap(id) {
      this.deleteId = id;
      this.dialogDelete = true;
    },
    openHeatmap(id) {
      this.$router.push(`/heatmap/${id}`);
    },
    setDialog(val) {
      this.dialog = val;
    },
    setDialogDelete(val) {
      this.dialogDelete = val;
    },
    setDialogProject(v) {
      this.dialogProject = v;
    },
    shareHeatmap(val) {
      this.shareId = val;
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.right {
  margin-bottom: 100px;
  .heatmaps {
    display: flex;
    gap: 40px;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 12px;
    justify-content: flex-start;
  }

  .uploads {
    flex-wrap: wrap;
  }
}
</style>
