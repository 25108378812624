<template>
  <div id="sticky" class="sticky">
    <div class="pa-4">
      <div class="d-flex" style="gap: 10px">
        <NewHeatmap
          :urlLoading="urlLoading"
          @trigger="trigger"
          :noCredits="noCredits"
        />

        <v-menu
          v-model="menuProject"
          :close-on-content-click="false"
          offset-x
          max-width="400"
          attach="#sticky"
          nudge-width="400"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              elevation="0"
              color="warning"
              outlined
              height="40"
              >New project</v-btn
            >
          </template>

          <v-card>
            <div class="pa-8">
              <v-text-field
                filled
                flat
                label="Project title"
                color="warning"
                hide-details=""
                v-model="projectTitle"
              ></v-text-field>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text @click="menuProject = false"> Cancel </v-btn>
              <v-btn
                color="success"
                :disabled="projectTitle === ''"
                @click="saveProject"
                :loading="savingProject"
                elevation="0"
              >
                Create project
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>

        <input
          v-if="!userProfile.trialEnded"
          ref="headerRef"
          type="file"
          multiple
          @change="onFileChange"
          @click="resetValue"
          accept="image/jpeg, image/png, image/jpg, video/mp4"
        />
        <v-text-field
          v-if="$route.name === 'Projects'"
          filled
          density="compact"
          label="Search project"
          bg-color="white"
          type="text"
          hide-details="true"
          v-model="search"
          flat
          variant="solo"
          clearable
          prepend-inner-icon="mdi-magnify"
          class="mb-2"
          style="max-width: 250px"
        ></v-text-field>
        <div v-if="$route.name === 'Heatmaps'">
          <FilterMenu />
        </div>
      </div>
    </div>
    <v-divider class="white"></v-divider>

    <NewProject :dialogProp="dialog" @dialog="setDialog" />
  </div>
</template>

<script>
import { mapState } from "vuex";
// import firebase from "firebase/app";
import { db, auth } from "@/firebase";
import NewProject from "@/components/newProject/NewProject.vue";
import NewHeatmap from "@/components/new-heatmap/NewHeatmap.vue";
import FilterMenu from "@/components/filterMenu/FilterMenu.vue";
import firebase from "firebase/app";

export default {
  name: "InfoComponent",
  props: [
    "urlLoading",
    "searchProp",
    "project",
    "tags",
    "users",
    "type",
    "toggle",
  ],
  components: {
    NewProject,
    NewHeatmap,
    FilterMenu,
  },
  data() {
    return {
      menu: false,
      menuProject: false,
      imageCount: 0,
      videoCount: 0,
      notAllowed: 0,
      dialog: false,
      projectTitle: "",
      newProject: "",
      savingProject: false,
      terms_last: null,
      limit: 5,
      showAdvanced: false,
      tagItems: ["ux", "some other tag", "advertisement", "billboard"],
      uploads: [],
      userItems: ["max", "roger", "jamie"],
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      defaults: (state) => state.defaults.defaults,
      loading: (state) => state.loading,
      workspace: (state) => state.workspace.workspace,
      heatmaps: (state) => state.heatmaps.heatmaps,
      projects: (state) => state.projects.projects,
      tagStore: (state) => state.heatmaps.tags,
      subscription: (state) => state.subscription.subscription,
      userStore: (state) => state.users.users,
      query: (state) => state.heatmaps.query,
    }),
    search: {
      get() {
        return this.$store.state.projects.search;
      },
      set(value) {
        this.$store.commit("setProjectSearch", value);
      },
    },
    filterActive() {
      let x =
        this.query.search ||
        this.query.application ||
        (this.query.tags && this.query.tags.length > 0) ||
        (this.query.type && this.query.type.length > 0) ||
        (this.query.owned_by && this.query.owned_by.length > 0);

      return x;
    },
    fire() {
      if (this.url.url != "") {
        return "primary";
      } else {
        return "error";
      }
    },
    freshProject() {
      return this.projects.find((p) => p.uid === this.$route.params.id);
    },
    noCredits() {
      if (!this.workspace.imageCredits) {
        return true;
      } else {
        return (
          this.workspace.imageCredits === 0 && this.workspace.videoCredits === 0
        );
      }
    },
  },
  methods: {
    back() {
      this.uploads = [];
    },
    allowed(file) {
      return new Promise((resolve) => {
        const videoLimit = this.userProfile.role === "trial" ? 61 : 901;

        function isImage(filename) {
          var ext = getExtension(filename);
          switch (ext.toLowerCase()) {
            case "jpg":
            case "gif":
            case "bmp":
            case "png":
            case "jpeg":
              return true;
          }
          return false;
        }

        function getExtension(filename) {
          var parts = filename.split(".");
          return parts[parts.length - 1];
        }

        function isVideo(filename) {
          var ext = getExtension(filename);
          switch (ext.toLowerCase()) {
            case "m4v":
            case "avi":
            case "mpg":
            case "mp4":
              // etc
              return true;
          }
          return false;
        }

        if (isVideo(file.name)) {
          this.videoCount++;
          var video = document.createElement("video");
          window.URL = window.URL || window.webkitURL;
          video.preload = "metadata";

          video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);
            var duration = video.duration;
            file.duration = duration;
            if (duration > videoLimit) {
              return resolve(false);
            } else {
              return resolve(true);
            }
          };
          video.src = URL.createObjectURL(file);
        } else if (isImage(file.name)) {
          this.imageCount++;
          return resolve(true);
        } else {
          return resolve(false);
        }
      });
    },
    errorMessage(name) {
      const videoLimit =
        this.userProfile.role === "trial" ? "1 minute" : "15 minutes";

      this.$emit("uploads", false);
      this.$emit("error", {
        text: `Your file ${name} format/size is not accepted. The accepted formats are .JPG, .PNG and .MP4 (videos) with a maximum upload of ${videoLimit} per video.`,
        dialog: true,
      });
    },
    async onFileChange() {
      var files = this.$refs.headerRef.files;
      console.log(files);
      this.$store.dispatch("addFiles", files);
    },
    saveProject() {
      this.savingProject = true;
      const vm = this;
      db.collection("projects")
        .add({
          title: vm.projectTitle,
          creator_uid: auth.currentUser.uid,
          heatmaps: [],
          created_at: new Date(),
          workspace: vm.userProfile.workspace,
        })
        .then((docRef) => {
          var doc = db.collection("projects").doc(docRef.id);

          return doc
            .update({
              uid: docRef.id,
            })
            .then(() => {
              console.log("Document successfully updated!");
              vm.menuProject = false;
              vm.savingProject = false;
              vm.projectTitle = "";
            })
            .then(() => {
              vm.$store.dispatch("fetchProjects");
              vm.$router.push(`/projects`);
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
            });
        })

        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },

    resetValue(event) {
      event.target.value = "";
    },
    setDialog(val) {
      this.dialog = val;
    },
    trigger() {
      this.$refs.headerRef.click();
      this.menu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 108px;
  background-color: #ebebef;
  z-index: 9;
}

.upload {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 140px;
  border: 2px solid white;
  background-color: #8b78fa;
  color: white;
  font-weight: 600;
  border-radius: 6px;
  margin-bottom: 25px;

  p {
    margin: 0;
  }
}

.divider {
  margin-top: 30px;
  margin-bottom: 30px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  span {
    text-align: center;
    font-size: 10pt;
  }
}

.filters {
  .v-btn {
    text-transform: none;
    font-weight: 450;
    font-size: 0.75rem;
    background-color: white;
  }
}

.v-btn {
  text-transform: none;
  font-weight: 600;
  font-size: 0.875rem;
}

.url {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.new-project {
  display: flex;
  gap: 10px;
}

input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  border: 2px solid red;
}
</style>
