<template>
  <div class="users-wrapper pa-10">
    <h1 class="mb-5">
      <span v-if="!workspace.name">Workspace</span>
      <span v-else
        >{{ workspace.name }}
        <span class="font-weight-regular">workspace</span></span
      >
    </h1>

    <v-card class="pa-4" variant="flat">
      <v-row align="center">
        <v-col class="grow">
          <b>{{ workspace.imageCredits }}</b> image credits left
        </v-col>
        <v-col class="grow">
          <b> {{ workspace.videoCredits }}</b>
          video credits left
        </v-col>
      </v-row>
    </v-card>

    <v-card
      class="d-flex align-center justify-space-between mt-2 pa-4"
      variant="flat"
      v-if="workspace.members"
    >
      <div>
        <b>{{ workspace.members.length }}</b> of
        {{ workspace.seats + workspace.members.length }} seats used in your
        workspace.
      </div>

      <v-btn
        :disabled="workspace.seats === 0"
        flat
        variant="tonal"
        @click="dialogInvite = true"
        color="primary"
        >Invite</v-btn
      >
    </v-card>

    <v-card variant="flat" class="pa-4 mt-2" v-if="workspace.api_key">
      <v-row>
        <v-col class="pb-0">
          <h3>API access</h3>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-space-between">
        <div class="grow">
          <v-col class="grow pb-0"> Workspace UID: {{ workspace.uid }} </v-col>
          <v-col v-if="key"> API key: {{ workspace.api_key }} </v-col>
        </div>
      </v-row>
      <v-row>
        <v-col class="pb-2 d-flex" style="gap: 15px">
          <v-btn
            color="success"
            density="compact"
            variant="flat"
            style="font-size: 10pt"
            @click="key = !key"
            >{{ key ? "Hide key" : "Reveil API key" }}</v-btn
          >
          <p>
            <span
              ><u style="cursor: pointer" @click="openDocs"
                >API documentation</u
              ></span
            >
            <span class="ml-4"
              ><u style="cursor: pointer" @click="openPostman"
                >Postman API documentation</u
              ></span
            >
          </p>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="mt-2" flat>
      <v-card-title v-if="users.length > 10">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users"
        :loading="!users && users.length === 0"
        :search="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            fab
            elevation="0"
            v-if="
              item.role != 'admin' &&
              userProfile.role === 'admin' &&
              item.uid != userProfile.uid
            "
            small
            @click="deleteItem(item)"
            ><v-icon>mdi-delete </v-icon></v-btn
          >
        </template>
        <template v-slot:[`item.image`]="{ item }">
          <v-avatar class="my-3" v-if="item.avatar">
            <img :src="item.avatar" :alt="item.name" />
          </v-avatar>
          <v-avatar class="my-3" color="accent" v-else>
            <v-icon color="white"> mdi-account</v-icon>
          </v-avatar>
        </template>
      </v-data-table>
    </v-card>

    <User
      @closeDialog="closeDialog"
      @updateSnackbar="showSnackbar"
      :dialog="dialog"
      v-bind:user="user"
      v-if="dialog"
      :currentUser="this.userProfile"
    />
    <Invite :dialogProp="dialogInvite" @dialog="setDialogInvite" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import User from "@/components/userProfile/UserProfile.vue";
import Invite from "@/components/invite/InviteCard.vue";

export default {
  name: "WorkspaceUser",
  components: {
    User,
    Invite,
  },
  data() {
    return {
      dialog: false,
      dialogInvite: false,
      loading: true,
      headers: [
        {
          title: "",
          value: "image",
        },
        {
          title: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          title: "Email",
          sortable: true,
          value: "email",
        },
        {
          title: "Role",
          value: "role",
        },
        {
          title: "Actions",
          value: "actions",
        },
      ],
      key: false,
      search: "",
      user: null,
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      users: (state) => state.users.users,
      workspace: (state) => state.workspace.workspace,
    }),
    seatsLeft() {
      return this.workspace.seats - this.workspace.members.length;
    },
  },
  methods: {
    async closeDialog(v) {
      if (v === "save") {
        const users = await this.$store.dispatch("fetchUsers");
        console.log(users);
      }
      this.dialog = false;
    },

    deleteItem(val) {
      let r = confirm(`Are you sure you want to delete user: ${val.name}?`);
      if (r) {
        this.$store.dispatch("removeUser", val);
      }
    },
    openDocs() {
      window.open("https://brainsight.gitbook.io/brainsight-api", "_blank");
    },
    openPostman() {
      window.open(
        "https://documenter.getpostman.com/view/20163500/2sA3XSALkQ",
        "_blank"
      );
    },
    setDialogInvite(v) {
      this.dialogInvite = v;
    },
    showSnackbar(val) {
      this.$emit("snackbar", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.users-wrapper {
  max-width: 1000px;
}
h1,
p,
td,
th {
  color: #282447;
}

.trialUsers {
  max-width: 600px;
  font-size: 14pt;
}
</style>
