import { db } from "@/firebase";

const docs = {
  state: {
    docs: [],
    loading: true,
  },
  mutations: {
    setDocs(state, val) {
      state.docs = val;
    },
    setLoading(state, val) {
      state.loading = val;
    },
  },
  actions: {
    fetchDocs({ commit }) {
      return new Promise((resolve) => {
        let docs = [];

        db.collection("docs")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let d = doc.data();
              docs.push(d);
            });
          })
          .then(() => {
            docs.forEach((d) => {
              if (d.group) {
                d.docs = [];
                db.collection("docs")
                  .doc(d.uid)
                  .collection("docs")
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      d.docs.push(doc.data());
                    });
                  });
              }
            });
          })
          .then(() => {
            docs.sort((a, b) =>
              a.index < b.index ? -1 : b.index < a.index ? 1 : 0
            );

            commit("setDocs", docs);
            resolve("docs loaded");
          });
      });
    },
  },
  getters: {},
};

export default docs;
