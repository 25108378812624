<template>
  <div class="login-wrapper">
    <div class="left">
      <div class="logo">
        <img src="@/assets/brand/full_logo.svg" height="40" alt="" />
      </div>
      <div>
        <h1 v-if="!workspace">Login &#9996;&#65039;</h1>
        <h1 v-if="workspace">
          <span class="font-weight-regular">Join</span>
          {{ workspaceName }} &#9996;&#65039;
        </h1>
        <p class="subtitle">
          Don't have an account yet?
          <u
            v-if="!workspace && !$route.query.sub"
            style="cursor: pointer"
            @click="$router.push('/signup')"
            >Go here</u
          >
          <u
            v-else-if="workspace"
            style="cursor: pointer"
            @click="
              $router.push(
                `/signup?workspace=${workspace}&workspaceName=${workspaceName}`
              )
            "
            >Go here</u
          >
          <u
            v-else-if="$route.query.sub"
            style="cursor: pointer"
            @click="$router.push(`/signup?sub=${$route.query.sub}`)"
            >Go here</u
          >
        </p>
        <div class="google" @click="google">
          <img src="@/assets/google.png" alt="" />
          <span>Sign in with Google</span>
        </div>
        <div class="divider">
          <hr />
          <span>or sign in with email</span>
          <hr />
        </div>
        <div class="form">
          <v-text-field
            label="Email"
            bg-color="white"
            type="email"
            hide-details="true"
            class="mb-5"
            rounded
            variant="solo"
            flat
            v-model="email"
          ></v-text-field>
          <v-text-field
            label="Password"
            bg-color="white"
            type="password"
            hide-details="true"
            rounded
            variant="solo"
            flat
            v-model="password"
          ></v-text-field>
          <p class="forgot" @click="$router.push('/reset')">
            <u>Forgot password?</u>
          </p>
          <v-btn
            @click="login"
            block
            rounded
            elevation="0"
            height="56"
            color="success"
            :disabled="!password || !email"
            >Login!</v-btn
          >
          <p class="error--text mt-3" v-if="notFound">User not found!</p>
          <p class="error--text mt-3" v-if="error">
            Email or password is not correct.
          </p>
        </div>
      </div>
      <div class="terms">
        <p>
          By signing in or creating an account, you agree with our
          <u @click="$router.push('/terms')" style="cursor: pointer"
            >terms and conditions</u
          >
          and
          <u @click="$router.push('/privacy')" style="cursor: pointer"
            >privacy policy</u
          >.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/";
// import * as fb from "@/firebase";
// import axios from "axios";
import { auth } from "@/firebase";

export default {
  name: "LoginPage",
  components: {},
  data() {
    return {
      email: "",
      error: false,
      index: 0,
      interval: null,
      notFound: false,
      password: "",
      workspace: "",
      workspaceName: "",
    };
  },
  computed: {
    activeMessage() {
      return this.messages[this.index];
    },
  },
  mounted() {
    if (this.$route.query.workspace) {
      this.workspace = this.$route.query.workspace;
      this.workspaceName = this.$route.query.workspaceName;
    }
  },
  methods: {
    async join() {
      this.$store.commit("setLoading", true);

      var seats = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("seatsAvailable");
      const res = await seats({ workspace: this.workspace });

      if (res.data) {
        const vm = this;
        this.$store.dispatch({
          type: "acceptInvite",
          uid: auth.currentUser.uid,
          workspace: vm.workspace,
          email: auth.currentUser.email,
          emailVerified: auth.currentUser.emailVerified,
        });
      } else {
        this.$emit("error", {
          text: "Unfortunately there are no seats available for this workspace. Please contact the workspace admin.",
          dialog: true,
        });
        this.$store.commit("setLoading", false);
      }
    },
    async google() {
      const provider = new firebase.auth.GoogleAuthProvider();
      const vm = this;
      await firebase
        .auth()
        .signInWithPopup(provider)
        .then(async function (result) {
          const user = result.user;
          if (result.additionalUserInfo.isNewUser) {
            user
              .delete()
              .then(() => {
                console.log("user deleted");
                vm.notFound = true;
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            console.log("existing user");
            if (vm.workspace) {
              vm.$router.push(
                `/invited?workspace=${vm.$router.currentRoute.query.workspace}&workspaceName=${vm.$router.currentRoute.query.workspaceName}`
              );
            }
          }
        });
    },
    login() {
      const vm = this;
      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.$emit("snackbar", {
            text: "Log in successful",
            type: "success",
          });
        })
        .then(() => {
          if (vm.workspace) {
            vm.$router.push(
              `/invited?workspace=${vm.$router.currentRoute.query.workspace}&workspaceName=${vm.$router.currentRoute.query.workspaceName}`
            );
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.message.includes("auth/user-not-found")) {
            this.notFound = true;
            this.error = false;
          } else {
            this.notFound = false;
            this.error = true;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  display: flex;
  color: #282447;

  .left {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    width: 60%;
    max-width: 400px;

    .logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        margin-bottom: 5px;
      }
    }
    .subtitle {
      font-size: 14pt;
    }

    .google {
      margin-top: 30px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: white;
      padding: 13px 0;
      border-radius: 30px;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 0px 3px rgba(209, 219, 229, 1);
      }
      img {
        width: 30px;
      }
    }

    .divider {
      margin-top: 30px;
      margin-bottom: 30px;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr 1fr;
      span {
        text-align: center;
        font-size: 10pt;
      }
    }
    .forgot {
      color: #8b78fa;
      margin-top: 10px;
      text-align: end;
      cursor: pointer;
    }

    .v-btn {
      text-transform: none;
      font-weight: 600;
      font-size: 12pt;
    }

    .terms {
      text-align: center;
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
