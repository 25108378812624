<template>
  <v-card flat style="border: 1.5px solid #3e1191; border-radius: 6px">
    <v-list class="py-0">
      <v-list-item class="bg-secondary" link style="width: 100%">
        <div class="py-4" style="width: 100%">
          <div class="d-flex align-center">
            <h4 class="font-weight-medium">Brand Attention:</h4>
            <v-spacer></v-spacer>
            <v-btn icon="mdi-information" variant="text" size="small"></v-btn>
          </div>
        </div>
      </v-list-item>

      <div v-if="metrics">
        <v-divider></v-divider>
        <v-tooltip
          text="The amount of seconds where a brand object or text is visible in the video."
        >
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" class="py-1" link>
              <div class="d-flex align-center justify-space-between">
                <span
                  class="mb-0 pb-0"
                  style="font-size: 10pt; font-weight: 600"
                >
                  Brand Visibility
                </span>

                <span class="ma-0" style="font-size: 10pt">
                  <span style="font-weight: 600; font-size: 18pt">{{
                    Math.round(metrics.brand_exposure_seconds) > duration
                      ? Math.round(duration)
                      : Math.round(metrics.brand_exposure_seconds)
                  }}</span>
                  / {{ Math.round(duration) }} s.
                </span>
              </div>
            </v-list-item>
          </template>
        </v-tooltip>

        <v-divider></v-divider>
        <v-tooltip
          text="The average attention towards all brand objects and texts when they are visible."
        >
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" class="py-1" link>
              <div class="d-flex align-center justify-space-between">
                <p class="mb-0 pb-0" style="font-size: 10pt; font-weight: 600">
                  Brand Attention
                </p>
                <v-spacer></v-spacer>
                <p class="ma-0" style="font-size: 10pt">
                  <span style="font-weight: 600; font-size: 18pt">{{
                    metrics.brand_attention_percentage
                  }}</span>
                  %
                </p>
              </div>
            </v-list-item>
          </template>
        </v-tooltip>
        <v-divider></v-divider>
        <!-- <v-list-item class="d-flex py-1" link>
          <p class="mb-0 pb-0" style="font-size: 10pt; font-weight: 600">
            Audio mentions
          </p>
          <v-spacer></v-spacer>
          <p class="ma-0" style="font-size: 10pt">
            <span style="font-weight: 600; font-size: 18pt">2</span> x
          </p>
        </v-list-item> -->
        <v-tooltip
          text="The amount of seconds passing before the first brand is detected."
        >
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" class="py-1" link>
              <div class="d-flex align-center justify-space-between">
                <p class="mb-0 pb-0" style="font-size: 10pt; font-weight: 600">
                  Time till first brand:
                </p>
                <v-spacer></v-spacer>
                <span style="font-weight: 600; font-size: 18pt"
                  >{{ Math.round(metrics.time_till_first_brand) }}
                  <span style="font-size: 10pt; font-weight: 500"
                    >s.</span
                  ></span
                >
              </div>
            </v-list-item>
          </template>
        </v-tooltip>

        <v-divider></v-divider>
        <v-list-item class="py-1" link>
          <div class="d-flex align-center justify-space-between">
            <p class="mb-0 pb-0" style="font-size: 10pt; font-weight: 600">
              Brand in first 2 sec.
            </p>
            <v-spacer></v-spacer>
            <v-icon size="small" color="success" v-if="metrics.first_2"
              >mdi-check-circle</v-icon
            >
            <v-icon v-else size="small" color="error">mdi-close-circle</v-icon>
          </div>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="py-1" link>
          <div class="d-flex align-center justify-space-between">
            <p class="mb-0 pb-0" style="font-size: 10pt; font-weight: 600">
              Brand in first 5 sec.
            </p>
            <v-spacer></v-spacer>
            <v-icon size="small" color="success" v-if="metrics.first_5"
              >mdi-check-circle</v-icon
            >
            <v-icon v-else size="small" color="error">mdi-close-circle</v-icon>
          </div>
        </v-list-item>
      </div>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    metrics: {
      type: Object,
      default: null,
    },
    duration: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
};
</script>
