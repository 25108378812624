import firebase from "firebase";
import router from "@/router/index";

const uploads = {
  state: {
    uploads: [],
    loading: false,
    dialog: false,
    uploading: {
      values: [],
      show: false,
      amount: 0,
      timeout: -1,
    },
  },
  mutations: {
    setUploads(state, val) {
      state.uploads = val;
    },
    setUploadsDialog(state, val) {
      state.dialog = val;
    },
    setUploadsLoading(state, val) {
      state.loading = val;
    },
    setUploading(state, val) {
      state.uploading = val;
    },
    addUploading(state, val) {
      state.uploading.values.push(val);
    },
    updateUploading(state, val) {
      console.log(val);
      state.uploading.values.find((i) => i.uid === val.uid).value = val.value;
      if (val.value === 100) {
        let i = state.uploads.findIndex((h) => h.uid === val.uid);
        state.uploads.splice(i, 1);
      }
    },
    setUploadingFalse(state) {
      state.uploading.show = false;
    },
    setTimeout(state, val) {
      state.uploading.timeout = val;
    },
  },
  actions: {
    async requestBanner({ commit }, val) {
      commit("setUploadsLoading", true);
      var getBanner = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("html5banner");
      await getBanner(val);
      commit("setUploadsLoading", false);
    },
    async requestUrl({ commit, dispatch }, val) {
      commit("setUploadsLoading", true);
      console.log(val);
      function getHeight(v) {
        return new Promise((resolve) => {
          var img = document.createElement("img");
          img.onload = async function () {
            resolve(img.height);
          };
          img.src = v;
        });
      }

      var getScreenshot = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("getScreenshot");
      const uids =
        router.currentRoute.value.name === "Project"
          ? [router.currentRoute.value.params.id]
          : [];
      const uploads = [];

      async function top(optionsPart, i, val) {
        console.log(i);
        const shot = await getScreenshot(optionsPart);
        const image = await fetch(shot.data);
        const blob = await image.blob();
        const myRenamedFile = new File([blob], "screenshot.png", {
          type: blob.type,
        });
        const res = await dispatch("toBase64", blob);

        uploads.push({
          index: i,
          original: myRenamedFile,
          thumbnail: res,
          type: "image",
          title: `${i + 1}. ${val.mobile ? "Mobile" : "Desktop"}/FullPage - ${val.url}`,
          application: "UX Design",
          device: val.mobile ? "Mobile" : "Desktop",
          projectIds: uids,
          country: "",
          industry: "",
          fullPage: val.fullPage,
          url: val.url,
          loading: true,
          audit: val.audit ? val.audit : false,
        });
        return;
      }

      if (!val.mobile && !val.fullPage) {
        const options = {
          format: "png",
          click_accept: true,
          hide_cookie_banners: true,
          cookie: ["cookie=OptIn=true"],
          url: val.url,
          height: "1080",
          width: "1920",
          force: true,
          hide_selector: val.hide_selector ? val.hide_selector : null,
          delay: val.delay ? val.delay : 0,
          scroll_to: val.scroll_to ? val.scroll_to : null,
        };
        const screenshot = await getScreenshot(options);

        const image = await fetch(screenshot.data);
        const blob = await image.blob();
        const myRenamedFile = new File([blob], "screenshot.png", {
          type: blob.type,
        });
        const res = await dispatch("toBase64", blob);

        uploads.push({
          original: myRenamedFile,
          thumbnail: res,
          type: "image",
          title: `Desktop/AboveFold - ${val.url}`,
          application: "UX Design",
          device: "Desktop",
          projectIds: uids,
          country: "",
          industry: "",
          fullPage: val.fullPage,
          url: val.url,
          loading: true,
          audit: val.audit ? val.audit : false,
        });
      } else if (!val.mobile && val.fullPage) {
        const options = {
          format: "png",
          click_accept: true,
          hide_cookie_banners: true,
          cookie: ["cookie=OptIn=true"],
          url: val.url,
          full_page: true,
          width: "1920",
          hide_selector: val.hide_selector ? val.hide_selector : null,
          force: true,
          delay: val.delay ? val.delay : 0,
          scroll_to: val.scroll_to ? val.scroll_to : null,
        };
        const screenshot = await getScreenshot(options);
        const height = await getHeight(screenshot.data);

        let promises = [];

        if (height > 1080) {
          for (let i = 0; i < height / 900; i++) {
            let scroll = i * 900;
            const optionsPart = {
              format: "png",
              click_accept: true,
              hide_cookie_banners: true,
              cookie: ["cookie=OptIn=true"],
              url: val.url,
              height: "1080",
              width: "1920",
              hide_selector: val.hide_selector ? val.hide_selector : null,
              force: true,
              delay: val.delay ? val.delay : 0,
              scroll_to: String(scroll),
            };
            console.log("promise push " + i);
            promises.push(top(optionsPart, i, val));
          }
          await Promise.all(promises);
          uploads.sort((a, b) =>
            a.index < b.index ? -1 : b.index < a.index ? 1 : 0
          );
        } else {
          const image = await fetch(screenshot.data);
          const blob = await image.blob();
          const myRenamedFile = new File([blob], "screenshot.png", {
            type: blob.type,
          });
          const res = await dispatch("toBase64", blob);

          uploads.push({
            original: myRenamedFile,
            thumbnail: res,
            type: "image",
            title: `${i + 1}. Desktop/FullPage - ${val.url}`,
            application: "UX Design",
            device: "Desktop",
            projectIds: uids,
            country: "",
            industry: "",
            fullPage: val.fullPage,
            url: val.url,
            loading: true,
            audit: val.audit ? val.audit : false,
          });
        }
      } else if (val.mobile && !val.fullPage) {
        const options = {
          format: "png",
          click_accept: true,
          hide_cookie_banners: true,
          cookie: ["cookie=OptIn=true"],
          url: val.url,
          full_page: false,
          width: "375",
          height: "812",
          hide_selector: val.hide_selector ? val.hide_selector : null,
          force: true,
          delay: val.delay ? val.delay : 0,
          scroll_to: val.scroll_to ? val.scroll_to : null,
        };
        const screenshot = await getScreenshot(options);
        const image = await fetch(screenshot.data);
        const blob = await image.blob();
        const myRenamedFile = new File([blob], "screenshot.png", {
          type: blob.type,
        });
        const res = await dispatch("toBase64", blob);

        uploads.push({
          original: myRenamedFile,
          thumbnail: res,
          type: "image",
          title: `Mobile/AboveFold - ${val.url}`,
          application: "UX Design",
          device: "Mobile",
          projectIds: uids,
          country: "",
          industry: "",
          fullPage: val.fullPage,
          url: val.url,
          loading: true,
          audit: val.audit ? val.audit : false,
        });
      } else if (val.mobile && val.fullPage) {
        const options = {
          format: "png",
          click_accept: true,
          hide_cookie_banners: true,
          cookie: ["cookie=OptIn=true"],
          url: val.url,
          full_page: true,
          hide_selector: val.hide_selector ? val.hide_selector : null,
          width: "375",
          delay: val.delay ? val.delay : 0,
          force: true,
        };
        const screenshot = await getScreenshot(options);
        const height = await getHeight(screenshot.data);
        let promises = [];
        if (height > 812) {
          for (let i = 0; i < height / 700; i++) {
            let scroll = i * 700;
            const optionsPart = {
              format: "png",
              click_accept: true,
              hide_cookie_banners: true,
              cookie: ["cookie=OptIn=true"],
              url: val.url,
              height: "812",
              hide_selector: val.hide_selector ? val.hide_selector : null,
              width: "375",
              scroll_to: String(scroll),
              force: true,
              delay: val.delay ? val.delay : 0,
            };

            promises.push(top(optionsPart, i, val));
          }
          await Promise.all(promises);

          uploads.sort((a, b) =>
            a.index < b.index ? -1 : b.index < a.index ? 1 : 0
          );
        } else {
          const image = await fetch(screenshot.data);
          const blob = await image.blob();
          const myRenamedFile = new File([blob], "screenshot.png", {
            type: blob.type,
          });
          const res = await dispatch("toBase64", blob);

          uploads.push({
            original: myRenamedFile,
            thumbnail: res,
            type: "image",
            title: `${i + 1}. Mobile/FullPage - ${val.url}`,
            application: "UX Design",
            device: "Mobile",
            projectIds: uids,
            country: "",
            industry: "",
            fullPage: val.fullPage,
            url: val.url,
            loading: true,
            index: i,
            audit: val.audit ? val.audit : false,
          });
        }
      }

      if (val.add_scrolling_video) {
        uploads.push({
          original: null,
          scrolling_video: true,
          type: "video",
          title: `Scrolling Video - ${val.url}`,
          projectIds: uids,
          loading: false,
          country: "",
          device: `${val.mobile ? "Mobile" : "Desktop"}`,
          industry: "",
          application: "UX Design",
          url: val.url,
          delay: val.delay,
          hide_selector: val.hide_selector,
        });
      }

      commit("setUploads", uploads);
      commit("setUploadsDialog", true);
      commit("setUploadsLoading", false);
    },
    async toBase64({ state }, file) {
      return new Promise((resolve, reject) => {
        console.log(state);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async addFiles({ state, commit, dispatch }, files) {
      function getExtension(filename) {
        var parts = filename.split(".");
        return parts[parts.length - 1];
      }

      function isImage(filename) {
        var ext = getExtension(filename);
        switch (ext.toLowerCase()) {
          case "jpg":
          case "gif":
          case "bmp":
          case "png":
          case "jpeg":
            //etc
            return true;
        }
        return false;
      }

      function isVideo(filename) {
        var ext = getExtension(filename);

        switch (ext.toLowerCase()) {
          case "m4v":
          case "avi":
          case "mpg":
          case "mp4":
            // etc
            return true;
        }
        return false;
      }

      let uploads = [];
      let imageCount = 0;
      let videoCount = 0;
      let notAllowed = 0;

      for (let f of files) {
        const uids =
          router.currentRoute.value.name === "Project"
            ? [router.currentRoute.value.params.id]
            : [];

        const allowed = await dispatch("allowed", f);

        if (allowed === false) {
          notAllowed++;
          // this.errorMessage(f.name);
          window.alert(f.name);
          console.log("Not allowed");
        }

        if (isImage(f.name)) {
          const res = await dispatch("toBase64", f);
          imageCount++;

          if (allowed) {
            uploads.push({
              original: f,
              type: "image",
              thumbnail: res,
              title: f.name,
              projectIds: uids,
              loading: false,
            });
          }
        } else if (isVideo(f.name)) {
          videoCount++;

          if (allowed) {
            uploads.push({
              original: f,
              type: "video",
              title: f.name,
              projectIds: uids,
              loading: false,
              duration: f.duration,
            });
          }
        }
      }

      commit("setUploads", uploads);
      commit("setUploadsDialog", true);
    },
  },
  getters: {},
};

export default uploads;
