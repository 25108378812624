<template>
  <div v-if="tutorial.dialog">
    <v-dialog v-model="tutorial.dialog" persistent width="60%">
      <v-card>
        <v-card-title class="accent py-3 pl-10 white--text">
          <v-icon color="white" class="mr-3">mdi-school</v-icon
          ><span class="text-h6">{{ video.title }}</span>
          <v-spacer></v-spacer>
          <v-btn elevation="0" text class="white--text" @click="close()">
            <v-icon class="mr-1">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-0 px-0 middle">
          <div class="px-10 pb-8 accent">
            <video
              :src="video.src"
              controls
              width="100%"
              autoplay
              style="
                display: block;
                border-radius: 10px;
                filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
              "
              @playing="playing = true"
              @pause="playing = false"
              id="video"
              ref="video"
            ></video>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  watch: {},
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      defaults: (state) => state.defaults.defaults,
      blogs: (state) => state.blogs.blogs,
      tutorial: (state) => state.tutorial,
      tutorials: (state) => state.tutorials,
    }),
    video() {
      const vid = this.tutorials.find((t) => t.id === this.tutorial.id);
      return vid;
    },
  },
  data() {
    return {
      playing: false,
    };
  },
  methods: {
    close() {
      this.$store.commit("setTutorial", { id: null, dialog: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.middle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .bottom {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
