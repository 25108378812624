<template>
  <div class="login-wrapper">
    <div class="left">
      <div class="logo">
        <img src="@/assets/logo-dark.svg" width="50" alt="" />
        <span><b>brainsight</b></span>
      </div>
      <div>
        <h1>
          <span class="font-weight-regular">Invite to join</span>
          {{ workspaceName }}
        </h1>
        <p
          class="mt-3 subtitle"
          v-if="
            $store.state.users.userProfile.role === 'admin' &&
            !$store.state.workspace.workspace.canceled
          "
        >
          You are currently admin of
          <b>{{ $store.state.workspace.workspace.name }}</b> with a paid account
          and therefore cannot join another workspace. <br /><br />
          Please cancel your active subscription of your current workspace under
          account > subscription, and then click the link in the invite email
          again.
        </p>
        <p
          v-else-if="
            $store.state.users.userProfile.role === 'member' &&
            workspaceUID != $store.state.users.userProfile.workspace
          "
        >
          You are currently member of
          <b>{{ $store.state.workspace.workspace.name }}</b
          >. If you would like to join {{ workspaceName }}, you will be removed
          from your current workspace and your created heatmaps will stay in
          your current workspace.
        </p>
        <p
          class="mt-3 subtitle"
          v-else-if="
            available &&
            workspaceUID != $store.state.users.userProfile.workspace
          "
        >
          A paid user seat has been appointed to you. Click 'Join!' if you wish
          to join with your current email account:
          {{ $store.state.users.userProfile.email }}
        </p>
        <p
          class="mt-3 subtitle"
          v-else-if="$store.state.users.userProfile.workspace === workspaceUID"
        >
          Congrats! &#127881; You're part of the the workspace
          <b>{{ workspaceName }}</b
          >!
        </p>
        <p class="mt-3 subtitle" v-else>
          Unfortunately all user seats are taken in this workspace. Please get
          in touch with the admin of your workspace.
        </p>

        <div
          class="d-flex"
          v-if="
            ($store.state.users.userProfile.role === 'admin' &&
              !$store.state.workspace.workspace.canceled) ||
            !available ||
            $store.state.users.userProfile.workspace === workspaceUID
          "
        >
          <v-btn
            rounded
            color="success"
            elevation="0"
            @click="$router.push('/home')"
            >Continue</v-btn
          >
        </div>

        <div class="d-flex" v-else>
          <v-btn
            :loading="loading"
            width="150"
            large
            rounded
            color="success"
            elevation="0"
            @click="join"
            >Join!</v-btn
          ><v-btn
            large
            color="warning"
            elevation="0"
            text
            class="ml-5"
            @click="changeAccount"
            >Join with different account</v-btn
          >
        </div>
      </div>
      <div class="terms"></div>
    </div>
    <div class="right"></div>
  </div>
</template>

<script>
import firebase from "firebase/";
import { auth } from "@/firebase";
import store from "@/store";

export default {
  name: "InvitedPage",
  data() {
    return {
      available: null,
      loading: false,
      workspaceUID: "",
      workspaceName: "",
    };
  },
  async mounted() {
    this.$store.commit("setLoading", true);
    if (this.$route.query.workspace) {
      this.workspaceUID = this.$route.query.workspace;
      this.workspaceName = this.$route.query.workspaceName;
    }
    var seats = firebase
      .app()
      .functions("europe-west1")
      .httpsCallable("seatsAvailable");
    const seatsAvailable = await seats({
      workspace: this.$route.query.workspace,
    });
    this.available = seatsAvailable.data;
    this.$store.commit("setLoading", false);
  },
  methods: {
    async join() {
      if (this.available) {
        const vm = this;
        this.$store.dispatch({
          type: "acceptInvite",
          uid: auth.currentUser.uid,
          workspace: vm.workspaceUID,
          email: auth.currentUser.email,
          emailVerified: true,
          currentWorkspace: this.$store.state.users.userProfile.workspace,
        });
      } else {
        this.$emit("error", {
          text: "All user seats are taken in this workspace.",
          dialog: true,
        });
      }
    },
    async changeAccount() {
      await auth.signOut();
      store.commit("setUserProfile", {});
      store.commit("setHeatmaps", []);
      store.commit("setProjects", []);
      store.commit("setDefaults", {});
      store.commit("setTags", []);
      store.commit("setUsers", []);
      store.commit("setReports", []);
      store.commit("setWorkspace", {});
      store.commit("setDemo", {});
      this.$router.push(
        `/signup?workspace=${this.workspaceUID}&workspaceName=${this.workspaceName}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  color: #282447;

  .left {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    width: 50%;
    max-width: 500px;

    .logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        margin-bottom: 5px;
      }
    }
    .subtitle {
      font-size: 14pt;
    }

    .google {
      margin-top: 30px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background-color: white;
      padding: 13px 0;
      border-radius: 30px;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 0px 3px rgba(209, 219, 229, 1);
      }
      img {
        width: 30px;
      }
    }

    .divider {
      margin-top: 30px;
      margin-bottom: 30px;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr 1fr;
      span {
        text-align: center;
        font-size: 10pt;
      }
    }
    .forgot {
      color: #8b78fa;
      margin-top: 10px;
      text-align: end;
    }

    .v-btn {
      text-transform: none;
      font-weight: 600;
      font-size: 12pt;
    }

    .terms {
      text-align: center;
    }
  }

  .right {
    background-color: #282447;
  }
}
</style>
