<template>
  <div>
    <v-dialog :value="dialog" persistent width="600px">
      <v-card>
        <template slot="progress">
          <v-progress-linear
            color="accent"
            height="5"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-card-title class="accent py-5 white--text">
          <v-icon color="white" class="mr-3">mdi-texture-box</v-icon
          ><span class="text-h5">Import AOIs</span>
        </v-card-title>

        <v-card-text>
          <p class="mt-6">
            Import Area's of Interest (AOI's) from other heatmaps to get the
            exact same coordinates of the hand drawn boxes. This is especially
            useful when creating variants of the same page or ad.
          </p>
          <v-img class="my-6" src="@/assets/importAOI.svg"></v-img>
          <p style="display: flex; justify-content: space-around">
            <span>A</span><span>B</span>
          </p>

          <v-select
            class="mt-2"
            label="Select heatmap"
            hint="Select heatmap to import AOI's from"
            persistent-hint
            :items="heatmaps.filter((h) => h.type === 'image')"
            item-text="title"
            item-value="areas_of_interest"
            :selected="selected"
            @change="$emit('update:selected', selected)"
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="
              selected = [];
              $emit('dialog', false);
            "
          >
            Close
          </v-btn>
          <v-btn
            :loading="buttonLoading"
            color="warning"
            outlined
            text
            @click="importAOI()"
          >
            Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import moment from "moment";
// import { db, auth } from "@/firebase";
import { mapState } from "vuex";

export default {
  props: {
    selectedProp: {
      type: Array,
    },
    dialog: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      users: (state) => state.users.users,
      workspace: (state) => state.workspace.workspace,
      heatmaps: (state) => state.heatmaps.heatmaps,
      projects: (state) => state.projects.projects,
      tags: (state) => state.heatmaps.tags,
      defaults: (state) => state.defaults.defaults,
      subscription: (state) => state.subscription.subscription,
      reports: (state) => state.reports.reports,
    }),
  },
  data() {
    return {
      buttonLoading: false,
      selected: this.selectedProp
    };
  },
  methods: {
    importAOI() {
      this.buttonLoading = true;
      const vm = this;
      setTimeout(function () {
        vm.$emit("importAOI");
        vm.buttonLoading = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped></style>
