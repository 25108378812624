<template>
  <div class="youtube">
    <div class="left pa-4">
      <v-card width="100%" class="pa-4" flat>
        <h3 class="mb-2">Options:</h3>
        <p style="font-size: 8pt; margin-bottom: 5px">Thumbnail:</p>
        <v-select
          solo
          hide-details=""
          class="mb-3"
          flat
          background-color="smoke"
          return-object
          :items="
            $store.state.heatmaps.heatmaps.filter((h) => h.type === 'image')
          "
          item-title="title"
          item-props=""
          item-key="uid"
          v-model="heatmap"
          placeholder="Pick thumbnail"
        >
          <template v-slot:item="{ item }">
            <v-tooltip right color="warning">
              <template v-slot:activator="{ props }">
                <div v-bind="props">
                  <v-list-item
                    @click="heatmap = item.props"
                    :title="item.title"
                  ></v-list-item>
                </div>
              </template>
              <div class="py-2">
                <img :src="item.props.thumbnail" alt="" />
              </div>
            </v-tooltip>
          </template>
        </v-select>
        <p style="font-size: 8pt; margin-bottom: 5px">Search query:</p>
        <v-text-field
          class="mb-3"
          solo
          background-color="smoke"
          flat
          hide-details=""
          placeholder="Keyword"
          v-model="query"
        ></v-text-field>
        <p style="font-size: 8pt; margin-bottom: 5px">Results position:</p>
        <v-select
          solo
          hide-details=""
          class="mb-4"
          flat
          item-title="text"
          item-value="value"
          background-color="smoke"
          :items="[
            { text: 1, value: 0 },
            { text: 2, value: 1 },
            { text: 3, value: 2 },
            { text: 4, value: 3 },
          ]"
          v-model="position"
        ></v-select>
        <v-switch
          color="primary"
          label="Hide shorts"
          v-model="hide_shorts"
        ></v-switch>
        <v-btn
          color="success"
          elevation="0"
          block
          class="warning--text mb-2"
          :loading="loading"
          @click="generate"
          >{{ result ? "Redo" : "Start" }}</v-btn
        >
        <v-btn
          block
          elevation="0"
          dark
          v-if="this.result && !loading"
          @click="getHeatmap"
          >Generate heatmap</v-btn
        >
      </v-card>
    </div>
    <div class="right pt-4 pr-4 pb-4">
      <img ref="image" alt="" @load="loaded" v-show="result" />
      <img
        :src="require('@/assets/youtube_explainer.png')"
        alt=""
        v-show="!result"
      />
    </div>
  </div>
</template>

<script>
import firebase from "firebase/";

export default {
  data() {
    return {
      hide_shorts: false,
      heatmap: null,
      loading: false,
      position: 0,
      result: null,
      query: "",
    };
  },
  methods: {
    async generate() {
      this.loading = true;
      var getScreenshot = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("getScreenshot");

      const options = {
        format: "png",
        url: `https://www.youtube.com/results?search_query=${this.query}`,
        width: "1920",
        height: "1080",
        force: true,
        delay: 5000,
        // wait_for: "img.yt-core-image",
        js: `let i = document.body.querySelectorAll("ytd-video-renderer img.yt-core-image")\ni[${this.position}].src ="${this.heatmap.imageURL}"`,
      };

      if (this.hide_shorts) {
        options.hide_selector = "ytd-reel-shelf-renderer";
      }

      console.log(options);
      let result = await getScreenshot(options);
      console.log(result.data);
      this.result = result.data;
      this.$refs.image.src = result.data;
      //   this.loading = false;
    },
    async getHeatmap() {
      var createHeatmap = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("generateFromURL");
      const res = await createHeatmap({
        src: this.result,
        title: `${this.query} - #${this.position}`,
      });
      console.log(res);
      this.$store.commit("setSnackbar", {
        text: "Heatmap added",
        timeout: 2000,
        show: true,
      });
    },
    loaded() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.youtube {
  display: grid;
  grid-template-columns: 250px auto;
  margin-top: 25px;
  .right img {
    width: 90%;
    max-width: 1000px;
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}
</style>
