<template>
  <v-dialog persistent :model-value="dialog" width="auto">
    <v-card width="800">
      <div class="pa-4 bg-primary d-flex justify-space-between align-center">
        <div>
          <p style="font-size: 14pt">API Request</p>
          <p style="font-size: 10pt">{{ request.uid }}</p>
        </div>
        <v-btn
          icon="mdi-close"
          @click="$emit('close')"
          variant="flat"
          color="primary"
        ></v-btn>
      </div>

      <div class="pa-4 bg-dark">
        <pre>{{ JSON.stringify(request, null, 2) }}</pre>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
    },
    request: {
      type: Object,
    },
  },
  data() {
    return {};
  },

  methods: {
    async save() {
      this.$emit("close");
    },
  },
};
</script>
