<template>
  <div>
    <v-dialog persistent opacity="0.75" max-width="1000" v-model="dialog">
      <template v-slot:activator="{ props: activatorProps }">
        <v-btn
          v-bind="activatorProps"
          class="ml-4 mr-2"
          flat
          height="40"
          color="dark"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <template v-slot:default="{ isActive }">
        <v-card>
          <v-sheet
            class="d-flex justify-space-between align-center bg-primary pa-4"
          >
            <h3>
              Add subject
              <span v-if="files" style="font-weight: 400"
                >1/{{ files ? files.length : 0 }}</span
              >
            </h3>
            <v-btn
              variant="text"
              @click="isActive.value = false"
              icon="mdi-close"
            ></v-btn>
          </v-sheet>
          <v-stepper
            editable
            v-model="step"
            flat
            :items="['Upload', 'Set categories', 'Review']"
          >
            <template v-slot:actions="{ prev, next }">
              <v-stepper-actions
                v-if="step < 3"
                @click:next="next"
                @click:prev="prev"
              ></v-stepper-actions>
              <div v-else class="px-6 pb-4 d-flex justify-end">
                <v-btn variant="flat" color="primary" @click="addSubject"
                  >Save</v-btn
                >
              </div>
            </template>
            <template v-slot:item.1>
              <v-card
                title="Upload"
                flat
                class="d-flex flex-column align-center"
              >
                <input
                  multiple
                  ref="uploadRef"
                  type="file"
                  @change="onFileChange"
                  @click="resetValue"
                  accept="image/jpeg, image/png, image/jpg"
                />
                <v-btn
                  @click="trigger"
                  class="ml-4 mr-2"
                  flat
                  height="40"
                  color="dark"
                >
                  <v-icon>mdi-upload</v-icon>
                </v-btn>
              </v-card>
            </template>

            <template v-slot:item.2>
              <v-card
                flat
                style="display: grid; grid-template-columns: 1fr 1fr; gap: 30px"
              >
                <div>
                  <v-card class="bg-dark pa-4">
                    <v-combobox
                      variant="solo"
                      flat
                      bg-color="smoke"
                      class="mb-2"
                      v-model="category.key"
                      hide-details
                      label="Categories"
                      :items="benchmark.categories"
                      item-title="name"
                      item-value="name"
                      :return-object="false"
                    ></v-combobox>
                    <v-combobox
                      v-if="category.key"
                      v-model="category.value"
                      :items="
                        benchmark.categories.find(
                          (c) => c.name === category.key
                        ) != undefined
                          ? benchmark.categories.find(
                              (c) => c.name === category.key
                            ).values
                          : []
                      "
                      variant="solo"
                      flat
                      hide-details
                      bg-color="smoke"
                      class="mb-2"
                      label="Value"
                    ></v-combobox>
                    <v-btn
                      @click="addCategory"
                      color="white"
                      variant="tonal"
                      :disabled="!category.value || !category.key"
                      >Add category</v-btn
                    >
                  </v-card>
                </div>
                <div>
                  <h3>Categories:</h3>
                  <p v-if="categories.length === 0">No categories yet</p>
                  <div
                    v-else
                    v-for="(c, index) in categories"
                    :key="index"
                    style="display: grid; grid-template-columns: auto 60px"
                  >
                    <v-btn flat readonly>{{ c.key }} - {{ c.value }}</v-btn>
                    <v-btn
                      @click="categories.splice(index, 1)"
                      icon="mdi-close"
                      density="comfortable"
                    ></v-btn>
                  </div>
                </div>
              </v-card>
            </template>

            <template v-slot:item.3>
              <v-card
                title="Review"
                flat
                class="d-flex flex-column align-center"
              >
                <div
                  style="
                    width: 100%;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 30px;
                  "
                >
                  <v-img
                    style="position: relative"
                    width="100%"
                    v-if="file"
                    id="reviewImg"
                    :src="file.src"
                  >
                  </v-img>
                  <div>
                    <h3>Details</h3>
                    <p><u>categories</u></p>
                    <p>{{ final_categories }}</p>
                  </div>
                </div>
              </v-card>
            </template>
          </v-stepper>
          <!-- <v-btn
              color="primary"
              text="Close"
              class="mx-auto my-3"
              variant="text"
              @click="isActive.value = false"
            ></v-btn> -->
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import firebase from "firebase/";
import { db } from "@/firebase";

export default {
  mounted() {
    console.log(this.coordinates);
    console.log(this.categories);
  },
  data() {
    return {
      categories: [],
      category: {
        key: null,
        value: null,
      },
      dialog: false,
      files: null,
      step: 1,
    };
  },
  computed: {
    ...mapState({
      benchmark: (state) => state.benchmarks.benchmark,
    }),
    file() {
      let file = this.files && this.files.length > 0 ? this.files[0] : null;
      if (file) {
        file.src = URL.createObjectURL(file);
      }
      return file;
    },
    final_categories() {
      let categories = {};

      this.categories.map((varItem) => {
        categories[varItem.key] = varItem.value;
      });

      console.log(categories);
      return categories;
    },
  },
  methods: {
    addCategory() {
      this.categories.push(this.category);
      this.category = {
        key: null,
        value: null,
      };
    },
    async addSubject() {
      console.log("Adding subject");

      // SAVE TEMPLATE

      await this.upload({
        file: this.file,
        categories: this.final_categories,
      });

      // EMPTY everything
      //

      this.categories = [];
      this.category = {
        key: null,
        value: null,
      };
      this.step = 1;
      this.files = null;
      this.dialog = false;
      await this.$store.dispatch("fetchBenchmark", this.benchmark.uid);
      this.$store.commit("setLoading", false);

      this.$store.commit("setSnackbar", {
        text: "Subject added!",
        timeout: 2000,
        show: true,
      });
    },
    async onFileChange() {
      this.step = 2;
      if (this.$refs.uploadRef.files) {
        this.files = this.$refs.uploadRef.files;
      }
    },
    trigger() {
      this.$refs.uploadRef.click();
    },
    async upload(upload) {
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            console.log(upload);
            var fileExtension = upload.file.name.split(".").pop();
            var storageRef = firebase.storage().ref();

            const docRef = await db
              .collection("benchmarks")
              .doc(this.benchmark.uid)
              .collection("subjects")
              .add({
                extension: fileExtension,
                categories: upload.categories,
                thumbnail: null,
                created: Math.round(Date.now() / 1000),
              });
            await db
              .collection("benchmarks")
              .doc(this.benchmark.uid)
              .collection("subjects")
              .doc(docRef.id)
              .update({
                uid: docRef.id,
              });

            let path = storageRef.child(
              `benchmarks/${this.benchmark.uid}/subjects/${docRef.id}.${fileExtension}`
            );

            var uploadTask = path.put(upload.file);
            this.$store.commit("setLoading", true);
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                var progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(progress);

                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    // console.log("Upload is paused");
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    // console.log("Upload is running");
                    break;
                }
              },
              (error) => {
                switch (error.code) {
                  case "storage/unauthorized":
                    // User doesn't have permission to access the object
                    break;
                  case "storage/canceled":
                    // User canceled the upload
                    break;

                  // ...

                  case "storage/unknown":
                    // Unknown error occurred, inspect error.serverResponse
                    break;
                }
              },
              async () => {
                uploadTask.snapshot.ref
                  .getDownloadURL()
                  .then(async (downloadURL) => {
                    await db
                      .collection("benchmarks")
                      .doc(this.benchmark.uid)
                      .collection("subjects")
                      .doc(docRef.id)
                      .update({
                        src: downloadURL,
                        thumbnail: downloadURL,
                      });

                    return resolve();
                  });
              }
            );
          } catch (err) {
            return reject(err);
          }
        })();
      });
    },
  },
};
</script>
<style scoped lang="scss">
input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  border: 2px solid red;
}
</style>
