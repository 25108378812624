<template>
  <div class="workspace-wrapper">
    <div class="left">
      <div class="logo">
        <img src="@/assets/logo-dark.svg" width="50" alt="" />
        <span><b>brainsight</b></span>
      </div>
      <div>
        <h1>Thanks for subscribing! &#127881;</h1>
        <p class="subtitle">Let's create your workspace.</p>
        <div class="form">
          <v-text-field
            variant="solo"
            flat
            label="Workspace name"
            bg-color="white"
            type="text"
            hide-details="true"
            class="mb-5"
            rounded
            v-model="name"
            prepend-inner-icon="mdi-domain"
          ></v-text-field>
          <v-select
            :items="items"
            label="How did you find us?"
            rounded
            variant="solo"
            flat
            bg-color="white"
            v-model="find"
            hide-details=""
            prepend-inner-icon="mdi-magnify"
            :menu-props="{ rounded: true, offsetY: true }"
          ></v-select>
          <v-btn
            class="mt-5"
            @click="createWorkspace"
            block
            rounded
            elevation="0"
            height="56"
            color="success"
            :disabled="disabled || !subscription.active"
            >Create workspace</v-btn
          >
        </div>
      </div>
      <div class="terms"></div>
    </div>
    <div class="right"></div>
  </div>
</template>

<script>
import { db, auth } from "@/firebase";
import { mapState } from "vuex";

export default {
  name: "WorkSpace",
  data() {
    return {
      checkbox: false,
      disabled: true,
      find: "",
      items: [
        "Advertisement",
        "Google/search",
        "Invited",
        "Linkedin/Facebook",
        "Referred by friend / colleague",
        "Other",
      ],
      name: "",
      subscriptions: [],
      subscription_tier: "",
      subscription_active: false,
    };
  },
  watch: {
    name() {
      if (this.name != "") {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      users: (state) => state.users.users,
      workspace: (state) => state.workspace.workspace,
      heatmaps: (state) => state.heatmaps.heatmaps,
      projects: (state) => state.projects.projects,
      tags: (state) => state.heatmaps.tags,
      defaults: (state) => state.defaults.defaults,
      subscription: (state) => state.subscription.subscription,
      reports: (state) => state.reports.reports,
    }),
  },
  async mounted() {
    const u = await this.$store.dispatch("fetchUserProfile", auth.currentUser);
    console.log(u);
    if (this.userProfile.workspace && this.userProfile.workspace != "") {
      this.existingWorkspace();
    }
  },
  methods: {
    createWorkspace() {
      const vm = this;
      db.collection("workspaces")
        .add({
          name: this.name,
          admin: auth.currentUser.uid,
          members: [auth.currentUser.uid],
          seats: this.defaults[this.subscription.tier].user_seats - 1,
          find: vm.find,
          tags: [],
          imageCredits: this.defaults[this.subscription.tier].images,
          videoCredits: this.defaults[this.subscription.tier].videos,
          imageHeatmaps: 0,
          videoHeatmaps: 0,
          invites: [],
        })
        .then((docRef) => {
          var workspaceRef = db.collection("workspaces").doc(docRef.id);

          workspaceRef
            .update({
              uid: docRef.id,
            })
            .then(() => {
              var userRef = db.collection("users").doc(auth.currentUser.uid);

              userRef
                .update({
                  workspace: docRef.id,
                  role: "admin",
                })
                .then(() => {
                  console.log("User updated to admin!");
                  vm.$store.dispatch("fetchUserProfile", auth.currentUser);
                })
                .then(() => {
                  vm.$router.push("/dashboard");
                })
                .catch((error) => {
                  console.error("Error updating document: ", error);
                });
            })
            .then(() => {
              db.collection("heatmaps")
                .where("requested_by", "==", auth.currentUser.uid)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    db.collection("heatmaps")
                      .doc(doc.id)
                      .update({
                        workspace: docRef.id,
                      })
                      .then(() => {
                        console.log("Updated workspace field on heatmap");
                      })
                      .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                      });
                  });
                })
                .catch((error) => {
                  console.log("Error getting documents: ", error);
                });

              db.collection("projects")
                .where("creator_uid", "==", auth.currentUser.uid)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    db.collection("projects")
                      .doc(doc.id)
                      .update({
                        workspace: docRef.id,
                      })
                      .then(() => {
                        console.log("Updated workspace field on heatmap");
                      })
                      .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                      });
                  });
                })
                .catch((error) => {
                  console.log("Error getting documents: ", error);
                });

              db.collection("reports")
                .where("created_by_uid", "==", auth.currentUser.uid)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const heatmaps = doc.data().heatmaps;
                    heatmaps.forEach((h) => {
                      db.collection("heatmaps")
                        .doc(h)
                        .update({
                          workspace: docRef.id,
                        })
                        .then(() => {
                          console.log("Updated workspace field on heatmap");
                        })
                        .catch((error) => {
                          // The document probably doesn't exist.
                          console.error("Error updating document: ", error);
                        });
                    });
                    db.collection("reports")
                      .doc(doc.id)
                      .update({
                        workspace: docRef.id,
                      })
                      .then(() => {
                        console.log("Updated workspace field on heatmap");
                      })
                      .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                      });
                  });
                })
                .catch((error) => {
                  console.log("Error getting documents: ", error);
                });
            })
            .then(() => {
              db.collection("mail")
                .add({
                  to: auth.currentUser.email,
                  from: "Brainsight <info@brainsight.app>",
                  template: {
                    name: "subscription",
                    data: {
                      images: vm.defaults[vm.subscription.tier].images,
                      videos: vm.defaults[vm.subscription.tier].videos,
                      tier: vm.subscription.tier,
                      first: vm.userProfile.firstName,
                    },
                  },
                })
                .then(function () {
                  console.log("Subscription email send to user");
                })
                .catch(function (error) {
                  console.error("Error adding document: ", error);
                });
            })
            .catch((error) => {
              console.error("Error updating document: ", error);
            });
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    existingWorkspace() {
      const vm = this;
      db.collection("workspaces")
        .doc(vm.userProfile.workspace)
        .update({
          beta: false,
          imageCredits: vm.defaults[vm.subscription.tier].images,
          videoCredits: vm.defaults[vm.subscription.tier].videos,
          seats:
            vm.defaults[vm.subscription.tier].user_seats -
            vm.workspace.members.length,
        })
        .then(() => {
          db.collection("users")
            .doc(auth.currentUser.uid)
            .update({
              role: "admin",
            })
            .catch((error) => {
              console.error("Error updating document: ", error);
            });
          if (vm.workspace.admin != auth.currentUser.uid) {
            db.collection("users")
              .doc(vm.workspace.admin)
              .update({
                role: "member",
              })
              .catch((error) => {
                console.error("Error updating document: ", error);
              });
          }

          db.collection("workspaces")
            .doc(vm.userProfile.workspace)
            .update({
              admin: auth.currentUser.uid,
            })
            .catch((error) => {
              console.error("Error updating document: ", error);
            });
        })
        .then(() => {
          db.collection("mail")
            .add({
              to: auth.currentUser.email,
              from: "Brainsight <info@brainsight.app>",
              template: {
                name: "subscription",
                data: {
                  images: vm.defaults[vm.subscription.tier].images,
                  videos: vm.defaults[vm.subscription.tier].videos,
                  tier: vm.subscription.tier,
                  first: vm.userProfile.firstName,
                },
              },
            })
            .then(function () {
              console.log("Subscription email send to user");
            })
            .catch(function (error) {
              console.error("Error adding document: ", error);
            });
        })
        .then(() => {
          console.log("Joined with exisiting workspace!");
          vm.$store.dispatch("fetchUsers");
          vm.$store.dispatch("fetchWorkspace");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.workspace-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  color: #282447;

  .left {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    width: 50%;
    max-width: 500px;

    .logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        margin-bottom: 5px;
      }
    }
    .subtitle {
      font-size: 14pt;
      margin-bottom: 30px;
    }

    .v-btn {
      text-transform: none;
      font-weight: 600;
      font-size: 12pt;
    }
  }

  .right {
    background-color: #282447;
  }
}
</style>
