<template>
  <v-card flat tile class="mx-auto my-10 warning--text" max-width="1000">
    <div class="summary pa-10">
      <div class="left">
        <h1>Report Title here</h1>
        <p style="font-size: 14pt">Display Ads Impact Report</p>
        <p class="mb-0">Created by: Max van Kaathoven</p>
        <p>01-02-2022</p>
      </div>
      <div class="right">
        <v-btn color="success" elevation="0"
          ><v-icon small class="mr-1">mdi-share</v-icon> Share</v-btn
        >
        <v-btn class="ml-2" elevation="0"
          ><v-icon class="mr-1" small>mdi-download</v-icon> Download</v-btn
        >
      </div>
    </div>
    <v-divider></v-divider>
    <div class="overall pa-10">
      <h2 class="mb-5">Overall winners</h2>
      <v-card class="grey lighten-4 mb-3" elevation="0">
        <div class="winner pa-6">
          <v-avatar color="primary" size="62" class="mr-2">
            <span class="white--text text-h6">62%</span>
          </v-avatar>
          <div class="left">
            <p class="mb-0">Attention Score:</p>
            <p class="font-weight-medium mb-0" style="font-size: 15pt">
              Title of the uploaded ad
            </p>
          </div>

          <img src="./6.jpg" alt="" />
        </div>
        <v-divider v-if="show"></v-divider>

        <v-expand-transition>
          <div v-show="show">
            <v-card-text class="px-6">
              The ad with the highest benchmarked attention score has the
              highest chance of being seen by any user on any website that this
              ad is placed.
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>

      <v-card class="grey lighten-4 mb-3" elevation="0">
        <div class="winner pa-6">
          <v-avatar color="primary" size="62" class="mr-2">
            <span class="white--text text-h6">62%</span>
          </v-avatar>
          <div class="left">
            <p class="mb-0">Clarity Score:</p>
            <p class="font-weight-medium mb-0" style="font-size: 15pt">
              Title of the uploaded ad
            </p>
          </div>

          <img src="./6.jpg" alt="" />
        </div>
        <v-divider v-if="show"></v-divider>

        <v-expand-transition>
          <div v-show="show">
            <v-card-text class="px-6">
              The ad with the highest benchmarked attention score has the
              highest chance of being seen by any user on any website that this
              ad is placed.
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>

      <v-card class="grey lighten-4 mb-3" elevation="0">
        <div class="winner pa-6">
          <v-avatar color="primary" size="62" class="mr-2" icon>
            <v-icon class="white--text">mdi-trophy-award</v-icon>
          </v-avatar>
          <div class="left">
            <p class="mb-0">Combined winner:</p>
            <p class="font-weight-medium mb-0" style="font-size: 15pt">
              Title of the uploaded ad
            </p>
          </div>

          <img src="./6.jpg" alt="" />
        </div>
        <v-divider v-if="show"></v-divider>

        <v-expand-transition>
          <div v-show="show">
            <v-card-text class="px-6">
              The ad with the highest benchmarked attention score has the
              highest chance of being seen by any user on any website that this
              ad is placed.
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>
    </div>

    <v-divider></v-divider>

    <div class="ads pa-10">
      <h2 class="mb-5">Results per ad:</h2>

      <div class="winner ad px-6 text-center">
        <p>Attention:</p>
        <p>Clarity:</p>
        <p>Ad:</p>
      </div>
      <v-card class="grey lighten-4 mb-3" elevation="0">
        <div class="winner ad pa-6 text-center">
          <p style="font-size: 18pt" class="font-weight-bold mb-0">65%</p>
          <p style="font-size: 18pt" class="font-weight-bold mb-0">65%</p>
          <img src="./6.jpg" alt="" />
        </div>
      </v-card>
      <v-card class="grey lighten-4 mb-3" elevation="0">
        <div class="winner ad pa-6 text-center">
          <p style="font-size: 18pt" class="font-weight-bold mb-0">65%</p>
          <p style="font-size: 18pt" class="font-weight-bold mb-0">65%</p>
          <img src="./6.jpg" alt="" />
        </div>
      </v-card>
      <v-card class="grey lighten-4 mb-3" elevation="0">
        <div class="winner ad pa-6 text-center">
          <p style="font-size: 18pt" class="font-weight-bold mb-0">65%</p>
          <p style="font-size: 18pt" class="font-weight-bold mb-0">65%</p>
          <img src="./6.jpg" alt="" />
        </div>
      </v-card>
      <v-btn elevation="0" width="100" class="mx-auto" color="success"
        >View all</v-btn
      >
    </div>

    <v-divider></v-divider>

    <div class="temps pa-10">
      <h2 class="mb-5">Results per template:</h2>
    </div>

    <v-divider></v-divider>

    <div class="about pa-10">
      <h2 class="mb-5">About this report:</h2>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ReportsDisplay',
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
