<template>
  <div>
    <v-dialog :model-value="dialogProp" persistent width="400px">
      <v-card>
        <template v-slot:loader>
          <v-progress-linear
            color="accent"
            height="5"
            indeterminate
          ></v-progress-linear>
        </template>

        <div class="bg-accent pa-4 white--text">
          <v-icon color="white" class="mr-3">mdi-account-multiple</v-icon
          ><span class="text-h5">Invite members</span>
        </div>
        <v-window v-model="step">
          <v-window-item :value="1">
            <v-card-text>
              <div v-for="(invite, index) in invites" :key="index">
                <v-text-field
                  class="mb-2"
                  hide-details=""
                  label="Email"
                  clearable
                  variant="solo"
                  flat
                  bg-color="smoke"
                  @click="invite"
                  v-model="invite.email"
                ></v-text-field>
              </div>
              <v-btn @click="addOne" variant="text">+ Add another</v-btn>
            </v-card-text>
          </v-window-item>

          <v-window-item :value="2">
            <div class="pa-4 text-center">
              <v-img
                class="mb-6"
                contain
                height="128"
                :src="require('@/assets/sent.svg')"
              ></v-img>
              <div style="width: 50%; margin: auto">
                <h3 class="text-h6 font-weight-light mb-2">
                  Invites are on their way!
                </h3>
              </div>
            </div>
          </v-window-item>
        </v-window>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            variant="text"
            @click="
              invites = [{ email: '' }];
              $emit('dialog', false);
              step = 1;
            "
          >
            Close
          </v-btn>
          <v-btn
            :loading="sending"
            v-if="step === 1"
            color="success"
            @click="invite"
            variant="flat"
          >
            Invite!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import moment from "moment";
import { db } from "@/firebase";
import { mapState } from "vuex";
import firebase from "firebase/";

export default {
  props: {
    upload: {
      type: String,
    },
    dialogProp: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      users: (state) => state.users.users,
      workspace: (state) => state.workspace.workspace,
      defaults: (state) => state.defaults.defaults,
    }),
    success() {
      if (this.copied) {
        return "Copied to clipboard";
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      invites: [{ email: "" }],
      sending: false,
      step: 1,
    };
  },
  methods: {
    addOne() {
      this.invites.push({ email: "" });
    },
    async invite() {
      this.sending = true;
      const group = this.invites;
      var exists = firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("checkExistingUser");

      await Promise.all(
        group.map(async (element) => {
          const res = await exists({ email: element.email });

          let link = "signup";
          if (res.data) {
            link = "login";
          }

          let base = window.location.origin.includes("ai.brainsight.app")
            ? "https://ai.brainsight.app"
            : "https://deepgaze-staging.web.app";

          const vm = this;
          const docRef = await db.collection("mail").add({
            to: element.email,
            from: "Brainsight <info@brainsight.app>",
            template: {
              name: "invite",
              data: {
                url: `${base}/${link}?workspace=${vm.userProfile.workspace}&workspaceName=${vm.workspace.name}`,
                invited_by: vm.userProfile.name,
                workspaceName: vm.workspace.name,
              },
            },
          });
          console.log(docRef.id);
          console.log("email sent to " + element.email);
          var workspace = db.collection("workspaces").doc(vm.workspace.uid);

          workspace.update({
            invites: firebase.firestore.FieldValue.arrayUnion(element.email),
          });
        })
      );

      this.sending = false;

      this.step = 2;
    },
  },
};
</script>

<style lang="scss" scoped></style>
