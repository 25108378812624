<template>
  <div class="success-container">
    <div class="lottie">
      <lottie
        :options="defaultOptions"
        :height="height"
        :width="width"
        v-on:animCreated="handleAnimation"
      />
    </div>
    <div class="content" v-show="!loading">
      <v-img
        contain
        width="50"
        :src="require('@/assets/logo-dark.svg')"
        class="mb-10"
      ></v-img>
      <h1 class="primary--text">Success!</h1>
      <p style="font-size: 14pt" class="warning--text">
        Your payment has been processed successfully.
      </p>
      <v-btn
        class="mt-3"
        color="success"
        style="text-transform: none"
        elevation="0"
        @click="next"
        >Continue</v-btn
      >
    </div>
  </div>
</template>

<script>
import * as animationData from "@/assets/celebration.json";
import { mapState } from "vuex";
export default {
  name: 'PaymentSuccess', 
  components: {
  },
  data() {
    return {
      animationSpeed: 1,
      defaultOptions: {
        animationData: animationData.default,
        autoplay: false,
        loop: false,
      },
      height: 0,
      loaded: false,
      width: 0,
    };
  },
  watch: {
    loading() {
      if (this.loading === false) {
        this.play();
      }
    },
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.users.userProfile,
      loading: (state) => state.loading,
      defaults: (state) => state.defaults.defaults,
      subscription: (state) => state.subscription.subscription,
    }),
  },
  methods: {
    next() {
      this.$router.push("/add-workspace");
    },
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    handleResize({ width, height }) {
      this.width = width;
      this.height = height;
    },
    stop: function () {
      this.anim.stop();
    },
    play: function () {
      this.anim.play();
    },
    pause: function () {
      this.anim.pause();
    },
    onSpeedChange: function () {
      this.anim.setSpeed(this.animationSpeed);
    },
  },
};
</script>

<style lang="scss" scoped>
.success-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: relative;
}
.lottie {
  position: absolute;
  height: 90vh;
  width: 90vw;
  top: 0;
  z-index: 1;
}
.content {
  text-align: center;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
