<template>
  <div class="d-flex flex-wrap pa-10" style="gap: 30px">
    <v-card outlined width="400">
      <div class="pa-4">
        <div class="ps-4 text-caption text-medium-emphasis">Users</div>

        <v-card-title class="pt-0 mt-n1 d-flex align-center">
          <div class="me-2">{{ users.all[users.all.length - 1] }}</div>

          <v-chip
            :color="color"
            :prepend-icon="`mdi-arrow-${users.all[users.all.length - 1] - users.all[users.all.length - 2] < 0 ? 'down' : 'up'}`"
            class="pe-1"
            size="x-small"
            label
          >
            <template v-slot:prepend>
              <v-icon size="10"></v-icon>
            </template>

            <span class="text-caption">{{
              users.all[users.all.length - 1] - users.all[users.all.length - 2]
            }}</span>
          </v-chip>
        </v-card-title>
      </div>

      <v-sparkline
        :color="color"
        :gradient="[`${color}E6`, `${color}33`, `${color}00`]"
        :model-value="users.all"
        height="50"
        line-width="1"
        min="0"
        padding="0"
        fill
        smooth
      ></v-sparkline>
    </v-card>

    <v-card outlined width="400">
      <div class="pa-4">
        <div class="ps-4 text-caption text-medium-emphasis">MRR</div>

        <v-card-title class="pt-0 mt-n1 d-flex align-center">
          <div class="me-2">€ {{ mrr[mrr.length - 1] }}</div>

          <v-chip
            :color="color"
            :prepend-icon="`mdi-arrow-${mrr[mrr.length - 1] - mrr[mrr.length - 2] < 0 ? 'down' : 'up'}`"
            class="pe-1"
            size="x-small"
            label
          >
            <template v-slot:prepend>
              <v-icon size="10"></v-icon>
            </template>

            <span class="text-caption"
              >€ {{ mrr[mrr.length - 1] - mrr[mrr.length - 2] }}</span
            >
          </v-chip>
        </v-card-title>
      </div>

      <v-sparkline
        :color="color"
        :gradient="[`${color}E6`, `${color}33`, `${color}00`]"
        :model-value="mrr"
        height="50"
        line-width="1"
        min="0"
        padding="0"
        fill
        smooth
      ></v-sparkline>
    </v-card>

    <v-card outlined width="400">
      <div class="pa-4">
        <div class="ps-4 text-caption text-medium-emphasis">Heatmaps</div>

        <v-card-title class="pt-0 mt-n1 d-flex align-center">
          <div class="me-2">{{ heatmaps.all[heatmaps.all.length - 1] }}</div>

          <v-chip
            :color="color"
            :prepend-icon="`mdi-arrow-${heatmaps.all[heatmaps.all.length - 1] - heatmaps.all[heatmaps.all.length - 2] < 0 ? 'down' : 'up'}`"
            class="pe-1"
            size="x-small"
            label
          >
            <template v-slot:prepend>
              <v-icon size="10"></v-icon>
            </template>

            <span class="text-caption">{{
              heatmaps.all[heatmaps.all.length - 1] -
              heatmaps.all[heatmaps.all.length - 2]
            }}</span>
          </v-chip>
        </v-card-title>
      </div>

      <v-sparkline
        :color="color"
        :gradient="[`${color}E6`, `${color}33`, `${color}00`]"
        :model-value="heatmaps.all"
        height="50"
        line-width="1"
        min="0"
        padding="0"
        fill
        smooth
      ></v-sparkline>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      stats: (state) => state.admin.stats,
    }),
    users() {
      let users = {
        all: [],
        paid: [],
        trial: [],
        freemium: [],
      };
      if (this.stats) {
        this.stats.map((s) => {
          users.freemium.push(s.users.freemium);
          users.trial.push(s.users.trial);
          users.paid.push(s.users.paid);
          users.all.push(s.users.paid + s.users.trial + s.users.freemium);
        });
      }

      return users;
    },
    mrr() {
      let mrr = [];
      if (this.stats) {
        this.stats.map((s) => mrr.push(s.mrr));
      }
      return mrr;
    },
    heatmaps() {
      let heatmaps = {
        image: [],
        video: [],
        all: [],
      };
      if (this.stats) {
        this.stats.map((s) => {
          heatmaps.image.push(s.heatmaps.image);
          heatmaps.video.push(s.heatmaps.video);
          heatmaps.all.push(s.heatmaps.image + s.heatmaps.video);
        });
      }
      return heatmaps;
    },
  },
  name: "SuperAdminMobile",
  components: {},
  data() {
    return {
      title: "Bandwidth Used",
      value: "1.01 TB",
      change: "-20.12%",
      color: "#6515dd",
      datas: [5, 2, 5, 9, 5, 10, 3, 5, 3, 7, 1, 8, 2, 9, 6],
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "./styles.scss";
</style>
